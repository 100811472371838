import Button from "components/Button";
import { FC } from "react";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";

interface Props {
    isYearly?: boolean;
    isCurrent?: boolean;
    isUpgrade?: boolean;
    isDowngrade?: boolean;
    id: string;
    type?: string;
    handleClick: () => void;
    isPayNow?: boolean;
    isRegFunnel?: boolean;
    isTrial?: boolean
}
const btnShadow = "!shadow-[2px_5px_30px_-4px_rgba(28,_163,_147,_0.15)]"

const StartDollarTrial: FC<Props> = ({ isYearly, isTrial, id, handleClick, isPayNow }) => {
    const btnTitle = isPayNow ? "Pay Now" : `Start 1$ Trial ${isYearly ? "Now" : ""}`
    const stnBtnStyles = `mb-4 w-full max-w-[246px] ${btnShadow} ${isTrial ? "" : isYearly ? "bg-darkBlue !border-darkBlue text-smoothGreen hover:!border-smoothGreen dark:text-smoothGreen" : "dark:!text-darkBlue"}`
    const stnTitleStyles = `!text-base ${isTrial ? "font-medium" : "!font-bold"} ${isYearly && !isTrial ? " group-hover:text-darkBlue" : ""}`
    const height = "h-[56px]"

    return (
        <Button title={btnTitle} titleClassName={stnTitleStyles} height={height} className={stnBtnStyles} color={isTrial ? "primaryPurple" : "smoothGreen"} handleClick={handleClick} />
    )
}
const ShopifyPlanTrialButton: FC<Props> = (props) => {
    const { isUpgrade, isCurrent, isDowngrade, isTrial, isYearly } = props
    const changePlanUrl = PATH.shopify.upgrade;
    const isChangeNow = isTrial && !isCurrent
    const isCurrentTrial = isTrial && isCurrent
    const upgradeNowStyles = {
        className: `mb-4 max-w-[248px] shadow-greenShadow`,
        titleClassName: "!text-base !font-bold dark:text-darkBlue group-hover:text-white ",
        height: "h-[56px]",
        color: "smoothGreen" as "smoothGreen",
        title: "Upgrade Now",
        isLink: true,
    }
    return isCurrentTrial ?
        <Button
            title="Subscribe Now!"
            className="mb-4 max-w-[248px] bg-darkBlue hover:bg-smoothGreen hover:border-smoothGreen dark:!border-darkBlue dark:hover:!border-smoothGreen dark:hover:bg-smoothGreen"
            titleClassName="text-smoothGreen !text-base !font-bold group-hover:text-darkBlue"
            height="h-[56px]"
            isLink
            redirect={changePlanUrl + "?isCurrentTrial=true"}
        />
        : isCurrent ?
            <div className="h-[56px] max-w-[248px] justify-between items-between flex-col flex w-full mx-auto mb-4">
                <div className="h-[2px]" />
                <p className={`text-sm text-electricBlue text-center `}>This is your Current Plan</p>
                <div className="h-[2px] w-full bg-secondary rounded-full" />
            </div>
            : isChangeNow ?
                <Button title="Change" isLink redirect={changePlanUrl}
                    className={`${isYearly ? "my-5" : "my-3"} max-w-[248px] border-smoothGreen !shadow-[2px_5px_30px_-4px_rgba(28,_163,_147,_0.15)] dark:!border-smoothGreen dark:hover:!border-primaryPurple dark:hover:!bg-primaryPurple`}
                    titleClassName="!text-base dark:text-darkBlue dark:group-hover:text-white" height="h-[56px]" /> :
                isDowngrade ? <Link to={changePlanUrl}
                    className="flex items-center justify-center h-[56px] mb-4 font-medium cursor-pointer hover:bg-primaryPurple w-full max-w-[248px] rounded-full hover:text-white">Downgrade</Link>
                    : isUpgrade ? <Button {...upgradeNowStyles} redirect={changePlanUrl} /> : <StartDollarTrial {...props} />
}

export default ShopifyPlanTrialButton;