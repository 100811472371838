import HoveredIcon from "components/HoveredIcon";
import ProductNameCell from "components/ProductNameCell";
import {
  CURRENCY_SIGNS,
  formatCurrency,
  formatNumber
} from "utils/formatCurrency";

import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Box } from "assets/icons/boxes/box.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Watchers } from "assets/icons/eye.svg";
import { ReactComponent as GlobeFlag } from "assets/icons/globe-flag.svg";
import { ReactComponent as LineGraph } from "assets/icons/line-graph.svg";
import { ReactComponent as LocationIcon } from "assets/icons/location.svg";
import { ReactComponent as Shipping } from "assets/icons/shipping.svg";
import { ReactComponent as Star } from "assets/icons/star.svg";
import CutLongTitle from "components/CutLongTitle";
import IconFinderBox from "components/IconFinderBox";
import Tooltip from "components/Tooltip";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { CURRENCY } from "types/currency";
import { CatResList } from "types/services/catRes";
import { IconFinderItem } from "types/services/iconFinder";
import { getCountryFlag } from "utils/getCountryFlag";
import { makeQueryParams } from "utils/makeQueryParams";

export const catResCols = (
  ebayLink: string,
  onBoxClick: (item: IconFinderItem) => void,
  location: string,
  currency: CURRENCY_SIGNS
): ColumnDef<CatResList>[] => [
    {
      header: () => <CompetitorResearch className="fill-darkBlue dark:fill-grey100 w-5.5 h-5.5" />,
      accessorKey: "countryCode",
      enableSorting: false,
      minSize: 50,
      cell: ({ row: { original } }) =>
        original.countryCode ? (
          <div>
            <img
              src={getCountryFlag(original.countryCode)}
              alt={original.countryCode}
              className="w-[18px] h-[18px]"
              data-tooltip-id={original.itemId + "001"}
            />
            <Tooltip
              title={original.countryName}
              anchorId={original.itemId + "001"}
              place="top"
            />
          </div>
        ) : (
          <GlobeFlag className="fill-darkBlue dark:fill-grey100 w-5 h-5" />
        ),
      meta: {
        hideBorder: true,
      },
    },
    {
      header: "Seller",
      accessorKey: "itemId",
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <Link
          className="cursor-pointer hover:text-primaryPurple dark:hover:text-purple500 text-left"
          to={`${ebayLink}/usr/${original.seller}`}
          target="_blank"
          rel="noreferrer"
        >
          <CutLongTitle
            titleClassName="cursor-pointer hover:text-primaryPurple dark:hover:text-purple500"
            title={original.seller}
            length={15}
          />
        </Link>
      ),
      meta: {
        hideBorder: true,
        align: "justify-start",
        tooltipTitle: "The username of the seller",
      },
    },
    {
      header: "",
      minSize: 50,
      accessorKey: "seller",
      cell: (seller) => (
        <Link
          to={PATH.competitorResearch.ebay + `?search=${seller.getValue()}`}
          target="_blank"
          rel="noreferrer"
        >
          <HoveredIcon
            Icon={CompetitorResearch}
            iconClassName="w-[1.3125rem] h-[1.1875rem]"
            className="w-[2.0625rem] h-[2rem]"
            title="Analyze Competitor"
          />
        </Link>
      ),
    },
    {
      header: () => <Star className="fill-darkBlue dark:fill-grey100" />,
      accessorKey: "feedback",
      cell: (feedback) => formatNumber(String(feedback.getValue())),
      meta: {
        tooltipTitle: "Feedback score"
      }
    },
    {
      header: () => <Shipping className="fill-darkBlue dark:fill-grey100" />,
      accessorKey: "numberOfSales",
      cell: (sales) => formatNumber(String(sales.getValue())),
      meta: {
        tooltipTitle: "Number of sales"
      }
    },
    {
      header: "Product Name",
      accessorKey: "ebayTitle",
      cell: ({ row: { original } }) => (
        <div>
          <ProductNameCell
            name={original.ebayTitle}
            img={original.ebayImageUrl}
            url={original.ebayUrl}
            isDropshipper={
              original.isAliDropship ||
              original.isAmzDropshipUK ||
              original.isWalmartDropship ||
              original.isAmzUSDropship
            }
            checkKeywords
          />
        </div>
      ),
      meta: {
        align: "justify-start",
        alignHead: "justify-between"
      },
    },
    {
      header: () => <Shipping className="fill-darkBlue dark:fill-grey100" />,
      accessorKey: "mpn",
      cell: ({ row: { original } }) => formatNumber(original.numberOfSales),
      meta: {
        sortColumnId: "numberOfSales",
      },
    },
    {
      header: () => <Watchers className="fill-darkBlue dark:fill-grey100" />,
      accessorKey: "numberOfWatch",
      cell: (watchCount) => formatNumber(String(watchCount.getValue())),
      meta: {
        tooltipTitle: "Watch",
      },
    },
    {
      header: "Price",
      accessorKey: "ebayPrice",
      cell: (val) =>
        formatCurrency(String(val.getValue()), CURRENCY.none, currency),
      meta: {
      }
    },
    {
      header: () => <LocationIcon className="fill-darkBlue dark:fill-grey100" />,
      enableSorting: false,
      minSize: 50,
      accessorKey: "location",
      cell: ({
        row: {
          original: { itemId, location: loc },
        },
      }) => (
        <div data-tooltip-id={itemId.toString()}>
          <Tooltip title={loc} anchorId={itemId} place="top" />
          {location !== "Worldwide" ? (
            <img
              src={getCountryFlag(location)}
              alt={location}
              className="w-[18px] h-[18px]"
            />
          ) : (
            <GlobeFlag className="fill-darkBlue dark:fill-grey100 w-5 h-5" />
          )}
        </div>
      ),
      meta: {
        alignHead: "justify-center"
      }
    },
    {
      header: () => <Box className="fill-darkBlue dark:fill-grey100" />,
      enableSorting: false,
      minSize: 50,
      meta: {
        tooltipTitle:
          "Use the item finder to locate products from your chosen supplier. To switch suppliers, go to Quick Settings on the left-side menu",
        alignHead: "justify-center"
      },
      accessorKey: "isAliDropship",
      cell: ({ row: { original } }) => (
        <IconFinderBox
          openIconFinder={onBoxClick}
          isGreen={original.isGreen}
          isSimilar={original.isSimilar}
          sales={Number(original.numberOfSales)}
          currentPrice={original.ebayPrice}
          title={original.ebayTitle}
          itemID={original.itemId}
          images={original.ebayImageUrl}
          productUrl={original.ebayUrl}
        />
      ),
    },
    {
      header: () => <LineGraph className="fill-darkBlue dark:fill-grey100" />,
      minSize: 50,
      enableSorting: false,
      accessorKey: "isAmzDropshipUK",
      cell: ({
        row: {
          original: { ebayTitle },
        },
      }) => (
        <Link
          to={makeQueryParams(PATH.productResearch.ebay, { search: encodeURIComponent(ebayTitle) })}
          target="_blank"
          rel="noreferrer"
        >
          <HoveredIcon Icon={LineGraph} title="Analyze Product" />
        </Link>
      ),
      meta: {
        alignHead: "justify-center"
      }
    },
  ];
