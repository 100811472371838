import { createApi } from "@reduxjs/toolkit/query/react";
import {
  GetEbayPECategoriesRes,
  GetEbayProductExplorerReq,
  GetEbayProductExplorerRes,
} from "types/services/ebayProductExplorer";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";

export const ebayProductExplorerApi = createApi({
  reducerPath: "ebayProductExplorer",
  baseQuery,
  endpoints: (builder) => ({
    getEbayPEList: builder.query<
      GetEbayProductExplorerRes,
      GetEbayProductExplorerReq
    >({
      query: (body) => ({
        url: queryKeys.ebayProdExplor.get,
        method: "POST",
        body,
      }),
    }),
    getEbayPECats: builder.query<GetEbayPECategoriesRes, string>({
      query: (type) => ({
        url: queryKeys.ebayProdExplor.categories,
        method: "GET",
        params: { type },
      }),
    }),
  }),
});

export const { useLazyGetEbayPEListQuery, useGetEbayPECatsQuery } =
  ebayProductExplorerApi;
