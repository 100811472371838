import {
  conditionList,
  listingsList,
  marketPlaces,
  saleDateRangeArr,
  shippingLocations,
} from "assets/data/filters";

export const prEbayFilters = {
  eBaySite: marketPlaces[0].value,
  searchedEBaySite: marketPlaces[0].value,
  saleDateRange: saleDateRangeArr[0].value,
  shippingLocation: shippingLocations[0].value,
  listingType: listingsList[0].value,
  excludePhrase: "",
  condition: conditionList[0].value,
  ebaySearchedPeriod: "30",
  price: {
    min: null,
    max: null,
  },
  feedback: {
    min: null,
    max: null,
  },
};

export const prAliFilters = {
  price: {
    min: null,
    max: null,
  },
  feedback: {
    min: null,
    max: null,
  },
  rating: {
    min: null,
    max: null,
  },
  reviews: {
    min: null,
    max: null,
  },
  sales: {
    min: null,
    max: null,
  },
};

export const prEbayData = {
  totalEarnings: 0,
  totalListings: 0,
  totalSales: 0,
  succcessfulListingsPercentage: 0,
  sellThrough: 0,
  averageProductPrice: 0,
  currency: "$",
  site: ".com",
  message: "",
  isKeywordAdded: false,
  maxValueKeyWord: 0,
  topTenSellingTitles: [],
  pieData: [],
  sellers: [],
  longTailKeywords: [],
  genericSingleKeywords: [],
  priceRangeGraphOne: [],
  priceRangeGraphTwo: [],
  lineGraph: [],
};

export const prAliData = {
  items: [],
  sellthrough: 0,
  totalListings: 0,
  successfulItems: 0,
  totalSales: 0,
  totalRevenue: 0,
  averagePrice: 0,
  isKeywordAdded: false,
  sevenDays: 0,
  message: "",
  fourteenDays: 0,
  month: 0,
  threeMonth: 0,
  mapData: [],
  chartData: [],
  donutData: [],
};
