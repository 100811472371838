import { useState } from "react";

const useFilters = <T>(initialState: T) => {
  const [filters, setFilters] = useState(initialState);

  const handleFilterChange = (
    newVal: string | number | Date | null | any,
    name: keyof typeof filters,
    type?: "min" | "max"
  ) => {
    setFilters((prev) => ({
      ...prev,
      [name]:
        typeof prev[name] === "object" && !Array.isArray(prev[name])
          ? {
              ...prev[name],
              [type || "min"]: newVal,
            }
          : newVal,
    }));
  };

  return {
    filters,
    handleFilterChange,
    setFilters,
  };
};

export default useFilters;
