import HoveredIcon from "components/HoveredIcon";
import { ChangeEvent, FC, useEffect, useId, useLayoutEffect, useState } from "react";

import { ReactComponent as Cancel } from "assets/icons/clear-input.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as Save } from "assets/icons/save.svg";
import Tooltip from "components/Tooltip";
import { useAppDispatch } from "hooks/useAppDispatch";
import useHover from "hooks/useHover";
import useOutsideClick from "hooks/useOutsideClick";
import { toast } from "react-toastify";
import { crApi } from "store/api/crApi";
import { useEbayCrCommentUpdateMutation } from "store/api/watchListApi";
import { newCutLongTitle } from "utils/cutLongTitle";

interface Props {
    comment: string;
    searchedUserId: number;
    className?: string;
    title?: string;
}

const iconStlyes = {
    width: "w-5",
    height: "h-5",
}

const EbayCrEditComment: FC<Props> = ({
    className,
    comment,
    searchedUserId,
    title
}) => {
    const [updatedComment, setUpdatedComment] = useState(comment);
    const [hoverRef, isHovered] = useHover<HTMLDivElement>()
    const [isEdit, setIsEdit] = useState(false);
    const [initialWidth, setInitialWidth] = useState(0);
    const dispatch = useAppDispatch();
    const [updateComment, { isLoading }] = useEbayCrCommentUpdateMutation();
    const [txt, setTxt] = useState(comment);
    const id = useId();
    const handleChange = (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) => setTxt(e.target.value);
    const onEdit = () => setIsEdit(true);
    const onCancel = () => {
        setIsEdit(false);
        setTxt(updatedComment);
    };
    const onSave = () => {
        updateComment({ searchedUserId, comment: txt }).unwrap()
            .then(() => {
                toast.success("Competitor comment updated successfully"); setIsEdit(false); setUpdatedComment(txt);
                dispatch(crApi.util.invalidateTags([{ type: "EbayCrHistory" }]));
            })
            .catch((err) => toast.error(err.data === "not found" ? "Domain not found" : "Something went wrong"));

    }

    const updateWidth = () => {
        if (ref.current) {
            setInitialWidth(ref.current.offsetWidth)
        }
    }
    useLayoutEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    useEffect(() => {
        updateWidth();
    }, [])

    const [ref] = useOutsideClick<HTMLDivElement>(onSave);

    return (
        <div ref={isEdit ? ref : null} className="w-full">
            <div
                data-tooltip-id={id}
                className={`flex justify-between relative rounded-[10px] py-2 max-h-[64px] border-2 border-solid dark:bg-deepSpace900
            ${isEdit ? `dark:border-purple500 border-primaryPurple bg-neutral2` : "cursor-pointer bg-secondary dark:hover:border-purple500 hover:border-primaryPurple dark:border-deepSpace900 border-secondary"} ${className || ""}`}
                onClick={() => {
                    if (!isEdit) {
                        setIsEdit(true)
                    }
                }}
                ref={hoverRef}
            >
                {isEdit ? (
                    <textarea
                        value={txt?.toString()}
                        onChange={handleChange}
                        maxLength={250}
                        className={`text-xs w-full block overflow-hidden pl-2 font-medium white-scrollbar resize-none bg-neutral2 dark:bg-deepSpace900 overflow-y-auto h-[48px] dark:text-grey100 rounded-[5px]`}
                    />
                ) : (
                    <p className={`pl-2 font-medium break-all ${txt.length > 48 && !isEdit ? "overflow-y-scroll overflow-x-hidden" : ""}`}>{newCutLongTitle(txt, 100)}</p>
                )}

                {isEdit ? (
                    <div className="flex flex-col items-center gap-1 mr-1.5">
                        <HoveredIcon
                            Icon={Cancel}
                            handleClick={onCancel}
                            title="Cancel"
                            iconClassName="w-2 h-2"
                            className="!border-0"
                            iconColor="fill-darkBlue dark:fill-purple500 dark:group-hover:fill-grey100"
                            {...iconStlyes}
                        />
                        <HoveredIcon
                            Icon={Save}
                            handleClick={onSave}
                            title="Save"
                            isLoading={isLoading}
                            iconClassName="w-[13.33px] h-[7.5px]"
                            iconColor="fill-darkBlue dark:fill-purple500 dark:group-hover:fill-grey100"
                            className="!border-0"
                            {...iconStlyes}
                        />
                    </div>
                ) : (
                    <div>
                        <HoveredIcon
                            Icon={Edit}
                            handleClick={onEdit}
                            title="Edit"
                            iconClassName="w-[9.93px] h-[10.37px]"
                            iconColor={isHovered ? "fill-primaryPurple" : ""}
                            {...iconStlyes}
                            className=" mr-1.5"
                        />
                    </div>
                )}
                {title && <Tooltip anchorId={id} title={title} />}
            </div>
        </div>
    )
}
export default EbayCrEditComment;