import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import homeCn from '../public/locales/cn/home.json';
import homeDe from '../public/locales/de/home.json';
import homeEn from '../public/locales/en/home.json';
import homeEs from '../public/locales/es/home.json';
import homeFr from '../public/locales/fr/home.json';
import homeIt from '../public/locales/it/home.json';

const resources = {
    en: {
        home: homeEn,
    },
    cn: {
        home: homeCn,
    },
    de: {
        home: homeDe,
    },
    es: {
        home: homeEs,
    },
    it: {
        home: homeIt,
    },
    fr: {
        home: homeFr,
    }
}

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en',
        debug: false,
        fallbackLng: 'en',
        saveMissing: true
    });

export default i18next;