export enum PLAN_TYPE {
  PRO = "Pro",
  PRO_PLUS = "ProPlus",
  ENTERPRISE = "Enterprise",
}

export enum PLAN_NAME {
  ProMonthly = "StarterM",
  ProYearly = "StarterY",
  ProLifetime = "ProLT",
  ProPlusMonthly = "StandardM",
  ProPlusYearly = "StandardY",
  ProPlusLifetime = "ProPlusLT",
  EnterpriseMonthly = "EnterpriseM",
  EnterpriseYearly = "EnterpriseY",
  EnterpriseLifetime = "EnterpriseLT",
}

export interface PlansType {
  period: string;
  planList: PlanList[];
}

export interface TSelectedPlan {
  type: string;
  planName: string;
  planPrice: number;
  skip: boolean;
}

export interface ShopifyPlanList {
  type: string;
  name: string;
  id: string;
  skipAndSave: number;
  isDiscount: boolean;
  description: string;
  price: number;
  discountedPrice: number;
  isYearly: boolean;
}
export interface PlanList {
  type: string;
  planName: string;
  planId: string;
  isDiscount?: boolean;
  planDescription: string;
  planPrice: number;
  planDiscountPrice: number;
  upcampaignPrice?: number;
  highlight: boolean;
  skipAmout?: number;
}

export interface PlanComparisonItemType {
  icon: string;
  title: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  description: string;
  isNew?: boolean;
  pro?: boolean | string;
  proPlus?: boolean | string;
  enterprise?: boolean | string;
  monthly?: boolean | string;
  yearly?: boolean | string;
  iconClassName?: string;
}
