import Modal from "components/Modal";
import useModal from "hooks/useModal";
import { FC, useState } from "react";
import CampaignSettings from "./CampaignSettings";
import ChooseLanding from "./ChooseLanding";

interface Props {
  helpers: ReturnType<useModal>;
}

const AddCampaign: FC<Props> = ({ helpers }) => {
  const [chosenLanding, setChosenLanding] = useState("");

  const onChooseLanding = (newVal: string) => setChosenLanding(newVal);

  return (
    <Modal
      helpers={helpers}
      title={chosenLanding ? "Create New Campaign" : "Choose Landing Page"}
      maxWidth={
        chosenLanding ? "max-w-[580px]" : "max-w-[1225px] max-lg:max-w-[600px]"
      }
      titleClassName="pt-9 !mb-8"
      buttonCloseClassName={`${chosenLanding ? "right-2" : "right-[20px]"} top-[20px]`}
      handleCloseClick={() => onChooseLanding("")}
    >
      {chosenLanding ? (
        <CampaignSettings
          onBackClick={() => onChooseLanding("")}
          pageSection={chosenLanding}
          handleClose={helpers.close}
        />
      ) : (
        <ChooseLanding onSelect={onChooseLanding} />
      )}
    </Modal>
  );
};

export default AddCampaign;
