import { FC } from "react";


interface Props {
    className?: string;
    activePlans: "shopify" | "ebay";
    fromRegFunnel?: boolean;
    isDashboardLayout?: boolean;
    period?: string;
    pulsing?: boolean;
    showYearlyTxt?: boolean
}
const PlansDiscountTxt: FC<Props> = ({ className, isDashboardLayout, activePlans, period, fromRegFunnel = false, pulsing, showYearlyTxt }) => {
    return (
        <div className={`text-[24px] max-sm:text-[20px] text-center font-medium ${!showYearlyTxt && activePlans === "ebay" && period === "yearly" ? "hidden" : ""} ${className}`}>
            {activePlans === "ebay" && period === "yearly" && showYearlyTxt ? "Unlock Unlimited Market Insights with ZIK" : (
                <span>
                    Start Now with Up to <span className="text-[#24921A] font-bold">50% Off</span>
                </span>
            )}
        </div>



    )
}

export default PlansDiscountTxt;