import { shopifyPlanComparison, shopifyPlans } from "assets/data/plans/shopifyPlans"
import NextPrevSlider from "components/Plans/NextPrevSlider"
import PlansCardB from "components/Plans/PlanCardB"
import SwiperWrapper from "components/SwiperWrapper"
import { FC, useState } from "react"
import { SwiperSlide } from "swiper/react"
import { ShopifyPlanList, TSelectedPlan } from "types/plans"
import PlansDetailedB from "./PlansDetailedB"
import Trusted from "./Trusted"

interface Props {
    handleSelectPlan: (plan: TSelectedPlan) => void;
}
interface PlanProps extends Props {
    plan: ShopifyPlanList
}
const responsive = [
    {
        breakpoint: 700,
        settings: {
            slidesToShow: 1.7,
            slidesToScroll: 1,
        }
    },
    {
        breakpoint: 550,
        settings: {
            slidesToShow: 1.5,
            slidesToScroll: 1,
        }
    },
    {
        breakpoint: 490,
        settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
        }
    },
    {
        breakpoint: 390,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        }
    },
]


const ShopifyDetailedPlanWithCard: FC<PlanProps> = ({ plan, handleSelectPlan }) => {
    return (
        <div className="flex flex-col mb-10" >
            <PlansCardB
                wrapperClassName={` mx-auto ${plan.id === "monthly" ? "mt-12 mb-[190px] max-lg:mb-[154px] max-[700px]:mb-[201px] max-sm:mb-[274px]" : "max-lg:mb-[128px] mb-[168px] max-[700px]:mb-[175px] max-sm:mb-[248px]"}`}
                isMonthly={plan.id === "monthly"}
                discountPrice={plan.discountedPrice}
                handleSelectPlan={handleSelectPlan}
                key={plan.id}
                skipAmount={plan.skipAndSave}
                isShopify
                {...plan}
            />
            <PlansDetailedB
                wrapperClassName="mx-auto"
                {...plan}
                key={plan.id}
                skipAmount={plan.skipAndSave}
                handleSelectPlan={handleSelectPlan}
                features={shopifyPlanComparison.map((item) => ({
                    Icon: item.Icon,
                    title: item.title,
                    subtitle: item.description,
                    width: 14,
                    height: 14
                }))}
            />
        </div>
    )

}
const RegistrationFunnelBShopifyPlans: FC<Props> = ({ handleSelectPlan }) => {
    const [activeSlide, setActiveSlide] = useState(1);
    const onActiveSlideChange = (idx: number) => setActiveSlide(idx);

    return (
        <div className="w-full relative mt-8" >
            <div className="w-full min-[671px]:hidden">
                <SwiperWrapper
                    onActiveChange={onActiveSlideChange}
                >
                    {shopifyPlans.map((plan) => (
                        <SwiperSlide className="h-auto max-w-[310px]" content="fit" key={plan.id}>
                            <ShopifyDetailedPlanWithCard handleSelectPlan={handleSelectPlan} plan={plan} />
                        </SwiperSlide>
                    ))}
                </SwiperWrapper>
            </div>
            <div className="flex justify-between max-w-[668px] mx-auto max-[670px]:hidden w-full">
                {shopifyPlans.map((plan) => (
                    <ShopifyDetailedPlanWithCard handleSelectPlan={handleSelectPlan} plan={plan} key={plan.id} />
                ))}
            </div>
            <div className="w-full flex flex-col items-center absolute left-0 top-[353px] max-lg:top-[328px]">
                <NextPrevSlider isShopify wrapperStyles="w-full" activeSlide={activeSlide} />
                <Trusted />
            </div>
        </div >

    )
}

export default RegistrationFunnelBShopifyPlans