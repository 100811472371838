import Card from "components/Card";
import Search from "components/Search";
import usePagination from "hooks/usePagination";
import { useEffect, useState } from "react";

//icons
import { scannedSellersCols } from "assets/tableColumns/scannedSellers";
import Button from "components/Button";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import useDebaunceSearch from "hooks/useDebaunceSearch";
import useModal from "hooks/useModal";
import { SortDir as SortDirection } from "hooks/useSort";
import useTable from "hooks/useTable";
import { uniq } from "lodash";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  useDeleteScannedSellerMutation,
  useGetScannedSellersQuery
} from "store/api/bulkScannerApi";
import { BulkScannedSellerList } from "types/services/bulkScanner";
import AddMultiScanner from "../Modal/AddMultiScanner";
enum SortDir {
  asc = "Asc",
  desc = "Desc",
}

const initialSort = {
  direction: SortDir.desc,
  column: "UserSellerScanRequestId",
};

const tableStyles = {
  px: "px-0",
  style: "mb-6",
  head: { contHeight: "h-[37px]", rowHeight: "h-[55px]" },
  body: { contHeight: "h-[37px]", rowHeight: "h-[55px]" },
  empty: { contHeight: "h-[50px]", rowHeight: "h-[85px]" },
};

const ScannedSellers = () => {
  const [sort, setSort] = useState(initialSort);
  const [rowSelection, setRowSelection] = useState({});
  const { t } = useTranslation(['home']);
  const modal = useModal();
  const [pageCount, setPageCount] = useState(0);
  const pagination = usePagination(pageCount);
  const { delayedValue, immediateValue, onChange } = useDebaunceSearch();
  const { data, isFetching } = useGetScannedSellersQuery({
    search: delayedValue,
    userid: 0,
    page: pagination.current + 1,
    orderByValue: sort.column,
    orderBy: sort.direction,
  }, {
    refetchOnMountOrArgChange: true,
  });

  const [openedPages, setOpenedPages] = useState<BulkScannedSellerList[]>([])

  const [deleteItem, { isLoading: deleteLoading }] =
    useDeleteScannedSellerMutation();

  useEffect(() => {
    if (data?.result.length) {
      if (data.result[0].totalRows !== pageCount) {
        setPageCount(data.result[0].totalRows);
      }
      const newOpenedPages = openedPages;
      data.result.forEach((item) => {
        if (!newOpenedPages.some(page => page.userSellerScanRequestId === item.userSellerScanRequestId)) {
          newOpenedPages.push(item);
        }
      });

      setOpenedPages(newOpenedPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSort = (column: string) => {
    const dir =
      sort.column === column
        ? sort.direction === SortDir.desc
          ? SortDir.asc
          : SortDir.desc
        : SortDir.asc;

    setSort({
      column,
      direction: dir,
    });
    pagination.goto(0);
  };

  const onDeleteSuccess = (isSingleItem?: boolean) => {
    pagination.goto(0);
    toast.success(
      isSingleItem
        ? "Scanned Seller Deleted Successfully"
        : "Selected Scanned Sellers Deleted Successfully"
    );
    setRowSelection({});
  };
  const onDeleteFail = () => {
    toast.error("Something went wrong!");
  };

  const handleDelete = (id: string) => {
    deleteItem([id])
      .unwrap()
      .then(() => onDeleteSuccess(true))
      .catch(onDeleteFail);
  };

  const onDeleteSelected = () => {
    const currentPageItems = table
      .getSelectedRowModel()
      .flatRows.map((item) => String(item.original.userSellerScanRequestId));
    const itemsToDelete = uniq([...Object.keys(rowSelection), ...currentPageItems]);

    if (itemsToDelete.length < 1) {
      toast.error("No sellers have been selected for deletion.");
    } else {
      deleteItem(
        itemsToDelete
      )
        .unwrap()
        .then(onDeleteSuccess)
        .catch(onDeleteFail);
    }
  };
  const onScanClick = () => {
    const currentPageItems = table
      .getSelectedRowModel()
      .flatRows.map((item) => String(item.original.userSellerScanRequestId));

    const itemsToAdd = uniq([...Object.keys(rowSelection), ...currentPageItems]);

    if (itemsToAdd.length < 1) {
      toast.error("No sellers selected");
    } else if (itemsToAdd.length > 5) {
      toast.warning("Maximum 5 sellers allowed per scan");
      setRowSelection({});
    } else {
      modal.open();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getRowId = (row: any, relativeIndex: any, parent: any) =>
    // In row object you have access to data.
    // Yon choose parameter. In this example I used uniqueId
    parent ? [parent.id, row.userSellerScanRequestId].join(".") : row.userSellerScanRequestId

  const { table } = useTable({
    data: data?.result || [],
    columnDeps: [sort, pagination.current],
    enableRowSelection: true,
    rowSelection,
    onRowSelectionChange: setRowSelection,
    dataDeps: [data],
    columns: scannedSellersCols(
      handleDelete,
      onDeleteSelected,
      handleSort
    ),
    sort: true,
    getRowId
  });

  const getSelectedSellerNames = () => {
    const selectedIds = Object.keys(rowSelection).map(Number);
    const selectedData = (openedPages || []).filter(item => selectedIds.includes(item.userSellerScanRequestId));

    return selectedData.map(x => x.ebayUsername);
  }


  return (
    <>
      <Card className="p-4 xl:w-1/2 h-fit pt-8" shadow={false}>
        <div className="text-[1rem] font-bold mb-[33px] dark:text-grey100">{t('ScannedSellerListTitle')}</div>
        <div className="flex items-center gap-5 mb-9">
          <Search
            search={immediateValue}
            handleChange={(newVal) => {
              onChange(newVal);
              pagination.goto(0);
            }}
            size="sm"
            htmlFor="tableSearch"
            className=" flex justify-end"
          />
          <Button
            loading={false}
            height="h-9"
            className="max-w-[192px] w-full bg-darkBlue text-smoothGreen dark:text-paradiseBlue font-bold hover:bg-smoothGreen dark:hover:bg-paradiseBlue rhover:!text-darkBlue hover:dark:!text-lilac400 hover:border-smoothGreen dark:hover:border-paradiseBlue dark:bg-lilac400 dark:hover:!text-deepSpace900 dark:!text-deepSpace900"
            title="Add to Multi Scanner"
            handleClick={() => onScanClick()}
          />
        </div>

        <Table<BulkScannedSellerList>
          table={table}
          sort={{ ...sort, direction: sort.direction === "Asc" ? "asc" as SortDirection : "des" as SortDirection }}
          isLoading={isFetching || deleteLoading}
          styles={tableStyles}
        />
        <Pagination pagination={pagination} labeled />
        <AddMultiScanner
          helpers={modal}
          selectedUsernames={getSelectedSellerNames()}
        />
      </Card>
    </>
  );
};

export default ScannedSellers;
