import { createApi } from "@reduxjs/toolkit/query/react";
import {
  PostCatRes,
  PostCatResRequest,
  PostCatResResponse,
} from "types/services/catRes";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";

export const catResApi = createApi({
  reducerPath: "categoryResearch",
  baseQuery,
  endpoints: (builder) => ({
    getCat: builder.query<PostCatRes, void>({
      query: () => ({
        url: queryKeys.catRes.getCategory,
        method: "GET",
      }),
    }),
    getCatRes: builder.mutation<PostCatResResponse, PostCatResRequest>({
      query: (body) => ({
        url: queryKeys.catRes.get,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetCatQuery, useGetCatResMutation } = catResApi;
