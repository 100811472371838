import ShimmerLoading from "components/ShimmerLoading";
import { FC } from "react";

interface Props {
    numberOfRows: number;
    dateRangeLength: number;
}

interface RowProps {
    dateRangeLength: number;
}

const LoadingRow: FC<RowProps> = ({ dateRangeLength }) => {
    return (
        <tr className="">
            <td className="p-0">
                <div className="border-y-2 border-secondary dark:border-deepSpace800 dark:bg-black900 border-l-2 rounded-l-10 h-[81px] flex items-center">
                    <div className="border-r-2 px-3.5 border-secondary dark:border-deepSpace800 w-full flex items-center gap-[16px]">
                        <ShimmerLoading className="max-w-[75px] h-[40px] mx-auto shrink-0" />
                    </div>
                </div>
            </td>
            <td className="p-0">
                <div className="flex items-center border-y-2 border-secondary dark:border-deepSpace800  dark:bg-black900 h-[81px]">
                    <div className="w-full border-r-2 px-3.5 border-secondary dark:border-deepSpace800">
                        <ShimmerLoading className="h-[17px] mb-[8px]" />
                        <ShimmerLoading className="max-w-[71px] h-[17px]" />
                    </div>

                </div>
            </td>
            <td className="p-0">
                <div className="flex items-center border-y-2 border-secondary dark:border-deepSpace800  dark:bg-black900 h-[81px]">
                    <div className="flex w-full border-secondary px-1 dark:border-deepSpace800 border-r-2 px-3.5  justify-center">
                        <ShimmerLoading className="max-w-[100px] h-[17px]" />
                    </div>
                </div>
            </td>
            <td className="p-0">
                <div className="flex items-center border-y-2 border-secondary dark:border-deepSpace800  dark:bg-black900 h-[81px]">
                    <div className="flex w-full flex-col border-secondary px-1 dark:border-deepSpace800 border-r-2 px-3.5 items-center">
                        <ShimmerLoading className="max-w-[100px] h-[17px] mb-[8px]" />
                        <ShimmerLoading className="max-w-[100px] h-[17px]" />
                    </div>
                </div>
            </td>
            <td className="p-0">
                <div className="flex items-center border-y-2 border-secondary dark:border-deepSpace800  dark:bg-black900 h-[81px]">
                    <div className="flex w-full flex-col border-secondary px-1 dark:border-deepSpace800 border-r-2 px-3.5 items-center">
                        <ShimmerLoading className="max-w-[100px] h-[17px] mb-[8px]" />
                        <ShimmerLoading className="max-w-[100px] h-[17px]" />
                    </div>
                </div>
            </td>
            {dateRangeLength >= 1 ? (
                <td className="p-0">
                    <div className="flex items-center border-y-2 border-secondary dark:border-deepSpace800  dark:bg-black900 h-[81px]">
                        <div className="flex  w-full border-secondary px-1 dark:border-deepSpace800 border-r-2 px-3.5  justify-center">
                            <ShimmerLoading className="max-w-[100px] h-[17px]" />
                        </div>
                    </div>
                </td>
            ) : ""}
            {dateRangeLength >= 2 ? (
                <td className="p-0">
                    <div className="flex items-center border-y-2 border-secondary dark:border-deepSpace800  dark:bg-black900 h-[81px]">
                        <div className="flex  w-full border-secondary px-1 dark:border-deepSpace800 border-r-2 px-3.5  justify-center">
                            <ShimmerLoading className="max-w-[100px] h-[17px]" />
                        </div>
                    </div>
                </td>
            ) : ""}
            {dateRangeLength === 3 ? (
                <td className="p-0">
                    <div className="flex items-center border-y-2 border-secondary dark:border-deepSpace800  dark:bg-black900 h-[81px]">
                        <div className="flex  w-full border-secondary px-1 dark:border-deepSpace800 border-r-2 px-3.5  justify-center">
                            <ShimmerLoading className="max-w-[100px] h-[17px]" />
                        </div>
                    </div>
                </td>
            ) : ""}

            <td className="p-0">
                <div className="flex items-center border-y-2 border-r-2 rounded-r-10 border-secondary dark:border-deepSpace800  dark:bg-black900 h-[81px]">
                    <div className="flex  w-full border-secondary px-1 dark:border-deepSpace800 px-3.5  justify-center">
                        <ShimmerLoading className="max-w-[100px] h-[17px]" />
                    </div>
                </div>
            </td>
        </tr>)

}

const TableLoading = ({ numberOfRows, dateRangeLength }: Props) => {
    const rows = Array.from({ length: numberOfRows }, (v, k) => k + 1);;
    return (
        <>
            {rows.map((row, i) => (<LoadingRow dateRangeLength={dateRangeLength} key={i + Date.now()} />))}
        </>)


}

export default TableLoading