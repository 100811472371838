import Modal from "components/Modal";
import useModal from "hooks/useModal";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import PeNewPopup from "./pe-new";
import PeRegularPopup from "./pe-regular";



const PePopup = () => {

    const navigate = useNavigate()
    const [cookie, setCookie] = useCookies(['pe-pp'])
    const cv = cookie["pe-pp"]

    const handleClick = (val: string, type: "explore" | "skip") => {
        const attempt = cv?.attempt || 0;

        const oneDay = Date.now() + 24 * 60 * 60 * 1000;
        const never = Date.now() + 5 * 365 * 24 * 60 * 60 * 1000;
        const cookieValue = {
            value: val,
            attempt: attempt + 1,
            expiration: type === "explore" ? never : oneDay,
        };

        setCookie("pe-pp", JSON.stringify(cookieValue), {
            path: "/",
            expires: new Date(never),

        });

        type === "skip" ? modal.close() : navigate(PATH.productExplorer.root)
    }

    const modal = useModal()
    useEffect(() => {
        if (!cv || (Date.now() > cv?.expiration && cv?.attempt < 3)) {
            setTimeout(() => {
                modal.open()
            }, 2000)
        }
    }, [])


    return (
        <Modal shouldCloseOnOverlayClick={false} overlay overlayColor="rgb(13 13 13 / 50%)" maxWidth="max-w-[700px]" cardClassName="rounded-2xl" hideCloseIcon helpers={modal}>
            {cv?.value === "new" ? <PeNewPopup handleClick={handleClick} /> : <PeRegularPopup handleClick={handleClick} />}
        </Modal>
    )
}

export default PePopup