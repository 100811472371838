
import { FC } from "react";

interface Props {
    wrapperStyles?: string;
    activeSlide: number;
    showTwoPlans?: boolean;
    isShopify?: boolean;
}

const NextPrevSlider: FC<Props> = ({ isShopify, wrapperStyles, activeSlide, showTwoPlans }) => {
    const twoPlans = showTwoPlans || isShopify

    return (
        <div className={`px-[60px] max-sm:px-[30px] flex justify-between items-center gap-[56px] w-full py-4 ${wrapperStyles}`}>
            <p
                className="text-xs font-medium"
            >
                Scroll
            </p>

            <div
                className="relative w-[186px] h-2 rounded-full bg-secondary"
            >
                <div
                    className={`h-full bg-smoothGreen rounded-full 
                          ${twoPlans ? (activeSlide === 1 ? "w-full" : "w-1/2") : (activeSlide === 0 ? "w-1/3" : activeSlide === 1 ? "w-2/3" : "w-full")}`}
                />
            </div>
        </div>
    )
}

export default NextPrevSlider