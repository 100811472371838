import { FC } from "react";

interface Props {
  title?: string;
  bgColor: "success" | "errorOrange" | string;
  className?: string;
  maxWidth?: string;
}

const Label: FC<Props> = ({ title, bgColor, className, maxWidth }) => (
  <div
    className={`${bgColor === "success" ? "bg-success dark:bg-paradiseBlue" : "bg-errorOrange"} py-1.5 rounded-full ${maxWidth || "max-w-[151px]"
      }  w-full ${className || ""} `}
  >
    <p className="text-center dark:text-deepSpace900">{title}</p>
  </div>
);

export default Label;
