import { ReactComponent as NewsFeed } from "assets/icons/NewsFeed.svg";
import HoveredIcon from "components/HoveredIcon";
import Tooltip from "components/Tooltip";
import { BEAMER_PRODUCT_ID } from "config/config";
import useDecodeToken from "hooks/useDecodeToken";
import { useEffect, useId } from "react";

const HeaderNewsFeed = () => {
  const userDetails = useDecodeToken();
  const noPlan = userDetails?.package_name === "" && userDetails?.shopify_package_name === ""
  useEffect(() => {
    if (!noPlan) {
      const script = document.createElement('script');
      script.src = 'https://localhost/js/beamer-embed.js';
      script.async = true;
      script.onload = () => {
        const beamerConfig = {
          product_id: BEAMER_PRODUCT_ID,
          selector: '.beamer-trigger',
          button: false
        };
        (window as any).beamer_config = beamerConfig;
        if ((window as any).Beamer) {
          (window as any).Beamer.init();
        }
      };
      document.body.appendChild(script);
    }

  }, [noPlan]);


  const handleBeamerOpen = () => {
    if ((window as any).Beamer) {
      (window as any).Beamer.show();
    }
  };
  const id = useId();
  return (
    <>
      <div className="relative" data-tooltip-id={id}>
        <HoveredIcon
          Icon={NewsFeed}
          handleClick={handleBeamerOpen}
          className={"beamer-trigger"}
          iconColor={"dark:fill-grey100 fill-darkBlue"}
          iconClassName="w-[21px] h-[21px]"
          width="w-9"
          height="h-9"
        />
      </div>
      {<Tooltip anchorId={id} title="News" />}   </>
  );
};

export default HeaderNewsFeed;
