import { createSlice } from "@reduxjs/toolkit";
import { turboScannerApi } from "store/api/turboScannerApi";

import { turboScanFilters } from "store/initialValues/turboScanner";

export interface TurboScanPayload {
  field: keyof typeof turboScanFilters;
  newVal: string | number;
  type?: "min" | "max";
}

const initialState = {
  scanFilters: turboScanFilters,
};

const tuboScannerSlice = createSlice({
  name: "turboScannerSlice",
  initialState,
  reducers: {
    setTsFilters: (state, { payload }) => {
      state.scanFilters = payload;
    },
    handleTsFilterChange: (
      state,
      { payload }: { payload: TurboScanPayload }
    ) => {
      const { field, type, newVal } = payload;
      // @ts-ignore
      state.scanFilters[field] =
        type === "min" || type === "max"
          ? {
              // @ts-ignore
              ...state.scanFilters[field],
              ...(type && { [type]: newVal }),
            }
          : newVal;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      turboScannerApi.endpoints.turboScanPrevFilters.matchFulfilled,
      (state, { payload }) => {
        if (payload?.result) {
          state.scanFilters = {
            supplier: (payload?.result.type || "1").toString(),
            category: payload?.result.categories as string[],
            inclusions: [] as string[],
            rating: payload?.result.rating,
            reviewCount: {
              min: payload.result.minTotalReviews,
              max: payload.result.maxTotalReviews,
            },
            sellerRanking: {
              min: payload.result.minBSR,
              max: payload.result.maxBSR,
            },
            recentSale: { min: payload.result.minSales },
            price: {
              min: payload.result.minPrice,
              max: payload.result.maxPrice,
            },
            numberOfProducts: { max: payload.result.numberOfProductsFiltered },
            keywords: payload.result.keywords || "",
          };
        }

        return state;
      }
    );
  },
});

export const { handleTsFilterChange, setTsFilters } = tuboScannerSlice.actions;

export default tuboScannerSlice.reducer;
