import ManagementPanel from "pages/ManagementPanel/Index";
import ShopifyBilling from "pages/Shopify/Billing";
import ShopifyInvoice from "pages/Shopify/Billing/Invoice";
import ShopifyPlans from "pages/Shopify/Billing/Plans";
import ShopifyMarketInsights from "pages/Shopify/MarketInsights";
import ProductExplorer from "pages/Shopify/ProductExplorer";
import QuickSettings from "pages/Shopify/QuickSettings";
import SalesSpy from "pages/Shopify/SalesSpy";
import SalesSpyCompetitorResearch from "pages/Shopify/SalesSpy/CompetitorResearch";
import StoreFinder from "pages/Shopify/StoreFinder";
import EbayLocked from "pages/Subscription/EbayLocked";
import ThemeDetector from "pages/ThemeDetector";
import { PATH } from "./path";

export const shopifyRoutes = [
    {
        path: PATH.shopify.salesSpy,
        element: <SalesSpy />
    },
    {
        path: PATH.shopify.salesSpyItem,
        element: <SalesSpyCompetitorResearch />
    },
    {
        path: PATH.shopify.marketInsights,
        element: <ShopifyMarketInsights />
    },
    {
        path: PATH.shopify.storeFinder,
        element: <StoreFinder />
    },
    {
        path: PATH.shopify.productExplorer,
        element: <ProductExplorer />
    },

    {
        path: PATH.shopify.billing,
        element: <ShopifyBilling />
    },
    {
        path: PATH.shopify.invoice.invoice,
        element: <ShopifyInvoice />
    },
    {
        path: PATH.shopify.plans,
        element: <ShopifyPlans />
    },
    {
        path: PATH.themeDetector,
        element: <ThemeDetector />
    },
    {
        path: PATH.ebayLocked,
        element: <EbayLocked />
    },
    {
        path: PATH.managementPanel.root,
        element: <ManagementPanel />,
    },
    {
        path: PATH.quickSettings.root,
        element: <QuickSettings />,
    },
]
