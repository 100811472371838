import { createApi } from "@reduxjs/toolkit/query/react";
import {
  AddVATRes,
  CancelSubscriptionReq,
  CancelSubscriptionRes,
  CreditCardReq,
  CreditCardRes,
  DefaultCouponResponse,
  GetPaymentInvoiceReq,
  GetPaymentInvoiceRes,
  JourneyCompleteRes,
  LatestSubscriptionRes,
  PayoneerPurchaseReq,
  PayoneerPurchaseRes,
  PayoneerSubscriptionReq,
  PayoneerSubscriptionRes,
  PaypalPurchaseReq,
  PaypalPurchaseRes,
  PaypalRegReq,
  PaypalRegRes,
  PaypalSubscriptionReq,
  PaypalSubscriptionRes,
  PfTokenRes,
  RegCheckReq,
  RegCheckRes,
  SubscriptionHistoryRes,
  UpgradeCheckReq,
  UpgradeCheckRes,
  UpgradeReq,
  ValidateCouponReq,
  ValidateCouponRes,
} from "types/services/managePayments";
import { GetUserPlanRes } from "types/services/user";
import { makeQueryParams } from "utils/makeQueryParams";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";
import { queryTags } from "./queryTags";

export const managePaymentsApi = createApi({
  reducerPath: "ManagePaymentsApi",
  baseQuery,
  tagTypes: [
    queryTags.managePayments.subscription,
    queryTags.managePayments.defaultCoupon,
    queryTags.managePayments.checkSubscriptionRegistration,
  ],
  endpoints: (builder) => ({
    getSubscriptionHistory: builder.query<SubscriptionHistoryRes, null>({
      query: () => ({
        url: queryKeys.manageSubscription.getSubscriptionHistory,
        method: "GET",
      }),
    }),
    getLatestSubscription: builder.query<LatestSubscriptionRes, null>({
      query: () => ({
        url: queryKeys.manageSubscription.getlatestsubscription,
        method: "GET",
      }),
    }),
    checkUpgrade: builder.query<UpgradeCheckRes, UpgradeCheckReq>({
      query: (query) => ({
        url: makeQueryParams(
          `${queryKeys.manageSubscription.checkSubscriptionUpgrade}/${query.package}`,
          {
            coupon: query.coupon,
            ...(query.tool && { tool: query.tool }),
          }
        ),
        method: "GET",
      }),
    }),
    validateCoupon: builder.query<ValidateCouponRes, ValidateCouponReq>({
      query: (query) => ({
        url:
          queryKeys.manageSubscription.validateCoupon +
          `?couponName=${query.couponName}&subscriptionType=${query.subscriptionType}`,
        method: "GET",
      }),
    }),
    upgrade: builder.query<UpgradeCheckRes, UpgradeReq>({
      query: (query) => ({
        url: makeQueryParams(queryKeys.manageSubscription.upgrade, {
          type: query.type,
          couponName: query.couponName,
          ...(query.tool && { tool: query.tool }),
        }),
        method: "POST",
      }),
    }),
    shopifyUpgrade: builder.query<UpgradeCheckRes, UpgradeReq>({
      query: (query) => ({
        url:
          queryKeys.manageSubscription.shopifyUpgrade +
          `?type=${query.type}&couponName=${query.couponName}`,
        method: "POST",
      }),
    }),
    paypalPurchase: builder.query<PaypalPurchaseRes, PaypalPurchaseReq>({
      query: (query) => ({
        url:
          queryKeys.manageSubscription.paypalPurchase +
          `?requestedSubscriptionType=${query.requestedSubscriptionType}&couponName=${query.couponName}`,
        method: "POST",
      }),
    }),
    payoneerPurchase: builder.query<PayoneerPurchaseRes, PayoneerPurchaseReq>({
      query: (query) => ({
        url:
          queryKeys.manageSubscription.payoneerPurchase +
          `?type=${query.type}&skipTrial=${query.skipTrial}&couponName=${query.couponName}`,
        method: "POST",
      }),
    }),
    paypalSubscription: builder.query<
      PaypalSubscriptionRes,
      PaypalSubscriptionReq
    >({
      query: ({ isShopify, ...query }) => ({
        url:
          queryKeys.manageSubscription[
            isShopify ? "shopifyPaypalSubscription" : "paypalSubscription"
          ] + `?invoiceId=${query.invoiceId}&errorCode=${query.errorCode}`,
        method: "POST",
      }),
    }),
    payoneerSubscription: builder.query<
      PayoneerSubscriptionRes,
      PayoneerSubscriptionReq
    >({
      query: ({ isShopify, ...query }) => ({
        url:
          queryKeys.manageSubscription[
            isShopify ? "payoneerShopifySubscription" : "payoneerSubscription"
          ] + `?code=${query.code}&state=${query.state}`,
        method: "POST",
      }),
    }),
    getUserPlan: builder.query<GetUserPlanRes, null>({
      query: () => ({
        url: queryKeys.user.getPlan,
        method: "GET",
      }),
      providesTags: [queryTags.managePayments.subscription],
    }),
    getPfToken: builder.query<PfTokenRes, null>({
      query: () => ({
        url: queryKeys.manageSubscription.getPfToken,
        method: "POST",
      }),
    }),
    journeyComplete: builder.query<JourneyCompleteRes, null>({
      query: () => ({
        url: queryKeys.manageSubscription.journeyComplete,
        method: "POST",
      }),
    }),
    submbitCreditCard: builder.query<CreditCardRes, CreditCardReq>({
      query: ({ isShopify, ...param }) => ({
        url: queryKeys.manageSubscription[
          isShopify ? "shopifySubmitCreditCard" : "submitCreditCard"
        ],
        body: param,
        method: "POST",
      }),
    }),
    getDefaultCoupon: builder.query<DefaultCouponResponse, string>({
      query: (subscriptionType: string) => ({
        url: makeQueryParams(queryKeys.manageSubscription.getDefaultCoupon, {
          subscriptionType,
        }),
        method: "GET",
      }),
      providesTags: [queryTags.managePayments.defaultCoupon],
    }),
    getPaymentInvoice: builder.query<
      GetPaymentInvoiceRes,
      GetPaymentInvoiceReq
    >({
      query: (params) => ({
        url: queryKeys.manageSubscription.getPaymentInvoice,
        params,
        method: "GET",
      }),
    }),
    checkRegistration: builder.query<RegCheckRes, RegCheckReq>({
      query: (query) => ({
        url:
          queryKeys.manageSubscription.checkSubscriptionRegistration +
          `/${query.package}?coupon=${query.coupon}&skipTrial=${query.skipTrial}`,
        method: "GET",
      }),
      providesTags: [queryTags.managePayments.checkSubscriptionRegistration],
    }),
    addVatNumber: builder.mutation<AddVATRes, string>({
      query: (body) => ({
        url: queryKeys.user.addVatNumber + `?vatNumber=${body}`,
        method: "POST",
      }),
    }),
    paypalRegistrationPurchase: builder.query<PaypalRegRes, PaypalRegReq>({
      query: (query) => ({
        url:
          queryKeys.manageSubscription.paypalPurchaseRegistration +
          `?requestedSubscriptionType=${query.requestedSubscriptionType}&couponName=${query.couponName}&skipTrial=${query.skipTrial}`,
        method: "POST",
      }),
    }),
    cancelSubscription: builder.mutation<
      CancelSubscriptionRes,
      CancelSubscriptionReq
    >({
      query: (body) => ({
        url: queryKeys.manageSubscription.cancelSubscription,
        method: "POST",
        body,
      }),
      // invalidatesTags: [queryTags.managePayments.subscription],
    }),
  }),
});

export const {
  useGetSubscriptionHistoryQuery,
  useLazyCheckUpgradeQuery,
  useLazyValidateCouponQuery,
  useLazyUpgradeQuery,
  useCancelSubscriptionMutation,
  useLazyPaypalPurchaseQuery,
  useLazyPayoneerPurchaseQuery,
  useLazyShopifyUpgradeQuery,
  useLazyPaypalSubscriptionQuery,
  useLazyPayoneerSubscriptionQuery,
  useGetUserPlanQuery,
  useLazyGetPfTokenQuery,
  useLazySubmbitCreditCardQuery,
  useGetDefaultCouponQuery,
  useLazyCheckRegistrationQuery,
  useAddVatNumberMutation,
  useGetPaymentInvoiceQuery,
  useLazyPaypalRegistrationPurchaseQuery,
  useLazyJourneyCompleteQuery,
  useLazyGetLatestSubscriptionQuery,
} = managePaymentsApi;
