import { ReactComponent as Filters } from "assets/icons/filters.svg";
import { ReactComponent as Settings } from "assets/icons/management-panel.svg";
import { ReactComponent as Video } from "assets/icons/video.svg";
import { useTooltipContext } from "context/TooltipContext";
import useHover from "hooks/useHover";
import { FC, useId } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

export enum HeaderVariants {
  settings = "settings",
  video = "video",
  filters = "filters",
}
interface Props {
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  className?: string;
  tooltipTitle?: string;
  variant?: HeaderVariants;
  iconClassName?: string;
  handleClick?: () => void;
}
const variants = {
  [HeaderVariants.video]: {
    Icon: Video,
    className: "py-[7px] px-1",
    iconClassName: "w-6 h-4.5",
  },
  [HeaderVariants.settings]: {
    Icon: Settings,
    className: "p-[5.5px]",
    iconClassName: "w-[21px] h-[21px]",
  },
  [HeaderVariants.filters]: {
    Icon: Filters,
    className: "py-[8.5px] px-[3.5px]",
    iconClassName: "h-[15px] w-[25px]",
  },
};
const HeaderIcon: FC<Props> = ({
  Icon,
  iconClassName,
  className,
  variant,
  handleClick,
  tooltipTitle,
}) => {
  const [hoverRef, isHovered] = useHover<HTMLButtonElement>();
  const CustomIcon = variant ? variants[variant].Icon : Icon;
  const id = useId();
  const { activeTooltipId, setActiveTooltipId } = useTooltipContext(); // Use the context

  const handleMouseEnter = () => {
    setActiveTooltipId(id);
  };

  const handleMouseLeave = () => {
    setActiveTooltipId(null);
  };

  return (
    <>
      <button
        ref={hoverRef}
        className={`border-2 border-transparent rounded-full hover:border-primaryPurple dark:hover:border-purple500 p-2 cursor-pointer group ${className || ""
          } ${variant ? variants[variant].className : ""}`}
        onClick={handleClick}
        id={id}
        data-tooltip-id={id}
        data-tooltip-content={tooltipTitle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {CustomIcon && (
          <CustomIcon
            className={`${iconClassName || ""} ${variant ? variants[variant].iconClassName : ""} group-hover:fill-primaryPurple dark:group-hover:fill-purple500 fill-darkBlue dark:fill-grey100`}
          />
        )}
      </button>
      <Tooltip
        place="left"
        id={id}
        isOpen={isHovered && id === activeTooltipId}
        className={`bg-darkBlue opacity-100 px-2.5 pt-2.5 pb-3 rounded-10 !z-[9999] max-w-[300px] dark:bg-grey700 dark:text-lilac40 text-[10px]`}
      />
    </>
  );
};

export default HeaderIcon;
