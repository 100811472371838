import { createApi } from "@reduxjs/toolkit/query/react";
import {
  DeleteWaitingListItemReq,
  LoadWaitingListReq,
  LoadWaitingListRes,
} from "types/services/waitingList";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";
import { queryTags } from "./queryTags";

export const waitingListApi = createApi({
  reducerPath: "waitingListApi",
  baseQuery,
  tagTypes: [queryTags.waitingList.list],
  endpoints: (builder) => ({
    loadWaitingList: builder.query<LoadWaitingListRes, LoadWaitingListReq>({
      query: (body) => ({
        url: queryKeys.waitingList.load,
        method: "POST",
        body,
      }),
      providesTags: [queryTags.waitingList.list],
    }),
    deleteWaitingListItem: builder.mutation<null, DeleteWaitingListItemReq>({
      query: (params) => ({
        url: queryKeys.waitingList.delete,
        method: "DELETE",
        params,
      }),
      invalidatesTags: [queryTags.waitingList.list],
    }),
    deleteWaitingList: builder.mutation<null, void>({
      query: () => ({
        url: queryKeys.waitingList.deleteAll,
        method: "DELETE",
      }),
      invalidatesTags: [queryTags.waitingList.list],
    }),
    deleteWaitingPage: builder.mutation<null, number[]>({
      query: (body) => ({
        url: queryKeys.waitingList.deletePage,
        method: "DELETE",
        body,
      }),
      invalidatesTags: [queryTags.waitingList.list],
    }),
  }),
});

export const {
  useLoadWaitingListQuery,
  useDeleteWaitingListItemMutation,
  useDeleteWaitingListMutation,
  useDeleteWaitingPageMutation,
} = waitingListApi;
