import { ReactComponent as RatingEmpty } from "assets/icons/rating-empty.svg";
import { FC, useState } from "react";

const ratingArr = [1, 2, 3, 4, 5];

interface Props {
  rating: number;
  handleRatingChange: (rate: number) => void;
  itemClassName?: string
}

const Rating: FC<Props> = ({ rating, handleRatingChange, itemClassName }) => {
  const [hoveredRating, setHoveredRating] = useState(0);

  return (
    <div className="flex">
      {ratingArr.map((item) => (
        <button
          onClick={() => handleRatingChange(item)}
          key={item}
          onMouseOver={() => setHoveredRating(item)}
          onMouseOut={() => setHoveredRating(0)}
          className={`py-1 px-[5.5px] ${itemClassName || ""}`}
        >
          <RatingEmpty
            className={
              item <= hoveredRating || item <= rating ? "fill-[#E4C210]" : ""
            }
          />
        </button>
      ))}
    </div>
  );
};

export default Rating;
