import { useState } from "react";

type useModal = (initialOpen?: boolean) => {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
const useModal: useModal = (initialOpen = false) => {
  const [isOpen, setIsOpen] = useState<boolean>(initialOpen);
  const open = () => setIsOpen(true);
  const close = () => {
    setIsOpen(false);
  };
  const toggle = () => setIsOpen((prev) => !prev);

  return { isOpen, open, close, toggle };
};

export default useModal;
