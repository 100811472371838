import { ReactComponent as Automation } from "assets/icons/automation.svg";
import { ReactComponent as Education } from "assets/icons/education.svg";
import { ReactComponent as Keywords } from "assets/icons/keywords.svg";
import { ReactComponent as LineGraph } from "assets/icons/line-graph.svg";
import { ReactComponent as Loop } from "assets/icons/loop.svg";
import { ReactComponent as Settings } from "assets/icons/management-panel.svg";
import { FACEBOOK_GROUP, YOUTUBE_CHANNEL } from "config/constants";
import { UserPackages } from "hooks/useDecodeToken";
import { PATH } from "routes/path";

import { ReactComponent as CategoryResearch } from "assets/icons/category-research.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import {
  ReactComponent as Facebook,
  ReactComponent as FacebookIcon,
} from "assets/icons/facebook-f.svg";
import { ReactComponent as MyProducts } from "assets/icons/my-products.svg";
import { ReactComponent as ProductExplorer } from "assets/icons/product-exlorer.svg";
import { ReactComponent as ProductResearch } from "assets/icons/product-research.svg";
import { ReactComponent as Save } from "assets/icons/save.svg";
import { ReactComponent as Search } from "assets/icons/search.svg";
import { ReactComponent as YoutubeIcon } from "assets/icons/social/youtube.svg";
import { ReactComponent as StoreFinder } from "assets/icons/store-finder-shopify.svg";
import { ReactComponent as TitleBuilder } from "assets/icons/title-builder.svg";
import { ReactComponent as TutorialsTraining } from "assets/icons/tutorials-training.svg";
import { ReactComponent as Video } from "assets/icons/video.svg";
import { ReactComponent as ZikPro } from "assets/icons/zik-pro.svg";

const tools = (plan: UserPackages) => ({
  title: "Favorites",
  id: "01",
  items: [
    {
      title: "Product Research",
      id: 1,
      iconClassName: "w-3 h-3",
      Icon: Loop,
      submenus: [
        {
          title: "Product Research Tool",
          path: PATH.productResearch.root,
          id: 101,
          icon: ProductResearch,
          iconClassName: "w-3 h-3",
        },
        {
          title: "Category Research",
          path: PATH.categoryResearch.root,
          id: 102,
          icon: CategoryResearch,
          iconClassName: "w-3 h-[11px]",
        },
        {
          title: "Product Explorer",
          path: PATH.productExplorer.root,
          id: 105,
          icon: ProductExplorer,
          iconClassName: "w-[14px] h-[15px]",
        },
        {
          title: "500 Best Selling eBay Items",
          path: PATH.zikPro.bestSellingItems,
          id: 103,
          icon: ZikPro,
          iconClassName: "w-[14px] h-3",
          disabled: plan === "Pro",
        },
        {
          title: "My Products",
          path: PATH.myProducts.root,
          id: 104,
          icon: MyProducts,
          iconClassName: "w-3 h-[11px]",
        },
      ],
    },
    {
      title: "Competitor Analysis",
      id: 2,
      iconClassName: "w-[13px] h-3",
      Icon: LineGraph,
      submenus: [
        {
          title: "Competitor Research",
          path: PATH.competitorResearch.root,
          id: 201,
          icon: CompetitorResearch,
          iconClassName: "w-3 h-[13px]",
        },
        {
          title: "Competitors Waiting List",
          path: PATH.managementPanel.waitingList,
          icon: CompetitorResearch,
          iconClassName: "w-3 h-[13px]",
          id: 202,
        },
        {
          title: "My Competitors",
          path: PATH.myCompetitors,
          id: 203,
          icon: CompetitorResearch,
          iconClassName: "w-3 h-[13px]",
        },
      ],
    },
    {
      title: "Keywords & Title",
      id: 3,
      iconClassName: "w-4 h-3.5",
      Icon: Keywords,
      submenus: [
        {
          title: "Title Builder",
          path: PATH.titleBuilder.root,
          id: 301,
          icon: TitleBuilder,
          iconClassName: "w-3",
        },
      ],
    },
    {
      title: "Dropshipping Automations",
      id: 4,
      iconClassName: "w-4.5 h-3",
      Icon: Automation,
      submenus: [
        {
          title: "Autopilot",
          id: 401,
          limited: true,
          path: PATH.zikPro.autopilot,
          disabled: plan === "Pro",
          icon: ZikPro,
          iconClassName: "w-[14px] h-3",
        },
        {
          title: "Turbo Scanner",
          id: 402,
          limited: true,
          path: PATH.zikPro.turboScanner.root,
          disabled: plan === "Pro",
          icon: ZikPro,
          iconClassName: "w-[14px] h-3",
        },
        {
          title: "Bulk Scanner",
          id: 403,
          path: PATH.zikPro.zikProBulkScanner,
          disabled: plan === "Pro",
          icon: ZikPro,
          iconClassName: "w-[14px] h-3",
        },
        {
          title: "Ali Growth",
          id: 404,
          path: PATH.zikPro.aliGrowthScanner,
          disabled: plan === "Pro",
          icon: ZikPro,
          iconClassName: "w-[14px] h-3",
        },
      ],
    },

    {
      title: "Education & Community",
      id: 5,
      iconClassName: "w-4.5 h-3",
      Icon: Education,
      submenus: [
        {
          title: "Academy",
          path: PATH.academy,
          id: 501,
          icon: TutorialsTraining,
          iconClassName: "w-4 h-2.5",
        },
        {
          title: "Tutorials",
          path: PATH.tutorials,
          id: 502,
          icon: TutorialsTraining,
          iconClassName: "w-4 h-2.5",
        },
        {
          title: "Product Research Training",
          path: `${PATH.tutorials}?product-research=true`,
          id: 503,
          icon: ProductResearch,
          iconClassName: "w-3 h-3",
        },
        {
          title: "Youtube Channel",
          href: YOUTUBE_CHANNEL,
          id: 504,
          icon: YoutubeIcon,
          iconClassName: "w-[15px] h-[11px]",
        },
        {
          title: "ZIK VIP Member Group",
          href: FACEBOOK_GROUP,
          id: 505,
          icon: Facebook,
          iconClassName: "w-2 h-[17px]",
        },
      ],
    },

    {
      title: "Settings & Analytics",
      iconClassName: "w-4 h-4",
      id: 6,
      Icon: Settings,
      submenus: [
        {
          title: "Saved Product Searches",
          path: PATH.managementPanel.savedSearches,
          id: 601,
          icon: Save,
          iconClassName: "w-4 h-[9px]",
        },
        {
          title: "My Store Analytics",
          path: PATH.managementPanel.storeAnalytics,
          id: 602,
          icon: LineGraph,
          iconClassName: "w-[13px] h-3",
        },
        {
          title: "Advanced Settings",
          path: PATH.quickSettings.account,
          id: 603,
          icon: Settings,
          iconClassName: "w-3.5 h-3.5",
        },
      ],
    },
  ],
});

const shopifyTools = {
  title: "Favorites",
  id: "01",
  items: [
    {
      title: "Store Research",
      iconClassName: "w-3 h-3",
      id: 1,
      Icon: Loop,
      submenus: [
        {
          title: "Sales Tracker",
          path: PATH.shopify.salesSpy,
          id: 701,
          icon: CompetitorResearch,
          iconClassName: "w-[12px] h-[13px]",
        },
        {
          title: "Store Finder",
          path: PATH.shopify.storeFinder,
          id: 702,
          icon: StoreFinder,
          iconClassName: "w-[13px] h-[15px]",
        },
        {
          title: "Product Explorer",
          path: PATH.shopify.productExplorer,
          icon: ProductExplorer,
          iconClassName: "w-[14px] h-[15px]",
          id: 703,
        },
        {
          title: "Title Builder",
          path: PATH.titleBuilder.root,
          icon: TitleBuilder,
          iconClassName: "w-3 h-[11px]",
          id: 704,
        },
        {
          title: "Theme Detector",
          path: PATH.themeDetector,
          icon: Search,
          iconClassName: "!w-[14px] !h-[14px]",
          id: 705,
        },
      ],
    },
    {
      title: "Education & Community",
      iconClassName: "w-4 h-2.5",
      id: 2,
      Icon: Education,
      submenus: [
        {
          title: "Academy",
          path: PATH.academy,
          id: 501,
          icon: TutorialsTraining,
          iconClassName: "w-4 h-2.5",
        },
        {
          title: "Tutorials",
          path: PATH.tutorials,
          id: 502,
          icon: Video,
          iconClassName: "w-4 h-3",
        },
        {
          title: "Youtube Channel",
          path: YOUTUBE_CHANNEL,
          id: 504,
          icon: YoutubeIcon,
          iconClassName: "w-[15px] h-[11px]",
        },
        {
          title: "ZIK VIP Member Group",
          path: FACEBOOK_GROUP,
          icon: FacebookIcon,
          iconClassName: "w-2 h-[17px]",
          id: 505,
        },
      ],
    },
  ],
};
export { shopifyTools };
export default tools;
