
import NoImage from "assets/icons/no-image";
import CutLongTitle from "components/CutLongTitle";
import useFindkeywords from "hooks/useFindKeywords";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import ProductIcons from "./ProductIcons";
import ProductImage from "./ProductImage";
import ProductTitle from "./ProductTitle";

interface Props {
  name?: string;
  className?: string;
  titleClassName?: string;
  imgWrapperClassName?: string;
  titleWrapperClassName?: string;
  hoveredImgClassName?: string;
  isDropshipper?: boolean;
  checkKeywords?: boolean;
  maxHeight?: string;
  width?: string;
  url?: string;
  height?: string;
  img?: string;
  isFirst?: boolean;
  isLast?: boolean;
  adjustFirstandLastItemsAuto?: boolean
  cutLongTitle?: number;
  hoveredImgWidth?: number;
  imageAsLink?: boolean;
  disableRedirect?: boolean;
  responsiveImageWidth?: number;
  isAliDropship?: boolean;
  isAmzDropshipUK?: boolean;
  isWalmartDropship?: boolean;
  isAmzUSDropship?: boolean;
}

const ProductNameCell: FC<Props> = ({
  name,
  className,
  titleClassName,
  width,
  isDropshipper,
  img,
  maxHeight,
  hoveredImgClassName,
  url,
  checkKeywords,
  imgWrapperClassName,
  titleWrapperClassName,
  cutLongTitle,
  height,
  isFirst,
  hoveredImgWidth,
  isLast,
  adjustFirstandLastItemsAuto,
  imageAsLink,
  disableRedirect,
  responsiveImageWidth,
  isAliDropship,
  isAmzDropshipUK,
  isWalmartDropship,
  isAmzUSDropship,
}) => {
  const keywords = useFindkeywords(name || "", checkKeywords);
  const noTitle = !name;
  const showImage = img !== undefined && img !== null;
  const emptyImage = img === undefined
  const [imageLoadError, setImageLoadError] = useState(false);
  return (
    <div
      className={`flex items-center relative ${noTitle ? "auto" : "max-w-[37.5rem] w-full"
        }  ${className || ""}`}
    >
      {showImage && !imageLoadError ? (
        imageAsLink ?
          <Link to={url || ""} target="_blank">
            <ProductImage
              img={img}
              isFirst={isFirst}
              isLast={isLast}
              width={width}
              hoveredImgWidth={hoveredImgWidth}
              height={height}
              noTitle={noTitle}
              adjustFirstandLastItemsAuto={adjustFirstandLastItemsAuto}
              hoveredImgClassName={hoveredImgClassName}
              maxHeight={maxHeight}
              imgWrapperClassName={imgWrapperClassName}
              responsiveImageWidth={responsiveImageWidth}
              setImageLoadError={setImageLoadError}
            />
          </Link>
          :
          <ProductImage
            img={img}
            isFirst={isFirst}
            isLast={isLast}
            adjustFirstandLastItemsAuto={adjustFirstandLastItemsAuto}
            width={width}
            hoveredImgWidth={hoveredImgWidth}
            height={height}
            noTitle={noTitle}
            hoveredImgClassName={hoveredImgClassName}
            maxHeight={maxHeight}
            imgWrapperClassName={imgWrapperClassName}
            responsiveImageWidth={responsiveImageWidth}
            setImageLoadError={setImageLoadError}
          />
      ) : emptyImage ? "" : (
        <div className={`w-full flex items-center justify-center rounded-[7px] bg-neutral1 dark:bg-grey800 text-grey200 dark:text-grey700 shrink-0 ${noTitle ? "" : "ml-2"}`} style={{ width: width || 60, height: height || 60 }}>
          <NoImage />
        </div>
      )}
      {!noTitle ? (
        <div
          className={`${showImage ? "ml-3" : ""} max-w-[37.5rem] w-full ${titleWrapperClassName || ""
            } flex flex-col `}
        >
          <Link
            onClick={(e) => { disableRedirect && e.preventDefault() }}
            to={url || ""}
            target="_blank"
            className={`font-medium cursor-pointer hover:text-primaryPurple dark:hover:text-purple500 pr-2 ${keywords.foundRisky || keywords.foundVero ? "" : ""
              } ${titleClassName || ""}`}
            rel="noreferrer"
          >
            {!checkKeywords || keywords.noKeywords ? (
              cutLongTitle ? <CutLongTitle title={name} length={cutLongTitle} /> : name
            ) : (
              <ProductTitle
                name={name}
                cutLongTitle={cutLongTitle}
                checkKeywords={checkKeywords}
                keywords={keywords}
              />
            )}
          </Link>

          <ProductIcons
            checkKeywords={checkKeywords}
            isDropshipper={isDropshipper || false}
            keywords={keywords}
            isAliDropship={isAliDropship}
            isAmzDropshipUK={isAmzDropshipUK}
            isWalmartDropship={isWalmartDropship}
            isAmzUSDropship={isAmzUSDropship}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProductNameCell;
