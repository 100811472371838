import { ReactComponent as AliexpressHovered } from "assets/icons/aliexpress-hovered.svg";
import { ReactComponent as Aliexpress } from "assets/icons/aliexpress.svg";
import { ReactComponent as AmazonHovered } from "assets/icons/amazon-hovered.svg";
import { ReactComponent as Amazon } from "assets/icons/amazon.svg";
import { ReactComponent as Cj } from "assets/icons/cj.svg";
import { ReactComponent as Ebay } from "assets/icons/ebay.svg";
import { ReactComponent as GoogleHovered } from "assets/icons/google-hovered.svg";
import { ReactComponent as Google } from "assets/icons/google.svg";
import { ReactComponent as Shopify } from "assets/icons/shopify-text.svg";
import { ReactComponent as Walmart } from "assets/icons/walmart.svg";
import HoveredIcon from "components/HoveredIcon";

import Drawer from "components/Drawer";
import useModal from "hooks/useModal";
import { FC } from "react";
import { Link } from "react-router-dom";


interface Props {
    helpers: ReturnType<useModal>;
    redirectTo: {
        google: string;
        amazon: string;
        aliexpress: string;
        walmart: string;
        cj: string
    }
    type: "ebay" | "shopify"
    onHandleClose?: () => void;
    children: JSX.Element | JSX.Element[];
}
const ItemFinderWrapper: FC<Props> = ({ helpers, redirectTo, onHandleClose, type, children }) => {
    const iconClassName = "w-[38px] h-[38px]";

    return (
        <Drawer
            helpers={helpers}
            onHandleClose={onHandleClose}
            videoIcon
            className={`pb-[190px] !pt-[15px] rounded-tl-10 ${helpers.isOpen ? "border-2 border-primaryPurple dark:border-transparent border-r-0 border-b-0" : ""}`}
        >
            <div className="flex items-center mb-9">
                {type === "shopify" ? <Shopify className="fill-[#549C28] w-12 h-4" /> : <Ebay />}
                <div className="flex items-center gap-4.5 mx-auto">
                    <Link to={redirectTo.google} target="_blank" rel="noreferrer">
                        <HoveredIcon
                            Icon={Google}
                            title="Search by photo on Google"
                            tooltipClassName="dark:!bg-deepSpace900"
                            HoveredIcon={GoogleHovered}
                            className={iconClassName}
                        />
                    </Link>
                    <Link to={redirectTo.amazon} target="_blank" rel="noreferrer">
                        <HoveredIcon
                            Icon={Amazon}
                            title="Search title on Amazon"
                            tooltipClassName="dark:!bg-deepSpace900"
                            HoveredIcon={AmazonHovered}
                            className={iconClassName}
                        />
                    </Link>
                    <Link to={redirectTo.aliexpress} target="_blank" rel="noreferrer">
                        <HoveredIcon
                            Icon={Aliexpress}
                            title="Search title on AliExpress"
                            HoveredIcon={AliexpressHovered}
                            tooltipClassName="dark:!bg-deepSpace900"
                            className={iconClassName}
                        />
                    </Link>
                    <Link to={redirectTo.walmart} target="_blank" rel="noreferrer">
                        <HoveredIcon
                            Icon={Walmart}
                            title="Search title on Walmart"
                            tooltipClassName="dark:!bg-deepSpace900"
                            iconColor="fill-canaryYellow"
                            className={iconClassName}
                        />
                    </Link>
                    <Link to={redirectTo.cj} target="_blank" rel="noreferrer">
                        <HoveredIcon
                            Icon={Cj}
                            title="Search title on CJ dropshipping"
                            iconColor="fill-errorOrange"
                            className={iconClassName}
                        />
                    </Link>
                </div>
            </div>
            {children}
        </Drawer>
    )
}

export default ItemFinderWrapper