import { ReactComponent as Lock } from "assets/icons/lock.svg";
import Tooltip from "components/Tooltip";
import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
interface Props {
  title: string | ReactNode;
  handleClick?: () => void;
  isActive?: boolean;
  isLocked?: boolean;
  className?: string;
  path?: string;
  id?: string;
  tooltipText?: string;
}

const HeaderTab: FC<Props> = ({
  title,
  handleClick,
  isActive,
  className,
  isLocked,
  path,
  id,
  tooltipText,
}) => {
  const style = `${isActive
    ? "text-primaryPurple dark:text-purple500 relative"
    : "hover:rounded-[100px] hover:bg-neutral1 hover:text-primaryPurple  dark:text-grey100 dark:hover:text-purple500 dark:bg-transparent"
    } h-9 min-w-[8.375rem] px-2.5 flex justify-center items-center text-sm ${className} ${isLocked ? "gap-2" : ""}`;

  return path ? (
    <>
      <Link to={path} className={style} data-tooltip-id={id?.toString() || ""} onClick={() => { if (handleClick) { handleClick() } }}>
        {isLocked ? <Lock className="fill-darkBlue" /> : ""}
        {title}
        {isActive && <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-primaryPurple dark:bg-purple500 rounded-full" />}
      </Link>
      {tooltipText &&
        <Tooltip place="top" title={tooltipText?.toString() || ""} anchorId={id?.toString() || "header-text-tooltip"} />
      }

    </>
  ) : (
    <>
      <button
        data-tooltip-id={id?.toString() || ""}
        onClick={() => {
          handleClick && handleClick();
        }}
        className={style}
      >
        {isLocked ? <Lock className="fill-darkBlue" /> : ""}
        <p>{title}</p>
        {isActive && <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-primaryPurple dark:bg-purple500 rounded-full" />}
      </button>
      {tooltipText &&
        <Tooltip place="top" title={tooltipText?.toString() || ""} anchorId={id?.toString() || "header-text-tooltip"} />
      }
    </>
  );


};

export default HeaderTab;
