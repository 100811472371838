import { ReactComponent as Letters } from 'assets/icons/logo-letters.svg';
import { ReactComponent as Image } from 'assets/icons/logo.svg';

interface Props {
    className?: string;
    imageClassName?: string;
    lettersClassName?: string;
}


const index = ({ imageClassName, lettersClassName, className }: Props) => (
    <div className={`flex items-center ${className || ""}`}>
        <Image className={`${imageClassName || ""}`} />
        <Letters className={`${lettersClassName || ""}`} />
    </div>
)

export default index