import { COUNTRY_FLAGS } from "../config/constants";

const getCountryFromMarketplace = (country: string) => {
  let cntr = country;
  switch (country) {
    case "EBAY-US":
      cntr = "US";
      break;
    case "EBAY-GB":
      cntr = "GB";
      break;
    case "EBAY-AU":
      cntr = "AU";
      break;
    case "EBAY-ENCA":
      cntr = "CA";
      break;
    case "EBAY-FR":
      cntr = "FR";
      break;
    case "EBAY-DE":
      cntr = "DE";
      break;
    case "EBAY-IT":
      cntr = "IT";
      break;
    case "EBAY-ES":
      cntr = "ES";
      break;
    case "EBAY-MY":
      cntr = "MY";
      break;
    case "EBAY-SG":
      cntr = "SG";
      break;
  }

  return cntr;
};

export const getCountryFlag = (country: string) => {
  if (
    country === "ALL" ||
    country === "WW" ||
    country?.toLowerCase() === "worldwide"
  ) {
    return `${COUNTRY_FLAGS}/globe.png`;
  }

  return `${COUNTRY_FLAGS}/${getCountryFromMarketplace(
    country
  )?.toUpperCase()}.png`;
};
