import Loading from "components/Loading";

interface Props {
    title: string;
    altTitle: string;
    icon: string;
    handleClick: (id: number) => Promise<void>;
    id: number;
    isLoading: boolean
}


const ShopifyAffiliateActionButton = ({ title, altTitle, icon, handleClick, id, isLoading }: Props) => {
    return (
        <div onClick={() => !isLoading && handleClick(id)} className="relative flex flex-col items-center justify-center max-w-[360px] h-[140px] w-full bg-neutral1 rounded-2xl cursor-pointer border-2 border-transparent hover:border-primaryPurple hover:bg-neutral2">
            {isLoading ?
                <Loading fill={""} /> :
                <>
                    <span className="text-[18px] leading-[28px] max-sm:text-[16px] max-sm:leading-[20px] font-bold mb-[4px] text-center">{title}</span>
                    <span className="text-[16px] leading-[24px] max-sm:text-[14px] text-center">{altTitle}</span>
                </>}

            <img className="absolute bottom-[12px] right-[16px]" src={icon} />
        </div>
    )
}

export default ShopifyAffiliateActionButton