/* eslint-disable no-lone-blocks */
import { ReactComponent as Ebay } from "assets/icons/ebay.svg";
import Button from "components/Button";
import Loading from "components/Loading";
import AddVatNumberInput from "components/VatNumber";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import useDecodeToken from "hooks/useDecodeToken";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useLazyJourneyCompleteQuery } from "store/api/managePaymentApi";
import { handleBootIntercom } from "store/slices/intercomSlice";
import { setUserAccessToken } from "store/slices/userSlice";
import { TSelectedPlan } from "types/plans";
import { UpgradeCheckRes } from "types/services/managePayments";
import { formatCurrency } from "utils/formatCurrency";
import { SetAccessToken } from "utils/localStorage";
import { getPlanFullSuffix } from "utils/planUtils";

interface Props {
  selectedPlan: TSelectedPlan;
  isLoading: boolean;
  data?: UpgradeCheckRes;
  fromRegistration?: boolean;
  wrapperStyles?: string;
  titleStyles?: string;
  promoOrGiftStyles?: string;
  handleVatNumberUpdate?: () => void;
  isShopify?: boolean;
  hideAlreadyTrialWarning?: boolean;
  isDollarTrial?: boolean;
  alreadyTrial?: boolean;
  isTrialLimit?: boolean;
  isCreditCard?: boolean;
  fromAlreadyTrial?: boolean;
  fromNoSubscription?: boolean;
  handleAlreadyTrialChange?: (isAlreadyTrial: boolean) => void;
  cancelAnytimeClassName?: string;
  couponUsed?: boolean;
}

const CheckoutOrderSummary = ({
  selectedPlan,
  isLoading,
  isShopify,
  wrapperStyles,
  titleStyles,
  promoOrGiftStyles,
  isTrialLimit,
  data,
  fromRegistration = false,
  fromAlreadyTrial,
  fromNoSubscription,
  hideAlreadyTrialWarning,
  isDollarTrial,
  isCreditCard,
  handleVatNumberUpdate,
  alreadyTrial,
  handleAlreadyTrialChange,
  cancelAnytimeClassName,
  couponUsed,
}: Props) => {
  const [journeyComplete, { isFetching: journeyCompleteFetching }] = useLazyJourneyCompleteQuery();
  const { dashboardMarketplace } = useAppSelector((state) => state.marketplaceSlice)
  const user = useDecodeToken()
  const [showInput, setShowInput] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const hideInput = () => setShowInput(false);
  const userDetails = useDecodeToken();
  var daysForTrial = isShopify ? userDetails.shopify_days_for_trial : userDetails.days_for_trial;
  const handleJourneyComplete = () => {
    journeyComplete(null).unwrap().then((data) => {
      SetAccessToken(data.message);
      dispatch(setUserAccessToken(data.message));
      const handleNavigate = (route: string) => {
        if (isCreditCard) {
          window.location.replace(route)
        } else {
          navigate(route)
        }
      }
      if (fromRegistration || fromAlreadyTrial || fromNoSubscription) {
        handleNavigate(PATH.alreadyTrial + (couponUsed ? "?CouponUsed=true" : ""));
      } else {
        const params = couponUsed ? "?CouponUsed=true" : "?AlreadyTrial=true"
        if (dashboardMarketplace === "shopify") {
          handleNavigate(PATH.ebayLocked + params)
        } else {
          handleNavigate(PATH.shopifyLocked + params)
        }
      }
    })
  }

  useEffect(() => {
    if ((isShopify && user.shopify_already_trial === "1") || (!isShopify && user.already_trial === "1")) {
      if (handleAlreadyTrialChange) {
        handleAlreadyTrialChange(true);
      }
    }
  }, [user]);

  const horlineStyles = `w-full h-[2px] bg-secondary  rounded-full ${isTrialLimit ? "dark:bg-grey500" : "dark:bg-deepSpace900"}`
  const flexStyles = "flex items-center justify-between"
  const flexStylesWithMb = `${flexStyles} mb-6`
  const contStyles = fromRegistration
    ? "w-full max-sm:mb-0 max-lg:max-w-[376.5px] max-lg:mx-auto"
    : `rounded-[10px] max-w-[445px] border-[2px] border-[#EBF4FE] w-full px-[25px] py-7 max-sm:p-2 dark:bg-black900 dark:border-grey700 dark:text-grey100"} ${wrapperStyles || ""}`
  const toggleInput = () => {
    setShowInput(!showInput);
  };

  return (
    <div
      className={contStyles}
    >
      <h4 className={`font-medium dark:text-grey100 ${fromRegistration ? "text-center text-lg mb-[15px] max-lg:mb-2 " : "mb-6"} ${titleStyles}`}>Order Summary</h4>
      {isLoading ? (
        <Loading
          fill="fill-primaryPurple dark:fill-purple500"
          className="h-[100px] flex items-center justify-center"
        />
      ) : (
        <>
          <div className={`${horlineStyles} ${fromRegistration ? "mb-4" : "mb-6"} `} />
          <div className={`${flexStylesWithMb} max-sm:mb-2.5`}>
            <div className="flex items-center gap-4">
              <div className={`text-sm ${fromRegistration ? "font-medium" : "font-bold"}`}>
                {selectedPlan.planName}  {isShopify ? <span className="font-medium">(Shopify)</span> : (!fromRegistration && <span className={isTrialLimit ? "font-medium" : ""}>{isTrialLimit ? "/ " : ""}{getPlanFullSuffix(selectedPlan.type)}</span>)}
              </div>
              {isTrialLimit ? (
                <div className="flex justify-center items-center w-[62px] h-8 bg-neutral2 rounded-full dark:bg-deepSpace900">
                  <Ebay className="w-[41px] h-[21.75px]" />
                </div>
              ) : ""}
            </div>
            <div className="font-medium text-sm">{formatCurrency(data?.result.price || "")} {fromRegistration && !isShopify && getPlanFullSuffix(selectedPlan.type)}</div>
          </div>
          {data?.result.extraDiscount ? (
            <div className={`${flexStylesWithMb} max-lg:mb-2`}>
              <h4 className="font-medium text-sm">Current Plan Discount</h4>
              <p className="font-medium text-sm uppercase text-success">
                -{formatCurrency((data?.result.extraDiscount) || 0)}
              </p>
            </div>
          ) : ""}

          {data?.result.skipDiscount !== null && data?.result.skipDiscount !== 0 && (
            <div className={`${flexStylesWithMb} max-lg:mb-2`}>
              <h4 className="font-medium text-sm">Skip Trial Discount</h4>
              <p className="font-medium text-sm uppercase text-success">
                -{formatCurrency(data?.result.skipDiscount || 0)}
              </p>
            </div>
          )}
          {data?.result.couponDiscountPercentage !== null && (
            <div className={`${flexStylesWithMb} max-lg:mb-2 max-sm:mb-2.5`}>
              <div className={` text-sm font-medium ${promoOrGiftStyles}`}>
                Promo or Gift Code {getPlanFullSuffix(selectedPlan.type) !== "Yearly" ? `(${data?.result.couponDiscountPercentage}%)` : ""}</div>
              <div className="font-medium text-sm uppercase text-success">
                -{formatCurrency(data?.result.couponDiscount || 0)}
              </div>
            </div>
          )}


          {data?.result.vatCharge != null && (
            <div className={`${flexStyles} max-sm:flex-col w-full`}>
              <div className="flex justify-between w-full">
                <h4 className={`text-sm uppercase ${fromRegistration ? "font-medium" : "font-bold"}`}>Vat<span className="font-medium">({data?.result.vatPercentage}%)</span></h4>
                <span className="ml-4">
                  {data?.result.vatNumber}
                </span>
              </div>
              <div className={`flex  w-full ${data?.result.eligibleForVat ? "justify-between" : "justify-end"}`}>
                {data?.result.eligibleForVat && (
                  <button onClick={toggleInput} className="w-[102px] h-5 text-sm underline text-electricBlue mr-[10px]">
                    + Add Number
                  </button>
                )}
                <p className="font-medium text-sm">${data?.result.vatCharge}</p>
              </div>
            </div>
          )}
          {showInput && (
            <AddVatNumberInput handleVatNumberUpdate={handleVatNumberUpdate} hideInput={hideInput} data={data} />
          )}
          <div className={`${horlineStyles} ${fromRegistration ? "mt-4 mb-8 max-lg:my-4 max-sm:mb-3" : "my-6"}`} />
          {(fromRegistration || isDollarTrial) && !selectedPlan.skip && data?.result.discountedPrice !== data?.result.totalToday &&
            <div className={`${flexStylesWithMb} font-medium text-sm max-lg:mb-2 max-sm:mb-2.5`}>
              <h6>Total after 7 days</h6>
              <p>{formatCurrency(data?.result.discountedPrice || 0)}</p>
            </div>}
          <div className={`${flexStylesWithMb} ${fromRegistration ? "text-lg" : "text-sm"}  max-sm:mb-2.5 max-lg:mb-2`}>
            <div className={`font-bold `}>{(fromRegistration || isDollarTrial) ? "Total today" : data?.result.isActiveTrial ? "Total in " + daysForTrial + (daysForTrial === "1" ? " day" : " days") : "Total"}</div>
            <div className="font-medium">
              {formatCurrency(data?.result.totalToday || 0)}
            </div>
          </div>
          {fromRegistration || isDollarTrial ? (
            <div className={`${flexStyles} text-xs font-medium text-neutral3 ${cancelAnytimeClassName}`}>
              <p>Cancel anytime hassle free</p>
              <button className="underline" onClick={() => dispatch(handleBootIntercom(true))}>
                Need Help?
              </button>
            </div>
          ) : ""}
          {data?.result.skipDiscount !== null && data?.result.defaultCouponName && data?.result.frequency === 'Monthly' && data?.result.skipDiscount !== 0 &&
            <p className="font-medium text-sm mt-3">
              You skipped the trial and will receive {formatCurrency(data?.result.skipDiscount || "")} discount this month. The {data?.result.defaultCouponName} coupon will be applied to your {isShopify ? "next month's" : "next 3 months"} bill.
            </p>
          }
        </>
      )}
      {
        (alreadyTrial || couponUsed) && !hideAlreadyTrialWarning &&
        <div className="mt-[15px]">
          <div className={fromRegistration ? "w-full h-[2px] bg-secondary rounded-full mb-2.5 max-lg:mb-2 max-w-[283px] mx-auto max-sm:mb-3.5 max-lg:max-w-[376.5px]" : `${horlineStyles} mb-6`} />
          <div className="mb-2.5 text-sm font-bold text-center max-lg:mb-2 max-sm:mb-3.5 max-lg:max-w-[376.5px] max-lg:text-left max-lg:mx-auto">{selectedPlan.planName} {isShopify ? "Shopify" : getPlanFullSuffix(selectedPlan.type)}</div>
          <p className="mb-[15px] text-sm text-center font-medium w-full max-lg:mb-4 max-sm:mb-[35px] max-lg:max-w-[376.5px] max-lg:text-left max-lg:mx-auto">
            {alreadyTrial ? "You used a Trial before, do you wish to continue with the subscription?" : "Exclusive coupon previously used. The full amount will be charged. Would you like to continue with your subscription?"}
          </p>
          <div className="flex justify-center items-center gap-3 flex-col max-w-[215px] max-lg:max-w-[576px] max-lg:gap-4 mx-auto max-sm:gap-5">
            <Button
              title="Yes"
              height="h-11 max-lg:h-16 max-sm:h-[60px]"
              titleClassName="!text-base font-bold"
              handleClick={() => handleAlreadyTrialChange && handleAlreadyTrialChange(false)}
              loading={false}
              className="font-medium bg-darkBlue text-smoothGreen hover:bg-smoothGreen hover:text-darkBlue hover:!border-smoothGreen"
            />
            <Button
              title="No"
              height="h-11 max-lg:h-16 max-sm:h-[60px]"
              titleClassName="!text-base font-bold"
              handleClick={() => {
                handleJourneyComplete();
              }}
              loading={journeyCompleteFetching}
              className="font-medium"
            />
          </div>
        </div>
      }
    </div >
  )
};

export default CheckoutOrderSummary;
