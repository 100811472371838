import { LANDING_APP_URL } from "config/config";
import { COOKIE_DOMAIN } from "config/constants";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import useDecodeToken, { DecodedUser } from "hooks/useDecodeToken";
import jwt from "jwt-decode";
import Processing from "pages/Loading/processing";
import Upgrade from "pages/Payment/upgrade";
import RegistrationPaymentCompeted from "pages/RegistrationFunnel/steps/RegistrationPaymentCompleted";
import ShopifyAffiliateFunnel from "pages/ShopifyAffiliateFunnel";

import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PATH } from "routes/path";
import {
  useLazyGetLatestSubscriptionQuery,
  useLazyPayoneerSubscriptionQuery,
  useLazyPaypalSubscriptionQuery
} from "store/api/managePaymentApi";
import { DASHBOARD_MARKET_PLACES, setDashboardMarketplace } from "store/slices/marketplaceSlice";
import { setUserAccessToken } from "store/slices/userSlice";
import { SubscriptionRes } from "types/services/managePayments";
import { gtmStartTrial, gtmSubscriptionPurchase } from "utils/googleTagManager";
import { SetAccessToken, SetMarketplace } from "utils/localStorage";

interface Props {
  isPaypal?: boolean;
  isPayoneer?: boolean;
  isUpgrade?: boolean;
  isCreditCard?: boolean;
  isDowngrade?: boolean;
  isChanged?: boolean;
  isShopify?: boolean;
}

const PurchaseComplete = ({
  isPaypal,
  isPayoneer,
  isUpgrade,
  isChanged,
  isCreditCard,
  isDowngrade,
  isShopify,
}: Props) => {
  const dispatch = useAppDispatch();
  const [isCouponUsed, setIsCouponUsed] = useState(false)
  const [searchParams] = useSearchParams();
  const [, , removeCookie] = useCookies(["plan", "skip", "step", "introStep"])
  const { search } = useLocation();
  const [shopify, setShopify] = useState(isShopify);
  const invoiceId = searchParams.get("INVOICE_ID") || "";
  const errorCode = searchParams.get("ERROR") || "";
  const { dashboardMarketplace } = useAppSelector((state) => state.marketplaceSlice);
  const navigate = useNavigate();
  const code = searchParams.get("code") || "";
  const state = searchParams.get("state") || "";
  const registration = !isUpgrade && !isDowngrade && !isChanged;
  const userDetails = useDecodeToken()

  useEffect(() => {
    if (isCouponUsed) {
      const userHasEbayPackage = userDetails?.package_name !== ""
      const userHasShopifyPackage = userDetails?.shopify_package_name !== "";
      if (userHasEbayPackage || userHasShopifyPackage) {
        const couponUsedParam = "?CouponUsed=true";
        if (shopify && userHasEbayPackage) {
          navigate(PATH.shopifyLocked + couponUsedParam);
        } else {
          if (userHasShopifyPackage) {
            navigate(PATH.ebayLocked + couponUsedParam);
          } else {
            navigate(couponUsedPath);
          }
        }
      } else {
        navigate(couponUsedPath);
      }
      setIsCouponUsed(false)
    }
  }, [isCouponUsed, userDetails])

  useEffect(() => {
    if (state && state.includes('course')) {
      const coursePayment = `${LANDING_APP_URL}AnalyticsSuccess/PayoneerConsent?` + window.location.href.split('?')[1];
      window.location.href = coursePayment;
    }
  }, []);

  const alreadyTrialPath = PATH.alreadyTrial + (shopify ? "?isShopify=true" : "")
  const couponUsedPath = PATH.alreadyTrial + `?CouponUsed=true${shopify ? "&isShopify=true" : ""}`
  const isShopifyDashboard = dashboardMarketplace === "shopify"
  const [getLatestSubscription, { isFetching: lastSubFetching }] = useLazyGetLatestSubscriptionQuery();

  const [paypalSubscription, { isFetching: paypalFetching, }] =
    useLazyPaypalSubscriptionQuery();

  const [payoneerSubscription, { isFetching: payoneerFetching }] =
    useLazyPayoneerSubscriptionQuery();

  const goToDashboard = (marketplace?: "shopify" | "ebay") => {
    const redirToShopify = marketplace ? marketplace === "shopify" : isShopifyDashboard
    if (!registration) {
      setTimeout(() => {
        dispatch(setDashboardMarketplace(DASHBOARD_MARKET_PLACES[redirToShopify ? "shopify" : "ebay"]))
        SetMarketplace(redirToShopify ? "shopify" : "ebay");
        navigate(redirToShopify ? PATH.shopify.marketInsights : PATH.dashboard.root);
      }, 3000);
    }
  };

  const getLastSub = (marketplace: "ebay" | "shopify") => {
    getLatestSubscription(null).unwrap().then(data => {
      if (data) {
        if (data.result.activeTrial) {
          gtmStartTrial(data);
        }
        else {
          gtmSubscriptionPurchase(data, isUpgrade, isDowngrade);
        }
      }
      if (!isCreditCard) {
        goToDashboard(marketplace);
      }
    });
  }


  const onSuccess = (res: SubscriptionRes, isShopifyPlan?: boolean) => {
    if (res) {
      const { claims, message } = res
      SetAccessToken(claims);
      dispatch(setUserAccessToken(claims));
      const decodedToken: DecodedUser = jwt(claims);
      const alreadyTrialParam = "?AlreadyTrial=true";
      const userHasEbayPackage = decodedToken.package_name !== ""
      const isShopifyParam = isShopifyPlan ? "?isShopify=true" : ""
      const userHasShopifyPackage = decodedToken.shopify_package_name !== "";
      if (message === "AlreadyTrial") {
        if (isShopify || isShopifyPlan) {
          navigate(userHasEbayPackage ?
            (PATH.shopifyLocked + alreadyTrialParam) :
            (alreadyTrialPath + isShopifyParam));
        } else {
          navigate(userHasShopifyPackage ?
            (PATH.ebayLocked + alreadyTrialParam) :
            alreadyTrialPath
          );
        }
      }
      else {
        const changeMarketplace = (marketplace: "ebay" | "shopify") => {
          dispatch(setDashboardMarketplace(DASHBOARD_MARKET_PLACES[marketplace]))
          SetMarketplace(marketplace);
          getLastSub(marketplace);
        }
        const shopifyPurchasing = isPayoneer ? isShopifyPlan : isShopify
        changeMarketplace(shopifyPurchasing ? "shopify" : "ebay");
      }
    }
  }

  const onError = (err: any) => {
    if (err.data.message?.includes("Exclusive Coupon")) {
      setIsCouponUsed(true);
    }
  }


  useEffect(() => {
    removeCookie('plan', COOKIE_DOMAIN);
    removeCookie('skip', COOKIE_DOMAIN);
    removeCookie('step', COOKIE_DOMAIN);
    removeCookie('introStep', COOKIE_DOMAIN);
  }, []);

  useEffect(() => {
    if (isPaypal) {
      paypalSubscription({
        invoiceId: invoiceId,
        errorCode: errorCode,
        isShopify
      }).unwrap().then((data) => onSuccess(data.result)).catch((err) => onError(err));
    }

    if (isPayoneer && state && !state.includes('course')) {
      const isShopifyPlan = search.includes("_shopify");
      setShopify(isShopifyPlan);
      payoneerSubscription({
        code: code,
        state: state.replace("_shopify", ""),
        isShopify: isShopifyPlan
      }).unwrap().then((data) => onSuccess(data.result, isShopifyPlan)).catch((err) => onError(err));
    }

    if (isUpgrade || isChanged || isCreditCard || isDowngrade) {
      if (!isChanged) {
        getLastSub(isShopify ? "shopify" : "ebay");
      }
      else {
        goToDashboard();
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {paypalFetching || payoneerFetching ? (
        <Processing />
      ) :
        registration ? isShopify || search.includes("_shopify") ? <ShopifyAffiliateFunnel isCC={isCreditCard} /> : (<RegistrationPaymentCompeted loading={lastSubFetching} isCC={isCreditCard} />) : (
          <Upgrade isUpgrade={isUpgrade} isChanged={isChanged} isDowngrade={isDowngrade} isRegistration={registration} />
        )}
    </>
  );
};

export default PurchaseComplete;
