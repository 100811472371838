import Toaster from "components/Toast";
import AddStore from "../components/AddStore";

const itemClass = "max-[1100px]:w-full";
const StoreAnalytics = () => (
  <>
    <div className="flex items-start gap-[25px] max-[1100px]:flex-col">
      <AddStore className={itemClass} />
      {/*  <MyStores className={itemClass} /> */}
    </div>
    <Toaster />
  </>
);

export default StoreAnalytics;
