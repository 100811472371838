import { tokenCols } from "assets/tableColumns/tokens";
import Table from "components/Table";
import { TableStyles } from "components/Table/Table";
import useTable from "hooks/useTable";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteStoreMutation,
  useGetStoreQuery,
} from "store/api/quickSettingsApi";

const tableStyles: TableStyles = {
  style: "max-h-[220px] overflow-y-auto",
  px: "px-0 pr-2",
  body: {
    rowHeight: "h-11",
    contHeight: "h-8",
  },
  head: {
    rowHeight: "h-12",
    contHeight: "h-8",
  },
  empty: {
    rowHeight: "h-[60px]",
    contHeight: "h-[37px]",
  },
};
const TokenList = () => {
  const [selectedItem, setSelectedItem] = useState<string>("");
  const { data, isLoading } = useGetStoreQuery();
  const [deleteStore, { isLoading: updateLoading }] = useDeleteStoreMutation();
  const { t } = useTranslation(['home']);
  const onDelete = (id: string) => {
    setSelectedItem(id);
    deleteStore(id);
  };
  const { table } = useTable({
    columns: tokenCols(onDelete, updateLoading, selectedItem),
    dataDeps: [data],
    columnDeps: [updateLoading, selectedItem],
    data: data?.result || [],
  });

  return (data && data?.result.length > 0) || isLoading ? (
    <Table table={table} isLoading={isLoading} styles={tableStyles} />
  ) : (
    <div className="text-sm flex justify-center items-center w-full h-full min-h-[150px]">
      <p className="-mt-6">{t('NoTokensAdded')}.</p>
    </div>
  );
};

export default TokenList;
