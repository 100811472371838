import { FACEBOOK_GROUP, YOUTUBE_CHANNEL } from "config/constants";
import { PATH } from "routes/path";

const shortcutsAndSupports = [
  {
    id: "software_tutorials",
    title: "Software Tutorials",
    translate:"SoftwareTutorials",
    path: PATH.tutorials,
  },
  {
    id: "facebook_group",
    title: "Facebook Group",
    translate:"FacebookGroup",
    href: FACEBOOK_GROUP,
  },
  {
    id: "youtube_channel",
    title: "Youtube Channel",
    translate:"YouTubeChannel",
    href: YOUTUBE_CHANNEL,
  },
  {
    id: "need_help",
    title: "Need Help? Contact Us",
    translate:"NeedHelpContactUs",
    path: "",
  },
  {
    id: "my_searches",
    title: "My Searches",
    translate:"MySearches",
    path: PATH.managementPanel.savedSearches,
  },
  {
    id: "item_folders",
    title: "Item Folders",
    translate:"ItemFolders",
    path: PATH.myProducts.root,
  },
];

export default shortcutsAndSupports;
