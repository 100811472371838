export const catResFilters = {
  keywords: "",
  page: "1",
  pageSize: "100",
  type: "all",
  location: "US",
  condition: "New",
  minPrice: "",
  maxPrice: "",
  exclude: "",
  minFeedback: "",
  maxFeedback: "",
  categoryId: "",
  categoryText: "",
  subCategoryText: "",
  userId: 0,
};

export const catResData = {
  averagePrice: 0,
  successItem: 0,
  currency: "$",
  successfulListings: 0,
  totalListing: "",
  totalSales: 0,
  totalWatch: 0,
  ebayDataViewmodelList: [],
};
