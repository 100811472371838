import { FC } from "react";
import Input, { InputProps } from ".";

interface Props extends InputProps {
  label: string;
  labelClassName?: string;
  wrapperClassName?: string;
  message?: string;
}
const LabeledInput: FC<Props> = ({
  label,
  labelClassName,
  wrapperClassName,
  message,
  ...inputProps
}) => (
  <div className={`flex flex-col gap-3.5 ${wrapperClassName || ""}`}>
    <label
      htmlFor={inputProps.name}
      className={`text-darkBlue dark:text-grey100 ${labelClassName || ""}`}
    >
      {label}
    </label>
    <Input {...inputProps} error={message} />
    {message && <p className={`text-errorRed text-xs`}>{message}</p>}
  </div>
);

export default LabeledInput;
