import { ReactComponent as Fire } from "assets/icons/fire.svg";
import Loading from "components/Loading";
import Scrollbar from "components/Scrollbar";
import { useAppSelector } from "hooks/useAppSelector";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { useGetEbayNichesQuery, useUpdateNichePreferencesMutation } from "store/api/newDashboardApi";
import { CURRENCY } from "types/currency";
import {
  MARKET_PLACES,
  SHIPPED_MODEL,
  SOURCE_MARKET_PLACES
} from "types/sellerSettings";
import { TrendingNichesList } from "types/services/newDashboard";
import { formatCurrency } from "utils/formatCurrency";
import { makeQueryParams } from "utils/makeQueryParams";

import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import { ReactComponent as SortArrowUp } from "assets/icons/sort-arrow-up.svg";
import Tooltip from "components/Tooltip";
import MainDashboardCard from "./MainDashboardCard";

interface ItemProps extends TrendingNichesList {
  isFire?: boolean;
  currency?: CURRENCY;
  productlink: string;
  location: string;
}
interface Props {
  subtitle: "Last 6 Months" | "Last 30 Days";
  title: string;
}

interface CountryType {
  selectedCountry: SOURCE_MARKET_PLACES | SHIPPED_MODEL | null | string;
}

const Item: FC<ItemProps> = ({ salesEarning, isFire, keywords, currency, productlink, location }) => (
  <div className="flex justify-between hover:text-primaryPurple dark:hover:text-purple500 py-2 px-3.5 gap-4 text-sm  text-darkBlue dark:text-grey100 cursor-pointer">
    <Link
      to={makeQueryParams(productlink, { search: keywords, action: "refresh", location })}
      target="_blank"
      rel="noreferrer"
      data-tooltip-id={productlink}
    >
      <span>{keywords.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span>
      {isFire && <Fire className="ml-2.5 inline" />}
      <Tooltip anchorId={productlink} title="Analyze Niche" />
    </Link>
    <div>{formatCurrency(salesEarning, currency)}</div>
  </div>
);

const TrendingNiches: FC<Props> = ({ subtitle, title }) => {
  const [ascending, setAscending] = useState(false);
  const [sortedColKey, setSortedColKey] = useState("")
  const [sortedNiches, setSortedNiches] = useState<TrendingNichesList[]>([]);
  const [nicheTooltip, setNicheTooltip] = useState("");
  const {
    marketplace,
    businessModel,
    sourceMarketplace,
    currency,
    shippedPlace,
  } = useAppSelector((state) => state.sellerSettings);
  const getSource = () => {
    if (([MARKET_PLACES.shopify, MARKET_PLACES.woocommerce, MARKET_PLACES.aliexpress].includes(marketplace)) && sourceMarketplace !== SOURCE_MARKET_PLACES.ebay) {
      return "aliexpress";
    } else {
      return "ebay";
    }
  };

  const productResearchPath = PATH.productResearch[getSource()];

  const [selectedCountry, setSelectedCountry] =
    useState<CountryType["selectedCountry"]>(shippedPlace);

  // State to keep track of parameter changes
  const [updatedParams, setUpdatedParams] = useState({
    selling: marketplace,
    businessModel: businessModel || 'none',
    sourceProduct: sourceMarketplace || 'none',
    productSource: shippedPlace?.toUpperCase() || 'none',
    nichesLocation: selectedCountry?.toUpperCase() || 'none',
  });
  // Update the state when parameters change
  useEffect(() => {
    let country: SOURCE_MARKET_PLACES | SHIPPED_MODEL | null | string = shippedPlace
    if (shippedPlace) {
      country = shippedPlace
    } else {
      marketplace === MARKET_PLACES.aliexpress ? country = "ali" :
        marketplace === MARKET_PLACES.ebay ? country = "us" :
          marketplace === MARKET_PLACES.ebayDe ? country = "de" :
            marketplace === MARKET_PLACES.ebayUk ? country = "uk" :
              marketplace === MARKET_PLACES.ebayAu ? country = "au" :
                country = "us"
    }

    setUpdatedParams({
      selling: marketplace,
      businessModel: businessModel || 'none',
      sourceProduct: sourceMarketplace || 'none',
      productSource: shippedPlace?.toUpperCase() || 'none',
      nichesLocation: country?.toUpperCase() || 'none',
    });
    setSelectedCountry(country);

  }, [marketplace, businessModel, sourceMarketplace, shippedPlace]);
  // Query with updated parameters
  const { data, isFetching } = useGetEbayNichesQuery(updatedParams);
  const [saveNichePreference] =
    useUpdateNichePreferencesMutation();

  useEffect(() => {
    if (
      (marketplace === MARKET_PLACES.woocommerce ||
        marketplace === MARKET_PLACES.shopify) &&
      sourceMarketplace === SOURCE_MARKET_PLACES.aliexpress || (selectedCountry === "ali")
    ) {
      setNicheTooltip(
        "Trending AliExpress niches by revenue and success rate in the past 6 months. Click any of the suggested keywords to analyze them on Product Research Tool page"
      );
    } else {
      setNicheTooltip(
        "Trending eBay niches by revenue and success rate in the past 30 days. Click any of the suggested keywords to analyze them in the Product Research Tool page"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceMarketplace, selectedCountry]);

  useEffect(() => {
    if (data) {
      setSortedNiches(data.result);
    }
  }, [data]);



  const handleSort = (by: string) => {
    setSortedColKey(by)
    const col = (by === "Revenue" && "salesEarning") || by.toLocaleLowerCase()
    setSortedColKey(by)
    if (data && data.result.length > 0) {
      setAscending((prev) => !prev);
      const sortedList = [...data.result].sort((a: Record<string, any>, b: Record<string, any>) => {
        const valueA = a[col];
        const valueB = b[col];

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        } else if (typeof valueA === 'number' && typeof valueB === 'number') {
          return ascending ? valueA - valueB : valueB - valueA;
        } else {
          return 0;
        }
      });
      setSortedNiches(sortedList);
    }
  };

  const columnSortableTitle = (key: string) => (
    <th className="group">
      <p className={`flex items-center gap-1 text-[10px] font-medium cursor-pointer dark:text-grey100 group-hover:text-primaryPurple dark:group-hover:text-purple500 
        ${key === "Revenue" ? "justify-end" : "justify-center"}`}
        onClick={() => handleSort(key)}>
        {key}
        <div className={`w-2 h-2 justify-center items-center ${sortedColKey !== key ? "hidden group-hover:flex" : "flex"}`}>
          <>{sortedColKey !== key ?
            <ArrowDown className={"w-2 h-2 group-hover:fill-primaryPurple dark:group-hover:text-purple500 "} />
            : <SortArrowUp className={`fill-darkBlue dark:fill-grey100 group-hover:fill-primaryPurple dark:group-hover:text-purple500  ${ascending ? "" : "rotate-180"}`} />
          }</>
        </div>
      </p>
    </th>
  )

  const handleCountryChange = (
    value: SOURCE_MARKET_PLACES | SHIPPED_MODEL | null | string
  ) => {
    setSelectedCountry(value);
    setUpdatedParams((prev) => ({
      ...prev,
      nichesLocation: value?.toUpperCase() || 'none',
    }))
    saveNichePreference(updatedParams)
  };

  return (
    <MainDashboardCard
      className="pb-2.5 pt-[25px]"
      title={title}
      subtitle={subtitle}
      infoTitle={nicheTooltip}
      tooltipTop="top-[1.75rem]"
      showCountryDropdown
      dropdownSelectedValue={selectedCountry}
      handleDropdownChange={handleCountryChange}
    >


      <div className="pr-2.5">
        <Scrollbar className="h-[17.4rem] flex flex-col gap-y-1.5">
          <div className="pt-4 px-[16px] flex flex-row items-center justify-between pb-2 sticky top-0 bg-white dark:bg-black900">
            {columnSortableTitle("Keywords")}
            {columnSortableTitle("Revenue")}
          </div>
          {isFetching || sortedNiches.length < 1 ? (
            <div className="w-full flex justify-center items-center h-full">
              {isFetching ? (
                <Loading fill="fill-primaryPurple dark:fill-purple500" width={40} height={40} />
              ) : (
                <p className="text-base text-neutral3">No Data</p>
              )}
            </div>
          ) : (
            sortedNiches.map((item, index) => (
              <Item
                {...item}
                key={index}
                currency={item.currencySymbol as CURRENCY || currency}
                productlink={productResearchPath}
                location={updatedParams.nichesLocation}
              />
            ))
          )}
        </Scrollbar>
      </div>
    </MainDashboardCard>
  );
};

export default TrendingNiches;
