import { ReactComponent as BackArrow } from "assets/icons/back-arrow.svg";
import Button from "components/Button";
import Header from "components/Header";
import { uniq } from "lodash";

import Toaster from "components/Toast";
import useFilters from "hooks/useFilters";
import usePagination from "hooks/usePagination";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { PATH } from "routes/path";
import { useGetFolderItemsQuery } from "store/api/foldersApi";
import { MinMax } from "types/common";
import { makeQueryParams } from "utils/makeQueryParams";
import FolderButtons from "./components/FolderButtons";
import FolderFilters from "./components/FolderFilters";
import FolderItemsList from "./components/FolderItemsList";

enum SortDir {
  asc = "asc",
  desc = "desc",
}

export interface FolderItemsFilterState {
  ebayPrice: MinMax;
  aliexpressPrice: MinMax;
  profit: MinMax;
  sales: MinMax;
  search: string;
}

export const folderItemsFiltersState: FolderItemsFilterState = {
  ebayPrice: {
    min: null,
    max: null,
  },
  aliexpressPrice: {
    min: null,
    max: null,
  },
  profit: {
    min: null,
    max: null,
  },
  sales: {
    min: null,
    max: null,
  },
  search: "",
};

const initialSort = {
  direction: SortDir.desc,
  column: "SingleScannedItemId",
};

const MyProductsItem = () => {
  const pagination = usePagination(0, 50);
  const [sort, setSort] = useState(initialSort);
  const [allChecked, setAllChecked] = useState(false);
  const { filters, setFilters } = useFilters(folderItemsFiltersState);
  const { id } = useParams();
  const onFilterClick = (newFilters: FolderItemsFilterState) => {
    setFilters(newFilters);
  };
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const { data, isFetching } = useGetFolderItemsQuery({
    startIndex: pagination.begin,
    pageSize: pagination.pageSize,
    orderByFieldName: sort.column,
    orderByFieldDirection: sort.direction,
    pageNumber: pagination.current,
    folderId: Number(id) || 0,
    minEbayPrice: filters.ebayPrice.min,
    maxEbayPrice: filters.ebayPrice.max,
    minMultiPrice: filters.aliexpressPrice.min,
    maxMultiPrice: filters.aliexpressPrice.max,
    minProfit: filters.profit.min,
    maxProfit: filters.profit.max,
    minSales: filters.sales.min,
    maxSales: filters.sales.max,
    searchValue: filters.search,
  }, { refetchOnMountOrArgChange: true });
  const [searchParams] = useSearchParams();

  const handleSort = (column: string) => {
    const dir =
      sort.column === column
        ? sort.direction === SortDir.desc
          ? SortDir.asc
          : SortDir.desc
        : SortDir.asc;

    setSort({
      column,
      direction: dir,
    });
    pagination.goto(0);
  };


  const isAliexpress = searchParams.get("tab") === "aliexpress";

  useEffect(() => {
    if (data) {
      pagination.setTotal(data.result.totalRecords);
    }
    if (allChecked) {
      const loadedData = data?.result.items.map(x => x.singleScannedItemId)
      if (loadedData) {
        const itemsToAdd = uniq([...selectedItems, ...loadedData]);
        setSelectedItems(itemsToAdd);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Header
        leftContent={
          <Button
            color="primaryPurple"
            title="Go Back"
            height="h-9.5"
            iconClassName="w-3.5"
            className="max-w-[130px] dark:bg-deepSpace900"
            StartIcon={BackArrow}
            isLink
            redirect={makeQueryParams(PATH.myProducts.root, isAliexpress ? { tab: "aliexpress" } : {})}
          />
        }
        leftContentClassName="w-full"
      />
      <div className="flex flex-col gap-5">
        <FolderButtons
          selectedItems={selectedItems}
          filters={filters}
          allChecked={allChecked}
        />
        <FolderFilters
          filters={filters}
          onFilterClick={onFilterClick}
          filterLoading={isFetching}
          isAliexpress={isAliexpress}
        />
        <FolderItemsList
          data={data?.result.items || []}
          handleSort={handleSort}
          isLoading={isFetching}
          sort={sort}
          filters={filters}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          onChangeSelectedItems={(newList) => setSelectedItems(newList)}
          pagination={pagination}
          selectedIds={selectedItems}
        />
      </div>
      <Toaster />
    </>
  );
};

export default MyProductsItem;
