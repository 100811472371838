
import { helpVideos } from "assets/data/helpVideos";
import Header from "components/Header";
import HeaderHelpVideo from "components/Header/HelpVideo";
import HeaderTab from "components/Header/Tab";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { PATH } from "routes/path";
import { useGetUserPlanQuery } from "store/api/managePaymentApi";
import Upgrade from "./components/ManagePayments/Upgrade";

const Billing = () => {
  const { pathname } = useLocation()
  const [, setCookie] = useCookies(["upCampaign"]);
  const isUpcampaign = pathname.includes(PATH.managementPanel.upcampaign);

  const { data } = useGetUserPlanQuery(null);
  const ebayPlan = data?.result.find((item) => !item.isShopify)
  const paymentStatus = ebayPlan?.status || 0;

  useEffect(() => {
    setCookie("upCampaign", isUpcampaign ? "true" : "false")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    <Header
      className="!mb-5 max-xl:!flex-row"
      leftContent={
        <HeaderTab
          title="Billing"
          isActive={true}
          className="!min-w-[65px]"
          handleClick={() => { }}
        />
      }
      rightContent={
        <HeaderHelpVideo
          videos={
            helpVideos.managePayments
          }
        />
      }
    />
    <Upgrade
      paymentStatus={paymentStatus}
    />
  </>

}

export default Billing