import { PlanList, TSelectedPlan } from "types/plans";
import PlanFooterItem from "./PlanFooterItem";

interface Props {
  planList: PlanList[];
  showCheckout?: () => void;
  handleSelectPlan?: (newVal: TSelectedPlan) => void;
  fromRegistration?: boolean;
  selectedPlan?: string;
  isPayNow?: boolean;
  isShopify?: boolean;
}


const PlanComparisonFooter = ({
  planList,
  selectedPlan,
  handleSelectPlan,
  fromRegistration = false,
  isShopify,
  isPayNow,
}: Props) => {
  const showTwoPlans = planList.length === 2;
  const mobilePlan = planList.find((plan) => plan.planId === selectedPlan);
  const sameProps = {
    handleSelectPlan,
    fromRegistration,
    isPayNow,
    showTwoPlans,
    isShopify
  }
  return (
    <div className="w-full">
      <div className="flex max-md:hidden">
        <div className="w-full" />
        {planList.map((plan) => <PlanFooterItem
          key={plan.planId}
          plan={plan}
          {...sameProps} />)}
      </div>
      <div className="md:hidden">
        {mobilePlan && (
          <PlanFooterItem
            plan={mobilePlan}
            {...sameProps} />
        )}
      </div>
    </div>
  )
}

export default PlanComparisonFooter;
