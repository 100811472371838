import { createApi } from "@reduxjs/toolkit/query/react";
import {
  BulkScannerSellersRes,
  GetBulkScannedSellersReq,
  GetMultiBulkScansRes,
  MoveToFolderReq,
  PatchEditMultiBulkReq,
  PatchEditMultiBulkRes,
  PostBulkScannerItemsListReq,
  PostMatchItemsListRes,
  PostMatchItemsReq,
  PostMatchItemsRes,
  PostNotFoundItemsListRes,
  PostSaveBulkReq,
  PostSaveBulkRes,
  SaveAllItemsReq,
} from "types/services/bulkScanner";
import { UpdateSiteReq } from "types/services/user";
import { makeQueryParams } from "../../utils/makeQueryParams";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";
import { queryTags } from "./queryTags";

const {
  scannedSellers,
  multiBulkSellers,
  matchedItemsList,
  similarItemsList,
  notFoundItemsList,
} = queryTags.bulkScanner;

export const bulkScannerApi = createApi({
  reducerPath: "bulkScanner",
  baseQuery,
  tagTypes: [
    scannedSellers,
    multiBulkSellers,
    matchedItemsList,
    similarItemsList,
    notFoundItemsList,
    queryTags.quickSettings.getSettings,
  ],
  endpoints: (builder) => ({
    getMultiBulkScans: builder.query<GetMultiBulkScansRes, null>({
      query: () => ({
        url: queryKeys.zikPro.bulkScanner.multiBulkList,
        method: "GET",
      }),
      providesTags: [multiBulkSellers],
    }),
    getScannedSellers: builder.query<
      BulkScannerSellersRes,
      GetBulkScannedSellersReq
    >({
      query: (body) => ({
        url: queryKeys.zikPro.bulkScanner.scannedSellerList,
        method: "POST",
        body,
      }),
      providesTags: [scannedSellers],
    }),

    deleteScannedSeller: builder.mutation({
      query(body: string[]) {
        return {
          url: queryKeys.zikPro.bulkScanner.deleteScannedSeller,
          method: "DELETE",
          body,
        };
      },
      invalidatesTags: [scannedSellers],
    }),
    saveBulk: builder.mutation<PostSaveBulkRes, PostSaveBulkReq>({
      query(body) {
        return {
          url: queryKeys.zikPro.bulkScanner.saveBulk,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [multiBulkSellers],
    }),
    editMultiBulk: builder.mutation<
      PatchEditMultiBulkRes,
      PatchEditMultiBulkReq
    >({
      query(body) {
        return {
          url: queryKeys.zikPro.bulkScanner.editMultiBulk,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: [multiBulkSellers],
    }),
    deleteMultiBulkSeller: builder.mutation({
      query(id) {
        return {
          url: makeQueryParams(
            queryKeys.zikPro.bulkScanner.deleteMultiBulkSeller,
            { multiBulkScanRequestId: id }
          ),
          method: "DELETE",
        };
      },
      invalidatesTags: [multiBulkSellers],
    }),

    getMatchItems: builder.query<PostMatchItemsRes, PostMatchItemsReq>({
      query: (params) => ({
        url: makeQueryParams(queryKeys.zikPro.bulkScanner.matchItems, params),
        method: "POST",
      }),
      providesTags: [matchedItemsList],
    }),
    getMatchItemsList: builder.query<
      PostMatchItemsListRes,
      PostBulkScannerItemsListReq
    >({
      query: (body) => ({
        url: queryKeys.zikPro.bulkScanner.matchItemsTableData,
        method: "POST",
        body,
      }),
      providesTags: [matchedItemsList],
    }),
    getSimilarItemsList: builder.query<
      PostMatchItemsListRes,
      PostBulkScannerItemsListReq
    >({
      query: (body) => ({
        url: queryKeys.zikPro.bulkScanner.similarItemsTableData,
        method: "POST",
        body,
      }),
      providesTags: [similarItemsList],
    }),
    getNotFoundItemsList: builder.query<
      PostNotFoundItemsListRes,
      PostBulkScannerItemsListReq
    >({
      query: (body) => ({
        url: queryKeys.zikPro.bulkScanner.notFoundItemTableData,
        method: "POST",
        body,
      }),
      providesTags: [notFoundItemsList],
    }),
    moveToFolder: builder.mutation<number | string, MoveToFolderReq>({
      query: (body) => ({
        url: queryKeys.iconFinder.saveItem,
        method: "POST",
        body,
      }),
    }),
    saveAllItems: builder.mutation<null, SaveAllItemsReq>({
      query: (body) => ({
        url: queryKeys.zikPro.bulkScanner.saveAllItems,
        method: "POST",
        body,
      }),
    }),
    updateSite: builder.mutation<null, UpdateSiteReq>({
      query: (params) => ({
        url: makeQueryParams(queryKeys.user.updateSite, params),
        method: "PATCH",
      }),
      invalidatesTags: [
        notFoundItemsList,
        similarItemsList,
        matchedItemsList,
        queryTags.quickSettings.getSettings,
      ],
    }),
  }),
});

export const {
  useGetMultiBulkScansQuery,
  useGetMatchItemsListQuery,
  useGetMatchItemsQuery,
  useGetSimilarItemsListQuery,
  useGetNotFoundItemsListQuery,
  useGetScannedSellersQuery,
  useSaveBulkMutation,
  useEditMultiBulkMutation,
  useDeleteMultiBulkSellerMutation,
  useDeleteScannedSellerMutation,
  useMoveToFolderMutation,
  useSaveAllItemsMutation,
  useUpdateSiteMutation,
} = bulkScannerApi;
