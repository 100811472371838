import { createApi } from "@reduxjs/toolkit/query/react";
import { UsesRemainingRes } from "types/banners";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";

export const bannersApi = createApi({
  reducerPath: "BannersApi",
  baseQuery,
  endpoints: (builder) => ({
    getRemainingUses: builder.query<UsesRemainingRes, string>({
      query: (couponName) => ({
        url: queryKeys.banners.getUsesRemaining + `/${couponName}`,
        method: "GET",
      }),
    }),
    bannersImpression: builder.mutation<null, null>({
      query: () => ({
        url: queryKeys.banners.impression,
        method: "POST",
      }),
    }),
    bannersClick: builder.mutation<null, null>({
      query: () => ({
        url: queryKeys.banners.click,
        method: "POST",
      }),
    }),
    bannersClose: builder.mutation<null, null>({
      query: () => ({
        url: queryKeys.banners.close,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useBannersClickMutation,
  useBannersCloseMutation,
  useBannersImpressionMutation,
  useGetRemainingUsesQuery,
} = bannersApi;
