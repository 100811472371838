import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import Button from "components/Button";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import FiltersCard from "components/Filters/FiltersCard";
import Select from "components/Select";
import useFilters from "hooks/useFilters";

// Icons
import { ReactComponent as Price } from "assets/icons/price.svg";
import { ReactComponent as Store } from "assets/icons/store.svg";
import Toaster from "components/Toast";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { queryTags } from "store/api/queryTags";
import {
  useGetSettingsQuery,
  useUpdateStoreSettingsMutation
} from "store/api/quickSettingsApi";
import { storeApi, useGetStoresQuery } from "store/api/storeApi";
import { getCountryFlag } from "utils/getCountryFlag";

const storeSite = [
  { text: "United States", value: ".com" },
  { text: "United Kingdom", value: ".co.uk" },
  { text: "Canada", value: ".ca" },
  { text: "Australia", value: ".au" },
  { text: "France", value: ".fr" },
  { text: "Germany", value: ".de" },
  { text: "Italy", value: ".it" },
  { text: "Spain", value: ".es" },
  { text: "Malaysia", value: ".com.my" },
];

const currencies = [
  { text: "British pound - £", value: "GBP" },
  { text: "USD - $", value: "USD" },
  { text: "AUD - $", value: "AUD" },
  { text: "EUR - €", value: "EUR" },
];

const initialState = {
  store: "",
  storeSite: ".com",
  currency: "GBP",
};

const getFlag = (val: string) => {
  const flag =
    val === ".com"
      ? "us"
      : val === ".co.uk"
        ? "gb"
        : val === ".com.my"
          ? "my"
          : val.split(".")[1];

  return flag?.toLocaleUpperCase();
};

const StoreSettings = () => {
  const { filters, handleFilterChange, setFilters } = useFilters(initialState);
  const { data } = useGetSettingsQuery();
  const [updateStore, { isLoading }] = useUpdateStoreSettingsMutation();
  const { data: storeList } = useGetStoresQuery();
  const { t } = useTranslation(['home']);
  const dispatch = useDispatch();
  const onUpdateSuccess = () => {
    toast.success("Store changes successfully made.");
    dispatch(

      storeApi.util.invalidateTags([
        queryTags.store.loadStore,
      ])
    )

  }

  const onUpdateFail = () =>
    toast.error("Something went wrong, Please try again.");

  const onSave = () => {
    if (filters.store === "") {
      toast.error(
        "No stores have been connected. Please connect at least one store to use this option."
      );
    } else {
      updateStore({
        storeName: filters.store,
        storeLocation: filters.storeSite,
        storeCurrency: filters.currency,
      })
        .unwrap()
        .then(onUpdateSuccess)
        .catch((error) => {
          if (error.data === "Ok") {
            onUpdateSuccess();
          } else {
            onUpdateFail();
          }
        });
    }
  };
  const list =
    storeList?.result.map((item) => ({
      text: item.ebayUsername,
      value: item.ebayUsername,
    })) || [];

  useEffect(() => {
    if (data) {
      setFilters({
        storeSite: data?.result.storeSiteDomain || ".com",
        currency:
          data?.result.currencySymbol === "$"
            ? "USD"
            : data?.result.currencySymbol,
        store: list?.[0]?.value || "",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (list.length > 0) {
      handleFilterChange(list?.[0].value, "store");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeList]);

  return (
    <>
      <div className="grid grid-cols-5 gap-8 max-xl:grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1">
        <FiltersCard
          title={t('Marketplace')}
          titleClassName="text-sm m-auto"
          Icon={SearchIcon}
          iconClassName="w-3.5"
        >
          <Select
            options={storeSite}
            btnClassName="w-full"
            value={filters.storeSite}
            img={getCountryFlag(getFlag(filters.storeSite))}
            handleChange={(newValue) => {
              handleFilterChange(newValue, "storeSite");
            }}
          />
        </FiltersCard>

        <FiltersCard
          title={t('SelectStore')}
          Icon={Store}
          iconClassName="w-3.5 h-3.5"
        >
          <Select
            options={[
              {
                text: "Select Store",
                value: "",
              },
              ...list,
            ]}
            btnClassName="w-full"
            value={filters.store}
            handleChange={(newValue) => {
              handleFilterChange(newValue, "store");
            }}
          />
        </FiltersCard>

        <FiltersCard
          title={t('Currency')}
          Icon={Price}
          iconClassName="w-3.5 h-3.5"
        >
          <FilterCheckbox
            options={currencies}
            handleChange={(value) => handleFilterChange(value, "currency")}
            value={filters.currency}
          />
        </FiltersCard>
      </div>
      <div className="w-full mt-2.5 flex justify-center">
        <Button
          title="Save"
          height="h-[43px]"
          loading={isLoading}
          className="max-w-[230px] dark:bg-deepSpace900"
          handleClick={onSave}
        />
      </div>
      <Toaster />
    </>
  );
};

export default StoreSettings;
