import { ReactNode } from "react";

interface Props {
  tabs?: ReactNode;
  className?: string;
}

const SettingTabs = ({ tabs, className }: Props) => (
  <div
    className={`flex items-center justify-between bg-darkBlue p-1 rounded-full dark:bg-lilac400 ${className || ""
      }`}
  >
    {tabs}
  </div>
);

export default SettingTabs;
