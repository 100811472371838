import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const useTabs = <T>(initialTab: T, isSubTab?: boolean) => {
  const [activeTab, setActiveTab] = useState<T>(initialTab);
  const [searchParams, setSearchParams] = useSearchParams();
  const key = isSubTab ? "subtab" : "tab";
  useEffect(() => {
    setActiveTab((searchParams.get(key) as T) || initialTab);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onTabChange = (tab: string) => {
    if (activeTab !== tab) {
      if (isSubTab) {
        searchParams.set("subtab", tab);
        setSearchParams(searchParams);
      } else {
        setSearchParams(tab === initialTab ? "" : { tab });
      }
    }
  };

  return {
    activeTab,
    onTabChange,
  };
};

export default useTabs;
