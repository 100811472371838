import { buyingFormats, conditionList, ebayPECategories, getMarketplaceFlag, getMarketplaceFromSettings, initialPEFiltersState, marketPlaces, shippingLocations, supplierSites } from "assets/data/ebayPEFilters";
import { saleDateRangeArr } from "assets/data/filters";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import { ReactComponent as Box } from "assets/icons/boxes/box.svg";
import { ReactComponent as CategoryResearch } from "assets/icons/category-research.svg";
import { ReactComponent as Clear } from "assets/icons/clear-input.svg";
import { ReactComponent as DollarIcon } from "assets/icons/dollar.svg";
import { ReactComponent as Dropshipper } from "assets/icons/dropshipper.svg";
import { ReactComponent as Globe } from "assets/icons/globe.svg";
import { ReactComponent as LocationIcon } from "assets/icons/location.svg";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as Pen } from "assets/icons/pen.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as ShippingLocation } from "assets/icons/shipping-location.svg";
import { ReactComponent as Star } from "assets/icons/star.svg";
import { ReactComponent as Time } from "assets/icons/time.svg";
import Button from "components/Button";

import Card from "components/Card";
import InfoTooltip from "components/Card/InfoTooltip";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import FiltersCard from "components/Filters/FiltersCard";
import MinMaxFilterAutoScale from "components/Filters/MinMaxFilterAutoScale";
import InputTags from "components/InputTags";
import Loading from "components/Loading";
import Select from "components/Select";
import useDecodeToken from "hooks/useDecodeToken";
import useFilters from "hooks/useFilters";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { useProPlusLimitsQuery } from "store/api/zikProApi";
import { PLAN_TYPE } from "types/plans";
import { EbayProductExplorerState } from "types/services/ebayProductExplorer";
import { getCountryFlag } from "utils/getCountryFlag";
import LimitReachedInfoPE from "./LimitReachedInfo";




interface Props {
    setSearchedFilters: Dispatch<SetStateAction<EbayProductExplorerState>>;
    listFetching?: boolean;
    onSearchClick: (peFilters: EbayProductExplorerState) => void;
    showFilters?: boolean;
    isDropshippersOnly: boolean;
    onDropshippersOnlyChange: (val?: boolean) => void;
}

export enum ITEM_TYPE {
    dropshippersOnly = "dropshippers-only",
}
const dropshippersOnly = { text: "Dropshippers Only", value: ITEM_TYPE.dropshippersOnly, icon: Dropshipper, iconClassName: "w-[18px] h-[18px] -mr-2" }

type TagInputKeys = "searchByKeywords" | "excludeKeywords"
const EbayProductExplorerFilters: FC<Props> = ({ setSearchedFilters, listFetching, onSearchClick, showFilters, isDropshippersOnly, onDropshippersOnlyChange }) => {
    const user = useDecodeToken();
    const plan = user.package;
    const isTrial = /^true$/i.test(user.active_trail);
    const [isFirstTimeSearch, setIsFirstTimeSearch] = useState(true);
    const showLimitInfo = isTrial || plan === PLAN_TYPE.PRO || plan === PLAN_TYPE.PRO_PLUS
    const { data: limitsData, isFetching: limitsLoading } = useProPlusLimitsQuery("EbayPe", { skip: !showLimitInfo, refetchOnMountOrArgChange: true });
    const { filters, setFilters, handleFilterChange } = useFilters(initialPEFiltersState);
    const hideSearchBtn = showLimitInfo ? limitsData?.result.scansLeft === 0 : false
    const { t } = useTranslation(['home']);
    const { data: settings } = useGetSettingsQuery()
    const handleSupplierSiteChange = (newValue: string | number) => {
        const { supplierWebsite } = filters
        const find = supplierWebsite.includes(newValue.toString());
        const remove = supplierWebsite.filter((item) => item !== newValue);
        if (filters.supplierWebsite.length === 3 && !find) {
        } else {
            setFilters((prev) => ({
                ...prev,
                supplierWebsite: find ? remove : [...prev.supplierWebsite, newValue.toString()]
            }));
        }
    }

    const showSelectAll = filters.categories.length === 0
    const cats = ebayPECategories[filters.marketplace === marketPlaces[0] ?
        marketPlaces[1] : filters.marketplace].map((item) => ({ value: item, text: item }));
    const catsLoading = false;
    const dividingPoint = cats.length % 2 === 1 ? cats.length / 2 + 1 : cats.length / 2
    const col1List = cats.slice(0, dividingPoint);
    const col2List = cats.slice(dividingPoint, cats.length);

    const onMarketplaceChange = (newValue: string | number) => {
        setFilters((prev) => ({
            ...prev,
            categories: [],
            supplierWebsite: [],
            shippingLocation: newValue === marketPlaces[1] ? shippingLocations[1] : newValue === marketPlaces[2] ? shippingLocations[2] : shippingLocations[0],
            marketplace: newValue.toString()
        }))
    }

    const handleCategoryChange = (newValue: string | number, isAllBtn?: boolean) => {
        const { categories } = filters
        const find = categories.includes(newValue.toString());
        const remove = categories.filter((item) => item !== newValue);
        setFilters((prev) => ({
            ...prev,
            categories: isAllBtn ? showSelectAll ? cats.map((item) => item.value) : [] :
                find ? remove : [...prev.categories, newValue.toString()]
        }));

    }
    const handleDateRangeChange = (newValue: string | number) => {
        const { salesDateRange } = filters
        const find = salesDateRange.includes(newValue.toString());
        const remove = salesDateRange.filter((item) => item !== newValue);
        const length = filters.salesDateRange.length
        if ((length === 3 && !find) || (length === 1 && find)) {
            return;
        } else {
            setFilters((prev) => ({
                ...prev,
                salesDateRange: find ? remove : [...prev.salesDateRange, newValue.toString()]
            }));
        }
    }

    const resetFilters = () => {
        if (settings) {
            const { result } = settings
            onDropshippersOnlyChange(false);
            const newFilters = {
                ...initialPEFiltersState,
                marketplace: getMarketplaceFromSettings(result.sellerSite || ".com"),
                salesDateRange: result.competitorDay.split(","),
                shippingLocation: result.sellerSite === ".com" ? shippingLocations[1] : result.sellerSite === ".co.uk" ? shippingLocations[2] : shippingLocations[0]
            }
            setFilters(newFilters);
            setSearchedFilters(newFilters);
        }

    }
    const handleSearchClick = () => {
        setSearchedFilters(filters);
        onSearchClick(filters);
    }

    useEffect(() => {
        if (settings) {
            const { result } = settings;
            const newFilts = {
                ...filters,
                marketplace: getMarketplaceFromSettings(result.sellerSite || ".com"),
                salesDateRange: result.competitorDay.split(","),
                shippingLocation: result.sellerSite === ".com" ? shippingLocations[1] : result.sellerSite === ".co.uk" ? shippingLocations[2] : shippingLocations[0]
            }
            setFilters(newFilts);
            if (!isFirstTimeSearch) {
                setSearchedFilters(newFilts);
                onSearchClick(newFilts);
            }
        }
    }, [settings])


    const addTags = (value: string, tagType: TagInputKeys) => {
        if (filters[tagType].length >= 10) {
            toast.warn("You have reached limit of provided keywords (10)");
            return;
        }
        setFilters((prev) => ({
            ...prev,
            [tagType]: [...filters[tagType], value]
        }))
    }
    const removeTag = (index: number, tagType: TagInputKeys, isRemoveWithBackSpace?: boolean) => {
        const remove = isRemoveWithBackSpace ? filters[tagType].slice(0, filters[tagType].length - 1) : filters[tagType].filter((el, i) => i !== index)
        setFilters((prev) => ({
            ...prev,
            [tagType]: remove
        }))
    }

    return (
        <Card className={`p-4 mb-5 ${showFilters ? "" : "hidden"}`}>
            <div className="flex gap-4 w-full max-md:flex-col">
                <div className="w-full">
                    <FiltersCard
                        Icon={SearchIcon}
                        title="Search by Keywords"
                        className="!p-3.5 mb-3.5"
                        infoTitle="Enter keywords that best describe what you're searching for."
                        titleClassName="mr-auto ml-[21px]"
                        titleWrapperClassName="mb-[15.2px]"
                        iconClassName="w-[14px] h-[14px]"
                    >
                        <InputTags
                            tagClassname="py-[6px]"
                            wrapperClassName="min-h-[44px] !py-[6px]"
                            tags={filters.searchByKeywords}
                            PlaceHolderIcon={SearchIcon}
                            addTag={(val) => addTags(val, "searchByKeywords")}
                            removeTag={(val) => removeTag(val, "searchByKeywords")}
                            removeWithBackspace={() => removeTag(0, "searchByKeywords", true)}
                        />
                    </FiltersCard>
                    <FiltersCard
                        Icon={Pen}
                        title="Exclude Keyword"
                        className="!p-3.5 mb-3.5"
                        infoTitle={"Enter any keywords you want to exclude from your search. For example, if you're not looking for cat-related items, type " + '"Cat"' + " to filter those out."}
                        titleClassName="mr-auto ml-[21px]"
                        titleWrapperClassName="mb-[15.2px]"
                        iconClassName="w-[14px] h-[14px]"
                    >
                        <InputTags
                            tags={filters.excludeKeywords}
                            tagClassname="py-[6px] "
                            wrapperClassName="min-h-[44px] !py-[6px]"
                            PlaceHolderIcon={SearchIcon}
                            addTag={(val) => addTags(val, "excludeKeywords")}
                            removeTag={(val) => removeTag(val, "excludeKeywords")}
                            removeWithBackspace={() => removeTag(0, "excludeKeywords", true)}
                        />
                    </FiltersCard>
                    <div className="grid grid-cols-3 max-xl:grid-cols-2 max-sm:grid-cols-1 gap-x-4 gap-y-[15.2px] mb-[20.8px]">
                        <FiltersCard
                            Icon={LocationIcon}
                            title={t('Marketplace')}
                            iconClassName="w-3.5 h-3"
                            className="pb-6"
                            titleWrapperClassName="mb-4"
                            infoTitle="Filter results based on eBay marketplaces"
                        >
                            <Select
                                img={getCountryFlag(getMarketplaceFlag[filters.marketplace])}
                                iconClassName="w-4 h-4"
                                options={marketPlaces.map((item) => ({ text: item, value: item }))}
                                btnClassName="w-full !h-9"
                                listWrapperClassName="!top-11"
                                value={filters.marketplace}
                                handleChange={onMarketplaceChange}
                            />
                        </FiltersCard>
                        <FiltersCard
                            Icon={ShippingLocation}
                            iconClassName="w-4.5 h-[11px]"
                            titleWrapperClassName="mb-4"
                            className="pb-6"
                            title={t('ShippingLocation')}
                            infoTitle="Filter data to items shipped from a specific location"
                        >
                            <Select
                                options={shippingLocations.map((item) => ({ text: item, value: item }))}
                                btnClassName="w-full !h-9"
                                listWrapperClassName="!top-11"
                                value={filters.shippingLocation}
                                handleChange={(value) =>
                                    handleFilterChange(value, "shippingLocation")
                                }
                            />
                        </FiltersCard>
                        <FiltersCard
                            Icon={Menu}
                            title="Buying Format"
                            className="h-full pb-6"
                            titleWrapperClassName="mb-4"
                            iconClassName="w-[17px] h-[11px]"
                            infoTitle="Select if you want to see fixed price listings, auctions or both."
                        >
                            <Select
                                options={[]}
                                btnClassName="w-full !h-9"
                                listWrapperClassName="!top-11 pr-2"
                                title={buyingFormats.find((item) => item === filters.buyingFormat) || "Select Option"}
                                CustomOptions={
                                    <FilterCheckbox
                                        checkmarkClassName="!w-2 !h-[6px] mt-[2px]"
                                        disabledOptionClassName="!!bg-grey100"
                                        checkboxClassName="!w-4 !h-4"
                                        optionLabelClassName="!h-9 !pl-3 pr-2 border-transparent bg-transparent dark:bg-transparent"
                                        selectedCheckmarkClassName="border-primaryPurple dark:!border-purple500"
                                        className="!gap-[3px]"
                                        options={buyingFormats.map((item) => ({ text: item, value: item !== buyingFormats[0] ? "" : item }))}
                                        handleChange={(val) => {
                                            handleFilterChange(filters.buyingFormat === val ? "" : val, "buyingFormat")
                                        }}
                                        value={filters.buyingFormat}
                                    />
                                }
                                value={""}
                                handleChange={() => { }}
                            />

                        </FiltersCard>
                        <FiltersCard
                            Icon={Star}
                            title={t('Condition')}
                            iconClassName="w-3.5 h-[13px]"
                            className="pb-6"
                            titleWrapperClassName="mb-4"
                            infoTitle="Choose the condition of the items you want to analyze. "
                        >
                            <Select
                                options={[]}
                                listWrapperClassName="!top-11 pr-2"
                                btnClassName="w-full !h-9"
                                title={filters.condition.length === 0 ? "Select Condition" : conditionList.find((item) => item === filters.condition)}
                                CustomOptions={
                                    <FilterCheckbox
                                        options={conditionList.map((item) => ({ text: item, value: item === conditionList[2] ? "" : item }))}
                                        checkmarkClassName="!w-2 !h-[6px] mt-[2px]"
                                        selectedCheckmarkClassName="border-primaryPurple dark:!border-purple500"
                                        checkboxClassName="!w-4 !h-4"
                                        className="!gap-[3px] mb-[3px]"
                                        optionLabelClassName="!h-9 !pl-3 pr-2 border-transparent bg-transparent dark:bg-transparent"
                                        handleChange={(val) => {
                                            handleFilterChange(filters.condition === val ? "" : val, "condition")
                                        }}
                                        value={filters.condition}
                                    />
                                }
                                value={""}
                                handleChange={() => { }}
                            />
                        </FiltersCard>
                        <FiltersCard
                            Icon={Time}
                            iconClassName="w-[11px] h-3"
                            className="pb-6"
                            titleWrapperClassName="mb-4"
                            title={t('Saledaterange')}
                            infoTitle="Filter results based on Sales Date Range"
                        >
                            <Select
                                options={[]}
                                btnClassName="w-full !h-9"
                                listWrapperClassName="!top-11"
                                title={filters.salesDateRange.length > 0 ? `${filters.salesDateRange.join(", ")} Days` : "Select Option"}
                                value={""}
                                CustomOptions={
                                    <FilterCheckbox
                                        options={saleDateRangeArr}
                                        multiselect
                                        optionLabelClassName="!h-9 pl-3 pr-2"
                                        className="!gap-[3px]"
                                        maxCheckedAndDisableAfter={3}
                                        checkboxClassName="!w-4 !h-4"
                                        checkmarkClassName="!w-2 !h-[6px] mt-[2px]"
                                        rectangleCheckbox
                                        handleChange={(value) =>
                                            handleDateRangeChange(value)
                                        }
                                        value={filters.salesDateRange}
                                    />
                                }
                                handleChange={(value) => handleFilterChange(value, "salesDateRange")}
                            />
                        </FiltersCard>

                        <FiltersCard
                            Icon={DollarIcon}
                            iconClassName="w-[14px] h-[14px]"
                            title="Sales"
                            className="!p-3.5 !pb-2.5"
                            infoTitle="Filter products by the sales generated within your selected date range."

                        >
                            <MinMaxFilterAutoScale
                                name="Sales"
                                customInputWrapperStyles="px-0 w-[77px]"
                                value={filters.sales}
                                inputWidth="max-w-[64px]"
                                handleChange={(newValue, type) =>
                                    handleFilterChange(newValue, "sales", type)
                                }
                                integerOnly
                                palceholder={{ min: "0", max: "0" }}
                                minInputLimit={9999999}
                                maxInputLimit={9999999}
                            />
                        </FiltersCard>
                        <FiltersCard
                            Icon={DollarIcon}
                            iconClassName="w-[14px] h-[14px]"
                            title="Revenue"
                            className="!p-3.5  !pb-2.5"
                            infoTitle="Filter products by the revenue generated within your selected date range."

                        >
                            <MinMaxFilterAutoScale
                                name="Revenue"
                                customInputWrapperStyles="px-0 w-[77px]"
                                value={filters.revenue}
                                inputWidth="max-w-[64px]"
                                handleChange={(newValue, type) =>
                                    handleFilterChange(newValue, "revenue", type)
                                }
                                palceholder={{ min: "$0", max: "$0" }}
                                inputPrefix="$"
                                minInputLimit={9999999}
                                maxInputLimit={9999999}
                            />
                        </FiltersCard>
                        <FiltersCard
                            Icon={DollarIcon}
                            iconClassName="w-[14px] h-[14px]"
                            title="Price"
                            className="!p-3.5 !pb-2.5"
                            infoTitle="Filter products by the price generated within your selected date range."

                        >
                            <MinMaxFilterAutoScale
                                name="Price"
                                customInputWrapperStyles="px-0 w-[77px]"
                                value={filters.price}
                                inputWidth="max-w-[64px]"
                                handleChange={(newValue, type) =>
                                    handleFilterChange(newValue, "price", type)
                                }
                                palceholder={{ min: "$0", max: "$0" }}
                                inputPrefix="$"
                                minInputLimit={9999999}
                                maxInputLimit={9999999}
                            />
                        </FiltersCard>
                        <FiltersCard
                            Icon={Box}
                            iconClassName="w-[14px] h-[14px]"
                            title="Other"
                            titleWrapperClassName="mb-4"
                            className="!p-3.5"

                        >
                            <div>
                                <FilterCheckbox
                                    options={[dropshippersOnly]}
                                    rectangleCheckbox
                                    optionLabelClassName="h-11 pl-1 pr-2"
                                    checkboxClassName="!w-[18px] !h-[18px] rounded-full"
                                    checkmarkClassName="!w-2.5 !h-2 mt-[1px]"
                                    handleChange={() => {
                                        onDropshippersOnlyChange();
                                        setFilters((prev) => ({
                                            ...prev,
                                            supplierWebsite: []
                                        }))
                                    }}
                                    value={isDropshippersOnly ? ITEM_TYPE.dropshippersOnly : ""}
                                />
                                {isDropshippersOnly ? (
                                    <>
                                        <div className="w-full h-6 flex justify-center items-center ">
                                            <ArrowDown className="fill-darkBlue dark:fill-lilac400 mx-auto" />
                                        </div>

                                        <div className="bg-neutral2 dark:bg-black900 rounded-[16px] p-2">
                                            <div className="flex items-center justify-between dark:text-grey100 mb-4">
                                                <div className="w-6 h-6 flex items-center justify-center">
                                                    <Globe className="fill-darkBlue w-[14px] h-[14px] dark:fill-grey100" />
                                                </div>
                                                <h6 className="text-sm font-bold">Supplier Site</h6>
                                                <div className="w-6 h-6 flex items-center justify-center">
                                                    <InfoTooltip title="Choose which website or supplier you are sourcing your product from" className="" />

                                                </div>
                                            </div>
                                            <FilterCheckbox
                                                options={supplierSites(filters.marketplace)}
                                                multiselect
                                                optionLabelClassName="h-11 pl-3 pr-2"
                                                className="!gap-2.5"
                                                maxCheckedAndDisableAfter={3}
                                                checkboxClassName={`!w-4 !h-4`}
                                                checkmarkClassName="!w-2 !h-[6px] mt-[2px]"
                                                rectangleCheckbox
                                                handleChange={handleSupplierSiteChange}
                                                value={filters.supplierWebsite}
                                            />
                                        </div>

                                    </>

                                ) : ""}
                            </div>

                        </FiltersCard>

                    </div>
                    <div className="flex items-center w-full justify-between max-xl:flex-col">
                        <Button title="Reset Filters" className="max-w-[150px] w-full border-[0px] max-xl:mb-2 mr-auto" height="h-11" handleClick={resetFilters} />
                        {limitsLoading ? <div className="h-11 min-w-[200px] flex items-center justify-center">
                            <Loading fill="fill-primaryPurple" />
                        </div> : (
                            <div className="flex justify-end max-lg:flex-col w-full gap-6 max-lg:gap-6">
                                {showLimitInfo && (
                                    <LimitReachedInfoPE />
                                )}
                                <Button
                                    title="Search"
                                    className={`max-w-[150px] w-full dark:bg-deepSpace900 ${hideSearchBtn ? "hidden" : ""}`}
                                    height="h-11"
                                    handleClick={() => { handleSearchClick(); setIsFirstTimeSearch(false) }}
                                    loading={listFetching}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="max-w-[500px] w-full max-lg:max-w-[100%]">
                    <FiltersCard
                        Icon={CategoryResearch}
                        iconClassName="w-3 h-[11px]"
                        titleWrapperClassName="mb-[0px]"
                        title="Select Category"
                        className="!p-3.5 !pr-1.5"
                        infoTitle="Choose the categories you wish to see the items for"

                    >
                        <div className="flex justify-end w-full mb-2">
                            <Button title={showSelectAll ? "Select All" : "Deselect All"} className="border-none max-w-[130px] ml-[auto] mr-6" height="h-9"
                                Icon={showSelectAll ? undefined : Clear} iconClassName="w-2 h-2" contClassName=""
                                handleClick={() => handleCategoryChange("", true)}
                            />
                        </div>
                        {catsLoading ? (
                            <div className="flex w-full min-h-[400px] justify-center items-center">
                                <Loading fill="primaryPurple" />
                            </div>

                        ) : (

                            <div className={`flex gap-4 overflow-y-auto scrollbar pr-1.5 ${isDropshippersOnly ? "max-h-[858px]" : "max-h-[614px]"}`}>
                                <div className="w-full">
                                    <FilterCheckbox
                                        options={col1List}
                                        multiselect
                                        optionLabelClassName="h-11"
                                        value={filters.categories}
                                        rectangleCheckbox
                                        checkboxClassName="!w-4 !h-4"
                                        checkmarkClassName="!w-2 !h-[6px] mt-[2px]"
                                        className="w-full !gap-4"
                                        handleChange={handleCategoryChange}
                                    />
                                </div>
                                <FilterCheckbox
                                    options={col2List}
                                    multiselect
                                    value={filters.categories}
                                    rectangleCheckbox
                                    optionLabelClassName="h-11"
                                    checkboxClassName="!w-4 !h-4"
                                    checkmarkClassName="!w-2 !h-[6px] mt-[2px]"
                                    className="w-full !gap-4"
                                    handleChange={handleCategoryChange}
                                />
                            </div>
                        )}

                    </FiltersCard>
                </div>
            </div>
        </Card>
    )
}

export default EbayProductExplorerFilters