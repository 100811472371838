import { shopifyPlans } from "assets/data/plans/shopifyPlans";
import Trusted from "pages/RegistrationFunnel/components/Trusted";
import ShopifyPlansCont from "pages/Shopify/Billing/components/plans/PlansContainer";
import { FC } from "react";
import { TSelectedPlan } from "types/plans";
import ShopifyPlansComparison from "./PlanComparison/ShopifyPlanComparison";
interface Props {
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    isPayNow?: boolean;
    fromRegistration?: boolean;
    hideTrustpilot?: boolean;
    showComparisonOnMobile?: boolean;
    customHandleClick?: boolean;
}

const SlidedShopifyWithComparison: FC<Props> = ({ handleSelectPlan, hideTrustpilot, fromRegistration, isPayNow, customHandleClick, showComparisonOnMobile }) => {
    const sameProps = {
        handleSelectPlan,
        isPayNow
    }

    return (
        <div>
            <ShopifyPlansCont
                {...sameProps}
                customHandleClick={customHandleClick}
                isRegFunnel={fromRegistration}
                isResponsive
            />
            {!hideTrustpilot && (
                <Trusted trustpilotStyles='bg-white' paymentMethodsWrapper='border-2 border-solid border-white' wrapperClassName="max-lg:pb-6" />
            )}
            <div className={`w-full ${showComparisonOnMobile ? "max-lg:px-6" : "max-md:hidden"}`}>
                <ShopifyPlansComparison plans={shopifyPlans} {...sameProps} fromRegistration={fromRegistration} />
            </div>
        </div>
    )
}

export default SlidedShopifyWithComparison;