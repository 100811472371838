import { createApi } from "@reduxjs/toolkit/query/react";
import {
  AutopilotPrevScanFiltersRes,
  DeleteScanReq,
  DeleteSelectedScanItemReq,
  ExportProductsReq,
  ExportProductsRes,
  LoadScanReq,
  LoadScanRes,
  LoadScansRes,
  StartScanReq,
  TurboScanFiltersRes,
  UploadProductsReq,
  UploadProductsRes,
} from "types/services/autopilot";
import { makeQueryParams } from "../../utils/makeQueryParams";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";

export const autopilotApi = createApi({
  reducerPath: "AutoPilotApi",
  baseQuery,
  tagTypes: ["Scan", "SingleScan"],
  endpoints: (builder) => ({
    loadScans: builder.query<LoadScansRes, null>({
      query: () => ({
        url: queryKeys.zikPro.autopilot.loadScans,
        method: "GET",
      }),
      providesTags: ["Scan"],
    }),

    getScanFilters: builder.query<TurboScanFiltersRes, string>({
      query: (scanId: string) => ({
        url: makeQueryParams(queryKeys.zikPro.autopilot.getScanFilters, {
          scanId,
        }),
        method: "GET",
      }),
    }),
    startScan: builder.mutation<string, StartScanReq>({
      query: (body) => ({
        url: queryKeys.zikPro.autopilot.startScan,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Scan"],
    }),
    loadScan: builder.query<LoadScanRes, LoadScanReq>({
      query: (body) => ({
        url: queryKeys.zikPro.autopilot.loadScan,
        method: "POST",
        body,
      }),
      providesTags: ["SingleScan"],
    }),
    uploadProducts: builder.mutation<UploadProductsRes, UploadProductsReq>({
      query: (body) => ({
        url: queryKeys.zikPro.autopilot.uploadProducts,
        method: "POST",
        body,
      }),
    }),
    exportProducts: builder.mutation<ExportProductsRes, ExportProductsReq>({
      query: (body) => ({
        url: queryKeys.zikPro.autopilot.exportProducts,
        method: "POST",
        body,
      }),
    }),
    deleteScan: builder.mutation<null, DeleteScanReq>({
      query: ({ scanId }) => ({
        url: makeQueryParams(queryKeys.zikPro.autopilot.deleteScan, { scanId }),
        method: "DELETE",
      }),
      invalidatesTags: ["Scan"],
    }),
    deleteSelectedScan: builder.mutation<null, DeleteSelectedScanItemReq>({
      query: (body) => ({
        url: queryKeys.zikPro.autopilot.deleteSelectedScan,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["SingleScan"],
    }),
    deleteAllScan: builder.mutation<null, null>({
      query: () => ({
        url: queryKeys.zikPro.autopilot.deleteAllScans,
        method: "DELETE",
      }),
      invalidatesTags: ["Scan"],
    }),
    autopilotPrevFilters: builder.query<AutopilotPrevScanFiltersRes, void>({
      query: () => ({
        url: queryKeys.zikPro.autopilot.getPreviousScanFilters,
        method: "GET",
      }),
      providesTags: ["Scan"],
    }),
  }),
});

export const {
  useLoadScansQuery,
  useGetScanFiltersQuery,
  useStartScanMutation,
  useLoadScanQuery,
  useUploadProductsMutation,
  useExportProductsMutation,
  useDeleteAllScanMutation,
  useDeleteScanMutation,
  useDeleteSelectedScanMutation,
  useAutopilotPrevFiltersQuery,
  useLazyLoadScansQuery,
} = autopilotApi;
