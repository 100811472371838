import { ReactComponent as LeftArrow } from "assets/icons/arrow-left-extended.svg";
import Button from "components/Button";
import { Step, Stepper } from "react-form-stepper";

import { FC } from "react";
import "./Stepper.css";

interface Props {
    step: number;
    isPricingFlow: boolean;
    onStepChange: (step: number) => void;
    handleBack: () => void;
}
const stepStyleConfig = {
    activeBgColor: "#1BCFBA",
    completedBgColor: "#ECF5FF",
    inactiveBgColor: "#ECF5FF",
    activeTextColor: "#150D3F",
    inactiveTextColor: "#B0B6BD",
    completedTextColor: "#B0B6BD",
    size: 36,
    circleFontSize: 16,
    labelFontSize: 16,
    borderRadius: 100,
    fontWeight: 500,
};
const RegFunnelStepper: FC<Props> = ({ step, onStepChange, handleBack, isPricingFlow }) => {
    const checkActive = (isActive: boolean) => `${isActive ? "stepper" : "inactive-stepper"} font-bold`
    return (
        <div className={`flex justify-between max-lg:justify-center w-full top-[0px] ${step === 3 ? "max-lg:px-5" : ""}`}>
            <Button
                title="Back"
                height="h-9"
                titleClassName="!font-bold"
                iconClassName="w-[13.33px] h-[11.67px]"
                contClassName="!gap-[9.33px]"
                className={`z-40 max-w-[104px] w-full ml-[35px] bg-white max-lg:hidden mt-[25px] ${step === 0 ? "invisible" : ""}`}
                handleClick={handleBack}
                StartIcon={LeftArrow}
            />
            <Stepper
                activeStep={isPricingFlow ? (step === 3 ? step - 1 : step) : step}
                className={`bg-green max-w-[416px] grow max-lg:!pt-4 max-lg:!pb-8 max-sm:!pt-2 max-sm:!pb-6 ${step === 3 ? "!pt-[64px]" : ""}`}
                styleConfig={stepStyleConfig}
            >
                <Step
                    className={checkActive(step === 0)}
                    onClick={() => onStepChange(0)}
                />
                <Step
                    className={checkActive(step === 1)}
                    onClick={() => onStepChange(1)}
                />
                <Step
                    className={`${checkActive(isPricingFlow ? (step == 3 || step === 2) : step === 2)}`}
                    onClick={() => onStepChange(2)}
                />
                {!isPricingFlow && (
                    <Step
                        className={checkActive(step === 3)}
                        onClick={() => onStepChange(3)}
                    />
                )}
            </Stepper>
            <div className="flex flex-col gap-3 mt-[30px] max-lg:hidden">
                <div className="self-center w-[80px] h-[30px] mr-10 invisible" />
            </div>
        </div>
    )
}

export default RegFunnelStepper