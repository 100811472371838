import { shopifyHelpVideos } from "assets/data/helpVideos";
import { ReactComponent as DropshipperIcon } from "assets/icons/dropshipper.svg";
import { ReactComponent as PrintOnDemandIcon } from "assets/icons/print-on-demand.svg";
import { ReactComponent as SingleProductIcon } from "assets/icons/single-products.svg";
import { ReactComponent as MetaIcon } from "assets/icons/social/meta.svg";
import { ReactComponent as PinterestIcon } from "assets/icons/social/pinterest.svg";
import { ReactComponent as SnapchatIcon } from "assets/icons/social/snapchat.svg";
import { ReactComponent as TikTokIcon } from "assets/icons/social/tiktok.svg";
import { productExplorerCols } from "assets/tableColumns/productExplorer";
import Card from "components/Card";
import Header from "components/Header";
import HeaderHelpVideo from "components/Header/HelpVideo";
import HeaderIcon, { HeaderVariants } from "components/Header/Icon";
import HeaderTab from "components/Header/Tab";
import ShopifyItemFinder from "components/IconFinder/ShopifyItemFinder";
import Table from "components/Table";
import Toaster from "components/Toast";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import useFilters from "hooks/useFilters";
import useModal from "hooks/useModal";
import useScrollPosition from "hooks/useScrollPosition";
import useTable from "hooks/useTable";
import { useCallback, useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { shopifyApi, useLazyProductExplorerSearchQuery } from "store/api/shopifyApi";
import { MinMax } from "types/common";
import { ProductExplorerList } from "types/services/productExplorer";
import Filters from "./components/Filters";
import TableLoading from "./components/TableLoading";

export const productExplorerSalesDateRange = [
    { text: "7 Days", value: "7" },
    { text: "14 Days", value: "14" },
    { text: "30 Days", value: "30" },
    { text: "60 Days", value: "60" }
]

export const productExplorerProductType = [
    { text: "Dropshipping", value: "dropshipping", icon: DropshipperIcon, iconClassName: "text-darkBlue dark:text-purple500 group-hover:text-white" },
    { text: "One Product Store", value: "singleproduct", icon: SingleProductIcon, iconClassName: "text-darkBlue dark:text-purple500 group-hover:text-white" },
    { text: "Print on Demand", value: "print", icon: PrintOnDemandIcon, iconClassName: "text-darkBlue dark:text-purple500 group-hover:text-white" },
    { text: "Others", value: "others" }
];

export const productExplorerAdsChannel = [
    { text: "Meta/Facebook", value: "meta", icon: MetaIcon, iconClassName: "w-[17px] h-[11px]" },
    { text: "TikTok", value: "tiktok", icon: TikTokIcon, iconClassName: "w-[10px] h-[12px] dark:fill-white" },
    { text: "Pinterest", value: "pinterest", icon: PinterestIcon, iconClassName: "w-[13px] h-[13px] fill-[#CB2027]" },
    { text: "Snapchat", value: "snapchat", icon: SnapchatIcon, iconClassName: "w-[13px] h-[13px] fill-[#FFFC00]" },
]


export interface ProductExplorerState {
    searchByKeywords: string[];
    excludeKeywords: string[];
    country: string[];
    language: string[];
    currency: string[];
    salesDateRange: string;
    sales: MinMax;
    revenue: MinMax;
    price: MinMax;
    productType: string[];
    adsChannels: string[];
}

export const productExplorerFiltersState: ProductExplorerState = {
    searchByKeywords: [],
    excludeKeywords: [],
    country: [],
    language: [],
    currency: [],
    salesDateRange: "30",
    sales: {
        min: null,
        max: null
    },
    revenue: {
        min: null,
        max: null
    },
    price: {
        min: null,
        max: null
    },
    productType: [],
    adsChannels: [],

}

enum SortDir {
    asc = "asc",
    desc = "desc",
}

const initialSort = {
    direction: SortDir.desc,
    column: "sales",
};


const ProductExplorer = () => {
    const { filters, setFilters, handleFilterChange } = useFilters(productExplorerFiltersState);
    const [sort, setSort] = useState(initialSort);
    const [searchParams, setSearchParams] = useSearchParams()
    const [autoSearch, setAutoSearch] = useState((searchParams.get("autosearch") === "true"))
    const [productExplorerSearch, { isFetching, }] = useLazyProductExplorerSearchQuery()
    const [showFilters, setShowFilters] = useState(true);
    const [selectedItem, setSelectedItem] = useState<ProductExplorerList | null>(null)
    const tableRef = useRef<HTMLDivElement>(null);
    const scrollPos = useScrollPosition()
    const showHorizontalScrollbar = scrollPos > (tableRef.current?.getBoundingClientRect().top || 0);
    const modal = useModal();
    const toggleFilters = () => {
        setShowFilters(!showFilters);
    }

    const [tableHeaderDays, setTableHeaderDays] = useState(filters.salesDateRange);
    const { isExpand } = useAppSelector((state) => state.themeSlice);
    const dispatch = useAppDispatch();
    const [pageNumber, setPageNumber] = useState(1);
    const [enableScrollTrigger, setEnableScrollTrigger] = useState(false);
    const [tableList, setTableList] = useState<ProductExplorerList[]>([]);
    const handleBoxClick = (item: ProductExplorerList) => {
        modal.open();
        setSelectedItem(item);
    }

    const handleSort = (column: string) => {
        const dir =
            sort.column === column
                ? sort.direction === SortDir.desc
                    ? SortDir.asc
                    : SortDir.desc
                : SortDir.asc;

        setSort({
            column,
            direction: dir,
        });
    };

    const { table } = useTable({
        columns: productExplorerCols(handleBoxClick, tableHeaderDays, handleSort),
        columnDeps: [sort, tableHeaderDays],
        sort: true,
        data: tableList,
        dataDeps: [tableList],
    });

    const getData = (type: string) => {
        if (type === "click") {
            setPageNumber(1);
            setEnableScrollTrigger(false);
            dispatch(shopifyApi.util.resetApiState());
            setTableList([])
        }
        const {
            searchByKeywords,
            excludeKeywords,
            country,
            language,
            currency,
            adsChannels,
            price,
            revenue,
            productType,
            sales,
            salesDateRange
        } = filters

        setTimeout(() => {
            productExplorerSearch({
                pageNumber: type === "click" ? 1 : pageNumber,
                pageSize: 20,
                orderBy: sort.column,
                direction: sort.direction,
                keywords: searchByKeywords.join() || null,
                excludedKeywords: excludeKeywords.join() || null,
                countries: country.join() || null,
                languages: language.join() || null,
                currencies: currency.join() || null,
                salesDateRange: Number(salesDateRange) || null,
                salesMin: sales.min || null,
                salesMax: sales.max || null,
                revenueMin: revenue.min || null,
                revenueMax: revenue.max || null,
                priceMin: price.min || null,
                priceMax: price.max || null,
                dropshipping: productType.includes("dropshipping") || null,
                singleProductStore: productType.includes("singleproduct") || null,
                printOnDemand: productType.includes("print") || null,
                others: productType.includes("others") || null,
                adsFacebook: adsChannels.includes("meta") || null,
                adsTikTok: adsChannels.includes("tiktok") || null,
                adsPinterest: adsChannels.includes("pinterest") || null,
                adsSnapchat: adsChannels.includes("snapchat") || null
            }).unwrap().then(data => {
                if (!data.result.length) {
                    if (type === "click" || type === "autosearch") {
                        toast.warning("No stores have been found, please try using different filters");
                        setTableList([]);
                        setEnableScrollTrigger(false);

                        return;
                    }
                    toast.warning("No more data have been found under this filters");
                    setEnableScrollTrigger(false);

                    return;
                }

                if (type === "click" || type === "autosearch") {
                    setTableList(data.result);
                    setTableHeaderDays(filters.salesDateRange)
                    scrollToTableTop();
                }
                if (type === "scroll") {
                    setTableList(prevData => [...prevData, ...data.result]);
                }
                setEnableScrollTrigger(true);
            });
        }, 100)

    }


    const scrollToTableTop = () => {
        let inter = setInterval(f, 10);
        function f() {
            if (tableRef.current !== null) {
                tableRef.current.scrollIntoView();
                clearInterval(inter);
            }
        }
    }


    const observer = useRef<IntersectionObserver | null>(null);
    const scrollTrigger = useCallback((node: any) => {
        if (isFetching) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && enableScrollTrigger) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        })
        if (node) observer.current.observe(node)
    }, [isFetching, enableScrollTrigger])


    useEffect(() => {
        if (enableScrollTrigger) {
            getData("scroll")
        }

    }, [pageNumber]);

    useEffect(() => {
        if (tableList.length) { //prevent call on pageload
            getData("click")
        }
    }, [sort]);


    useEffect(() => {
        // do autosearch if enabled
        if (autoSearch && filters.searchByKeywords.length) {
            getData("autosearch");
            setAutoSearch(false);
        }
    }, [filters.searchByKeywords.length])

    return (
        <>
            <Header
                leftContent={
                    <HeaderTab
                        title="Product Explorer"
                        isActive={true}
                        className="!min-w-[86px]"
                    />
                }
                rightContent={
                    <>
                        <HeaderIcon
                            variant={HeaderVariants.filters}
                            handleClick={toggleFilters}
                            tooltipTitle="Show or Hide Filters."
                        />
                        <HeaderHelpVideo videos={shopifyHelpVideos.productExplorer} tooltip={'Watch "Product Explorer" Tutorial'} />
                    </>
                }
                middleContentClassName="max-w-[570px] w-full"
                className="!px-5 mb-[20px]"
            />

            {showFilters &&
                <Filters filters={filters} setFilters={setFilters} handleFilterChange={handleFilterChange} onSearchClick={getData} isLoading={isFetching} />
            }

            <div ref={tableRef}>
                <Card className="py-3.5" shadow="shadow-0">
                    <Scrollbars style={{ width: "100%", }} autoHeight autoHeightMax={"100%"}
                        renderTrackHorizontal={() => <div className="h-[10px] fixed right-[45px] bottom-[10px]" style={{ left: isExpand ? 230 : 95, display: showHorizontalScrollbar ? "block" : "none" }} />}
                        renderThumbHorizontal={() => <div className="h-[10px] relative rounded-full bg-secondary cursor-pointer" />}
                    >
                        <Table table={table}
                            isLoading={isFetching}
                            customLoading={<TableLoading numberOfRows={4} />}
                            styles={{
                                tableClassName: "w-full min-w-[1240px]",
                                head: {
                                    cont: "px-[16px] pl-[16px]"
                                },
                                body: {
                                    rowHeight: "h-[81px]",
                                    cont: "px-3.5"

                                },

                            }} />

                    </Scrollbars>



                    <div ref={scrollTrigger} />

                </Card>
            </div>


            {selectedItem ? (

                <ShopifyItemFinder helpers={modal} item={
                    {
                        title: selectedItem.name,
                        price: selectedItem.price,
                        sold: selectedItem.sales || 0,
                        img: selectedItem.image,
                        url: selectedItem.url,
                        id: selectedItem.productId,
                        isSimilar: false,
                        isGreen: false,
                    }
                } />
            ) : ""}
            <Toaster />
        </>
    )
}

export default ProductExplorer