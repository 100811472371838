import { useEffect } from 'react';
import LoadingPage from './Loading';

const Logout = () => {
    useEffect(() => {
        localStorage.removeItem('access');
        window.location.href = '/login';
    }, []);

    return <LoadingPage loading={true}> </LoadingPage>;
};

export default Logout;