import LoadingPage from "pages/Loading";
import { useEffect } from "react";


const ThemeDetector = () => {
    useEffect(() => {
        const themeDetectorURL = process.env.REACT_APP_THEME_DETECTOR
        if (themeDetectorURL) {
            window.location.href = themeDetectorURL

            return
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <LoadingPage loading={true}> </LoadingPage>

    )
}

export default ThemeDetector