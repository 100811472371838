import ProTools from "pages/ProTools/Index";
import { ZIK_PRO_TABS } from "pages/ZikPro";

interface Props {
  isLocked: boolean;
  children: React.ReactNode;
  activeTab: ZIK_PRO_TABS | null
}

const ProToolsWrapper = ({ children, isLocked, activeTab }: Props) =>
  isLocked ? <ProTools activeTab={activeTab} /> : <>{children}</>;

export default ProToolsWrapper;
