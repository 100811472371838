import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isBootIntercom: false,
};

const intercomSlice = createSlice({
  name: "intercomSlice",
  initialState,
  reducers: {
    handleBootIntercom: (state, { payload }: { payload: boolean }) => {
      state.isBootIntercom = payload;
    },
  },
});

export const { handleBootIntercom } = intercomSlice.actions;

export default intercomSlice.reducer;
