import Button from "components/Button";
import Card from "components/Card";
import CheckoutOrderSummary from "components/CheckoutOrderSummary/Index";
import CheckoutCouponInput from "components/Input/CheckoutCouponInput";
import PaymentMethod from "components/PaymentMethod/Index";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useGetDefaultCouponQuery,
  useLazyCheckRegistrationQuery, useLazyCheckUpgradeQuery, useLazyPayoneerPurchaseQuery,
  useLazyPaypalPurchaseQuery,
  useLazyPaypalRegistrationPurchaseQuery,
  useLazyValidateCouponQuery
} from "store/api/managePaymentApi";
import { TSelectedPlan } from "types/plans";
import { gtmOnboardingPaymentSubmit, gtmOnboardingPaypalClick } from "utils/googleTagManager";
import { getPlanName, getPlanSuffix } from "utils/planUtils";
interface Props {
  selectedPlan: TSelectedPlan;
  cardClassName?: string;
  isShopify?: boolean;
  isUpgrade?: boolean;
  hideAlreadyTrialWarning?: boolean;
  fromAlreadyTrial?: boolean;
  fromNoSubscription?: boolean
}


const SubscriptionPurchase = ({ selectedPlan, cardClassName, isShopify, isUpgrade, hideAlreadyTrialWarning, fromAlreadyTrial, fromNoSubscription }: Props) => {
  const [coupon, setCoupon] = useState("");
  const [method, setMethod] = useState("");
  const [couponUsed, setCouponUsed] = useState(false);
  const [ccPaymentLoading, setCCPaymentLoading] = useState(false);
  const [isCCTAlreadyTrial, setIsCCAlreadyTrial] = useState(false);
  const changeCCPaymentLoading = (isLoading: boolean) => setCCPaymentLoading(isLoading);
  const [couponApplying, setCouponApplying] = useState(true);
  const changeCouponApplying = (applying: boolean) => setCouponApplying(applying);
  const handleCCAlreadyTrial = (isTrial: boolean) => { setIsCCAlreadyTrial(isTrial); setCouponApplying(false); };
  const changeCouponUsed = (isUsed: boolean) => setCouponUsed(isUsed)
  const handleMethod = (type: string) => setMethod(type);
  const [paypalPurchase, { isFetching: paypalFetching }] = useLazyPaypalPurchaseQuery();
  const [paypalRegisterPurchase, { isFetching: paypalRegisterFetching }] = useLazyPaypalRegistrationPurchaseQuery();
  const [payoneerPurchase, { isFetching: payoneerFetching }] = useLazyPayoneerPurchaseQuery();
  const handleApplyCoupon = (newVal: string) => setCoupon(newVal);
  const { data: defaultCoupon, isFetching: couponLoading } = useGetDefaultCouponQuery(selectedPlan.type, { skip: selectedPlan.type === "", refetchOnMountOrArgChange: true });
  const [, { isFetching: couponValidating }] = useLazyValidateCouponQuery();
  const [checkRegister, { data: registerData, isFetching: checkRegisterLoading }] = useLazyCheckRegistrationQuery();
  const [checkUpgrade, { data: upgradeData, isFetching: checkUpgradeLoading }] = useLazyCheckUpgradeQuery();
  const data = isUpgrade ? upgradeData : registerData;

  const handleCheck = () => {
    const body = {
      package: selectedPlan.type,
      coupon: coupon || "",
    }
    if (isUpgrade) {
      checkUpgrade(body)
        .unwrap()
        .then()
        .catch((error) => toast.error(error.data?.message));
    } else {
      checkRegister({
        ...body,
        skipTrial: selectedPlan.skip
      })
        .unwrap()
        .then()
        .catch((error) => toast.error(error.data?.message));
    }
  }

  useEffect(() => {
    if (!couponLoading && defaultCoupon?.message === "") {
      changeCouponApplying(false);
    }
  }, [defaultCoupon, couponLoading])

  useEffect(() => {
    if (selectedPlan.type && !couponValidating && !couponApplying) {
      handleCheck()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan, couponValidating, couponApplying]);

  const handlePurchase = () => {
    if (method === "") {
      toast.error("Please, select a payment method.");

      return;
    }

    if (method === "pp") {
      if (isUpgrade) {
        paypalPurchase({
          couponName: coupon,
          requestedSubscriptionType: selectedPlan.type,
        })
          .unwrap()
          .then((data) => window.location.replace(data.message)).catch(() => { });
      } else {
        gtmOnboardingPaypalClick()
        paypalRegisterPurchase({
          couponName: coupon || "",
          requestedSubscriptionType: selectedPlan.type,
          skipTrial: selectedPlan.skip
        })
          .unwrap()
          .then((data) => {
            gtmOnboardingPaymentSubmit();
            window.location.replace(data.message);
          }).catch(() => { });
      }
    }

    if (method === "py") {
      payoneerPurchase({
        couponName: coupon,
        type: selectedPlan.type,
        skipTrial: selectedPlan.skip
      })
        .unwrap()
        .then((data) => window.location.replace(data.message));
    }
  };


  const handleVatNumberUpdate = () => {
    if (selectedPlan?.type != null) {
      handleCheck()
    }
  }

  const isLifeTime = selectedPlan.type.includes("LT")
  const isEnterprise = selectedPlan.type.toLowerCase().includes("enterprise")

  return (
    <Card className={`px-[33px] py-[26px] justify-center max-sm:p-4 pt-[60px] max-sm:pt-0 dark:text-grey100 flex w-full gap-8 max-xl:flex-col content-stretch ${cardClassName || ""}`}>
      <div className={`rounded-[10px] border border-[#EBF4FE] px-[26px] max-sm:p-4 py-[30px] w-full max-w-[600px] bg-white dark:bg-black900 dark:border-grey700 ${isShopify ? "max-w-[660px]" : "max-w-[580px]"}`}>
        <div className="font-bold">Billing Details</div>
        <div className="flex flex-col md:flex-row items-center mt-[25px] gap-[29px]">
          <div className={`h-[100px] w-full dark:bg-deepSpace800 px-[25px] py-[20px] bg-neutral2 rounded-[15px] flex items-start justify-center flex-col ${isLifeTime && isEnterprise ? "" : " gap-[10px]"}`}>
            <div className="text-[14px] font-bold">Selected Plan</div>
            <div className="flex items-center text-[24px] max-sm:text-base">
              <span className={`font-bold ${isShopify ? "capitalize" : "uppercase"}`}>
                {isShopify ? selectedPlan.planName : getPlanName(selectedPlan.type)}
              </span>
              {isShopify ? "" : (
                <span className="font-medium ml-2">
                  {isLifeTime && !isEnterprise ? " /life" : getPlanSuffix(selectedPlan.type)}
                </span>
              )}
            </div>
            {isLifeTime && isEnterprise ?
              <span className="font-medium">
                Lifetime
              </span>
              : ""}
          </div>
        </div>
        <PaymentMethod
          className="my-[25px]"
          handleMethod={handleMethod}
          handleApplyCoupon={handleApplyCoupon}
          selectedPlan={selectedPlan.type}
          coupon={coupon}
          isShopify={isShopify}
          skip={selectedPlan.skip}
          onCCPaymentLoading={changeCCPaymentLoading}
          handleAlreadyTrial={handleCCAlreadyTrial}
          fromRegistration={false}
          alreadyTrial={isCCTAlreadyTrial}
          amount={data?.result.totalToday}
        />

        {(method === "pp" || method === "py") && (
          <Button
            className="mb-8 w-full mx-auto bg-darkBlue  hover:bg-smoothGreen hover:border-smoothGreen  dark:bg-lilac400 dark:border-lilac400 dark:hover:!bg-paradiseBlue dark:hover:!border-paradiseBlue"
            titleClassName="!text-base !font-bold text-smoothGreen group-hover:text-darkBlue dark:text-darkBlue"
            height="h-[56px]"
            title="Subscribe"
            loading={paypalFetching || paypalRegisterFetching || payoneerFetching || ccPaymentLoading}
            handleClick={() => {
              handlePurchase();
            }}
          />
        )}
        {!couponLoading && (
          <CheckoutCouponInput
            handleApplyCoupon={handleApplyCoupon}
            selectedPlan={selectedPlan.type}
            preloadCoupon={defaultCoupon?.message}
            changeCouponUsed={changeCouponUsed}
            couponValidating={changeCouponApplying}
          />
        )}
      </div>
      <CheckoutOrderSummary
        wrapperStyles="!border"
        selectedPlan={selectedPlan}
        isLoading={checkUpgradeLoading || checkRegisterLoading || couponLoading || couponApplying}
        data={data}
        isCreditCard={method === "cc"}
        hideAlreadyTrialWarning={hideAlreadyTrialWarning && !isCCTAlreadyTrial}
        isDollarTrial={!isUpgrade}
        isShopify={isShopify}
        handleVatNumberUpdate={handleVatNumberUpdate}
        alreadyTrial={isCCTAlreadyTrial}
        handleAlreadyTrialChange={handleCCAlreadyTrial}
        couponUsed={couponUsed}
        fromAlreadyTrial={fromAlreadyTrial}
        fromNoSubscription={fromNoSubscription}
      />
    </Card>
  );
};

export default SubscriptionPurchase;
