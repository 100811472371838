import Card from "components/Card";
import AffiliateTabs from "components/SettingsTabs/Index";
import AffiliateTab from "components/SettingsTabs/SettingsTab";
import Toaster from "components/Toast";
import useTabs from "hooks/useTabs";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AdvertisingChannels from "../affiliateTabs/AdvertisingChannels";
import Earnings from "../affiliateTabs/Earnings";
import PaymentHistory from "../affiliateTabs/PaymentHistory";
import ReferralStatistics from "../affiliateTabs/ReferralStatistics";
import Invoice from "../components/Invoice";

enum TABS {
  earnings = "earnings",
  referralStatistics = "referral-statistics",
  paymentHistory = "payment-history",
  advertisingChannels = "advertising-channels",
}

const tabContent = {
  [TABS.earnings]: {
    content: <Earnings />,
  },
  [TABS.referralStatistics]: {
    content: <ReferralStatistics />,
  },
  [TABS.paymentHistory]: {
    content: <PaymentHistory />,
  },
  [TABS.advertisingChannels]: {
    content: <AdvertisingChannels />,
  },
};

const AffiliateManager = () => {
  const { activeTab, onTabChange } = useTabs<TABS>(TABS.earnings, true);
  const [invoiceId, setInvoiceId] = useState<string | null>(null);
  const [paymentType, setPaymentType] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("invoice")) {
      setInvoiceId(searchParams.get("invoice"));
      setPaymentType(searchParams.get("paymentType"))
    } else {
      setInvoiceId(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("invoice")]);
  return (
    <>

      {invoiceId && process.env.REACT_APP_ENVIRONMENT === 'affiliate' ? (
        <Invoice id={invoiceId} paymentType={paymentType} />
      ) : (
        <Card className="py-5 px-4">
          <AffiliateTabs
            className="max-w-[660px] w-full m-auto mb-6 max-[991px]:flex-col max-[991px]:rounded-[10px] h-[46px] p-[2px]"
            tabs={
              <>
                <AffiliateTab
                  title="Earnings"
                  isActive={activeTab === TABS.earnings}
                  handleClick={() => onTabChange(TABS.earnings)}
                  divider
                  dividerClassName="max-[991px]:hidden"
                  className="h-[42px] max-w-[107px] !text-base"
                />

                <AffiliateTab
                  title="Referral Statistics"
                  isActive={activeTab === TABS.referralStatistics}
                  handleClick={() => onTabChange(TABS.referralStatistics)}
                  divider
                  dividerClassName="max-[991px]:hidden"
                  className="h-[42px]  max-w-[177px] !text-base"
                />

                <AffiliateTab
                  title="Payment History"
                  isActive={activeTab === TABS.paymentHistory}
                  handleClick={() => onTabChange(TABS.paymentHistory)}
                  divider
                  dividerClassName="max-[991px]:hidden"
                  className="h-[42px] max-w-[169px] !text-base"
                />

                <AffiliateTab
                  title="Advertising Channels"
                  isActive={activeTab === TABS.advertisingChannels}
                  handleClick={() => onTabChange(TABS.advertisingChannels)}
                  className="h-[42px] max-w-[205px] !text-base"
                />
              </>
            }
          />

          {tabContent[activeTab].content}
          <Toaster />
        </Card>
      )}
    </>
  );
};

export default AffiliateManager;
