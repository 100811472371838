// Hooks

// Icons
import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right-2.svg";
import { LANDING_PAGE_LINKS } from "config/constants";
import { toast } from "react-toastify";
import { CampaignList } from "types/services/affiliateManager";
import { formatNumber } from "utils/formatCurrency";

export const campaignListCols = (
  onSelectItem: (item: CampaignList) => void
): ColumnDef<CampaignList>[] => [
    {
      header: "Campaign Name",
      minSize: 140,
      accessorKey: "campaignName",
    },
    {
      header: "URL",
      accessorKey: "url",
      cell: (url) => (
        <button
          className="hover:cursor-pointer hover:text-primaryPurple dark:hover:text-purple500 text-left pr-4"
          id="click-to-copy-url"
          onClick={() => {
            toast.success("Copied to clipboard!");
            navigator.clipboard.writeText(
              LANDING_PAGE_LINKS.r + "/" + String(url.getValue())
            );
          }}
        >
          {`${LANDING_PAGE_LINKS.r}/`}<br />{String(url.getValue())}
        </button>
      ),
    },
    {
      header: "LP Views",
      minSize: 80,
      accessorKey: "clicks",
      cell: (clicks) => formatNumber(String(clicks.getValue())),
    },
    {
      header: "Trials",
      minSize: 80,
      accessorKey: "trialsCount",
      cell: (trials) => formatNumber(String(trials)),
    },
    {
      header: "Subscriptions",
      minSize: 120,
      accessorKey: "subscribersCount",
      cell: ({ row: { original } }) => formatNumber(original.subscribersCount),
    },
    {
      header: "",
      minSize: 140,
      accessorKey: "campaignId",
      cell: ({ row: { original } }) => (
        <button
          className="flex items-center text-sm gap-[13px] font-medium hover:text-primaryPurple dark:hover:text-paradiseBlue group"
          onClick={() => onSelectItem(original)}
        >
          View more <ArrowRight className="fill-darkBlue dark:fill-grey100 group-hover:fill-primaryPurple dark:group-hover:fill-paradiseBlue w-3 h-[14px]" />
        </button>
      ),
    },
  ];
