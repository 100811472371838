import SwiperWrapper from 'components/SwiperWrapper';
import Trusted from 'pages/RegistrationFunnel/components/Trusted';
import { useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import { PlansType, TSelectedPlan } from "types/plans";
import NextPrevSlider from "./NextPrevSlider";
import Plan from "./Plan";
import EbayPlansComparison from "./PlanComparison/EbayPlansComparison";



interface Props {
    plans: PlansType;
    plansWrapperClassName?: string;
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    fromRegistration?: boolean;
    isPayNow?: boolean;
    showComparisonOnMobile?: boolean;
}


const SlidedPlansWithComparison = ({
    plans,
    fromRegistration = false,
    plansWrapperClassName,
    showComparisonOnMobile,
    handleSelectPlan,
    isPayNow,
}: Props) => {
    const [activeSlide, setActiveSlide] = useState(1);
    const onActiveSlideChange = (idx: number) => setActiveSlide(idx);
    const showTwoPlans = plans.planList.length === 2
    const sameProps = {
        handleSelectPlan,
        fromRegistration,
        isPayNow,
    }

    const planList = plans.planList;

    return (
        <div
            className={`w-full ${plansWrapperClassName || ""} max-lg:max-w-[1140px] relative`}
        >
            <div className='lg:hidden'>
                <SwiperWrapper onActiveChange={onActiveSlideChange}>

                    {planList.map((plan) => (
                        <SwiperSlide className="max-w-[350px]" content="fit" key={plan.planId}>
                            <Plan
                                {...sameProps}
                                plan={plan}
                            />
                        </SwiperSlide>
                    ))}
                    <NextPrevSlider activeSlide={activeSlide} showTwoPlans={showTwoPlans} />
                </SwiperWrapper>
            </div>
            <div className={`flex justify-between w-full max-lg:hidden gap-2.5 mx-auto ${showTwoPlans ? "max-w-[748px]" : "max-w-[1146px]"}`}>
                {planList.map((plan, i) => (
                    <Plan
                        {...sameProps}
                        plan={plan}
                        key={plan.planId}
                    />
                ))}
            </div>
            <Trusted trustpilotStyles='bg-white' paymentMethodsWrapper='border-2 border-solid border-white' />
            <div className={`w-full ${showComparisonOnMobile ? "max-lg:px-6" : "max-md:hidden"}`}>
                <EbayPlansComparison {...sameProps} plans={plans} />
            </div>

        </div>)
};

export default SlidedPlansWithComparison;
