import { FC } from "react";
import "./loading.css";

interface Props {
  fill: string;
  width?: number;
  height?: number;
  className?: string;
}
const Loading: FC<Props> = ({ fill, width, height, className }) => (
  <div className={`${className}`}>
    <svg
      width={width || "25"}
      height={height || "26"}
      viewBox="0 0 25 26"
      className={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8281 5.54456C13.8281 6.35721 13.1982 7.02125 12.4218 7.02125C11.6454 7.02125 11.0155 6.36185 11.0155 5.54456V1.47205C11.0155 0.659404 11.6454 0 12.4218 0C13.1982 0 13.8281 0.659404 13.8281 1.47205V5.54456Z"
        className="line-1"
      />
      <path
        d="M21.2014 5.88819L18.4508 8.76728C17.9007 9.3431 17.009 9.3431 16.4589 8.76728C15.9088 8.19146 15.9088 7.25808 16.4589 6.68226L19.2094 3.80318C19.7595 3.22736 20.6512 3.22736 21.2014 3.80318C21.7559 4.37899 21.7559 5.31238 21.2014 5.88819C21.2058 5.88819 21.2058 5.88819 21.2014 5.88819Z"
        className="line-2"
      />
      <path
        d="M23.5892 11.5256C24.37 11.5256 25 12.185 25 13.0023C25 13.815 24.37 14.4744 23.5937 14.4744H19.6675C18.9044 14.4511 18.2922 13.801 18.2922 12.9977C18.2922 12.1943 18.9044 11.5442 19.6675 11.5256H23.5892Z"
        className="line-3"
      />
      <path
        d="M18.4508 17.2281L21.2014 20.1072C21.7515 20.683 21.7515 21.6164 21.2014 22.1922C20.6512 22.768 19.7595 22.768 19.2094 22.1922C19.2094 22.1922 19.2094 22.1922 19.2094 22.1875L16.4589 19.3131C15.9088 18.7373 15.9088 17.8039 16.4589 17.2281C17.009 16.6523 17.9007 16.6523 18.4508 17.2281Z"
        className="line-4"
      />
      <path
        d="M13.8281 24.5605C13.8104 25.3592 13.1893 26 12.4218 26C11.6543 26 11.0332 25.3592 11.0155 24.5605V20.4508C11.0155 19.6382 11.6454 18.9787 12.4218 18.9787C13.1982 18.9787 13.8281 19.6382 13.8281 20.4508V24.5605Z"
        className="line-5"
      />
      <path
        d="M3.61563 20.1304L6.38836 17.2281C6.93847 16.6523 7.83017 16.6523 8.38028 17.2281C8.93039 17.8039 8.93039 18.7373 8.38028 19.3131L5.62974 22.1922C5.62974 22.1922 5.62974 22.1922 5.62974 22.1968L5.60756 22.22C5.05301 22.768 4.17905 22.7587 3.63781 22.1922C3.09658 21.6256 3.08771 20.7108 3.61563 20.1304Z"
        className="line-6"
      />
      <path
        d="M5.29701 11.5256C6.07781 11.5256 6.70777 12.185 6.70777 13.0023C6.70777 13.815 6.07781 14.4744 5.30145 14.4744H1.37527C0.612217 14.4512 0 13.801 0 12.9977C0 12.1943 0.612217 11.5442 1.37527 11.5256H5.29701Z"
        className="line-7"
      />
      <path
        d="M6.38836 8.76728L3.61563 5.86497C3.08771 5.28451 3.09658 4.36971 3.63781 3.80318C4.17905 3.23665 5.05301 3.22736 5.60756 3.77996L8.38028 6.68691C8.93039 7.26273 8.93039 8.19611 8.38028 8.77192C7.83017 9.3431 6.93847 9.3431 6.38836 8.76728Z"
        className="line-8"
      />
    </svg>
  </div>
);

export default Loading;
