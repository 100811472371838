const purpleTextClass = "class='text-primaryPurple dark:text-purple500'";
export const bulkScanner = {
  headingPlan: "Pro+",
  title: `Use ZIK's <span ${purpleTextClass}>Bulk Scanner</span> to scan your competitors’ items and automatically find their best selling products on Amazon.Com, Amazon.co.uk and Walmart.`,
  description:
    "You will also gain access to ZIK’s 500 Best selling eBay Items and ZIK’s Ali Growth Scanner.",
};
export const bestSellingItems = {
  headingPlan: "Pro+",
  title: `Use ZIK's<span ${purpleTextClass}> 500 Best Selling eBay Items</span> to uncover trending products selling in the last 48 hours - before everyone else.`,
  description:
    "You will also gain access to ZIK's Bulk Scanner and ZIK's Ali Growth Scanner.",
};
export const aliGrowthScanner = {
  headingPlan: "Pro+",
  title: `Use ZIK's <span ${purpleTextClass}>Ali Growth Scanner</span> to unlock the world's biggest AliExpress products database with over 50M products.`,
  description:
    "You will also gain access to ZIK's 500 Best Selling eBay Items and ZIK's Bulk Scanner.",
};
export const turboScanner = {
  headingPlan: "Enterprise",
  title: `Use ZIK's <span ${purpleTextClass}>Turbo Scanner</span>  to find thousands of proftable items on Amazon.com, Amazon.com.uk and Walmart <br /> and automatically upload them to your store with ZIK’s integrations.`,
  description: "You will also gain access to all ZIK's Enterprise features.",
};
export const autoPilot = {
  headingPlan: "Enterprise",
  title: `Use ZIK's  <span ${purpleTextClass}>Autopilot</span>  to find thousands of profitable items from Amazon.com, Amazon.co.uk, Walmart and AliExpress <br /> and automatically upload them to your store with ZIK’s integrations.`,
  description: "You will also gain access to all ZIK's Enterprise features.",
};
