import Loading from "components/Loading";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useState } from "react";
import { queryTags } from "store/api/queryTags";
import {
  turboScannerApi,
  useTurboLoadScanMutation,
  useTurboScanHistoryQuery
} from "store/api/turboScannerApi";
import { zikProApi } from "store/api/zikProApi";
import TurboScannerList from "./components/lists/TurboScanner";
import TurboScannerFilters from "./components/TurboScannerFilters";
import TurboScannerInfo from "./components/TurboScannerInfo";

const TurboScanner = () => {
  const { scanFilters } = useAppSelector((state) => state.turboScannerSlice);
  const [loadScan] = useTurboLoadScanMutation();
  const { data: turboResults, isFetching: turboScanLoading } =
    useTurboScanHistoryQuery();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const onFilter = async () => {
    setIsLoading(true)

    let cats: number[] | null = scanFilters.category.map((item) => Number(item))
    if (cats.length <= 0 || cats[0] === null || cats[0] === 0) { //cat = 0 happens when the filter is an empty string
      cats = null
    }

    const {
      price,
      recentSale,
      rating,
      inclusions,
      keywords,
      reviewCount,
      supplier,
      numberOfProducts,
      sellerRanking,
    } = scanFilters;
    loadScan({
      amazontype: supplier,
      minSales: recentSale.min?.toString() ?? null,
      minTotalSale: "",
      minPrice: price.min?.toString() ?? null,
      maxPrice: price.max?.toString() ?? null,
      rating,
      onlyPrime: inclusions.includes("onlyPrime") ? 1 : 0,
      includeBooks: inclusions.includes("includeBooks") ? 1 : 0,
      includeDVDS: inclusions.includes("includeDVDs") ? 1 : 0,
      ...(supplier === "WAL" ? {} : {
        minBsr: sellerRanking.min,
        maxBsr: sellerRanking.max,
      }),
      minTotalReviews: reviewCount.min,
      maxTotalReviews: reviewCount.max,
      categories: cats,
      keywords,
      numberOfProducts: numberOfProducts.max,
      soldByWalmart: 0,
      startPage: 0,
      pageLength: 0,
      userId: 0,
    }).unwrap().then(() => {
      dispatch(zikProApi.util.invalidateTags([
        queryTags.proPlusLimits,
      ]));
    });
    dispatch(turboScannerApi.util.invalidateTags([
      queryTags.turboScanner,
    ]));


    const timeoutId = setTimeout(() => { //on purpose so it looks like we are doing something
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timeoutId);


  };


  return (
    <div className="flex flex-col gap-5">
      <TurboScannerFilters
        isLoading={isLoading || turboScanLoading}
        onFilter={onFilter}
      />
      {turboScanLoading ? (
        <div className="w-full h-[400px] flex justify-center items-center">
          <Loading fill="fill-primaryPurple dark:fill-purple500" width={48} height={48} />
        </div>
      ) : turboResults && turboResults?.result.length > 0 ? (
        <TurboScannerList
          data={turboResults?.result}
          isLoading={turboScanLoading}
        />
      ) : (
        <TurboScannerInfo />
      )}
    </div>
  );
};

export default TurboScanner;
