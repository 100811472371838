import { ReactComponent as Save } from "assets/icons/save.svg";
import HoveredIcon from "components/HoveredIcon";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { MarketPlaceSimilarItemsList } from "types/services/iconFinder";
import { formatCurrency } from "utils/formatCurrency";
import BabaCardFooter from "./BabaCardFooter";
import SimiliarImage from "./SimiliarImage";

interface Props {
  items: MarketPlaceSimilarItemsList[];
  onSaveClick: (item: MarketPlaceSimilarItemsList) => void;
  site?: string;
  isShopify?: boolean;
  saveLoading?: boolean;
}
const colStyles = "flex-1 border-l-2 border-secondary dark:border-deepSpace900 pl-4 flex text-center flex-col justify-center items-center h-16 "

const DrawerList: FC<Props> = ({ items, onSaveClick, site, isShopify, saveLoading }) => {
  const [savingItem, setSavingItem] = useState<null | string>();

  const handleSave = (item: MarketPlaceSimilarItemsList) => { onSaveClick(item); setSavingItem(item.id) }
  return (
    <ul className=" w-full relative z-1 overflow-y-auto scrollbar pr-2 dark:text-grey100" style={{ maxHeight: `calc(100% - ${isShopify ? "148px" : "248px"})` }}>
      {items.map((item, i) => (
        <div className="border-2 rounded-10 border-secondary dark:border-deepSpace900 p-4 mb-4 dark:bg-black900 text-xs" key={i}>
          {/* className={`grid ${isShopify || marketplace === 'Baba' ? "grid-cols-7" : "grid-cols-8"}  items-center gap-4 relative`} */}
          <li key={i} className={`flex items-center gap-4 relative`}>
            <span className="col-span-1 shrink-0">
              <SimiliarImage
                img={item.img}
                isFirst={i === 0}
                isLast={i === items.length - 1}
              />
            </span>
            <Link
              to={item.url}
              target="_blank"
              className="font-medium cursor-pointer hover:text-primaryPurple dark:hover:text-purple500 pr-4 col-span-4"
              rel="noreferrer"
            >
              {/* <span className="flex-grow flex items-center h-16 basis-[40%] col-span-4 font-medium"> */}
              {item.title}
              {/* </span> */}
            </Link>
            <span className={colStyles}>
              <span>Price</span>
              <span className="whitespace-pre">{formatCurrency(item.price)}</span>
            </span>
            {item.sales && <span className={colStyles}>
              <span>Sales</span>
              <span className="whitespace-pre">{item.sales}</span>
            </span>}
            <span className={colStyles} >
              <span>Profit</span>
              <span className="whitespace-pre">{formatCurrency(item.profit)}</span>
            </span>
            {!isShopify && site !== 'Baba' && <span className="flex-1 border-l-2 border-secondary dark:border-deepSpace900 pl-4 text-center flex justify-center items-center h-16">
              <HoveredIcon
                Icon={Save}
                handleClick={() => handleSave(item)}
                isLoading={(item.id === savingItem && saveLoading) || false}
                title="Save to Folder"
                width="w-6"
                height="h-6"
                tooltipClassName="dark:!bg-deepSpace900"
                iconClassName="w-3 h-1.5"
              />
            </span>}

          </li>
          {item.details && <BabaCardFooter details={item.details} />}
        </div>
      ))}

      {/* Repeat the above list item structure for each item */}
    </ul>
  )
};

export default DrawerList;
