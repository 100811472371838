interface Props {
  source: string;
  className?: string;
}

const VideoEmbed = ({ source, className }: Props) => (
  <div
    className={`aspect-video relative block h-0 p-0 overflow-hidden pb-[56.25%] ${
      className || ""
    }`}
  >
    <iframe
      title="tutorial-video"
      className="absolute top-0 bottom-0 left-0 w-full h-full border-0"
      src={source}
      allowFullScreen
    />
  </div>
);

export default VideoEmbed;
