import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/Button";
import ControlledInput from "components/Input/ControlledInput";
import { FC, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAddFolderMutation } from "store/api/foldersApi";
import { object, string } from "yup";
interface Props {
  type: 1 | 2;
}
interface FormValues {
  name: string;
  description: string;
}

let addFolderSchema = object({
  name: string().required("Folder Name is Required").max(30, "Please add max 30 characters"),
  description: string().max(200, "Please add max 200 characters"),
});

const AddNewFolder: FC<Props> = ({ type }) => {
  const [addFolder, { isLoading }] = useAddFolderMutation();
  const [error, setError] = useState(false);
  const { t } = useTranslation(['home']);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(addFolderSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    addFolder({
      title: values.name,
      description: values.description,
      type,
    })
      .unwrap()
      .then(() => {
        toast.success(`Folder "${values.name}" created`);
        reset();
      }).catch((err) => {
        err.data === "folder with same name already exists." && toast.error("New folder couldn’t be created");
        setError(true);
      });
  };

  const inputProps = (field: "name" | "description") => ({
    className: "w-full h-11 !pb-0",
    inputClassName: "w-full",
    erase: true,
    getValues: watch,
    clearInputValue: () => setValue(field, ""),
    register,
    name: field,
    errors
  })


  useEffect(() => {
    if (error) {
      const lastValue = getValues().name;
      setValue("name", "Folder name already exist");
      setTimeout(() => {
        setError(false)
        setValue("name", lastValue);
      }, 2000)
    }

  }, [error])

  return (
    <div className="w-full">
      <h5 className="font-bold mb-3 dark:text-grey100">{t('AddFolder')}</h5>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex items-end gap-[43px] "
      >
        <div className="w-full flex-col flex gap-3 max-w-[228px]">
          <ControlledInput<FormValues>
            placeholder={t('FolderName').toString()}
            {...inputProps("name")}
            className={`${error && "text-errorRed"}`}
          />
          <ControlledInput<FormValues>
            placeholder={t('Description').toString()}
            {...inputProps("description")}
          />
        </div>

        <Button
          title={t('CreateFolder')}
          type="submit"
          loading={isLoading}
          className="max-w-[156px] dark:bg-deepSpace900"
          height="h-9"
          disabled={error}
        />
        {/* <InfoTooltip title="To create a folder, enter the prefered name of the folder and create a short description. Click on create." /> */}
      </form>
    </div>
  );
};

export default AddNewFolder;
