import Button from "components/Button"
import Modal from "components/Modal"
import { useAppDispatch } from "hooks/useAppDispatch"
import useDecodeToken from "hooks/useDecodeToken"
import useModal from "hooks/useModal"
import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { PATH } from "routes/path"
import { onSelectPlan } from "store/slices/planSlice"
import { makeQueryParams } from "utils/makeQueryParams"
import { getPlanDetails } from "utils/planUtils"

interface Props {
    helpers: ReturnType<useModal>;
    getLimitedProducts: () => void;

}
const ExceedScanLimit: FC<Props> = ({ helpers, getLimitedProducts }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userDetails = useDecodeToken();
    const upgradePlan = userDetails.package_name === "StandardM" ? "EnterpriseM" : userDetails.package_name === "StandardY" ? "EnterpriseY" : "EnterpriseLT"
    const onUpgrade = () => {
        const plan = getPlanDetails(upgradePlan);
        dispatch(onSelectPlan({
            type: upgradePlan,
            planName: plan?.planName || "",
            planPrice: plan?.planPrice || 0,
            skip: false
        }))
        navigate(
            makeQueryParams(PATH.managementPanel.subscriptionUpgrade, { type: upgradePlan, from: "ZikPro" }))
    };
    const btnProps = {
        titleClassName: "!text-lg !font-bold",
        height: "h-16"
    }
    return (
        <Modal helpers={helpers} maxWidth="max-w-[717px]" cardClassName="pt-[38px] pb-11" buttonCloseClassName="top-[16px] right-[16px]" overlay>
            <h6 className="text-center mb-2.5 text-[20px] font-bold">You’ve Exceeded the Product Limit!</h6>
            <p className="text-center mb-6">You’re limited to 100 products per scan.<br />
                Upgrade your plan to unlock unlimited products per scan!</p>
            <div className="flex gap-8 justify-center">
                <Button title="Upgrade Now" handleClick={onUpgrade} className="max-w-[235px] shadow-[0px_0px_42.173px_0px_#BAF2EC,_0px_0px_24.601px_0px_#BAF2EC,_0px_0px_12.3px_0px_#BAF2EC,_0px_0px_3.514px_0px_#BAF2EC,_0px_0px_1.757px_0px_#BAF2EC]"
                    color="smoothGreen" {...btnProps} />
                <Button title="Get 100 Products" handleClick={() => { getLimitedProducts(); helpers.close() }} className="max-w-[235px]"  {...btnProps} />
            </div>

        </Modal>
    )
}

export default ExceedScanLimit