import { FC, ReactNode } from "react";
import "styles/scrollbar.css";

interface Props {
  children: ReactNode;
  className?: string;
  variant?: "default" | "white" | "transparent";
  as?: keyof JSX.IntrinsicElements;
}

const Scrollbar: FC<Props> = ({ children, as, className, variant }) => {
  const As = as || "div";
  const variants = {
    default: "scrollbar",
    white: "white-scrollbar",
    transparent: "transparent-scrollbar",
  };

  return (
    <As
      className={`${variants[variant || "default"]} overflow-y-auto ${className || ""
        }`}
    >
      {children}
    </As>
  );
};

export default Scrollbar;
