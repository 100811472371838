export const countryList = [
  { value: "2", text: "France" },
  { value: "3", text: "Estonia" },
  { value: "4", text: "Croatia" },
  { value: "5", text: "Serbia" },
  { value: "6", text: "Bosnia & Herzegovina" },
  { value: "7", text: "Israel" },
  { value: "8", text: "Germany" },
  { value: "9", text: "Afghanistan" },
  { value: "10", text: "Albania" },
  { value: "11", text: "Algeria" },
  { value: "12", text: "American Samoa" },
  { value: "13", text: "Andorra" },
  { value: "14", text: "Angola" },
  { value: "15", text: "Anguilla" },
  { value: "16", text: "Antarctica" },
  { value: "17", text: "Antigua and Barbuda" },
  { value: "18", text: "Argentina" },
  { value: "19", text: "Armenia" },
  { value: "20", text: "Aruba" },
  { value: "21", text: "Australia" },
  { value: "22", text: "Austria" },
  { value: "23", text: "Azerbaijan" },
  { value: "24", text: "Bahamas" },
  { value: "25", text: "Bahrain" },
  { value: "26", text: "Bangladesh" },
  { value: "27", text: "Barbados" },
  { value: "28", text: "Belarus" },
  { value: "29", text: "Belize" },
  { value: "30", text: "Benin" },
  { value: "31", text: "Bermuda" },
  { value: "32", text: "Bhutan" },
  { value: "33", text: "Bolivia" },
  { value: "34", text: "Botswana" },
  { value: "35", text: "Bouvet Island" },
  { value: "36", text: "Brazil" },
  { value: "37", text: "British Indian Ocean Territory" },
  { value: "38", text: "Brunei Darussalam" },
  { value: "39", text: "Bulgaria" },
  { value: "40", text: "Burkina Faso" },
  { value: "41", text: "Burundi" },
  { value: "42", text: "Cambodia" },
  { value: "43", text: "Cameroon" },
  { value: "44", text: "Canada" },
  { value: "45", text: "Cape Verde" },
  { value: "46", text: "Cayman Islands" },
  { value: "47", text: "Central African Republic" },
  { value: "48", text: "Chad" },
  { value: "49", text: "Chile" },
  { value: "50", text: "China" },
  { value: "51", text: "Christmas Island" },
  { value: "52", text: "Cocos (Keeling) Islands" },
  { value: "53", text: "Colombia" },
  { value: "54", text: "Comoros" },
  { value: "55", text: "Congo" },
  { value: "56", text: "Cook Islands" },
  { value: "57", text: "Costa Rica" },
  { value: "58", text: "Cuba" },
  { value: "59", text: "Cyprus" },
  { value: "60", text: "Czech Republic" },
  { value: "61", text: "Denmark" },
  { value: "62", text: "Djibouti" },
  { value: "63", text: "Dominica" },
  { value: "64", text: "Dominican Republic" },
  { value: "65", text: "East Timor" },
  { value: "66", text: "Ecuador" },
  { value: "67", text: "Egypt" },
  { value: "68", text: "El Salvador" },
  { value: "69", text: "Equatorial Guinea" },
  { value: "70", text: "Eritrea" },
  { value: "71", text: "Ethiopia" },
  { value: "72", text: "Falkland Islands (Malvinas)" },
  { value: "73", text: "Faroe Islands" },
  { value: "74", text: "Fiji" },
  { value: "75", text: "Finland" },
  { value: "76", text: "France, Metropolitan" },
  { value: "77", text: "French Guiana" },
  { value: "78", text: "French Polynesia" },
  { value: "79", text: "French Southern Territories" },
  { value: "80", text: "Gabon" },
  { value: "81", text: "Gambia" },
  { value: "82", text: "Georgia" },
  { value: "83", text: "Ghana" },
  { value: "84", text: "Gibraltar" },
  { value: "85", text: "Guernsey" },
  { value: "86", text: "Greece" },
  { value: "87", text: "Greenland" },
  { value: "88", text: "Grenada" },
  { value: "89", text: "Guadeloupe" },
  { value: "90", text: "Guam" },
  { value: "91", text: "Guatemala" },
  { value: "92", text: "Guinea" },
  { value: "93", text: "Guinea-Bissau" },
  { value: "94", text: "Guyana" },
  { value: "95", text: "Haiti" },
  { value: "96", text: "Heard and Mc Donald Islands" },
  { value: "97", text: "Honduras" },
  { value: "98", text: "Hong Kong" },
  { value: "99", text: "Hungary" },
  { value: "100", text: "Iceland" },
  { value: "101", text: "India" },
  { value: "102", text: "Isle of Man" },
  { value: "103", text: "Indonesia" },
  { value: "104", text: "Iran (Islamic Republic of)" },
  { value: "105", text: "Iraq" },
  { value: "106", text: "Ireland" },
  { value: "107", text: "Italy" },
  { value: "108", text: "Ivory Coast" },
  { value: "109", text: "Jersey" },
  { value: "110", text: "Jamaica" },
  { value: "111", text: "Japan" },
  { value: "112", text: "Jordan" },
  { value: "113", text: "Kazakhstan" },
  { value: "114", text: "Kenya" },
  { value: "115", text: "Kiribati" },
  { value: "116", text: "Korea, Democratic People's Republic of" },
  { value: "117", text: "Korea, Republic of" },
  { value: "118", text: "Kosovo" },
  { value: "119", text: "Kuwait" },
  { value: "120", text: "Kyrgyzstan" },
  { value: "121", text: "Lao People's Democratic Republic" },
  { value: "122", text: "Latvia" },
  { value: "123", text: "Lebanon" },
  { value: "124", text: "Lesotho" },
  { value: "125", text: "Liberia" },
  { value: "126", text: "Libyan Arab Jamahiriya" },
  { value: "127", text: "Liechtenstein" },
  { value: "128", text: "Lithuania" },
  { value: "129", text: "Luxembourg" },
  { value: "130", text: "Macau" },
  { value: "131", text: "Macedonia" },
  { value: "132", text: "Madagascar" },
  { value: "133", text: "Malawi" },
  { value: "134", text: "Malaysia" },
  { value: "135", text: "Maldives" },
  { value: "136", text: "Mali" },
  { value: "137", text: "Malta" },
  { value: "138", text: "Marshall Islands" },
  { value: "139", text: "Martinique" },
  { value: "140", text: "Mauritania" },
  { value: "141", text: "Mauritius" },
  { value: "142", text: "Mayotte" },
  { value: "143", text: "Mexico" },
  { value: "144", text: "Micronesia, Federated States of" },
  { value: "145", text: "Moldova, Republic of" },
  { value: "146", text: "Monaco" },
  { value: "147", text: "Mongolia" },
  { value: "148", text: "Montenegro" },
  { value: "149", text: "Montserrat" },
  { value: "150", text: "Morocco" },
  { value: "151", text: "Mozambique" },
  { value: "152", text: "Myanmar" },
  { value: "153", text: "Namibia" },
  { value: "154", text: "Nauru" },
  { value: "155", text: "Nepal" },
  { value: "156", text: "Netherlands" },
  { value: "157", text: "Netherlands Antilles" },
  { value: "158", text: "New Caledonia" },
  { value: "159", text: "New Zealand" },
  { value: "160", text: "Nicaragua" },
  { value: "161", text: "Niger" },
  { value: "162", text: "Nigeria" },
  { value: "163", text: "Niue" },
  { value: "164", text: "Norfolk Island" },
  { value: "165", text: "Northern Mariana Islands" },
  { value: "166", text: "Norway" },
  { value: "167", text: "Oman" },
  { value: "168", text: "Pakistan" },
  { value: "169", text: "Palau" },
  { value: "171", text: "Panama" },
  { value: "172", text: "Papua New Guinea" },
  { value: "173", text: "Paraguay" },
  { value: "174", text: "Peru" },
  { value: "175", text: "Philippines" },
  { value: "176", text: "Pitcairn" },
  { value: "177", text: "Poland" },
  { value: "178", text: "Portugal" },
  { value: "179", text: "Puerto Rico" },
  { value: "180", text: "Qatar" },
  { value: "181", text: "Reunion" },
  { value: "182", text: "Romania" },
  { value: "183", text: "Russian Federation" },
  { value: "184", text: "Rwanda" },
  { value: "185", text: "Saint Kitts and Nevis" },
  { value: "186", text: "Saint Lucia" },
  { value: "187", text: "Saint Vincent and the Grenadines" },
  { value: "188", text: "Samoa" },
  { value: "189", text: "San Marino" },
  { value: "190", text: "Sao Tome and Principe" },
  { value: "191", text: "Saudi Arabia" },
  { value: "192", text: "Senegal" },
  { value: "193", text: "Seychelles" },
  { value: "194", text: "Sierra Leone" },
  { value: "195", text: "Singapore" },
  { value: "196", text: "Slovakia" },
  { value: "197", text: "Slovenia" },
  { value: "198", text: "Solomon Islands" },
  { value: "199", text: "Somalia" },
  { value: "200", text: "South Africa" },
  { value: "201", text: "South Georgia South Sandwich Islands" },
  { value: "202", text: "Spain" },
  { value: "203", text: "Sri Lanka" },
  { value: "204", text: "St. Helena" },
  { value: "205", text: "St. Pierre and Miquelon" },
  { value: "206", text: "Sudan" },
  { value: "207", text: "Suriname" },
  { value: "208", text: "Svalbard and Jan Mayen Islands" },
  { value: "209", text: "Swaziland" },
  { value: "210", text: "Sweden" },
  { value: "211", text: "Switzerland" },
  { value: "212", text: "Syrian Arab Republic" },
  { value: "213", text: "Taiwan" },
  { value: "214", text: "Tajikistan" },
  { value: "215", text: "Tanzania, United Republic of" },
  { value: "216", text: "Thailand" },
  { value: "217", text: "Togo" },
  { value: "218", text: "Tokelau" },
  { value: "219", text: "Tonga" },
  { value: "220", text: "Trinidad and Tobago" },
  { value: "221", text: "Tunisia" },
  { value: "222", text: "Turkey" },
  { value: "223", text: "Turkmenistan" },
  { value: "224", text: "Turks and Caicos Islands" },
  { value: "225", text: "Tuvalu" },
  { value: "226", text: "Uganda" },
  { value: "227", text: "Ukraine" },
  { value: "228", text: "United Arab Emirates" },
  { value: "229", text: "United Kingdom" },
  { value: "230", text: "United States" },
  { value: "232", text: "Uruguay" },
  { value: "233", text: "Uzbekistan" },
  { value: "234", text: "Vanuatu" },
  { value: "236", text: "Venezuela" },
  { value: "237", text: "Vietnam" },
  { value: "242", text: "Yemen" },
  { value: "244", text: "Zambia" },
  { value: "245", text: "Zimbabwe" },
  { value: "246", text: "N/A" },
];
