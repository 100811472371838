import { createSlice } from "@reduxjs/toolkit";
import { catResApi } from "store/api/catResApi";
import { catResData, catResFilters } from "store/initialValues/catRes";
import { PostCatRes, PostCatResRequest } from "types/services/catRes";

export interface CatResFilterChangePayload {
  field: keyof PostCatResRequest;
  newVal: string | number;
}

const initialState = {
  filters: catResFilters as PostCatResRequest,
  categories: [] as PostCatRes[],
  catResData: catResData,
};
const catResSlice = createSlice({
  name: "catResSlice",
  initialState,
  reducers: {
    catResFilterChange: (state, { payload }) => {
      // @ts-ignore
      state.filters[payload.field] = payload.newVal;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      catResApi.endpoints.getCatRes.matchFulfilled,
      (state, { payload }) => {
        // @ts-ignore
        state.catResData = payload.result;

        return state;
      }
    );
  },
});

export const { catResFilterChange } = catResSlice.actions;

export default catResSlice.reducer;
