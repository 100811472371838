import { useAppDispatch } from "hooks/useAppDispatch";
import { FC, ReactNode, useEffect } from "react";
import { handleLoadingChange } from "store/slices/themeSlice";
import "./loading.css";

const loadingTitles = [
  "As entrepreneurs we learn new things everyday. ",
  "Seller statistics give you an overview of your competitor, but remember to focus your attention on the items your competitor is selling to find new opportunities.",
  "When doing competitor research focus on what matters; the number of sales, profit per item, titles and competition level.",
  "Found a high performing competitor? Observe what they do, the keywords in their titles, what photos they use and what their profit range is.",
  "The idea of competitor research is not to copy and paste items, it’s to give you a different perspective and act as a source of new ideas.",
  "It’s normal that the longer the keyword or title you search, the less results you’ll get. Make sure you adjust your decision making and the statistics you rely on to every situation.",
  "The sell-through rate shows you the level of demand from buyers. More demand means more potential to make money.",
  "The successful listings % will tell you what your chances of success are.",
  "The lower the competition, the higher your chances are to sell.",
  "Your price affects whether someone chooses to buy your product. Focus on matching price to value and you'll sell a lot.",
  "Feedback is a way you can evaluate your competitors. Try to find sellers with high sales, at a higher price than average but with a low level of feedback.",
  "The sale earnings gives you a feeling of how much profit you can actually make.",
  "If you find an interesting niche, add it to your saved searches so you can research it again in the future.",
  "Category Research gives you a broad understanding of the whole niche.",
  "The lifetime sales in the Category Research Tool lets you understand the potential of each item.",
  "After you find high selling items with the Category Research Tool, we recommend continuing your research with the Product Research Tool.",
];
interface Props {
  loading: boolean;
  children: ReactNode;
}
const LoadingPage: FC<Props> = ({ loading, children }) => {
  const dispatch = useAppDispatch();
  const linesClassName =
    "h-[27px] rounded-full absolute left-1/2 -translate-x-1/2";

  useEffect(() => {
    dispatch(handleLoadingChange(loading));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return loading ? (
    <div>
      <div className="bg-darkBlue absolute w-full h-full left-0 top-0 pl-[85px]">
        <div className="absolute top-1/2 left-1/2 max-sm:top-1/3 -translate-x-1/2 -translate-y-1/2">
          <div className="h-full">
            <div className="h-[95px] -rotate-[55deg] relative">
              <div
                className={`w-[103px] bg-primaryPurple top-0 firstLine -left-[150px] ${linesClassName} `}
              />
              <div
                className={`w-[155px] bg-smoothGreen top-[34px] secondLine ${linesClassName}`}
              />
              <div
                className={`w-[105px] bg-white top-[68px] thirdLine ${linesClassName}`}
              />
            </div>
          </div>
        </div>
        <div className="min-w-[625px] max-md:min-w-[400px] border-2 border-smoothGreen rounded-10 p-4.5 flex justify-center absolute bottom-[100px] left-1/2 -translate-x-1/2">
          <p className="text-white text-center max-w-[450px]">
            {loadingTitles[Math.floor(Math.random() * loadingTitles.length)]}
          </p>
        </div>
      </div>
    </div>
  ) : (
    <>{children}</>
  );
};

export default LoadingPage;
