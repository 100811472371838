import { advertisingChannels } from "assets/data/ManagementPanel/affiliateManager";
import { FC } from "react";

interface Props {
  title: string;
  subtitle: string;
}
const AdvertisingChannel: FC<Props> = ({ title, subtitle }) => (
  <div className="border-2 border-neutral2 dark:border-grey700 dark:bg-grey800 rounded-10 p-7">
    <div className="font-bold mb-2.5">
      {title}
      {title === "Forums" ? (
        <>
          <br />
          <span className="!font-normal text-sm">
            {"(quora, blackhatworld and others)"}
          </span>
        </>
      ) : (
        ""
      )}
    </div>
    <div className="text-sm">{subtitle}</div>
  </div>
);

const AdvertisingChannels = () => (
  <div className="flex flex-col gap-[60px] dark:text-grey100">
    <div>
      <div className="font-bold mb-4">Organic Traffic</div>
      <div className="grid grid-cols-4 gap-2.5 max-[1250px]:grid-cols-2 max-[880px]:grid-cols-1">
        {advertisingChannels.slice(0, 4).map((item) => (
          <AdvertisingChannel
            title={item.title}
            subtitle={item.subtitle}
            key={item.title}
          />
        ))}
      </div>
    </div>
    <div>
      <div className="font-bold mb-4">Paid Traffic</div>
      <div className="grid grid-cols-4 gap-2.5 max-[1250px]:grid-cols-2 max-[880px]:grid-cols-1">
        {advertisingChannels.slice(4, 8).map((item) => (
          <AdvertisingChannel
            title={item.title}
            subtitle={item.subtitle}
            key={item.title}
          />
        ))}
      </div>
    </div>
  </div>
);

export default AdvertisingChannels;
