import {
  useGetPaidRequestsQuery,
  useGetUnPaidRequestsQuery,
} from "store/api/affiliateManagerApi";
import RequestList from "../lists/AffiliateManager/RequestList";

const PaymentHistory = () => {
  const { data: paidList, isLoading: paidLoading } = useGetPaidRequestsQuery();
  const { data: unpaidList, isLoading: unpaidLoading } =
    useGetUnPaidRequestsQuery();

  return (
    <>
      {unpaidList && unpaidList?.result?.length > 0 ? (
        <RequestList
          list={unpaidList?.result || []}
          title="Unpaid Requests"
          listLoading={unpaidLoading}
        />
      ) : (
        ""
      )}
      <RequestList
        list={paidList?.result || []}
        title="Paid Requests"
        listLoading={paidLoading}
      />
    </>
  );
};

export default PaymentHistory;
