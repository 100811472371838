import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { ReactComponent as Info } from "assets/icons/info-i.svg";
import { ReactComponent as Shopify } from "assets/icons/shopify.svg";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { useShopifyStoreStatusMutation } from "store/api/onboardingApi";


interface Props {
    handleStepChange?: (step: number) => void;
    isCC?: boolean;
}

const StepThree = ({ handleStepChange, isCC }: Props) => {

    const navigate = useNavigate();
    const [postShopifyStatus, { data, isLoading }] = useShopifyStoreStatusMutation();
    const handleClick = async (id: number) => {
        postShopifyStatus(id).unwrap().then(() => {
            if (isCC) {
                window.location.reload();
            }
            navigate(PATH.shopify.marketInsights)
        });
    }

    return (
        <div className="shadow-2xl bg-white dark:bg-grey800 rounded-10 w-full dark:text-grey100 max-w-[910px] flex flex-col  max-lg:max-w-full max-lg:h-full max-lg:rounded-none max-lg:p-0 max-lg:px-5 lg:max-h-[40rem] xl:max-h-[47rem] lg:justify-center ">
            <div className="flex flex-col items-center justify-center pt-[17px] pb-[64px] px-[27px]">
                <div className="flex justify-end w-full">
                    <div className="w-[84px] h-[32px] flex items-center justify-center bg-neutral2 rounded-full"><Shopify className="w-[65px] h-auto" /></div>
                </div>
                <div className="w-8 h-8 rounded-full bg-canaryYellow flex items-center justify-center mt-[15px] mb-8"><Info fill="darkBlue" /></div>

                <h6 className="font-bold text-[20px] leading-[28px] mb-[16px] text-center max-sm:text-[18px]">Did you pay for your Shopify store?<br className="max-[450px]:hidden"></br>
                    Only $1 for a Month!</h6>

                <div className="text-[20px] lieading-[28px] max-w-[710px] text-center mb-8 max-sm:text-[18px]">If you don’t pay for your Shopify, you will lose access to your store in a short time. <a href="https://admin.shopify.com/subscription/pick_period/basic/monthly?online=true&selected=true" target="_blank" className="text-electricBlue underline font-bold">Open Shopify Store Plans</a></div>
                <div className="flex items-cente justify-center w-full gap-6">
                    <Button
                        title="Paid"
                        className="max-w-[157px] hover:text-white hover:bg-darkBlue w-full"
                        titleClassName=" !text-base"
                        height="h-[60px]"
                        handleClick={() => handleClick(3)}
                        StartIcon={Checkmark}
                        loading={isLoading}
                    />
                    <Button
                        title="I’ll pay later"
                        className="max-w-[153px] hover:text-white hover:bg-darkBlue w-full border-none"
                        titleClassName="!text-base"
                        height="h-[60px]"
                        handleClick={() => handleClick(4)}
                        loading={isLoading}
                    />
                </div>

            </div>
        </div>
    )
}

export default StepThree