// Icons
import { ColumnDef } from "@tanstack/react-table";
import { ReferredUserStatsList } from "types/services/affiliateManager";
import { formatDate } from "utils/formatTime";

export const referralStatisticsCols: ColumnDef<ReferredUserStatsList>[] = [
  {
    header: "Full Name",
    accessorKey: "firstName",
    cell: ({ row: { original } }) => (
      <p>{original.firstName + " " + original.lastName}</p>
    ),
  },
  {
    header: "Email",
    accessorKey: "email",
  },
  {
    header: "Date of Registration",
    accessorKey: "dateOfRegistration",
    cell: (date) => formatDate(String(date.getValue())),
  },

  {
    header: "Status",
    accessorKey: "status",
    cell: ({
      row: { original }
    }) => <p className={`${original.status.toLowerCase() === "canceled" ? "text-errorRed" : ""}`}>{original.status}</p>
  },
];
