import Header from "components/Header";

import Button from "components/Button";
import HeaderTab from "components/Header/Tab";
import { useParams } from "react-router-dom";
import { PATH } from "routes/path";

import { ReactComponent as BackArrow } from "assets/icons/back-arrow.svg";

import { saleDateRangeArr } from "assets/data/filters";
import Toaster from "components/Toast";
import useFilters from "hooks/useFilters";
import usePagination from "hooks/usePagination";
import useTabs from "hooks/useTabs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  useGetMatchItemsQuery,
  useUpdateSiteMutation
} from "store/api/bulkScannerApi";
import { queryTags } from "store/api/queryTags";
import {
  quickSettingsApi,
  useGetSettingsQuery
} from "store/api/quickSettingsApi";
import FiltersCard from "./components/FiltersCard";
import FlagsRow, { FlagKeys } from "./components/FlagsRow";
import MatchedItems from "./components/MatchedItems";
import NotFoundItemsList from "./components/NotFoundItemsList";
import SimilarItems from "./components/SimilarItems";
import StatsCards from "./components/StatsCards";

enum TABS {
  matchedItems = "matched",
  similarItems = "similar",
  notFoundItems = "not-found",
}

export interface BulkScannerFilterState {
  salesRange: string;
  sales: {
    min: string;
    max: string;
  };
  price: {
    min: string;
    max: string;
  };
  profit: {
    min: string;
    max: string;
  };
  uploadDate: {
    min: string | null;
    max: string | null;
  };
}

const initialFilterState = {
  salesRange: saleDateRangeArr[0].value,
  sales: {
    min: "",
    max: "",
  },
  price: {
    min: "",
    max: "",
  },
  profit: {
    min: "",
    max: "",
  },
  uploadDate: {
    min: null,
    max: null,
  },
};

const BulkScannerItems = () => {
  const { filters, handleFilterChange, setFilters } =
    useFilters(initialFilterState);
  const pagination = usePagination(0, 100);
  const [selectedCountry, setSelectedCountry] = useState<FlagKeys>("US");
  const { activeTab, onTabChange } = useTabs<TABS | null>(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const payload = {
    userId: 0,
    requestID: Number(id),
    saleRange: Number(filters.salesRange),
    minProfit: Number(filters.profit.min),
    maxProfit: Number(filters.profit.max),
    minCurrentPrice: Number(filters.price.min),
    maxCurrentPrice: Number(filters.price.max),
    minSalePrice: Number(filters.sales.min),
    maxSalePrice: Number(filters.sales.max),
    dateFrom: filters.uploadDate.min ? filters.uploadDate.min : "",
    dateTo: filters.uploadDate.max ? filters.uploadDate.max : "",
    searchText: "",
    pageNumber:
      activeTab === TABS.notFoundItems
        ? pagination.current
        : pagination.current + 1,
    pageLength: pagination.pageSize,
    orderByAsc: false,
    orderByValue: "Title",
  };

  const [searchedPayload, setSearchedPayload] = useState(payload);
  const { data, isFetching } = useGetMatchItemsQuery({ requestID: id || "" });
  const { data: settings } = useGetSettingsQuery();
  const [updateSite, { isLoading: updateLoading }] = useUpdateSiteMutation();
  const { t } = useTranslation(['home']);
  const countryInfo = {
    code: data?.result.searchedUser?.countryCode || "",
    name: data?.result.searchedUser?.countryName || "",
  };

  const handleFilterClick = () => {
    setSearchedPayload(payload);
  };

  const handleTabClick = (tab: TABS) => {
    setFilters(initialFilterState);
    pagination.goto(0);
    onTabChange(tab);
  };

  const handleCountryChange = async (country: FlagKeys) => {
    setSelectedCountry(country);
    if (country !== settings?.result.amazonsite) {
      await updateSite({ site: country })
        .unwrap()
        .then(() =>
          dispatch(
            quickSettingsApi.util.invalidateTags([
              queryTags.quickSettings.getSettings,
            ])
          )
        );
    }
    if (
      activeTab === TABS.similarItems &&
      selectedCountry !== "US" &&
      selectedCountry !== "UK" &&
      selectedCountry !== "WAL"
    ) {
      handleTabClick(TABS.matchedItems);
    }
  };

  const gotoPath = (tab: TABS) => `?tab=${tab}`
  useEffect(() => {
    if (settings?.result.amazonsite) {
      handleCountryChange(settings?.result.amazonsite as FlagKeys);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  return (
    <>
      <Header
        leftContent={
          <>
            <Button
              color="primaryPurple"
              title="Go Back"
              height="h-9"
              className="max-w-[130px] dark:bg-deepSpace900"
              iconClassName="w-3.5"
              StartIcon={BackArrow}
              isLink
              redirect={PATH.zikPro.zikProBulkScanner}
            />
            <HeaderTab
              title="Exact Matched Items"
              path={gotoPath(TABS.matchedItems)}
              isActive={activeTab === TABS.matchedItems}
              handleClick={() => handleTabClick(TABS.matchedItems)}
            />
            {selectedCountry === "US" || selectedCountry === "UK" || selectedCountry === "WAL" ? (
              <HeaderTab
                title="Similar Items"
                path={gotoPath(TABS.similarItems)}
                isActive={activeTab === TABS.similarItems}
                handleClick={() => handleTabClick(TABS.similarItems)}
              />
            ) : (
              ""
            )}
            <HeaderTab
              title={t('NotFoundItems')}
              path={gotoPath(TABS.notFoundItems)}
              isActive={activeTab === TABS.notFoundItems}
              handleClick={() => handleTabClick(TABS.notFoundItems)}
            />
          </>
        }
        leftContentClassName="w-full"
      />
      <>
        {activeTab !== TABS.notFoundItems ? (
          <>
            <FlagsRow
              country={countryInfo}
              selectedCountry={selectedCountry}
              handleCountryChange={handleCountryChange}
            />
            <StatsCards
              data={
                data
                  ? {
                    result: { ...data.result, successful: pagination.total },
                    success: data.success,
                  }
                  : undefined
              }
              isLoading={isFetching || updateLoading}
            />
          </>
        ) : (
          ""
        )}
        <FiltersCard
          isNotFoundItems={activeTab === TABS.notFoundItems}
          filters={filters}
          handleFilterChange={handleFilterChange}
          onFilter={handleFilterClick}
        />
      </>

      {activeTab === TABS.matchedItems ? (
        <MatchedItems
          payload={searchedPayload}
          pagination={pagination}
          updateLoading={updateLoading}
          currency={data?.result.currency || "$"}
          domain={data?.result.domain || ""}
          isWalmart={data?.result.amazonSite === 4}
        />
      ) : activeTab === TABS.similarItems ? (
        <SimilarItems
          payload={searchedPayload}
          updateLoading={updateLoading}
          pagination={pagination}
          currency={data?.result.currency || "$"}
          domain={data?.result.domain || ""}
          isWalmart={data?.result.amazonSite === 4}
        />
      ) : activeTab === TABS.notFoundItems ? (
        <NotFoundItemsList
          isWal={selectedCountry === "WAL"}
          payload={searchedPayload}
          updateLoading={updateLoading}
          pagination={pagination}
          currency={data?.result.currency || "$"}
          domain={data?.result.domain || ""}
        />
      ) : (
        ""
      )}
      <Toaster />
    </>
  );
};

export default BulkScannerItems;
