import { FC } from "react";

interface Tabs {
    id: string;
    title: string;
}

interface TabProps extends Tabs {
    isActive?: boolean;
    onPlanSelect: (plan: string) => void;
    isShopify?: boolean;
}
interface Props {
    isShopify?: boolean;
    selectedPlan: string;
    onPlanSelect: (plan: string) => void;
}

const ebayTabs: Tabs[] = [
    { id: "pro", title: "PRO" },
    { id: "pro-plus", title: "PRO+" },
    { id: "enterprise", title: "Ent." }
]
const shopifyTabs: Tabs[] = [
    { id: "monthly", title: "Monthly" },
    { id: "yearly", title: "Yearly" },
]

const Tab: FC<TabProps> = ({ id, title, isActive, onPlanSelect, isShopify }) => {
    const isLeftTab = (isShopify && id === "monthly") || id === "pro";
    const isRightTab = (isShopify && id === "yearly") || id === "enterprise"
    return (
        <button className={`h-full w-full flex items-center justify-center border-secondary  text-xl
        ${isActive ? "bg-neutral2 font-bold" : "font-medium text-secondary"} 
        ${isLeftTab ? `border-r-[1.5px] rounded-l-[8px]` :
                isRightTab ? `${isShopify ? "" : "border-l-[1.5px]"}  rounded-r-[8px]` : ""}`}
            onClick={() => onPlanSelect(id)}>
            {title}
        </button>
    )
}

const ComparsionMobileTabs: FC<Props> = ({ isShopify, selectedPlan, onPlanSelect }) => {
    const tabs = isShopify ? shopifyTabs : ebayTabs
    return (
        <div className="flex w-full justify-between bg-white rounded-[8px] h-[60px] max-w-[330px] mx-auto border border-[1.5px] border-secondary mb-6
        shadow-[0px_8px_32px_-6px_rgba(20,_23,_26,_0.12),_0px_12px_42px_-4px_rgba(20,_23,_26,_0.12)]">
            {tabs.map((item) => (
                <Tab {...item} key={item.id} isActive={selectedPlan === item.id} onPlanSelect={onPlanSelect} isShopify={isShopify} />
            ))}
        </div>
    )
}

export default ComparsionMobileTabs;