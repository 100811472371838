import { helpVideos } from "assets/data/helpVideos";
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left.svg";
import HeaderHelpVideo from "components/Header/HelpVideo";
import useModal from "hooks/useModal";
import { FC, ReactNode } from "react";

interface Props {
  helpers: ReturnType<useModal>;
  children: ReactNode;
  top?: string;
  videoIcon?: boolean;
  className?: string;
  wrapperClassName?: string;
  onHandleClose?: () => void;
}

const Drawer: FC<Props> = ({
  helpers,
  children,
  className,
  wrapperClassName,
  top,
  videoIcon,
  onHandleClose
}) => (
  <div
    className={`h-full z-50 fixed right-0 bg-white dark:bg-grey700 shadow transition-maxWidth duration-500 pt-2.5 pb-12 w-full ${helpers.isOpen ? "max-w-[615px]" : "max-w-[0px]"
      } ${className || ""} ${top || "top-0"}`}
  >
    <div className="flex justify-between items-center mb-9 px-4.5">
      <button
        onClick={() => { onHandleClose?.(); helpers.close(); }}
        className={
          "w-8 h-8 bg-neutral2 rounded-full flex items-center justify-center hover:bg-primaryPurple dark:hover:bg-purple500 dark:bg-deepSpace800 group"
        }
      >
        <LeftArrow
          className="rotate-180 group-hover:fill-white fill-darkBlue dark:fill-lilac400"
        />
      </button>
      {videoIcon ? (
        <HeaderHelpVideo videos={helpVideos.manualScanner} />

      ) : (
        ""
      )}
    </div>
    <div
      className={`scrollbar pl-4.5 pr-3.5 mr-1 h-full pb-4 ${helpers.isOpen ? "" : "hidden"
        } ${wrapperClassName || ""}`}
    >
      {helpers.isOpen ? children : ""}
    </div>
  </div>
);

export default Drawer;
