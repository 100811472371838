import { createSlice } from "@reduxjs/toolkit";
import {
  businessModelsList,
  sourceMarketplacesList,
} from "assets/data/dashboard/sellerSettings";
import { newDashboardApi } from "store/api/newDashboardApi";
import { CURRENCY } from "types/currency";
import {
  BUSINESS_MODEL,
  MARKET_PLACES,
  SHIPPED_MODEL,
  SOURCE_MARKET_PLACES,
} from "types/sellerSettings";
import { marketplaceList } from "../../assets/data/filters";

interface SellerSettingsState {
  marketplace: MARKET_PLACES;
  businessModel: BUSINESS_MODEL | null;
  sourceMarketplace: SOURCE_MARKET_PLACES | null;
  shippedPlace: SHIPPED_MODEL | null | SOURCE_MARKET_PLACES;
  currency: CURRENCY;
}

export const initialState: SellerSettingsState = {
  marketplace: MARKET_PLACES.ebay,
  businessModel: BUSINESS_MODEL.dropshipping,
  sourceMarketplace: SOURCE_MARKET_PLACES.amazon,
  shippedPlace: SHIPPED_MODEL.china,
  currency: CURRENCY.dollar,
};

const getCurrency = (payload: MARKET_PLACES) => {
  if (payload === MARKET_PLACES.ebayUk) {
    return CURRENCY.pound;
  } else if (
    payload === MARKET_PLACES.ebayDe ||
    payload === MARKET_PLACES.ebayEs ||
    payload === MARKET_PLACES.ebayIt ||
    payload === MARKET_PLACES.ebayFr
  ) {
    return CURRENCY.euro;
  } else if (payload === MARKET_PLACES.ebayMy) {
    return CURRENCY.malaysianRinggit;
  } else {
    return CURRENCY.dollar;
  }
};

export const sellerSettingsSlice = createSlice({
  name: "sellerSettings",
  initialState,
  reducers: {
    setMarketPlace: (state, action) => {
      state.marketplace = action.payload;
      state.currency = getCurrency(action.payload);
      state.businessModel = businessModelsList(action.payload)[0].value;
      state.sourceMarketplace = sourceMarketplacesList(action.payload)[0].value;
    },
    setBusinessModel: (state, action) => {
      state.businessModel = action.payload;
    },
    setSourceMarketPlace: (state, action) => {
      state.sourceMarketplace = action.payload;
    },
    setShippedPlace: (state, action) => {
      state.shippedPlace = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      newDashboardApi.endpoints.getInsightsPreferences.matchFulfilled,
      (state, { payload: { result } }) => {
        const marketplace = result?.sellingOn || marketplaceList[0].value;
        const sourceMarketplace =
          result?.sourcingFrom || sourceMarketplacesList(marketplace)[0].value;
        const shippedPlace = result?.shippedFrom || null;
        const businessModel =
          result?.businessModel || businessModelsList(marketplace)[0].value;
        const currency = getCurrency(result?.sellingOn);

        state = {
          businessModel,
          sourceMarketplace,
          marketplace,
          currency,
          shippedPlace,
        };

        return state;
      }
    );
  },
});

export const {
  setMarketPlace,
  setBusinessModel,
  setSourceMarketPlace,
  setShippedPlace,
} = sellerSettingsSlice.actions;

export default sellerSettingsSlice.reducer;
