import Button from "components/Button";
import { FC, ReactNode } from "react";
import { TSelectedPlan } from "types/plans";
import { formatCurrency } from "utils/formatCurrency";

interface Props {
    name: string;
    description: ReactNode | string;
    isDiscount?: boolean;
    price: number;
    discountPrice: number;
    id: string;
    type: string;
    popularBadge?: boolean;
    isShopify?: boolean;
    isMonthly?: boolean;
    skipAmount?: number;
    wrapperClassName?: string;
    handleSelectPlan: (plan: TSelectedPlan) => void;
}

const PlansCardB: FC<Props> = ({
    name,
    description,
    discountPrice,
    price,
    id,
    type,
    isDiscount,
    isMonthly,
    isShopify,
    popularBadge,
    skipAmount,
    wrapperClassName,
    handleSelectPlan
}) => {
    const isShopifyYearly = isShopify && id === "yearly";
    const isPopularEbayAndShopify = popularBadge || isShopifyYearly;
    const isEnterprise = type.toLowerCase().includes("enterprise")

    const onStartClick = (skip: boolean) => handleSelectPlan({
        type: type,
        planName: name,
        planPrice: price,
        skip: skip
    })
    return (
        <div
            className={`relative p-[14px] h-fit rounded-[16px] w-full max-w-[310px] border-2 ${wrapperClassName} ${isPopularEbayAndShopify ? "bg-darkBlue border-smoothGreen text-white" : "bg-white border-secondary text-darkBlue"}`}
        >
            <div className={`flex items-center justify-between ${isPopularEbayAndShopify ? "mb-2" : "mb-1"}`}>
                <h4 className={`font-bold text-[28px] leading-[36px] ${isEnterprise ? "" : "uppercase"} ${isPopularEbayAndShopify ? "text-white" : "text-darkBlue"}`}>
                    {name}
                </h4>
                {popularBadge && !isShopify && (
                    <span className="text-sm bg-neutral1 rounded-md px-[10.5px] py-[5px] text-darkBlue">
                        Most Popular
                    </span>
                )}
            </div>

            <p className={`text-xs leading-[16px] ${isPopularEbayAndShopify ? "mb-6" : "mb-4"}`}>{description}</p>
            <div className="flex flex-col items-center">
                {!isShopify && (
                    <p className="relative mb-1 text-lg leading-7 text-center">
                        <span className="absolute inset-x-0 top-1/2 transform -translate-y-1/2 h-[2px] bg-errorRed mx-auto max-w-[63px]"></span>
                        {formatCurrency(price)}
                    </p>
                )}
                {isDiscount && isShopify && (
                    <div className="relative mb-2">
                        <h5 className={`text-base font-normal ${isShopifyYearly ? "text-white" : "text-darkBlue"}`}>
                            {formatCurrency(price)}/mo
                        </h5>
                        <div className="absolute bottom-[12px] left-[4px] w-[46px] h-[2px] bg-errorRed transform -rotate-[16deg] rounded-full"></div>
                    </div>
                )}
                <h5
                    className={`text-[36px] leading-[44px] font-bold ${isShopify ? (isShopifyYearly ? "mb-1" : "mb-[9px]") : isMonthly ? "mb-0" : popularBadge ? "mb-6" : "mb-4"}`}
                >
                    {formatCurrency(discountPrice)}
                    {isShopifyYearly && <span className="text-xs leading-4 text-grey200 font-normal">month</span>}
                </h5>
                {isMonthly && !isShopify && (
                    <span className={`text-xs leading-[16px] font-medium ${popularBadge ? "text-grey100" : "text-grey400"} ${popularBadge ? "mb-6" : "mb-4"}`}>
                        <span>for 1st month</span>
                    </span>
                )}
                {isShopify && (
                    <div className="text-center mb-4 leading-[16px]">
                        <span className={`text-xs font-bold ${isShopifyYearly ? "text-[#E9BA1F] block mb-[9px]" : "text-errorRed mb-[5px]"}`}>
                            50% Off
                        </span>
                        {isShopifyYearly ? (
                            <span className="text-[10px] font-medium block leading-[12px]">
                                You Save $150 a year!
                            </span>
                        ) : <span className="text-[10px] font-medium"> for 1st month</span>}
                    </div>
                )}
                <Button
                    title="Start $1 Trial Now"
                    height="h-[56px]"
                    titleClassName="!text-base font-medium"
                    color="smoothGreen"
                    handleClick={() => onStartClick(false)}
                    className={`w-full max-w-[246px] mx-auto h-14 mb-2 bg-white shadow-[2px_5px_30px_-4px_rgba(28,_163,_147,_0.15)]`}
                />

                <button
                    onClick={() => onStartClick(true)}
                    className={`text-xs underline leading-4 ${isPopularEbayAndShopify ? "text-secondary" : "text-electricBlue"}`}>
                    Skip Trial {skipAmount === 0 ? "Now!" : `and Save ${formatCurrency(skipAmount || 0)}`}
                </button>
            </div>
        </div>
    );
};

export default PlansCardB;