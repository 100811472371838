import { ReactComponent as Lock } from "assets/icons/lock.svg";
import Card from "components/Card";
import useDecodeToken from "hooks/useDecodeToken";
import useOutsideClick from "hooks/useOutsideClick";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { PATH } from "routes/path";
import { PLAN_TYPE } from "types/plans";
import { SidebarSubmenuProps } from "./SidebarItem";

interface Props {
  submenu: SidebarSubmenuProps[];
  width?: string
  handleClose?: () => void;
  store?: string;
  isExpand?: boolean;
}

const createUrlPath = (item: SidebarSubmenuProps, store?: string) => {
  if (item.title === "Store Analytics" && store) {
    return `${PATH.store.root}/${store}`;
  }

  return item.path;
}



const SidebarSubmenu: FC<Props> = ({ submenu, handleClose, width, store, isExpand }) => {
  const { pathname, search } = useLocation();
  const [isHovered, setIsHovered] = useState("");
  const userDetails = useDecodeToken();
  const plan = userDetails.package;
  const isProPlus = plan === PLAN_TYPE.PRO_PLUS
  const [ref] = useOutsideClick<HTMLDivElement>(handleClose);
  const isActive = (itemPath: string) => pathname + search === itemPath;
  const [submenuOpened, setSubmenuOpened] = useState("");
  const { t } = useTranslation(['home']);
  const handleOpenSubMenu = (submenuTitle: string, e: any) => {
    e.preventDefault();
    setSubmenuOpened(submenuTitle);
  }

  return (
    <div
      className={`absolute ${isExpand ? "left-[182px]" : "left-[36px]"}  text-xs z-50 pointer-events-auto`}
      ref={ref}
    >
      <Card
        className={`bg-white border-primaryPurple dark:border-purple500 dark:bg-deepSpace900 ${width || "w-14rem"} p-2 flex items-center flex-col gap-[2px]`}
        rounded="rounded-[20px]"
        shadow="shadow-shadow4"
        bordered
      >
        {submenu.map((item, index) => {
          const submenuOffset = `${index * 36 + 18}px`;

          return (
            <>
              <Link
                to={createUrlPath(item, store)}
                onClick={(e) => { createUrlPath(item, store) === "" && handleOpenSubMenu(item.title, e) }}
                key={item.title}
                onMouseOver={() => setIsHovered(item.title)}
                onMouseOut={() => setIsHovered("")}
                className={`py-[10px] px-[8px] rounded-full border-2 cursor-pointer pointer-events-auto group
              hover:!bg-primaryPurple hover:border-primaryPurple dark:hover:!bg-purple500 dark:hover:border-purple500  hover:text-white  flex items-center w-full gap-2.5 h-full ${isActive(item.path)
                    ? "border-primaryPurple dark:border-purple500"
                    : "border-transparent"
                  }`}
              >
                {item.locked ? (
                  <Lock
                    className={`fill-darkBlue dark:fill-grey100 group-hover:fill-white w-3`}
                  />
                ) : item.Icon ? (
                  <item.Icon
                    className={`fill-darkBlue dark:fill-grey100 group-hover:fill-white w-4 h-3`}
                  />
                ) : (
                  ""
                )}

                <span className="dark:text-grey100">{t(item.translate)}</span>
                {item.limited && isProPlus ? (
                  <span className="text-primaryPurple dark:text-purple500 group-hover:text-white dark:group-hover:text-lilac400 ml-[auto]">
                    10 scans/month
                  </span>
                ) : ""}
              </Link>
              {
                item.submenu?.length &&
                <div style={{ top: submenuOffset }}
                  className={`absolute left-[calc(100%+8px)] text-xs z-50 pointer-events-auto ${submenuOpened === item.title ? "block" : "hidden"}`}>
                  <Card
                    className={`bg-white border-primaryPurple dark:border-purple500 w-auto p-[10px] flex items-center flex-col gap-[3px]`}
                    rounded="rounded-[20px]"
                    shadow="shadow-shadow4"
                    bordered
                  >
                    {item.submenu?.map((sub) => <Link
                      to={sub.path}
                      key={sub.title}
                      onMouseOver={() => setIsHovered(sub.title)}
                      onMouseOut={() => setIsHovered("")}
                      className={`py-[10px] rounded-full border-2 cursor-pointer pointer-events-auto pl-4 flex items-center w-[146px] gap-2.5 h-11 hover:bg-primaryPurple dark:hover:bg-purple500
                      ${isActive(sub.path)
                          ? "border-primaryPurple dark:border-purple500"
                          : "border-transparent dark:hover:border-purple500 hover:border-primaryPurple hover:text-white"
                        }`}
                    >
                      {
                        sub.Icon &&
                        <sub.Icon
                          className={`${sub.title === isHovered ? "fill-white" : "fill-darkBlue dark:fill-grey100"
                            } w-4 h-3`}
                        />
                      }
                      <span className="dark:text-grey100">{sub.title}</span></Link>)}
                  </Card>

                </div>
              }
            </>
          )
        })}
      </Card>
    </div >
  );
};

export default SidebarSubmenu;
