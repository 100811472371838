import InfoTooltip from "components/Card/InfoTooltip";
import { FC } from "react";

interface Props {
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  infoTitle?: string;
  children: JSX.Element | JSX.Element[];
  className?: string;
  loading?: boolean;
  iconClassName?: string;
  titleClassName?: string;
  titleWrapperClassName?: string;
  infoTooltipClassName?: string;
  disabled?: boolean
}

const FiltersCard: FC<Props> = ({
  children,
  Icon,
  title,
  infoTitle,
  loading,
  className,
  iconClassName,
  titleClassName,
  titleWrapperClassName,
  infoTooltipClassName,
  disabled,
}) => (
  <div
    className={`border-2 rounded-[20px] dark:bg-deepSpace900 relative h-min ${className || ""
      }  py-3 px-[10px] ${disabled ? "border-neutral1" : "border-[#EBF4FE] dark:border-grey700"}`}
  >
    {Icon || title || infoTitle ? (

      <div
        className={`flex justify-between items-center mb-2.5 h-6 ${titleWrapperClassName || ""
          }`}
      >
        {Icon ? (
          <Icon className={`fill-darkBlue dark:fill-grey100 ${iconClassName || ""}`} />
        ) : (
          <div className="w-2" />
        )}
        <p className={`font-bold text-sm ${disabled ? "text-grey100" : "dark:text-grey100 "} ${titleClassName || ""}`}>{title}</p>
        {infoTitle ? (
          <InfoTooltip title={infoTitle} className={infoTooltipClassName} />
        ) : (
          <div className="w-2" />
        )}
      </div>
    ) : ""}

    {loading ? "" : children}
  </div>
);

export default FiltersCard;
