import Card from "components/Card";
import InfoTooltip from "components/Card/InfoTooltip";
import CutLongTitle from "components/CutLongTitle";
import { THEME_COLORS } from "config/constants";
import { useAppSelector } from "hooks/useAppSelector";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { VictoryLabel, VictoryPie, VictoryTooltip } from "victory";

class CustomLabel extends React.Component {
  render() {
    return (
      <g>
        <VictoryLabel {...this.props} />
        <VictoryTooltip
          {...this.props}
          style={{
            fill: "#150D3F",
            fontSize: 24,
          }}
          x={200}
          y={260}
          orientation="top"
          pointerLength={0}
          flyoutWidth={100}
          flyoutHeight={100}
          flyoutStyle={{
            stroke: "transparent",
            fill: "transparent",
          }}
        />
      </g>
    );
  }
}

// @ts-ignore
CustomLabel.defaultEvents = VictoryTooltip.defaultEvents;

const ProductPieChart = () => {
  const { t } = useTranslation(['home']);
  const { isDarkMode } = useAppSelector((state) => state.themeSlice);
  const { pieData } = useAppSelector((state) => state.prSlice.ebayData.result);
  const data = pieData?.filter((item) => item.value > 0) || [];
  const firstItem = {
    x: data && data[0]?.label,
    y: data && data[0]?.value,
  };
  const [selected, setSelected] = useState(firstItem);
  const overall = data ? data.reduce((acc, cur) => acc + cur.value, 0) : 1;
  const getBorderColor = {
    [THEME_COLORS.primaryPurple]: isDarkMode ? "#AE70FF" : "#A056FF",
    [THEME_COLORS.darkBlue]: isDarkMode ? "#5571FF" : "#3C5BFF",
    [THEME_COLORS.smoothGreen]: isDarkMode ? "#20FFCB" : THEME_COLORS.spaceGreen,
  };

  return (
    <div className="relative h-[19.5rem] dark:text-grey100">
      <div className="absolute z-10 left-4 top-6">
        <h4 className={`text-sm font-bold`}>{t('MarketShare')}</h4>
      </div>
      <div className="absolute z-10 right-7 top-6">
        <InfoTooltip title="Use this to see how competitive the niche is." />
      </div>
      <Card
        className="w-[25.4375rem] h-full max-lg:w-full relative"
        rounded="rounded-xl"
        bordered
      >
        <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] text-center font-medium z-[10] dark:text-lilac400">
          <p><CutLongTitle title={selected.x} length={14} /> </p>
          <p>
            {((overall !== 0 ? selected.y / overall : 0) * 100).toFixed(1)}%
          </p>
        </div>
        <VictoryPie
          style={{
            data: {
              stroke: (d) =>
                selected.x === d.datum?.x
                  ? // @ts-ignore
                  getBorderColor[d.style?.fill]
                  : "none",
              strokeWidth: (d) => (selected.x === d.datum?.x ? 14 : 0),
            },
            labels: {
              display: "none",
            },
          }}
          padAngle={({ datum }) => (selected.x === datum?.x ? 2 : 0)}
          height={700}
          width={700}
          innerRadius={175}
          colorScale={isDarkMode ? [THEME_COLORS.purple500, THEME_COLORS.lilac400, THEME_COLORS.paradiseBlue] : [THEME_COLORS.primaryPurple, THEME_COLORS.darkBlue, THEME_COLORS.smoothGreen]}
          labelRadius={120}
          events={[
            {
              target: "data",
              eventHandlers: {
                onMouseOver: () => [
                  {
                    target: "data",
                    mutation: (d) => {
                      const data = d.datum;
                      setSelected(data);
                    },
                  },
                  {
                    target: "labels",
                    mutation: () => ({ active: true }),
                  },
                ],
                onMouseOut: () => [
                  {
                    target: "labels",
                    mutation: () => ({ active: false }),
                  },
                ],
              },
            },
          ]}
          data={data.map((item) => ({ x: item.label, y: item.value || 0 }))}
        />
      </Card>
    </div>
  );
};

export default ProductPieChart;
