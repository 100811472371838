import Card from "components/Card";
import InfoTooltip from "components/Card/InfoTooltip";
import TrendingSellersList from "components/TrendingSellers/TrendingSellersList";
import { useAppSelector } from "hooks/useAppSelector";
import { PATH } from "routes/path";
import { useGetTrendingShopifyStoresQuery } from "store/api/shopifyMarketInsights";
import { CURRENCY } from "types/currency";
import { TrendingEbaySellerList } from "types/services/newDashboard";
import { makeQueryParams } from "utils/makeQueryParams";


const ShopifyTrendingStores = () => {
    const { currency } = useAppSelector((state) => state.sellerSettings);
    const { data } = useGetTrendingShopifyStoresQuery();
    const list = data?.result?.map((item) => ({
        currencySymbol: CURRENCY.dollar,
        feedback: item.revenue,
        isFire: item.isFire,
        sales: item?.sales,
        searchedUserID: item.similarWebDomainId,
        sellerLocation: item.countryCode,
        sellerName: !item.storeName || item.storeName === "" || item.storeName === "undefined" ? item.domain.split(/\.(?=[^\.]+$)/)[0] : item.storeName,
        domain: item.domain,
        storeType: item.storeType
    }));
    const handleStoreRedir = (item: TrendingEbaySellerList) => `https://${item.domain}`
    const handleCompetitorRedir = (sellerId: string, storeDomain?: string) => makeQueryParams(`${PATH.shopify.salesSpy}/${sellerId}`, { storeDomain, redirected: true })
    return (
        <Card className="py-[18px] pr-1.5 max-xl:w-1/2 max-sm:w-full">
            <h6 className="text-center text-sm font-bold mb-[3px] dark:text-grey100">Trending Shopify Stores</h6>
            <p className="flex justify-center items-center gap-2.5 text-xs text-neutral3">
                Last 30 Days
                <InfoTooltip title="Explore stores that have shown high performance in the last 30 days." />
            </p>
            <TrendingSellersList isAli={false} isFetching={false} data={list || []} getStoreLink={handleStoreRedir} getSpyRoute={handleCompetitorRedir} currency={currency} isShopify scanSellerBtnTooltip="Analyze Store" />
        </Card>
    )
}

export default ShopifyTrendingStores