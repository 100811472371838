import { ReactComponent as Box } from "assets/icons/boxes/box.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Dropshipper } from "assets/icons/dropshipper.svg";
import { ReactComponent as PrintOnDemand } from "assets/icons/print-on-demand.svg";
import { ReactComponent as SingleStore } from "assets/icons/single-products.svg";
import { ReactComponent as Instagram } from "assets/icons/social/instagram.svg";
import { ReactComponent as Meta } from "assets/icons/social/meta.svg";
import { ReactComponent as Pinterest } from "assets/icons/social/pinterest.svg";
import { ReactComponent as SnapchatIcon } from "assets/icons/social/snapchat.svg";
import { ReactComponent as Tiktok } from "assets/icons/social/tiktok.svg";
import { ReactComponent as Twitter } from "assets/icons/social/twitter.svg";
import { ReactComponent as Youtube } from "assets/icons/social/youtube.svg";
import ShopifyItemFinderBox from "components/IconFinderBox/ShopifyItemFinderBox";

import { ColumnDef } from "@tanstack/react-table";
import HoveredIcon from "components/HoveredIcon";
import ProductNameCell from "components/ProductNameCell";
import Tooltip from "components/Tooltip";
import dayjs from "dayjs";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { ProductExplorerList } from "types/services/productExplorer";
import { getCountryFlag } from "utils/getCountryFlag";
import { makeQueryParams } from "utils/makeQueryParams";
const linkClassName = "bg-neutral2 dark:bg-deepSpace900 text-xs px-[9px] py-[5px] rounded-[5px]"


const getSocialByName = (name: string, productId: number) => {
    let channel: ReactNode;
    name.toLowerCase() === "youtube" ?
        channel = <div className="relative" data-tooltip-id={productId + name}>
            <Youtube className="w-[15px] h-[11px] fill-[#F61C0D]" />
            <Tooltip anchorId={productId + name} title={"awdawd"} />
        </div>
        :
        name.toLowerCase() === "instagram" ?
            channel = <Instagram className="w-[13px] h-[13px]" />
            :
            name.toLowerCase() === "twitter" ?
                channel = <div className="relative" data-tooltip-id={productId + name}>
                    <Twitter className="w-[12px] h-[11px] fill-deepSpace900" />
                    <Tooltip anchorId={productId + name} title={"awdawd"} />
                </div>
                :
                name.toLowerCase() === "facebook" ?
                    channel = <div className="relative" data-tooltip-id={productId + name}>
                        <Meta className="w-[16p.6x] h-[11px]" />
                        <Tooltip anchorId={productId + name} title={"Meta/Facebook"} />
                    </div>
                    :
                    name.toLowerCase() === "tiktok" ?
                        channel = <div className="relative" data-tooltip-id={productId + name}>
                            <Tiktok className="w-[12px] h-[13.63px]" />
                            <Tooltip anchorId={productId + name} title={"TikTok"} />
                        </div>
                        :
                        name.toLowerCase() === "pinterest" ?
                            channel = <div className="relative" data-tooltip-id={productId + name}>
                                <Pinterest className="w-[13px] h-[13px] fill-[#CB2027]" />
                                <Tooltip anchorId={productId + name} title={"Pinterest"} />
                            </div>
                            :
                            name.toLowerCase() === "snapchat" ?
                                channel = <div className="relative" data-tooltip-id={productId + name}>
                                    <SnapchatIcon className="w-[13px] h-[13px] fill-[#FFFC00]" />
                                    <Tooltip anchorId={productId + name} title={"Snapchat"} />
                                </div>
                                : <></>

    return channel;
}

const getStoreType = (storeType: number, productId: number) => {
    let st: ReactNode;
    storeType === 1 ?
        st = <div className="w-[20px] h-[20px] flex items-center justify-center relative" data-tooltip-id={String(productId + storeType)}>
            <Dropshipper className="w-[15px] h-[15px]" />
            <Tooltip anchorId={productId + storeType} title={"Dropshipping"} />
        </div>
        :
        storeType === 2 ?
            st = <div className="w-[20px] h-[20px] flex items-center justify-center" data-tooltip-id={String(productId + storeType)}>
                <PrintOnDemand className="w-[15px] h-[15px]" />
                <Tooltip anchorId={productId + storeType} title={"Print on Demand"} />
            </div>
            :
            storeType === 3 ?
                st = <div className="w-[20px] h-[20px] flex items-center justify-center" data-tooltip-id={String(productId + storeType)}>
                    <SingleStore className="w-[15px] h-[15px]" />
                    <Tooltip anchorId={productId + storeType} title={"One Product Store"} />
                </div>
                :
                <></>

    return st;
}

export const productExplorerCols = (handleBoxClick: (item: ProductExplorerList) => void, days: string, handleSort: (column: string) => void): ColumnDef<ProductExplorerList>[] => ([
    {
        header: "Image",
        accessorKey: "image",
        enableSorting: false,
        cell: ({ row, row: { original } }) => (
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-2.5 ml-[13px]">
                    <div className="w-[5rem]">
                        <ProductNameCell
                            img={original.image}
                            imgWrapperClassName="ml-0"
                            adjustFirstandLastItemsAuto
                            height="59px"
                            width="75px"
                            maxHeight="max-h-[59px]"
                        />
                    </div>

                </div>

            </div>

        ),
        meta: {
            align: "justify-start"

        }
    },
    {
        header: "Product",
        minSize: 350,
        accessorKey: "name",
        cell: ({ row: { original } }) => (
            <>
                <div className="flex flex-col gap-[8px]">
                    <div className="flex items-center gap-[8px]">
                        <img src={getCountryFlag(original.country || "")} className="w-4 h-4" alt="" />
                        <Link rel="external" to={original.url} target="_blank" data-tooltip-id={original.url}>
                            <p>{original.name}</p>
                            <Tooltip anchorId={original.url} title="Analyze Store" />
                        </Link>

                    </div>
                    <div className="flex items-center gap-[8px]">
                        {getStoreType(original.storeType, original.productId)}
                        {original.adsChannels?.map(channel => (
                            <div key={channel.adsChannel} className="w-[20px] h-[20px] flex items-center justify-center">
                                {channel.url ? <a href={channel.url} key={channel.adsChannel} target="_blank"> {getSocialByName(channel.adsChannel, original.productId)}</a> : getSocialByName(channel.adsChannel, original.productId)}
                            </div>
                        ))}


                    </div>
                </div>
            </>


        ),
        meta: {
            align: "justify-start"

        }
    },
    {
        header: "Upload Date",
        minSize: 130,
        accessorKey: "upladDate",
        cell: ({ row: { original } }) => (
            original.upladDate === null ? "-" :
                dayjs(original.upladDate).format('D MMM YYYY')

        ),
        meta: {
            align: "justify-start",
            customSort: () => handleSort("upladDate"),
        }

    },
    {
        header: "Price",
        accessorKey: "price",
        cell: ({ row: { original } }) => (
            <>
                <div data-tooltip-id={original.productId + "r002"} className="flex flex-col item-center justify-center">{
                    original.currency === "USD" ?
                        <p className="font-bold">${original.price === null ? 0 : original.price.toLocaleString("en-Us")}</p>
                        :
                        <>
                            <p className="font-bold">${original.price === null ? 0 : original.price.toLocaleString("en-Us")}</p>
                            <p className="text-grey300">{original.currency} {original.convertedPrice === null ? 0 : original.convertedPrice.toLocaleString("en-Us")}</p>
                        </>
                }
                </div>
                <Tooltip anchorId={original.productId + "r002"} title="Results in USD and your chosen currency" />
            </>

        ),
        meta: {
            align: "justify-start",
            customSort: () => handleSort("price"),

        }
    },
    {

        header: () => {
            return <>
                <div className="flex flex-col">
                    7 Days Sales <span className="text-[10px] leading-[12px] font-[500]">Est.</span>
                </div>

            </>
        },
        minSize: 130,
        accessorKey: "sales7Days",
        cell: ({ row: { original } }) => (
            <>
                <p>{original.sales7Days ? original.sales7Days.toLocaleString("en-Us") : 0}</p>

            </>

        ),
        meta: {
            align: "justify-start",
            customSort: () => handleSort("sales7Days"),
            tooltipTitle: "Estimated Sales based on store activity.",
            tooltipPosition: "left"

        }

    },
    {
        header: () => {
            return <>
                <div className="flex flex-col">
                    {days === "7" ? "30" : days} Days Sales <span className="text-[10px] leading-[12px] font-[500]">Est.</span>
                </div>

            </>
        },
        minSize: 130,
        accessorKey: "sales",
        cell: ({ row: { original } }) => (
            <>
                <p data-tooltip-id={original.productId + "r003"} >{original.sales ? original.sales.toLocaleString("en-Us") : 0}</p>
                <Tooltip anchorId={original.productId + "r003"} title="Number of products sold during the selected date range." />
            </>

        ),
        meta: {
            align: "justify-start",
            customSort: () => handleSort("sales"),
            tooltipTitle: "Estimated Sales based on store activity.",
            tooltipPosition: "left"
        }

    },
    {
        header: () => {
            return <>
                <div className="flex flex-col">
                    Revenue <span className="text-[10px] leading-[12px] font-[500]">Est.</span>
                </div>

            </>
        },
        accessorKey: "revenue",
        cell: ({ row: { original } }) => (
            <>
                <div data-tooltip-id={original.productId + "r001"} className="flex flex-col item-center justify-center">{
                    original.currency === "USD" ?
                        <p className="font-bold">${original.revenue === null ? 0 : original.revenue.toLocaleString("en-Us")}</p>
                        :
                        <>
                            <p className="font-bold">${original.revenue === null ? 0 : original.revenue.toLocaleString("en-Us")}</p>
                            <p className="text-grey300">{original.currency} {original.convertedRevenue === null ? 0 : Math.round(original.convertedRevenue).toLocaleString("en-Us")}</p>
                        </>
                }
                </div>
                <Tooltip anchorId={original.productId + "r001"} title="Revenue generated during the selected date range." />
            </>

        ),
        meta: {
            align: "justify-start",
            customSort: () => handleSort("revenue"),
            tooltipTitle: "Estimated Revenue based on store activity.",
            tooltipPosition: "left"

        }
    },
    {
        header: () => <CompetitorResearch className="fill-darkBlue w-[17.5px] h-[18.96px] dark:fill-grey100" />,
        accessorKey: "awd",
        minSize: 50,
        enableSorting: false,
        cell: ({ row: { original } }) => (
            <>
                <Link to={makeQueryParams(`${PATH.shopify.salesSpy}/${original.similarWebDomainId}`, { storeDomain: original.domain, redirected: true })} target="_blank">
                    <HoveredIcon Icon={CompetitorResearch} iconClassName="w-[17.5px] h-[18.96px]" variant="outlined" width="w-[35px]" height="h-[35px]" title="Analyze Store" />
                </Link>

            </>

        ),
        meta: {
            alignHead: "justify-center",
            tooltipTitle: "Scan Store",
            tooltipPosition: "left"
        }
    },
    {
        header: () => <Box className="fill-darkBlue dark:fill-grey100" />,
        accessorKey: "itemID",
        minSize: 50,
        enableSorting: false,
        cell: ({ row: { original } }) =>
            <ShopifyItemFinderBox handleClick={() => handleBoxClick(original)} />,
        meta: {
            align: "justify-center",
            alignHead: "justify-center",
            tooltipTitle: 'Find products from your selected supplier. Switch suppliers in "Quick Settings" on the left menu.',
            tooltipPosition: "left"
        }
    }

])

