import { helpVideos } from "assets/data/helpVideos"
import Header from "components/Header"
import HeaderHelpVideo from "components/Header/HelpVideo"
import HeaderTab from "components/Header/Tab"

const ShopifyBillingHeader = () => {
    return (
        <Header
            className="!mb-5 max-xl:!flex-row"
            leftContent={
                <HeaderTab
                    title={"Billing"}
                    isActive={true}
                    className="!min-w-[65px]"
                    handleClick={() => { }}
                />
            }
            rightContent={
                <HeaderHelpVideo
                    videos={
                        helpVideos.managePayments
                    }
                />
            }
        />
    )
}

export default ShopifyBillingHeader