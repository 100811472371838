export const turboScanFilters = {
  supplier: "1",
  category: [] as string[],
  inclusions: [] as string[],
  rating: 0,
  reviewCount: {
    min: null as number | null,
    max: null as number | null,
  },
  sellerRanking: {
    min: null as number | null,
    max: null as number | null,
  },
  recentSale: { min: null as number | null },
  price: {
    min: null as number | null,
    max: null as number | null,
  },
  numberOfProducts: { max: 200 },
  keywords: "",
};
