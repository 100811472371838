import { noOfEntries } from "assets/data/filters";
import Search from "components/Search";
import Select from "components/Select";

// Icons
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { referralStatisticsCols } from "assets/tableColumns/referralStatistics";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useTable from "hooks/useTable";
import { useGetReferralStatsQuery } from "store/api/affiliateManagerApi";

const tableStyles: TableStyles = {
  px: "px-0",
  minWidth: "min-w-[700px]",
  head: { contHeight: "h-[37px]", rowHeight: "h-[49px]" },
  body: { contHeight: "h-[37px]", rowHeight: "h-[77px]" },
};

const ReferralStatisticsList = () => {
  const { data, isLoading } = useGetReferralStatsQuery();

  const { table } = useTable({
    columns: referralStatisticsCols,
    data: data?.result.referredUser || [],
    dataDeps: [data?.result.referredUser],
    initialPageSize: 50,
    pagination: true,
    search: true,
  });

  return (
    <div className="max-w-[1140px] w-full m-auto border-2 border-neutral2 dark:border-grey700 dark:bg-grey800 rounded-[10px] px-5.5 pb-[11px] pt-6">
      <div className="flex justify-between items-end mb-[26px] max-[750px]:flex-col max-[750px]:items-start max-[750px]:gap-4.5">
        <Select
          options={noOfEntries}
          value={table.getState().pagination.pageSize.toString()}
          handleChange={(value) => table.setPageSize(Number(value))}
          Icon={Menu}
          btnClassName="min-w-[12rem]"
        />

        <Search
          search={table.getState().globalFilter}
          handleChange={table.setGlobalFilter}
          maxWidth="18.8125rem"
          className="w-[18.8125rem] border-darkBlue"
          size="sm"
          htmlFor="table-search"
        />
      </div>
      <Table table={table} styles={tableStyles} isLoading={isLoading} />
      <Pagination table={table} labeled />
    </div>
  );
};

export default ReferralStatisticsList;
