import { DecodedUser } from "hooks/useDecodeToken";
import { LatestSubscriptionRes } from "types/services/managePayments";
import { getPlanFullSuffix, getPlanName } from "./planUtils";

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const getBillingStatus = (status: string) => {
  switch (status) {
    case "True":
      return "Active";
    case "False":
      return "Canceled";
    case null:
      return "(no subscription)";
  }
};

export const initDataLayer = (userDetails: DecodedUser | null) => {
  window.dataLayer.push({
    page: {
      spa: true,
      class: "web",
    },
    user_properties: {},
    user_details: {
      id: userDetails?.nameid,
      email: userDetails?.email,
      first_name: userDetails?.unique_name.split(" ")[0],
      last_name: userDetails?.unique_name.split(" ")[1],
      phone_number: "",
      address_country: userDetails?.country,
    },
    user_profile: {
      user_id: userDetails?.nameid,
      user_status: userDetails?.role,
      user_subscription_plan: userDetails?.package,
      user_subscription_billing_cycle:
        userDetails?.package_name === ""
          ? "(no subscription)"
          : getPlanFullSuffix(userDetails?.package_name || ""),
      user_subscription_purchases: "",
      user_subscription_billing_status: getBillingStatus(
        userDetails?.active || ""
      ),
    },
  });
};

// when user choose CC on onboarding payment
export const gtmOnboardingCcClick = () => {
  window.dataLayer.push({
    event: "CE onboarding_payment_cc_click",
  });
};

// when user choose Paypal on onboarding payment
export const gtmOnboardingPaypalClick = () => {
  window.dataLayer.push({
    event: "CE onboarding_payment_paypal_click",
  });
};

// when user choose Payoneer on onboarding payment
export const gtmOnboardingPayoneerClick = () => {
  window.dataLayer.push({
    event: "CE onboarding_payment_payoneer_click",
  });
};

// when user click pay now button on onboarding payment
export const gtmOnboardingPaymentSubmitAttempt = () => {
  window.dataLayer.push({
    event: "CE onboarding_payment_submit_attempt",
  });
};

// when onboarding pay now button is success
export const gtmOnboardingPaymentSubmit = () => {
  window.dataLayer.push({
    event: "CE onboarding_payment_submit",
  });
};

// when user land on plans view on onboarding funnel
export const gtmOnboardingPlansScreenView = () => {
  window.dataLayer.push({
    event: "CE onboarding_plans_screen_view",
    page: { screen: "onboarding_pricing_screen" },
    ga_event: {
      category: "Onboarding",
    },
  });
};

export const gtmOnboardingPaymantScreenView = () => {
  window.dataLayer.push({
    event: "CE onboarding_payment_screen_view",
    page: { screen: "onboarding_plan_screen" },
    ga_event: {
      category: "Onboarding",
    },
  });
};

// when user attempt submit register form
export const gtmRegisterSubmitAttempt = () => {
  window.dataLayer.push({
    event: "CE signup_submit_attempt",
    ga_event: {
      category: "Signup",
    },
  });
};

//when user submit register form is success
export const gtmRegisterSubmit = (email: string, uid: number) => {
  window.dataLayer.push({
    event: "CE signup",
    event_properties: {
      signup_email: email,
      signup_user_id: uid,
    },
    ga_event: {
      category: "Signup",
      label: uid,
    },
  });
};

// when user submit login form

export const gtmLoginSubmit = (userDetails: any | null) => {
  window.dataLayer.push({
    event: "CE login",
    event_properties: {},
    user_details: {
      id: userDetails?.nameid,
      email: userDetails?.email,
      first_name: userDetails?.unique_name.split(" ")[0],
      last_name: userDetails?.unique_name.split(" ")[1],
      address_country: userDetails?.country,
    },
    user_properties: {
      /* user_seniority: response.userSeniority, */
      user_subscription_plan: userDetails?.package,
      user_subscription_billing_cycle:
        userDetails?.package_name === ""
          ? "(no subscription)"
          : getPlanFullSuffix(userDetails?.package_name || ""),
      user_status: userDetails?.role,
      user_subscription_billing_status: getBillingStatus(
        userDetails?.active || ""
      ),
    },
  });
};

// start trial event
export const gtmStartTrial = (eventData: LatestSubscriptionRes) => {
  window.dataLayer.push({
    event: "CE trial_start",
    event_properties: {
      subscription_id: eventData.result.subscription_id,
      subscription_plan: getPlanName(eventData.result.subscription_plan),
      subscription_billing_cycle: eventData.result.subscription_billing_cycle,
      transaction_id: eventData.result.transaction_id,
      transaction_coupon: eventData.result.transaction_coupon,
      transaction_value: eventData.result.transaction_value,
    },
  });
};

// subscription purchase event
export const gtmSubscriptionPurchase = (
  eventData: LatestSubscriptionRes,
  isUpgrade?: boolean,
  isDowngrade?: boolean
) => {
  window.dataLayer.push({
    event: "CE subscription_purchase",
    event_properties: {
      subscription_id: eventData.result.subscription_id,
      subscription_plan: getPlanName(eventData.result.subscription_plan),
      subscription_billing_cycle: eventData.result.subscription_billing_cycle,
      transaction_id: eventData.result.transaction_id,
      transaction_coupon: eventData.result.transaction_coupon,
      transaction_value: eventData.result.transaction_value,
      transaction_ftp: eventData.result.transaction_ftp,
      transaction_ftp_skip_trial: eventData.result.transaction_ftp_skip_trial,
      transaction_upgrade: isUpgrade
        ? [
            getPlanName(eventData.result.subscription_plan),
            getPlanFullSuffix(eventData.result.subscription_plan),
          ]
        : "(no upgrade)",
      transaction_downgrade: isDowngrade
        ? [
            getPlanName(eventData.result.subscription_plan),
            getPlanFullSuffix(eventData.result.subscription_plan),
          ]
        : "(no downgrade)",
    },
  });
};
