import { APP_URL } from "config/config";

export const PATH = {
  root: "/",
  maintenance: "/maintenance",
  regFunnel: {
    root: "/registration-funnel",
    b: "/registration-funnel-b",
  },
  auth: {
    autods: "/auth/autods",
    connectautods: `https://www.autods.com/integrate?response_type=code&client_id=h20d5onc7vpqdf1dbgksmvl0&redirect_uri=${APP_URL}/auth/autods`,
  },
  yorayppccreset: "/yorayppccreset",
  resetcc: "/resetcc",

  endTrial: "trialend/:id",
  index: {
    failure: "/index/failure",
  },
  productResearch: {
    root: "/product-research",
    aliexpress: "/product-research/aliexpress",
    ebay: "/product-research/ebay",
  },
  productExplorer: {
    root: "/product-explorer",
  },
  competitorResearch: {
    root: "/competitor-research",
    ebay: "/competitor-research/ebay",
    aliexpress: "/competitor-research/aliexpress",
  },
  categoryResearch: {
    root: "/category-research",
    results: "/category-research/results",
  },
  titleBuilder: {
    root: "/title-builder",
    results: "/title-builder/results",
  },
  themeDetector: "/theme-detector",
  dashboard: { root: "/dashboard", myDashboard: "/dashboard?tab=my-dashboard" },
  zikPro: {
    root: "/zik-pro",
    zikProBulkScanner: "/zik-pro?tab=bulk-scanner",
    bulkScanner: {
      root: "/zik-pro/bulk-scanner",
      items: "/zik-pro/bulk-scanner/:id",
    },
    turboScanner: {
      root: "/zik-pro?tab=turbo-scanner",
      turboScanner: "/zik-pro/turbo-scanner",
      items: "/zik-pro/turbo-scanner/:id",
    },
    bestSellingItems: "/zik-pro?tab=best-selling-items",
    aliGrowthScanner: "/zik-pro?tab=ali-growth-scanner",
    autopilot: "/zik-pro?tab=autopilot",
    autopilotscan: {
      root: "/zik-pro/autopilotscan",
      item: "/zik-pro/autopilotscan/:id",
    },
  },
  managementPanel: {
    root: "/management-panel",
    billing: "/management-panel/billing",
    upcampaign: "/management-panel/upcampaign",
    savedSearches: "/management-panel?tab=saved-searches",
    waitingList: "/competitor-research?tab=progress",
    managePayments: "/management-panel?tab=manage-payments",
    affiliateManager: "/management-panel?tab=affiliate-manager",
    storeAnalytics: "/management-panel?tab=store-analytics",
    mySettings: "/management-panel?tab=my-settings",
    subscriptionPurchase: "/management-panel/billing/purchase",
    subscriptionUpgrade: "/management-panel/billing/upgrade",
  },
  myProducts: {
    root: "/my-products",
    item: "/my-products/:id",
  },
  quickSettings: {
    root: "/quick-settings",
    account: "/quick-settings?tab=account",
    storeSettings: "/quick-settings?tab=store-settings",
    integration: "/quick-settings?tab=integrations",
  },
  store: {
    root: "/store",
    item: "/store/:id",
  },
  purchaseComplete: {
    paypal: "/AnalyticsSuccess/Processing",
    payoneer: "/AnalyticsSuccess/PayoneerConsent",
    creditcard: "/AnalyticsSuccess/cc",
    shopifyCreditCard: "/AnalyticsSuccess/Shopify/cc",
    upgrade: "/AnalyticsSuccess/Upgrade",
    shopifyUpgrade: "/AnalyticsSuccess/Shopify/Upgrade",
    downgrade: "/AnalyticsSuccess/Downgrade",
    shopifyDowngrade: "/AnalyticsSuccess/Shopify/Downgrade",
    changed: "/AnalyticsSuccess/Changed",
    shopifyPaypal: "/AnalyticsSuccess/Shopify/Processing",
    shopifyPayoneer: "/AnalyticsSuccess/PayoneerConsent/shopify",
  },
  myCompetitors: "/my-competitors",
  academy: "/academy",
  tutorials: "/tutorials",
  login: "/login",
  logout: "/logout",
  registration: "/registration",
  registrationB: "/registration-b",
  affiliateCreateAccount: "/affiliate-create-account",
  requestPassword: "/requestpassword",
  resetPassword: "/resetpassword",
  upgrade: "/upgrade-success",
  processing: "/processing",
  alreadyTrial: "/user/already-trial",
  noSubscription: "user/plans",
  notFound: "*",
  ebayLocked: "/subscription/ebay-locked",
  shopifyLocked: "/subscription/shopify-locked",
  shopify: {
    salesSpy: "/shopify/sales-tracker",
    salesSpyItem: "/shopify/sales-tracker/:id",
    marketInsights: "/shopify/market-insights",
    productExplorer: "/shopify/product-explorer",
    storeFinder: "/shopify/store-finder",
    billing: "/shopify/billing",
    plans: "/shopify/billing/plans",
    upgrade: "/shopify/billing/upgrade",
    invoice: {
      root: "/shopify/billing/invoice",
      invoice: "/shopify/billing/invoice/:id",
    },
  },
};
