import { noOfEntries } from "assets/data/filters";
import { requestPayout } from "assets/tableColumns/paymentHistory";
import Modal from "components/Modal";
import Search from "components/Search";
import Select from "components/Select";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useModal from "hooks/useModal";
import useTable from "hooks/useTable";
import { FC } from "react";
import { useGetPaymentDetailsQuery } from "store/api/affiliateManagerApi";

interface Props {
  helpers: ReturnType<useModal>;
  id: string;
}
const tableStyles: TableStyles = {
  body: {
    rowHeight: "h-[49px]",
  },
  head: {
    rowHeight: "h-[49px]",
  },
  style: "max-h-[550px] overflow-y-auto mb-12",
  px: "px-0",
};
const RequestPayout: FC<Props> = ({ helpers, id }) => {
  const { data, isFetching } = useGetPaymentDetailsQuery(id);
  const { table } = useTable({
    columns: requestPayout,
    data: data?.result || [],
    dataDeps: [data?.result],
    pagination: true,
    search: true,
    initialPageSize: 25,
  });

  return (
    <Modal
      helpers={helpers}
      className="flex flex-col items-center"
      cardClassName="pt-[35px] p-9"
      titleClassName="!mb-9"
      maxWidth="max-w-[670px]"
      title="User Request Payout"
      buttonCloseClassName="!top-5.5 right-[21px]"
    >
      <div className="flex items-end justify-between mb-9">
        <Select
          options={noOfEntries}
          title="Select Entries"
          handleChange={(value) => table.setPageSize(Number(value))}
          value={table.getState().pagination.pageSize.toString()}
        />
        <Search
          handleChange={table.setGlobalFilter}
          search={table.getState().globalFilter}
          maxWidth="301px"
          className="dark:bg-deepSpace900"
        />
      </div>

      <Table table={table} styles={tableStyles} isLoading={isFetching} />
      <Pagination labeled table={table} />
    </Modal>
  );
};

export default RequestPayout;
