import useDecodeToken from "hooks/useDecodeToken";
import { FC } from "react";
import { PlanList } from "types/plans";
import { formatCurrency, formatPercent } from "utils/formatCurrency";
import { getPlanFullSuffix, isCurrentPlan, isUpgrade } from "utils/planUtils";

interface Props {
    id: string;
    plan: PlanList;
    selectedPlan?: string;
    isShopify?: boolean;
}
const ComparisonHeader: FC<Props> = ({ id, selectedPlan, plan, isShopify }) => {
    const user = useDecodeToken();
    const isPopular = id === "pro-plus"
    const flexStyles = `flex flex-col w-full w-full pr-2 max-w-[152px] ${isShopify ? "max-md:max-w-[100%] max-md:h-[72px]" : ""} rounded-t-[8px] pl-1.5 pt-1.5
    ${isPopular ? "h-[123px] bg-darkBlue text-white " : "h-[106px] mt-[auto] bg-neutral2 border-2 border-secondary border-b-[0px]"} 
    ${id !== selectedPlan ? "max-md:hidden" : ""}
    `
    const currentPlan = (isShopify ? user.shopify_package_name : user.package_name) || "";
    const planPeriod = getPlanFullSuffix(plan.type);
    const isYearly = planPeriod === "Yearly";
    const isMonthly = planPeriod === "Monthly";
    const isLifetime = planPeriod === "Lifetime";
    const isTrial = /^true$/i.test(user.active_trail);
    const calcSavePercent = Math.round(100 - plan.planDiscountPrice / plan.planPrice * 100);
    const showCurrent = isCurrentPlan(currentPlan || "", plan.type);
    const showUpgrade = isUpgrade(currentPlan || "", plan.type);
    const hideDiscounted = !plan.isDiscount || ((!showUpgrade || showCurrent) && isMonthly && !isTrial);
    const price = hideDiscounted ? plan.planPrice : plan.planDiscountPrice;

    return (
        <div className={flexStyles}>
            {isPopular && <span className={`block ml-auto text-[10px] bg-electricBlue leading-[12px] p-1 -mt-[2.5px] rounded-[4px]`}>Most Popular</span>}
            <div className={`w-full flex flex-col ${isShopify ? "max-md:!flex-row max-md:justify-between max-md:items-center max-md:px-2" : ""}`}>
                <div className={`mb-2`}>
                    <h6 className={`text-[20px] leading-[28px] font-bold ${plan.planName !== "Enterprise" && !isShopify ? "uppercase" : ""}`}>{plan.planName}</h6>
                    <p className={`text-lg font-bold`}>
                        {formatCurrency(price)}<span className="font-normal">/{isLifetime ? "life" : "mo"}</span>
                    </p>
                </div>

                <div>
                    {isYearly && !plan.isDiscount && (<div className="text-xs">
                        <p>{formatCurrency(price * 12)}</p>
                        <p className="font-bold">Billed Yearly</p>
                    </div>)}
                    {!hideDiscounted && !isYearly && (
                        <div>
                            <span className={` font-bold ${isPopular ? "" : "text-[#24921A]"}`}>
                                {formatPercent(calcSavePercent)} off
                            </span>
                            {isShopify && (
                                <span className="block md:hidden text-sm">
                                    for 1st month
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>

        </div>
    )
}

export default ComparisonHeader;