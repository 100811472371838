import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/Button";
import LabeledInput from "components/Input/LabeledInput";
import Modal from "components/Modal";
import { useAppDispatch } from "hooks/useAppDispatch";
import useModal from "hooks/useModal";
import { FC, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { affiliateManagerApi, useGetInvoiceDetailsQuery, useRequestPayoutMutation } from "store/api/affiliateManagerApi";
import { queryTags } from "store/api/queryTags";
import { RequestPayoutRes } from "types/services/affiliateManager";
import { object, string } from "yup";

interface FormValues {
  fullName: string;
  address: string;
  vatNumber: string;
}

let integrationsSchema = object({
  fullName: string().required("Invoice Name is required"),
  address: string().required("Address is required"),
  vatNumber: string(),
});

interface Props {
  helpers: ReturnType<useModal>;
}

const inputClass = "h-9 pl-5 rounded-[7px] max-w-[370px] dark:bg-deepSpace900"
const RequestPayout: FC<Props> = ({ helpers }) => {
  const [requestPayout, { isLoading }] = useRequestPayoutMutation();
  const dispatch = useAppDispatch();
  const { data } = useGetInvoiceDetailsQuery(null);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(integrationsSchema),
  });
  useEffect(() => {
    if (data) {
      setValue('fullName', data?.result?.firstName || '');
      setValue('address', data?.result?.address || '');
      setValue('vatNumber', data?.result?.vatNumber || '');
    }
  }, [data, setValue]);
  const onResponse = (data: RequestPayoutRes) => {
    if (data.message === "ErrorRequest") {
      toast.error("You don't have enough money to request payment");

    } else if (data.message === "PayPalRequired") {
      toast.error("Please fill your paypal email!");
      helpers.close();
    } else {
      toast.success("Payment Requested Successfully");
      reset();
      dispatch(
        affiliateManagerApi.util.invalidateTags([
          queryTags.affiliateManager.earningStats
        ])
      )
      helpers.close();
    }
  };
  const onSubmit: SubmitHandler<FormValues> = (values) => {
    requestPayout(values).unwrap().then(onResponse);
  };

  return (
    <Modal
      helpers={helpers}
      className="flex flex-col items-center"
      cardClassName="pt-[35px] pb-[30px] px-[38px]"
      titleClassName="!mb-6"
      maxWidth="max-w-[590px]"
      title="Invoice Details"
      buttonCloseClassName="!top-5.5 right-[21px]"
    >
      <form
        className="flex flex-col gap-5"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="fullName"
          render={({ field: { onChange, value } }) => (
            <LabeledInput
              value={value}
              handleChange={onChange}
              placeholder="Type the name you wish"
              name="fullName"
              variant="contained"
              labelClassName="text-sm font-medium"
              className={inputClass}
              wrapperClassName=" !gap-2"
              label="Invoice Name"
              message={errors.fullName?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="address"
          render={({ field: { onChange, value } }) => (
            <LabeledInput
              value={value}
              handleChange={onChange}
              placeholder="Type the address you wish"
              name="address"
              variant="contained"
              labelClassName="text-sm font-medium"
              className={inputClass}
              wrapperClassName="!gap-2"
              label="Address"
              message={errors.address?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="vatNumber"
          render={({ field: { onChange, value } }) => (
            <LabeledInput
              value={value}
              handleChange={onChange}
              placeholder="Type the VAT Number"
              name="vatNumber"
              variant="contained"
              labelClassName="text-sm font-medium"
              className={inputClass}
              wrapperClassName=" !gap-2"
              label="Vat Number"
              message={errors.vatNumber?.message}
            />
          )}
        />
        <Button
          title="Request Payout"
          type="submit"
          height="h-9"
          loading={isLoading}
          className="max-w-[205px] mx-auto mt-4 dark:bg-deepSpace900"
        />
      </form>
    </Modal>
  );
};

export default RequestPayout;
