interface Props {
  title: string;
  divider?: boolean;
  isActive: boolean;
  handleClick?: () => void;
  className?: string;
  dividerClassName?: string;
}

const SettingsTab = ({
  title,
  divider,
  isActive,
  handleClick,
  className,
  dividerClassName,
}: Props) => (
  <>
    <div
      className={`flex justify-center items-center rounded-full w-full h-10 text-sm cursor-pointer ${className || ""
        } ${isActive ? `bg-white text-primaryPurple cursor-auto dark:bg-deepSpace900 dark:text-grey100` : "text-secondary dark:text-deepSpace900"
        } `}
      onClick={handleClick}
    >
      {title}
    </div>

    {divider && (
      <div className={`w-[1px] h-4 bg-darkBlue dark:bg-lilac400 ${dividerClassName || ""}`} />
    )}
  </>
);

export default SettingsTab;
