import { ReactComponent as Lock } from "assets/icons/lock.svg";
import Button from "components/Button";
import useDecodeToken from "hooks/useDecodeToken";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";

const ButtonGroup = () => {
  const userDetails = useDecodeToken();
  const { t } = useTranslation(['home']);
  const plan = userDetails.package;
  const navigate = useNavigate();
  const btnClassName =
    "dark:border-lilac400 dark:bg-deepSpace900 bg-white hover:!bg-primaryPurple hover:border-primaryPurple dark:hover:!bg-purple500 dark:hover:border-purple500  max-w-[184px]";
  const ShowLock = plan === "Pro" ? Lock : undefined

  return (
    <div className="flex items-center gap-6 max-lg:gap-2 mb-5 flex-wrap max-xl:justify-center">
      <Button
        title={t("ProductResearch")}
        color="primaryPurple"
        height="h-9"
        className={`${btnClassName}`}
        handleClick={() => navigate(PATH.productResearch.root)}
      />
      <Button
        title={t("CompetitorResearch")}
        color="primaryPurple"
        height="h-9"
        className={`${btnClassName} `}
        handleClick={() => navigate(PATH.competitorResearch.root)}
      />
      <Button
        title="Ali-Growth Scanner"
        color="primaryPurple"
        height="h-9"
        Icon={ShowLock}
        className={`${btnClassName}`}
        handleClick={() => navigate(PATH.zikPro.aliGrowthScanner)}
      />
      <Button
        title={t("Autopilot")}
        color="primaryPurple"
        height="h-9"
        Icon={ShowLock}
        className={`${btnClassName}  `}
        handleClick={() => navigate(PATH.zikPro.autopilot)}
      />
      <Button
        title={t("500BestsellingitemsonEbay")}
        color="primaryPurple"
        height="h-9"
        Icon={ShowLock}
        className={`${btnClassName} max-w-[300px]`}
        handleClick={() => navigate(PATH.zikPro.bestSellingItems)}
      />
    </div>
  );
};

export default ButtonGroup;
