import { helpVideos } from "assets/data/helpVideos";
import Card from "components/Card";
import Header from "components/Header";
import HeaderHelpVideo from "components/Header/HelpVideo";
import HeaderTab from "components/Header/Tab";
import SettingsMenu from "components/SettingsTabs/Index";
import SettingsTab from "components/SettingsTabs/SettingsTab";
import useTabs from "hooks/useTabs";
import { useTranslation } from "react-i18next";


import Account from "pages/QuickSettings/components/Account";
import SupplierSettings from "./components/SupplierSettings";


enum TABS {
    account = "account",
    supplierSettings = "supplier-settings",
}

const tabContent = {
    [TABS.account]: {
        content: <Account />,
    },
    [TABS.supplierSettings]: {
        content: <SupplierSettings />,
    },
};

const Index = () => {
    const { activeTab, onTabChange } = useTabs<TABS | null>(null);
    const { t } = useTranslation(['home']);
    const tabStyles = "h-[46px] "

    return (
        <>
            <Header
                leftContent={
                    <>
                        <HeaderTab
                            title={t("AdvancedSettings")}
                            isActive={true}
                        />
                    </>
                }
                rightContent={
                    <HeaderHelpVideo videos={helpVideos.settings} />
                }
            />

            <Card className="px-[44px] py-[24px] dark:text-grey100">
                <SettingsMenu
                    tabs={
                        <>
                            <SettingsTab
                                title={t("MyAccount")}
                                isActive={activeTab === TABS.account}
                                handleClick={() => onTabChange(TABS.account)}
                                divider
                                dividerClassName="max-[662px]:hidden"
                                className={`${tabStyles} max-w-[124px]`}
                            />
                            <SettingsTab
                                title={t("SourceWebsite")}
                                isActive={activeTab === TABS.supplierSettings}
                                handleClick={() => onTabChange(TABS.supplierSettings)}
                                className={`${tabStyles} max-w-[145px]`}
                            />
                        </>
                    }
                    className="max-w-[270px] h-[50px] p-[2px] mx-auto mb-[32px] max-[1300px]:justify-center"
                />

                {activeTab ? tabContent[activeTab].content : ""}
            </Card>
        </>
    );
};

export default Index;
