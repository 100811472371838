import {
  marketplacesDropdown,
  saleDateRangeArr,
  shippingLocations,
} from "assets/data/filters";
import Card from "components/Card";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import FiltersCard from "components/Filters/FiltersCard";
import Select from "components/Select";

import Header from "components/Header";
import HeaderTab from "components/Header/Tab";
import Input from "components/Input";
import Search from "components/Search";
import { PATH } from "routes/path";

import { helpVideos } from "assets/data/helpVideos";
import { ReactComponent as Location } from "assets/icons/location.svg";
import { ReactComponent as Pen } from "assets/icons/pen.svg";
import { ReactComponent as Store } from "assets/icons/store.svg";
import { ReactComponent as Time } from "assets/icons/time.svg";
import { keywordsWithEbay } from "assets/tableColumns/titleBuilder";
import HeaderHelpVideo from "components/Header/HelpVideo";
import useFilters from "hooks/useFilters";
import { FC, ReactNode } from "react";
import { IFilters } from "types/titleBuilder";

interface Props {
  children: ReactNode;
  filtersCard?: ReactNode;
  onTitleCopy?: any;
  setTableColumns?: any;
  selectedMarketplaces?: any;
  setSelectedMarketplaces?: any;
}

const initialFilterState: IFilters = {
  search: "",
  marketplace: [],
  location: shippingLocations[0].value,
  saleDateRange: saleDateRangeArr[0].value,
  excludePhase: "",
};

const TitleBuilderLayout: FC<Props> = ({
  children,
  filtersCard,
  onTitleCopy,
  setTableColumns,
  selectedMarketplaces,
  setSelectedMarketplaces,
}) => {
  const { filters, handleFilterChange } = useFilters(initialFilterState);

  const onFilterCheckboxChange = (newVal: any) => {
    const arr = [...filters.marketplace];
    arr.includes(newVal)
      ? arr.splice(arr.indexOf(newVal), 1)
      : arr.push(newVal);
    handleFilterChange(arr, "marketplace");
    setSelectedMarketplaces && setSelectedMarketplaces(arr);
    setTableColumns && setTableColumns(keywordsWithEbay(arr, onTitleCopy));
  };

  return (
    <>
      <Header
        leftContent={
          <HeaderTab
            title="Title Builder"
            className="min-w-[9rem]"
            isActive={true}
          />
        }
        middleContent={
          <Search
            search={filters.search}
            handleChange={(newValue: string) =>
              handleFilterChange(newValue, "search")
            }
            searchButton
            redirect={{
              pathname: PATH.titleBuilder.results,
              search: `search=${filters.search}`,
            }}
            htmlFor="search"
          />
        }
        rightContent={<HeaderHelpVideo videos={helpVideos.titleBuilder} />}
        leftContentClassName="w-fit"
        middleContentClassName="max-w-[38.1875rem] w-full"
      />
      <Card className="py-7 px-6 mb-5" rounded="rounded-[20px]">
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-flow-row gap-x-[109px] max-lg:gap-x-6 gap-y-8">
          <FiltersCard
            Icon={Store}
            title="Marketplace"
            className="pb-8"
            infoTitle="info"
          >
            <Select
              btnClassName="w-full"
              value={filters.location}
              title={
                selectedMarketplaces?.length === 0
                  ? "Select Marketplace"
                  : selectedMarketplaces?.join(", ")
              }
              handleChange={(newValue) =>
                handleFilterChange(newValue, "location")
              }
              CustomOptions={
                <div className="w-full">
                  <FilterCheckbox
                    options={marketplacesDropdown}
                    multiselect
                    className="mb-4"
                    handleChange={onFilterCheckboxChange}
                    value={filters.marketplace}
                  />
                </div>
              }
            />
          </FiltersCard>

          <FiltersCard
            Icon={Location}
            title="Shipping Location"
            className="pb-8"
            infoTitle="info"
          >
            <Select
              options={shippingLocations}
              btnClassName="w-full"
              value={filters.location}
              handleChange={(newVal) => handleFilterChange(newVal, "location")}
            />
          </FiltersCard>
          <FiltersCard
            Icon={Time}
            title="Sale date range"
            className="pb-8"
            infoTitle="info"
          >
            <Select
              options={saleDateRangeArr}
              btnClassName="w-full"
              value={filters.saleDateRange}
              handleChange={(newValue) =>
                handleFilterChange(newValue, "saleDateRange")
              }
            />
          </FiltersCard>
          <FiltersCard
            Icon={Pen}
            title="Exclude Phase"
            className="pb-8"
            infoTitle="info"
          >
            <Input
              value={filters.excludePhase}
              erase
              handleChange={(newVal) =>
                handleFilterChange(newVal, "excludePhase")
              }
              placeholder="e.g iphone 4 or s4 s5 s6"
              inputClassName="mt-6"
            />
          </FiltersCard>
        </div>
      </Card>

      {filtersCard}

      {children}
    </>
  );
};

export default TitleBuilderLayout;
