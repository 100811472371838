import Tooltip from "components/Tooltip";
import { FC, useId } from "react";

interface Props {
  title: string;
  titleClassName?: string;
  length: number;
  handleClick?: () => void;
}

const CutLongTitle: FC<Props> = ({
  title,
  length,
  titleClassName,
  handleClick,
}) => {
  const isLongTitle = title?.length - 3 >= length;
  const id = useId();

  return (
    <span className="block" onClick={handleClick}>
      <span data-tooltip-id={id} className={`${titleClassName || ""} block`}>
        {isLongTitle ? title.substring(0, length) + "..." : title}
      </span>
      {isLongTitle ? <Tooltip title={title} anchorId={id} /> : ""}
    </span>
  );
};

export default CutLongTitle;
