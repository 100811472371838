import Button from "components/Button";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import FiltersCard from "components/Filters/FiltersCard";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import Input from "components/Input";
import Select from "components/Select";
import useFilters from "hooks/useFilters";

// Icons
import {
  conditionList,
  listingsList,
  marketPlaces,
  saleDateRangeArr,
  shippingLocations
} from "assets/data/filters";
import { ReactComponent as Listing } from "assets/icons/listing.svg";
import { ReactComponent as Phrase } from "assets/icons/pen.svg";
import { ReactComponent as Price } from "assets/icons/price.svg";
import { ReactComponent as ShippingLocation } from "assets/icons/shipping-location.svg";
import { ReactComponent as Star } from "assets/icons/star.svg";
import { ReactComponent as Store } from "assets/icons/store.svg";
import { ReactComponent as Time } from "assets/icons/time.svg";
import Toaster from "components/Toast";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  useGetSettingsQuery,
  useUpdateSearchPrefMutation
} from "store/api/quickSettingsApi";
import { getCountryFlag } from "utils/getCountryFlag";

interface MinMax {
  min: number | null;
  max: number | null;
}

const initialState = {
  shippingLocation: "US",
  marketplace: "US",
  salesDateRange: "30",
  excludePhrase: "",
  listingType: "all",
  condition: "New",
  price: {
    min: null,
    max: null,
  } as MinMax,
  feedback: {
    min: null,
    max: null,
  } as MinMax,
};

const SearchPreferences = () => {
  const { data } = useGetSettingsQuery();
  const { filters, handleFilterChange, setFilters } = useFilters(initialState);
  const [save, { isLoading, isSuccess }] = useUpdateSearchPrefMutation();
  const { t } = useTranslation(['home']);
  useEffect(() => {
    if (isSuccess) {
      toast.success("Preferences successfully added and saved.");
    }
  }, [isSuccess]);
  useEffect(() => {
    if (data) {
      setFilters({
        marketplace: String(data?.result.productLocation || "ALL"),
        condition: data?.result.condition,
        excludePhrase: data?.result.excludePhrase,
        shippingLocation: data?.result.shippingLocation,
        listingType: data?.result.listingType,
        salesDateRange: data?.result.dateRange,
        price: {
          min: data?.result.productMinPrice,
          max: data?.result.productMaxPrice,
        },
        feedback: {
          min: data?.result.minFeedback,
          max: data?.result.maxFeedback,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSave = () => {
    const {
      shippingLocation,
      listingType,
      condition,
      price,
      feedback,
      excludePhrase,
      marketplace,
    } = filters;
    if (price.min !== null && price.max !== null && price.min > price.max) {
      toast.error("Min Price cannot be greater than Max Price");

      return;
    }
    if (feedback.min !== null && feedback.max !== null && feedback.min > feedback.max) {
      toast.error("Min feedback cannot be greater than Max feedback");

      return;
    }
    save({
      shippinglocation: shippingLocation,
      dateRange: filters.salesDateRange,
      listingType,
      condition,
      minPrice: price.min,
      maxPrice: price.max,
      minFeedback: feedback.min,
      maxFeedback: feedback.max,
      excludephrase: excludePhrase,
      eBaySite: marketplace,
      userId: 0,
    });
  };


  return (
    <>
      <div className="grid grid-cols-5 gap-8 gap-y-3.5 max-xl:grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1">
        <FiltersCard
          Icon={Store}
          title={t('Marketplace')}
          iconClassName="w-3.5 h-3"
          infoTitle="Choose your preferred eBay source site (Ex. ebay.it, ebay.com, ebay.ca )"
        >
          <Select
            className="!min-w-[100%]"
            options={marketPlaces}
            btnClassName="w-full !min-w-[100%]"
            value={filters.marketplace}
            img={getCountryFlag(filters.marketplace)}
            handleChange={(newValue) => {
              handleFilterChange(newValue, "marketplace");
            }}
          />
        </FiltersCard>

        <FiltersCard
          Icon={ShippingLocation}
          iconClassName="w-[18px] h-[11px]"
          title={t('ShippingLocation')}
          infoTitle="Only show items that are shipped from the location you select. 
          It can be useful to set this the same as your marketplace location. E.g. United Kingdom if selling on eBay.co.uk"
        >
          <Select
            className="!min-w-[100%]"
            options={shippingLocations}
            btnClassName="w-full !min-w-[100%]"
            value={filters.shippingLocation}
            handleChange={(newValue) => {
              handleFilterChange(newValue, "shippingLocation");
            }}
          />
        </FiltersCard>

        <FiltersCard
          Icon={Time}
          iconClassName="w-[12px] h-3"
          title={t('SaleDateRange')}
          infoTitle="Filter items by sales range. 30 days, 21, 14 & 7 days period."
        >
          <Select
            className="!min-w-[100%]"
            options={saleDateRangeArr}
            btnClassName="w-full !min-w-[100%]"
            value={filters.salesDateRange}
            handleChange={(newValue) => {
              handleFilterChange(newValue, "salesDateRange");
            }}
          />
        </FiltersCard>

        <FiltersCard
          Icon={Price}
          title={t('Price')}
          titleWrapperClassName="!mb-3"
          iconClassName="w-[14px] h-4"
          infoTitle="Filter items by price. Ex from $5 to $20"
        >
          <MinMaxFilter
            name="price"
            customInputWrapperStyles="px-0"
            value={filters.price}
            inputWidth="max-w-[64px]"
            handleChange={(newValue, type) =>
              handleFilterChange(newValue, "price", type)
            }
          />
        </FiltersCard>

        <FiltersCard
          Icon={Star}
          title={t('Feedback')}
          titleWrapperClassName="!mb-3"
          iconClassName="w-[15px]"
          infoTitle="The total amount of feedback that the product/seller has"
        >
          <MinMaxFilter
            name="feedback"
            customInputWrapperStyles="px-0"
            value={filters.feedback}
            inputWidth="max-w-[64px]"
            handleChange={(newValue, type) =>
              handleFilterChange(newValue, "feedback", type)
            }
          />
        </FiltersCard>

        <FiltersCard
          Icon={Phrase}
          title={t('ExcludePhrase')}
          infoTitle="Filter out specific keywords. For example, if you're searching for smartphone but you don't want to see Apple products, write Apple. To exclude more phrases, separate each with a comma (,)"
          titleWrapperClassName="!mb-8"
          iconClassName="w-[13px]"
        >
          <Input
            value={filters.excludePhrase}
            handleChange={(newVal) =>
              handleFilterChange(newVal, "excludePhrase")
            }
            erase
            placeholder="e.g iphone 4 or s4, s5, s6"
          />
        </FiltersCard>

        <FiltersCard
          Icon={Listing}
          title={t('Listings')}
          className="h-full"
          iconClassName="w-[17px] h-[11px]"
          infoTitle="Choose the type of the listing you wish to search for (All Listings, Fixed Price, or Auction)"
        >
          <FilterCheckbox
            options={listingsList}
            handleChange={(value) => handleFilterChange(value, "listingType")}
            value={filters.listingType}
          />
        </FiltersCard>

        <FiltersCard
          Icon={Star}
          title={t('Condition')}
          iconClassName="w-[15px] h-[14px]"
          infoTitle="Filter search based on whether the item is brand new or used "
        >
          <FilterCheckbox
            options={conditionList}
            handleChange={(value) => handleFilterChange(value, "condition")}
            value={filters.condition}
          />
        </FiltersCard>
      </div>
      <div className="w-full mt-2.5 flex justify-center">
        <Button
          title="Save"
          height="h-11"
          loading={isLoading}
          className="max-w-[230px] dark:bg-deepSpace900"
          handleClick={onSave}

        />
      </div>
      <Toaster />
    </>
  );
};

export default SearchPreferences;
