import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "config/config";
import {
  LoginRequest,
  LoginResponse,
  RequestPasswordRes,
  ResetPasswordReq,
} from "types/services/auth";
import { queryKeys } from "./queryKeys";

export const authApi = createApi({
  reducerPath: "auth",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: queryKeys.auth.login,
        method: "POST",
        body: credentials,
        credentials: "include",
      }),
    }),
    requestPassword: builder.query<RequestPasswordRes, string>({
      query: (param) => ({
        url: queryKeys.auth.requestPassword + `?email=${param}`,
        method: "POST",
      }),
    }),
    resetPassword: builder.query<RequestPasswordRes, ResetPasswordReq>({
      query: (body) => ({
        url: queryKeys.auth.resetPassword,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyRequestPasswordQuery,
  useLazyResetPasswordQuery,
} = authApi;
