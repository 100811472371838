import Button from "components/Button";
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from "hooks/useDecodeToken";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { onSelectPlan } from "store/slices/planSlice";
import { makeQueryParams } from "utils/makeQueryParams";
import { getPlanDetails } from "utils/planUtils";


const TableLimitReached = () => {
    const navigate = useNavigate();
    const userDetails = useDecodeToken();
    const dispatch = useAppDispatch();
    const upgradePlan = userDetails.package_name === "StandardM" ? "EnterpriseM" : userDetails.package_name === "StandardY" ? "EnterpriseY" : "EnterpriseLT"
    const onUpgrade = () => {
        const plan = getPlanDetails(upgradePlan);
        dispatch(onSelectPlan({
            type: upgradePlan,
            planName: plan?.planName || "",
            planPrice: plan?.planPrice || 0,
            skip: false
        }))
        navigate(makeQueryParams(PATH.managementPanel.subscriptionUpgrade, { type: upgradePlan, from: "ZikPro" }))
    }

    return (
        <div className="flex flex-col items-center text-center w-full bg-white dark:bg-deepSpace700 dark:text-grey100  absolute top-[88px] z-10">
            <h6 className="mb-2 text-xl font-bold">You’ve hit your Scan Limit!</h6>
            <p className="mb-6 text-xl">You’ve used all 10 scans for this month. Upgrade now for unlimited scans!</p>
            <Button title="Upgrade Now" handleClick={onUpgrade} color="smoothGreen" titleClassName="!text-lg !font-bold" height="h-16"
                className="max-w-[376px]  dark:bg-deepSpace900 shadow-[0px_0px_42.173px_0px_#BAF2EC,_0px_0px_24.601px_0px_#BAF2EC,_0px_0px_12.3px_0px_#BAF2EC,_0px_0px_3.514px_0px_#BAF2EC,_0px_0px_1.757px_0px_#BAF2EC]"
            />
        </div>
    )
}

export default TableLimitReached