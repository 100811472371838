import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { DecodedUser } from "hooks/useDecodeToken";
import jwt from "jwt-decode";
import DashboardLayout from "layouts/dashboard";
import Academy from "pages/AcademyAndTutorials/Academy/Index";
import Tutorials from "pages/AcademyAndTutorials/Tutorials/Index";
import AlreadyTrial from "pages/AlreadyTrial";
import { AutoDs } from "pages/Autods";
import Processing from "pages/Loading/processing";
import ResetCC from "pages/Loading/ResetCC";
import Logout from "pages/logout";
import Maintenance from "pages/Maintenance";
import PurchaseFailed from "pages/ManagementPanel/components/ManagePayments/PurchaseFailed";
import SubscriptionPurchasePage from "pages/ManagementPanel/components/ManagePayments/SubscriptionPurchasePage";
import SubscriptionUpgradePage from "pages/ManagementPanel/components/ManagePayments/SubscriptionUpgradePage";
import NotFound from "pages/NotFound";
import Upgrade from "pages/Payment/upgrade";
import ShopifyUpgrade from "pages/Shopify/Billing/Upgrade";
import TitleBuilder from "pages/TitleBuilder";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useRoutes } from "react-router-dom";
import { PATH } from "routes/path";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { DASHBOARD_MARKET_PLACES, setDashboardMarketplace } from "store/slices/marketplaceSlice";
import isPaymentPath from "utils/isPaymentPath";
import { GetAccessToken, GetMarketplace, SetMarketplace } from "utils/localStorage";
import { affiliateRoutes } from "./affiliateRoutes";
import { authRoutes } from "./authRoutes";
import { ebayRoutes } from "./ebayRoutes";
import { noSubscriptionRoutes } from "./noSubscription";
import { regFunnelRoutes } from "./regFunnelRoutes";
import registrationCompleteRoutes from "./registrationComplete";
import { shopifyRoutes } from "./shopifyRoutes";



const Router = () => {
  const { accessToken } = useAppSelector((state) => state.user.result);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { dashboardMarketplace } = useAppSelector((state) => state.marketplaceSlice);
  const getMarketplace = (GetMarketplace() || "ebay") as DASHBOARD_MARKET_PLACES
  const [userDetails, setUserDetails] = useState<DecodedUser | null>(null)
  const isLoggedIn = accessToken || GetAccessToken();
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const isAffiliate = environment === "affiliate" ? true : false;
  const isTest = environment === "dev" || environment === "beta" || environment === "pre-prod"
    ? true : false;

  const showRegFunnel = userDetails?.journey_completed.toLowerCase() === "false" && !isPaymentPath() && !isAffiliate
  const isNoSubPage = (userDetails?.package_name === "" && userDetails?.shopify_package_name === "") && userDetails?.journey_completed.toLowerCase() === "true"

  const onMarketplaceChange = (marketplace: string) => {
    dispatch(setDashboardMarketplace(marketplace))
  }

  useEffect(() => {
    if (GetMarketplace() === "") {
      SetMarketplace(userDetails?.default_page || "");
      if (userDetails?.default_page) {
        onMarketplaceChange(userDetails?.default_page)
      }
    }
    if (GetMarketplace() && GetMarketplace() !== "" && dashboardMarketplace !== GetMarketplace()) {
      onMarketplaceChange(getMarketplace)
    }
  }, [])

  useEffect(() => {
    const isPurchaseCompletePage = location.pathname.includes("/AnalyticsSuccess")
    if (userDetails) {
      if (userDetails.shopify_package_name !== "" && dashboardMarketplace !== "shopify" && userDetails.package_name === "") {
        onMarketplaceChange(DASHBOARD_MARKET_PLACES.shopify);
        SetMarketplace("shopify");
        if (!isPurchaseCompletePage) {
          navigate(PATH.shopify.marketInsights);
        }
      }
      if (userDetails.package_name !== "" && dashboardMarketplace !== "ebay" && dashboardMarketplace !== "aliexpress" && userDetails.shopify_package_name === "") {
        onMarketplaceChange(DASHBOARD_MARKET_PLACES.ebay);
        SetMarketplace("ebay");
        if (!isPurchaseCompletePage) {
          navigate(PATH.dashboard.root);
        }
      }
    }
  }, [userDetails])

  const marketInsightsPath = dashboardMarketplace === "shopify" ? PATH.shopify.marketInsights : PATH.dashboard.root;


  const { pathname, search } = useLocation()
  useGetSettingsQuery(undefined, { skip: !isLoggedIn });

  useEffect(() => {
    if (isLoggedIn) {
      setUserDetails(jwt(isLoggedIn))
    }
  }, [isLoggedIn])


  const protectedRoutes = [
    {
      path: PATH.root,
      element: <Navigate to={marketInsightsPath} />,
    },
    {
      path: PATH.maintenance,
      element: <Maintenance />
    },
    {
      path: PATH.logout,
      element: <Logout />,
    },
    {
      path: PATH.index.failure,
      element: <PurchaseFailed />
    },
    {
      path: PATH.login,
      element: <Navigate to={marketInsightsPath} />,
    },
    {
      path: PATH.registration,
      element: <Navigate to={marketInsightsPath} />,
    },
    {
      path: PATH.auth.autods,
      element: <AutoDs />,
    },
    {
      path: PATH.upgrade,
      element: <Upgrade isUpgrade={false} />,
    },
    {
      path: PATH.processing,
      element: <Processing />,
    },
    {
      path: PATH.alreadyTrial,
      element: <AlreadyTrial />,
    },
    {
      path: PATH.root,
      element: <DashboardLayout />,
      children: [
        ...(dashboardMarketplace === "shopify" ? shopifyRoutes : ebayRoutes),
        ...(showRegFunnel ? regFunnelRoutes : []),
        {
          path: PATH.managementPanel.subscriptionPurchase,
          element: <SubscriptionPurchasePage />,
        },
        {
          path: PATH.managementPanel.subscriptionUpgrade,
          element: <SubscriptionUpgradePage />,
        },
        {
          path: PATH.titleBuilder.root,
          element: <TitleBuilder />,
        },
        {
          path: PATH.academy,
          element: <Academy />,
        },
        {
          path: PATH.tutorials,
          element: <Tutorials />,
        },
        {
          path: PATH.shopify.upgrade,
          element: <ShopifyUpgrade />
        },
      ],
    },
    isTest ? {
      path: PATH.resetcc,
      element: <ResetCC />
    } : {},
    {
      path: "*",
      element: userDetails ? <NotFound /> : undefined
    },
    ...registrationCompleteRoutes
  ];

  const loggedInRoutes = isAffiliate ? affiliateRoutes : isNoSubPage ? noSubscriptionRoutes : protectedRoutes

  return useRoutes(isLoggedIn ? loggedInRoutes : authRoutes(isTest, pathname + search));
};

export default Router;


