import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { LoginResponse } from "types/services/auth";
import { SetAccessToken, SetUserDetails } from "utils/localStorage";
import { authApi } from "../api/authApi";

export const initialUserDetailsState = {
  result: {
    accessToken: null,
    refreshToken: null,
    user: {
      userId: null,
      firstName: null,
      lastName: null,
      email: null,
      countryId: null,
      roleName: null,
    },
  },
} as LoginResponse;

const userSlice = createSlice({
  name: "user",
  initialState: initialUserDetailsState,
  reducers: {
    logoutUser: (state) => {
      state.result.accessToken = initialUserDetailsState.result.accessToken;
      state.result.refreshToken = initialUserDetailsState.result.refreshToken;
      state.result.user = initialUserDetailsState.result.user;
    },
    setUserAccessToken: (state, { payload }) => {
      state.result.accessToken = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload: { result } }) => {
        state.result.accessToken = result.accessToken;
        state.result.refreshToken = result.refreshToken;
        state.result.user = result.user;
        result.accessToken && SetAccessToken(result.accessToken);
        result.user && SetUserDetails(result.user);
      }
    );
  },
});

export default userSlice.reducer;

export const { logoutUser, setUserAccessToken } = userSlice.actions;
export const useCurrentUser = (state: RootState) => state;
