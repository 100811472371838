import SlidedPlans from "components/Plans/SlidedPlans";
import ShopifyPlansCont from "pages/Shopify/Billing/components/plans/PlansContainer";
import { FC } from "react";
import { PlansType, TSelectedPlan } from "types/plans";
import Trusted from "./Trusted";

interface Props {
    plans: PlansType;
    isEbay?: boolean;
    handleSelectPlan: (newVal: TSelectedPlan) => void;
}

const PlansContentA: FC<Props> = ({ plans, isEbay, handleSelectPlan }) => {

    return (
        <div className="w-full mx-auto flex flex-col justify-center items-center">
            {isEbay ? (
                <SlidedPlans
                    plansWrapperClassName="w-full max-w-[748px]"
                    plans={plans}
                    handleSelectPlan={handleSelectPlan}
                    fromRegistration={true}
                    showTwoPlans={true}
                />
            ) :
                <ShopifyPlansCont handleSelectPlan={handleSelectPlan} isRegFunnel isResponsive wrapperStyles="mt-4" borderColor="border-neutral2" />
            }
            <Trusted />
        </div>
    )
}

export default PlansContentA