import Button from "components/Button";
import useDecodeToken from "hooks/useDecodeToken";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { PlanList, TSelectedPlan } from "types/plans";
import { formatCurrency } from "utils/formatCurrency";
import { isDowngrade, isUpgrade } from "utils/planUtils";

interface Props {
    plan: PlanList;
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    fromRegistration?: boolean;
    selectedPlan?: "pro" | "pro-plus" | "enterprise";
    isPayNow?: boolean;
    isShopify?: boolean;
    showTwoPlans?: boolean;
}
interface CurrentPlanProps {
    isTrial?: boolean;
}


const CurrentPlan: FC<CurrentPlanProps> = ({ isTrial }) => {
    return (
        <div className={`${isTrial ? "px-2" : " px-6"} 
        relative -top-[40px] max-w-[152px] h-fit max-md:max-w-full border border-electricBlue text-electricBlue w-full bg-secondary text-center pt-12 pb-2 rounded-b-[8px] text-sm`}>
            {isTrial ? "Current Trial Plan" : "This is your Current Plan"}
        </div>
    )

}

const PlanFooterItem: FC<Props> = ({ plan, fromRegistration, handleSelectPlan, isPayNow, isShopify }) => {
    const u = useDecodeToken();
    const activeTrial = isShopify ? u.shopify_active_trail : u.active_trail;
    const currentPlan = isShopify ? u.shopify_package_name : u.package_name;
    const isTrial = /^true$/i.test(activeTrial);
    const navigate = useNavigate();
    const showCurrent = plan.type === currentPlan && !isPayNow;
    const showUpgrade = isUpgrade(currentPlan || "", plan.type);
    const showDowngrade = isDowngrade(currentPlan || "", plan.type) && !isPayNow;
    const onPlanSelect = (skip: boolean) => {
        if (handleSelectPlan) {
            handleSelectPlan({
                type: plan.type,
                planName: plan.planName,
                planPrice: plan.planPrice,
                skip
            });
        } else {
            if (isShopify && (isTrial || showUpgrade)) {
                navigate(PATH.shopify.upgrade);
            }
        }
    }
    return showDowngrade ? <div className="h-10 max-w-[152px] max-md:max-w-full w-full"></div> :
        showCurrent ? <CurrentPlan isTrial={isTrial} /> : (
            <div className="px-2 w-full max-w-[152px] max-md:max-w-full py-5 flex flex-col gap-2">
                <h6 className="text-[20px] font-bold leading-[28px] text-center dark:text-grey100">
                    {plan.planName}
                </h6>
                {showUpgrade || isPayNow || fromRegistration || isTrial ? (
                    <div>
                        <Button
                            className="!bg-white hover:!bg-smoothGreen text-darkBlue font-bold flex w-full mx-auto"
                            height="h-11"
                            color="smoothGreen"
                            titleClassName="!font-medium group-hover:text-white !text-base dark:text-darkBlue"
                            title={
                                fromRegistration
                                    ? "Start $1 Trial"
                                    : isTrial
                                        ? "Change" : isPayNow ? "Pay Now" : "Upgrade!"

                            }
                            handleClick={() => onPlanSelect(false)}
                        />
                        {fromRegistration &&
                            <button
                                className={`text-xs w-full text-center cursor-pointer hover:text-primaryPurple dark:hover:text-purple500 underline mt-2 leading-[16px] text-electricBlue`}
                                onClick={() => onPlanSelect(true)}
                            >
                                Skip Trial {plan.skipAmout ? (<span>& Save {formatCurrency(plan.skipAmout || 0)}</span>) : "Now!"}
                            </button>
                        }
                    </div>
                ) : null}
            </div>

        )
}

export default PlanFooterItem;