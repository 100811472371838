import { ReactComponent as Check } from "assets/icons/checkmark.svg";
import { ReactComponent as Close } from "assets/icons/clear-input.svg";
import useDecodeToken from "hooks/useDecodeToken";
import { FC } from "react";
import { PlanComparisonItemType, PLAN_TYPE } from "types/plans";
import { getPlanFullSuffix } from "utils/planUtils";


interface Props {
  item: PlanComparisonItemType;
  isLast: boolean;
  selectedPlan?: string;
  showTwoPlans?: boolean;
  isFirst?: boolean;
  period?: string;
  isShopify?: boolean;
}

interface IncludeInPlan {
  include?: boolean | string;
  wrapperClassName?: string;
  selectedPlan?: string;
  planType?: PLAN_TYPE | "ShopProM" | "ShopProY";
  period?: string;
  type: string;
  isLast?: boolean;
  roundBR?: boolean;
  isShopify?: boolean;
}

const sameIconStyles = "fill-primaryPurple dark:fill-purple50"
const IncludeInPlan: FC<IncludeInPlan> = ({ include, selectedPlan, wrapperClassName, type, isLast, roundBR, planType, period, isShopify }) => {
  const user = useDecodeToken();
  const currentPackage = isShopify ? user.shopify_package_name : user.package || "";
  const planPeriod = getPlanFullSuffix(user.package_name || "");
  const isTrial = /^true$/i.test(isShopify ? user.shopify_active_trail : user.active_trail);
  const isCurrentTrial = isTrial && planType === currentPackage && (isShopify ? true : period?.toLowerCase() === planPeriod.toLowerCase());
  const currentRightPlan = isShopify ? planType === "ShopProY" : user.package === "Enterprise";

  return (<div className={`${isCurrentTrial ? "bg-[#F5F9FF]" : "bg-white"}  flex justify-center items-center max-w-[152px] w-full border-secondary 
    ${selectedPlan === type ? "" : "max-md:hidden"}
    ${wrapperClassName} ${isLast ? "max-md:rounded-br-[16px]" : ""} 
    ${roundBR && !currentRightPlan ? "rounded-br-[16px]" : ""}`}
  >
    {typeof include === "string" ? <p className={`text-center block max-w-[108px] mx-auto 
  ${include.toLowerCase().includes("unlimited") ? "text-primaryPurple text-sm font-medium" : "font-normal text-xs"}`}>{include}</p> : (
      <div className={` w-6 h-6 rounded-full flex justify-center items-center`}>
        {include ? (
          <Check className="fill-darkBlue w-3 h-[9px]" />
        ) : (
          <Close className="fill-grey200 w-3 h-3" />
        )}
      </div>
    )}
  </div>)
}
const PlansComparisonItem: FC<Props> = ({ item, selectedPlan, isLast, showTwoPlans, isFirst, isShopify, period }) => {
  const sameProps = {
    isLast,
    selectedPlan,
    period,
    isShopify
  }

  return (
    <div className="flex">
      <div className={`bg-white max-w-[400px] w-full ${isFirst ? `rounded-tl-[16px] ${isShopify ? "max-md:rounded-tl-[0px]" : ""}` : ""} ${isLast ? "rounded-bl-[16px]" : ""}`}>
        <div className="flex">
          <div className=" flex justify-center items-center min-w-[50px] h-full my-[auto] max-md:hidden">
            <item.Icon className={`${sameIconStyles} ${item.iconClassName}`} />
          </div>
          <div className={`border-l-secondary md:border-l border-secondary w-full px-4 py-2 ${isLast ? "" : "border-b"}`}>
            <div className="w-full flex items-center justify-between">
              <h6 className="font-bold text-darkBlue">
                {item.title}
              </h6>
              {item.isNew && (
                <p className="text-xs font-medium text-white bg-[#C93A1B] rounded-[4px] w-[46px] text-center leading-[20px] max-md:hidden">
                  New!
                </p>
              )}
            </div>
            <p className="text-sm">
              {item.description}
            </p>
          </div>
        </div>

      </div>
      {isShopify ? (<>
        <IncludeInPlan include={item.monthly} wrapperClassName="border-l md:border-r-[0.5px]" type="monthly" planType="ShopProM" {...sameProps} />
        <IncludeInPlan include={item.yearly} wrapperClassName={`border-l-[0.5px]`} roundBR={isLast} type="yearly" planType="ShopProY" {...sameProps} />
      </>) : (
        <>
          <IncludeInPlan include={item.pro} wrapperClassName="border-l md:border-r-[0.5px]" type="pro" planType={PLAN_TYPE.PRO} {...sameProps} />
          <IncludeInPlan include={item.proPlus} wrapperClassName={`border-l-[0.5px] ${showTwoPlans ? "" : "md:border-r-[0.5px]"}`}
            type="pro-plus" planType={PLAN_TYPE.PRO_PLUS} {...sameProps} roundBR={isLast && showTwoPlans} />
          {!showTwoPlans ? (
            <IncludeInPlan include={item.enterprise} wrapperClassName={`border-l-[0.5px]`}
              type="enterprise" planType={PLAN_TYPE.ENTERPRISE} {...sameProps} roundBR={isLast}

            />
          ) : ""}
        </>
      )}

    </div>
  )
};

export default PlansComparisonItem;
