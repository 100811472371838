import { ReactComponent as Diamond } from "assets/icons/diamond.svg";
import { ReactComponent as LockHovered } from "assets/icons/lock-hovered.svg";
import { ReactComponent as Lock } from "assets/icons/lock.svg";
import InfoTooltip from "components/Card/InfoTooltip";
import useDecodeToken from "hooks/useDecodeToken";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { PLAN_TYPE } from "types/plans";
import MainDashboardCard from "./MainDashboardCard";
interface ItemProps {
  path: string;
  title: string;
  limited?: boolean
}

const ZikProToolsItem: FC<ItemProps> = ({ path, title, limited }) => {
  const { t } = useTranslation(['home']);
  const userDetails = useDecodeToken();
  const plan = userDetails.package;
  const isLocked = plan === PLAN_TYPE.PRO;
  const isProPlus = plan === PLAN_TYPE.PRO_PLUS
  return (
    <Link to={path} target="_blank">
      <div
        className="flex cursor-pointer gap-[8.75px] items-center group"
      >
        {isLocked ? (
          <>
            <LockHovered className="group-hover:block hidden" />
            <Lock className="fill-smoothGreen group-hover:hidden block dark:fill-paradiseBlue" />
          </>
        ) : (
          <Diamond
            className="group-hover:fill-primaryPurple dark:group-hover:fill-purple500 fill-darkBlue dark:fill-grey100"
          />
        )}
        <h6
          className="my-[3px] text-base group-hover:text-primaryPurple dark:group-hover:text-purple500 text-darkBlue dark:text-grey100"
        >
          {t(title)}
        </h6>
        {limited && isProPlus ? (
          <>
            <InfoTooltip title="Limited to 10 scans a month. Upgrade later for Unlimited!" iconClassName="w-3 h-3" maxWidth="max-w-[100%]" />
            <p className="ml-[auto] text-primaryPurple dark:text-purple500 text-xs">10 scans/month</p>
          </>
        ) : ""}
      </div>
    </Link>
  );
};

const ZikProTools = () => {

  return (
    <MainDashboardCard
      className="py-4.5 px-3 text-sm"
      title={"Advanced Tools"}
      tooltipTop="top-5.5"
      tooltipRight="right-5.5"
      infoTitle="Advanced AI tools to help you find products, niches and trends automatically."
    >
      <div className="flex flex-col gap-2 mt-2">

        <ZikProToolsItem title="BulkScanner" path={PATH.zikPro.zikProBulkScanner} />
        <ZikProToolsItem title="500BestsellingitemsonEbay" path={PATH.zikPro.bestSellingItems} />
        <ZikProToolsItem title="AliGrowthScanner" path={PATH.zikPro.aliGrowthScanner} />
        <ZikProToolsItem title="TurboScanner" path={PATH.zikPro.turboScanner.root} limited />
        <ZikProToolsItem title="Autopilot" path={PATH.zikPro.autopilot} limited />

      </div>
    </MainDashboardCard>
  );
};

export default ZikProTools;
