import { useEffect } from "react";

const useExternalScript = (
  url: string,
  integrity: string,
  async = true,
  crossOrigin = "anonymous"
) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;

    script.async = async;

    if (integrity) {
      script.integrity = integrity;
    }

    script.crossOrigin = crossOrigin;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useExternalScript;
