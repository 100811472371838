import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { DecodedUser } from 'hooks/useDecodeToken';
import jwt from "jwt-decode";
import { useEffect, useState } from 'react';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { handleBootIntercom } from 'store/slices/intercomSlice';
import { GetAccessToken } from 'utils/localStorage';
const INTERCOM_APP = process.env.REACT_APP_INTERCOM_APP_ID === undefined
    ? ""
    : process.env.REACT_APP_INTERCOM_APP_ID;

const IntercomApp = () => (
    <IntercomProvider appId={INTERCOM_APP}>
        <HomePage />
    </IntercomProvider>
);

// Anywhere in your app
const HomePage = () => {
    const [userDetails, setUserDetails] = useState<DecodedUser | null>(null)
    const { boot, shutdown, update, show } = useIntercom();
    const dispatch = useAppDispatch()
    const { isBootIntercom } = useAppSelector((state) => state.intercomSlice)
    const { accessToken } = useAppSelector((state) => state.user.result);
    const token = accessToken || GetAccessToken();

    useEffect(() => {
        if (token) {
            setUserDetails(jwt(token))
        }
    }, [token])

    useEffect(() => {
        if (isBootIntercom) {
            show();
            dispatch(
                handleBootIntercom(false)
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBootIntercom])

    useEffect(() => {
        boot();
        if (userDetails) {
            update({ name: userDetails.unique_name, email: userDetails.email, userId: userDetails.nameid });
        }

        return () => {
            shutdown();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails]);

    return <></>;
};


export default IntercomApp;
