import StatsCard from "components/Card/StatsCard";
import ReferralStatisticsList from "../lists/AffiliateManager/ReferralStatisticsList";

import { ReactComponent as UserCheck } from "assets/icons/user-check.svg";
import { ReactComponent as UserCross } from "assets/icons/user-cross.svg";
import { ReactComponent as UserMinus } from "assets/icons/user-minus.svg";
import { ReactComponent as UserT } from "assets/icons/user-t.svg";
import { useTranslation } from "react-i18next";
import { useGetReferralStatsQuery } from "store/api/affiliateManagerApi";
import { formatNumber } from "utils/formatCurrency";

const ReferralStatistics = () => {
  const { data, isFetching } = useGetReferralStatsQuery();
  const { t } = useTranslation(['home']);
  const cardProps = {
    className: "shadow-none dark:bg-grey800 dark:border-grey700",
    titleClassName: "text-sm",
    isLoading: isFetching,
  }

  return (
    <>
      <div className="flex max-w-[1140px] w-full m-auto gap-[35px] mb-[25px] max-[1250px]:grid max-[1250px]:grid-cols-[1fr_1fr] max-[750px]:grid-cols-1 max-[750px]:gap-4.5">
        <StatsCard
          title={t('SuccessfulReferrals')}
          value={formatNumber(data?.result.successfullReferrals || 0)}
          Icon={UserCheck}
          valueClassName="text-[28px]"
          {...cardProps}
        />
        <StatsCard
          title={t('NonPayingReferrals')}
          value={formatNumber(data?.result.nonPayingReferrals || 0)}
          Icon={UserMinus}
          valueClassName="text-[28px] dark:!text-canaryYellow"
          {...cardProps}
        />
        <StatsCard
          title={t('CanceledReferrals')}
          value={data?.result.canceledReferrals || 0}
          Icon={UserCross}
          valueClassName="text-[28px] dark:!text-errorRed"
          {...cardProps}
        />
        <StatsCard
          title={t('TotalReferrals')}
          value={data?.result.totalReferrals || 0}
          Icon={UserT}
          valueClassName="text-[28px] dark:!text-grey100"
          {...cardProps}
        />
      </div>

      <ReferralStatisticsList />
    </>
  );
};

export default ReferralStatistics;
