import Header from "components/Header";
import HeaderTab from "components/Header/Tab";
import { useState } from "react";

//Icons
import { helpVideos } from "assets/data/helpVideos";
import ProToolsWrapper from "components/ProToolsWrapper/Index";
import Toaster from "components/Toast";
import useDecodeToken, { UserPackages } from "hooks/useDecodeToken";
import useTabs from "hooks/useTabs";
import { useTranslation } from "react-i18next";
import { PATH } from "routes/path";
import { GetHideBanner, HideBanner } from "utils/localStorage";
import AliGrowthScanner from "./AliGrowthScanner";
import Autopilot from "./Autopilot";
import BestSellingItems from "./BestSellingItems";
import BulkScanner from "./BulkScanner";
import ZikProBanner from "./components/ZikProBanner";
import TurboScanner from "./TurboScanner";

export enum ZIK_PRO_TABS {
  bulkScanner = "bulk-scanner",
  bestSellingItems = "best-selling-items",
  aliGrowthScanner = "ali-growth-scanner",
  turboScanner = "turbo-scanner",
  autopilot = "autopilot",
}


const tabContent = (plan: UserPackages) => ({
  [ZIK_PRO_TABS.bulkScanner]: {
    content: <BulkScanner />,
    btnTitle: "Watch our Bulk Scanner Training Video",
    helpVideo: helpVideos.bulkScanner,
  },
  [ZIK_PRO_TABS.bestSellingItems]: {
    content: <BestSellingItems />,
    btnTitle: "Watch our Training Video",
    helpVideo: helpVideos.bestSellingItems,
  },
  [ZIK_PRO_TABS.aliGrowthScanner]: {
    content: <AliGrowthScanner />,
    btnTitle: "Watch our Ali Growth Training Video",
    helpVideo: helpVideos.aliGrowth,
  },
  [ZIK_PRO_TABS.turboScanner]: {
    content: <TurboScanner />,
    helpVideo: helpVideos.turboScanner,
    btnTitle: "Watch our Turbo Scanner Training Video",
  },
  [ZIK_PRO_TABS.autopilot]: {
    content: <Autopilot />,
    btnTitle: "Watch our Autopilot Training Video",
    helpVideo: helpVideos.autopilot,
  },
});

const ZikPro = () => {
  const { activeTab } = useTabs<ZIK_PRO_TABS | null>(null);
  const [hideBanner, setHideBanner] = useState(GetHideBanner());
  const userDetails = useDecodeToken();
  const plan = userDetails.package;
  const { t } = useTranslation(['home']);
  const closeBanner = () => { setHideBanner(true); HideBanner() };
  const getTabContObj = tabContent(plan)[activeTab ? activeTab : ZIK_PRO_TABS.bulkScanner]
  return (
    <>
      <Header
        leftContent={
          <>
            <HeaderTab
              title={t('BulkScanner')}
              isActive={activeTab === ZIK_PRO_TABS.bulkScanner}
              path={PATH.zikPro.zikProBulkScanner}
              id="BulkScanner"
              className="!min-w-[113px]"
            />
            <HeaderTab
              title={t('500BestsellingitemsonEbay')}
              isActive={activeTab === ZIK_PRO_TABS.bestSellingItems}
              path={PATH.zikPro.bestSellingItems}
              id="500BestsellingitemsonEbay"
            />
            <HeaderTab
              title={t('AliGrowthScanner')}
              isActive={activeTab === ZIK_PRO_TABS.aliGrowthScanner}
              path={PATH.zikPro.aliGrowthScanner}
              id="AliGrowthScanner"
            />
            <HeaderTab
              title={t('TurboScanner')}
              isActive={activeTab === ZIK_PRO_TABS.turboScanner}
              path={PATH.zikPro.turboScanner.root}
              className="!min-w-[110px]"
              id="TurboScanner"
            />
            <HeaderTab
              title={t('Autopilot')}
              isActive={activeTab === ZIK_PRO_TABS.autopilot}
              path={PATH.zikPro.autopilot}
              className="!min-w-[90px]"
              id="Autopilot"
            />
          </>
        }
        leftContentClassName="!gap-10 max-xl:gap-6"
      />
      <ProToolsWrapper
        isLocked={plan === "Pro"}
        activeTab={activeTab}
      >
        {!hideBanner && (
          <ZikProBanner
            activeTab={activeTab}
            closeBanner={closeBanner}
            btnTitle={getTabContObj.btnTitle}
            videos={getTabContObj.helpVideo}
          />
        )}
        {activeTab ? getTabContObj.content : ""}
      </ProToolsWrapper>

      <Toaster />
    </>
  );
};

export default ZikPro;
