import NewDashboard from "pages/NewDashboard";
import { PATH } from "./path";

import BulkScannerItems from "pages/BulkScannerItems";
import CategoryResearch from "pages/CategoryResearch";
import CategoryResearchResults from "pages/CategoryResearch/Results";
import CompetitorResearchFilters from "pages/CompetitorResearch";
import CompetitorResearchAliexpress from "pages/CompetitorResearch/Aliexpress";
import CompetitorResearchEbay from "pages/CompetitorResearch/Ebay";
import EbayProductExplorer from "pages/EbayProductExplorer";
import Billing from "pages/ManagementPanel/Billing";
import ManagementPanel from "pages/ManagementPanel/Index";
import MyCompetitors from "pages/MyCompetitors";
import MyProducts from "pages/MyProducts";
import MyProductsItem from "pages/MyProducts/Product";
import ProductResearchFilters from "pages/ProductResearch";
import ProductResearchAliexpress from "pages/ProductResearch/Aliexpress";
import ProductResearchEbay from "pages/ProductResearch/Ebay";
import QuickSettings from "pages/QuickSettings/Index";
import StoreAnalytics from "pages/StoreAnalytics";
import ShopifyLocked from "pages/Subscription/ShopifyLocked";
import TitleBuilderResults from "pages/TitleBuilder/Results";
import AutopilotScan from "pages/ZikPro/AutopilotScan";
import ZikPro from "pages/ZikPro/index";
import TurboScannerItem from "pages/ZikPro/TurboScannerItem";

export const ebayRoutes = [
    {
        path: PATH.dashboard.root,
        element: <NewDashboard />,
    },
    {
        path: PATH.productResearch.root,
        element: <ProductResearchFilters />,
    },
    {
        path: PATH.productResearch.ebay,
        element: <ProductResearchEbay />,
    },
    {
        path: PATH.productResearch.aliexpress,
        element: <ProductResearchAliexpress />,
    },
    {
        path: PATH.competitorResearch.root,
        element: <CompetitorResearchFilters />,
    },
    {
        path: PATH.competitorResearch.aliexpress,
        element: <CompetitorResearchAliexpress />,
    },
    {
        path: PATH.competitorResearch.ebay,
        element: <CompetitorResearchEbay />,
    },
    {
        path: PATH.productExplorer.root,
        element: <EbayProductExplorer />
    },
    {
        path: PATH.categoryResearch.root,
        element: <CategoryResearch />,
    },
    {
        path: PATH.categoryResearch.results,
        element: <CategoryResearchResults />,
    },
    {
        path: PATH.titleBuilder.results,
        element: <TitleBuilderResults />,
    },
    {
        path: PATH.zikPro.root,
        element: <ZikPro />,
    },
    {
        path: PATH.zikPro.bulkScanner.items,
        element: <BulkScannerItems />,
    },
    {
        path: PATH.zikPro.turboScanner.items,
        element: <TurboScannerItem />,
    },
    {
        path: PATH.zikPro.autopilotscan.item,
        element: <AutopilotScan />,
    },
    {
        path: PATH.myCompetitors,
        element: <MyCompetitors />,
    },
    {
        path: PATH.myProducts.root,
        element: <MyProducts />,
    },
    {
        path: PATH.myProducts.item,
        element: <MyProductsItem />,
    },
    {
        path: PATH.quickSettings.root,
        element: <QuickSettings />,
    },
    {
        path: PATH.managementPanel.root,
        element: <ManagementPanel />,
    },
    {
        path: PATH.managementPanel.billing,
        element: <Billing />
    },
    {
        path: PATH.managementPanel.upcampaign,
        element: <Billing />
    },
    {
        path: PATH.store.item,
        element: <StoreAnalytics />,
    },
    {
        path: PATH.shopifyLocked,
        element: <ShopifyLocked />
    },
]
