import Modal from "components/Modal";
import Tooltip from "components/Tooltip";
import { LANDING_PAGE_LINKS } from "config/constants";
import useModal from "hooks/useModal";
import { FC } from "react";
import { toast } from "react-toastify";
import { CampaignList } from "types/services/affiliateManager";
import CampaignGraph from "./CampaignGraph";

interface Props {
  helpers: ReturnType<useModal>;
  item: CampaignList;
}

const InvoiceDetails: FC<Props> = ({ helpers, item }) => {
  const url = LANDING_PAGE_LINKS.r + "/" + item.url;
  const valStyle = "font-normal ml-2"
  const rowStyle = "font-bold"
  const onUrlCopy = () => {
    toast.success("Copied to clipboard!");
    navigator.clipboard.writeText(url);
  };

  return (
    <Modal
      helpers={helpers}
      title={`Campaign: ${item.campaignName}`}
      overlay
      maxWidth="max-w-[740px]"
      titleClassName="!mb-[34px]"
      cardClassName="py-[18px]"
      buttonCloseClassName="!top-[22px] !right-[22px]"
    >
      <div className="text-sm grid grid-cols-2 items-center max-w-[550px] gap-12 mb-[34px] mx-auto">
        <div className="flex flex-col gap-2.5">
          <h6 className={rowStyle}>URL</h6>
          <div>
            <button
              className="hover:cursor-pointer hover:text-primaryPurple dark:hover:text-purple500"
              data-tooltip-id="click-to-copy-url"
              onClick={onUrlCopy}
            >
              {url}
            </button>
            <Tooltip title="Click to copy!" anchorId="click-to-copy-url" />
          </div>

          <h6 className={rowStyle}>
            Total Clicks:<span className={valStyle}>{item.clicks}</span>{" "}
          </h6>
          <h6 className={rowStyle}>
            Subscribers:<span className={valStyle}>{item.subscribersCount}</span>{" "}
          </h6>
        </div>
        <div className="flex flex-col gap-2.5">
          <h6 className={rowStyle}>Page Section</h6>
          <p>{item.pageSection}</p>
          <h6 className={rowStyle}>
            Trials: <span className={valStyle}>{item.trialsCount}</span>
          </h6>
          <h6 className={rowStyle}>
            Total Registered:<span className={valStyle}>{item.registered}</span>
          </h6>
        </div>
      </div>

      <CampaignGraph campaignId={item.campaignId.toString()} />
    </Modal>
  );
};

export default InvoiceDetails;
