import { helpVideos } from "assets/data/helpVideos";
import Card from "components/Card";
import Header from "components/Header";
import HeaderHelpVideo from "components/Header/HelpVideo";
import HeaderTab from "components/Header/Tab";
import SettingsMenu from "components/SettingsTabs/Index";
import SettingsTab from "components/SettingsTabs/SettingsTab";
import useTabs from "hooks/useTabs";
import { useTranslation } from "react-i18next";
import Account from "./components/Account";
import CompetitorSettings from "./components/CompetitorSettings";
import Integrations from "./components/Integrations";
import SearchPreferences from "./components/SearchPreferences";
import StoreSettings from "./components/StoreSettings";
import SupplierSettings from "./components/SupplierSettings";

enum TABS {
  account = "account",
  searchPreferences = "search-preferences",
  storeSettings = "store-settings",
  competitorSettings = "competitor-settings",
  integrations = "integrations",
  supplierSettings = "supplier-settings",
}

const tabContent = {
  [TABS.account]: {
    content: <Account />,
  },
  [TABS.searchPreferences]: {
    content: <SearchPreferences />,
  },
  [TABS.storeSettings]: {
    content: <StoreSettings />,
  },
  [TABS.competitorSettings]: {
    content: <CompetitorSettings />,
  },
  [TABS.integrations]: {
    content: <Integrations />,
  },
  [TABS.supplierSettings]: {
    content: <SupplierSettings />,
  },
};

const Index = () => {
  const { activeTab, onTabChange } = useTabs<TABS | null>(null);
  const { t } = useTranslation(['home']);
  const tabStyles = "h-[46px] "

  return (
    <>
      <Header
        leftContent={
          <>
            <HeaderTab
              title={t("AdvancedSettings")}
              isActive={true}
            />
          </>
        }
        rightContent={
          <>
            <HeaderHelpVideo videos={helpVideos.settings} />

          </>
        }
      />

      <Card className="px-[44px] py-[24px] dark:text-grey100">
        <SettingsMenu
          tabs={
            <>
              <SettingsTab
                title={t("MyAccount")}
                isActive={activeTab === TABS.account}
                handleClick={() => onTabChange(TABS.account)}
                divider
                dividerClassName="max-[662px]:hidden"
                className={`${tabStyles} max-w-[124px]`}
              />
              <SettingsTab
                title={t("ProductResearch")}
                isActive={activeTab === TABS.searchPreferences}
                handleClick={() => onTabChange(TABS.searchPreferences)}
                divider
                dividerClassName="max-[662px]:hidden"
                className={`${tabStyles} max-w-[160px]`}
              />
              <SettingsTab
                title={t("StoreAnalytics")}
                isActive={activeTab === TABS.storeSettings}
                handleClick={() => onTabChange(TABS.storeSettings)}
                divider
                dividerClassName="max-[874px]:visible max-[1103px]:invisible max-[662px]:hidden"
                className={`${tabStyles} max-w-[141px]`}
              />
              <SettingsTab
                title={t("CompetitorResearch")}
                isActive={activeTab === TABS.competitorSettings}
                handleClick={() => onTabChange(TABS.competitorSettings)}
                divider
                dividerClassName="max-[1103px]:visible max-[1300px]:invisible max-[662px]:hidden"
                className={`${tabStyles} max-w-[181px]`}
              />
              <SettingsTab
                title={t("Integrations")}
                isActive={activeTab === TABS.integrations}
                handleClick={() => onTabChange(TABS.integrations)}
                divider
                dividerClassName="max-[662px]:hidden"
                className={`${tabStyles} max-w-[121px]`}
              />
              <SettingsTab
                title={t("SourceWebsite")}
                isActive={activeTab === TABS.supplierSettings}
                handleClick={() => onTabChange(TABS.supplierSettings)}
                className={`${tabStyles} max-w-[145px]`}
              />
            </>
          }
          className="max-w-[880px] h-[50px] p-[2px] mx-auto mb-[32px] max-[1300px]:flex-wrap max-[1300px]:justify-center max-[1300px]:rounded-[10px] max-[662px]:flex-col"
        />

        {activeTab ? tabContent[activeTab].content : ""}
      </Card>
    </>
  );
};

export default Index;
