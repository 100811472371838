import { createApi } from "@reduxjs/toolkit/query/react";
import {
  AddStoreReq,
  AddStoreRes,
  DisconnectStoreReq,
  DisconnectStoreRes,
  GetStoreRes,
  GetUserStoreDetailsReq,
  GetUserStoreDetailsRes,
  LoadUserStoreReq,
  LoadUserStoreRes,
  UpdateStoreReq,
  UpdateStoreRes,
} from "types/services/store";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";
import { queryTags } from "./queryTags";

export const storeApi = createApi({
  reducerPath: "storeApi",
  baseQuery,
  tagTypes: [queryTags.store.getStores],
  endpoints: (builder) => ({
    getStores: builder.query<GetStoreRes, void>({
      query: () => ({
        url: queryKeys.store.getStores,
        method: "GET",
      }),
      providesTags: [queryTags.store.getStores],
    }),
    getLazyStores: builder.query<GetStoreRes, void>({
      query: () => ({
        url: queryKeys.store.getStores,
        method: "GET",
      }),
    }),
    addStore: builder.mutation<AddStoreRes, AddStoreReq>({
      query: (params) => ({
        url: queryKeys.store.addStore,
        method: "POST",
        params,
      }),
      //invalidatesTags: [queryTags.store.getStores],
    }),
    updateStore: builder.mutation<UpdateStoreRes, UpdateStoreReq>({
      query: (body) => ({
        url: queryKeys.store.updateStore,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [queryTags.store.getStores],
    }),
    disconnectStore: builder.mutation<DisconnectStoreRes, DisconnectStoreReq>({
      query: (params) => ({
        url: queryKeys.store.disconnect,
        method: "PATCH",
        params,
      }),
      invalidatesTags: [queryTags.store.getStores],
    }),
    loadUserStore: builder.query<LoadUserStoreRes, LoadUserStoreReq>({
      query: (body) => ({
        url: queryKeys.store.loadUserStore,
        method: "POST",
        body,
      }),
      providesTags: [queryTags.store.loadStore],
    }),
    getUserStoreDetails: builder.query<
      GetUserStoreDetailsRes,
      GetUserStoreDetailsReq
    >({
      query: (params) => ({
        url: queryKeys.store.getUserStoreDetails,
        method: "POST",
        params,
      }),
      providesTags: [queryTags.store.loadStore],
    }),
  }),
});

export const {
  useGetUserStoreDetailsQuery,
  useLoadUserStoreQuery,
  useDisconnectStoreMutation,
  useUpdateStoreMutation,
  useGetStoresQuery,
  useAddStoreMutation,
  useLazyGetLazyStoresQuery,
} = storeApi;
