import { MutableRefObject, useEffect, useRef, useState } from "react";

function useHover<T extends HTMLElement>(
  type: "hover" | "focus" = "hover"
): [MutableRefObject<T | null>, boolean] {
  const [value, setValue] = useState<boolean>(false);
  const ref = useRef<T | null>(null);

  const prevNodeRef = useRef<T | null>(null);
  const isMouseOverRef = useRef(false); // Track if mouse is over the element

  const handleMouseOver = (): void => {
    isMouseOverRef.current = true;
    setValue(true);
  };

  const handleMouseOut = (): void => {
    isMouseOverRef.current = false;
    setTimeout(() => {
      if (!isMouseOverRef.current) {
        setValue(false);
      }
    }, 0);
  };

  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener(
        type === "focus" ? "focus" : "mouseover",
        handleMouseOver
      );
      node.addEventListener(
        type === "focus" ? "blur" : "mouseout",
        handleMouseOut
      );

      prevNodeRef.current = node;

      return () => {
        node.removeEventListener(
          type === "focus" ? "focus" : "mouseover",
          handleMouseOver
        );
        node.removeEventListener(
          type === "focus" ? "blur" : "mouseout",
          handleMouseOut
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, type]);

  useEffect(
    () => () => {
      if (prevNodeRef.current) {
        prevNodeRef.current.removeEventListener(
          type === "focus" ? "focus" : "mouseover",
          handleMouseOver
        );
        prevNodeRef.current.removeEventListener(
          type === "focus" ? "blur" : "mouseout",
          handleMouseOut
        );
      }
    },
    []
  );

  return [ref, value];
}

export default useHover;
