
import { ReactComponent as Amazon } from "assets/icons/amazon-f.svg";
import { ReactComponent as Ebay } from "assets/icons/ebay.svg";
import { ReactComponent as Other } from "assets/icons/other-seller.svg";
import { ReactComponent as Shopify } from "assets/icons/shopify.svg";
import Modal from "components/Modal";
import Toaster from "components/Toast";
import { BLUESNAP_SCRIPT } from "config/config";
import { REGISTRATION_BACKGROUND } from "config/constants";
import useExternalScript from "hooks/useExternalScript";
import useModal from "hooks/useModal";
import { FC, ReactNode, useEffect } from "react";
import { useLazyGetPfTokenQuery } from "store/api/managePaymentApi";
import { useGetFeedbackQuery } from "store/api/onboardingApi";
import RegistrationFunnelNavbar from "./Navbar";
import RegFunnelStepper from "./Stepper";


interface Props {
    step: number;
    channels: number[];
    helpers: ReturnType<useModal>,
    introStep: number;
    handleBack: () => void;
    onStepChange: (newStep: number) => void;
    children: ReactNode;
    isPricingFlow: boolean;
}
const stepWidth = {
    intro: "max-w-[717px]",
    salesChannel: "max-w-[910px]",
    questionaire: "max-w-[900px]",
}
const RegFunnelWrapper: FC<Props> = ({ step, channels, helpers, introStep, handleBack, onStepChange, isPricingFlow, children }) => {
    const [getPfToken] = useLazyGetPfTokenQuery();
    const { data: feedback } = useGetFeedbackQuery();
    const showFeedback = feedback?.message === null || feedback?.message === "";
    const showFullModal = isPricingFlow ? step >= 2 : step >= 3;

    const modalMaxWidth = [
        stepWidth.intro,
        stepWidth[isPricingFlow ? "questionaire" : "salesChannel"],
        stepWidth.questionaire,
    ]
    const onBack = () => {
        if (isPricingFlow && (step === 2 || step === 3)) {
            onStepChange(step === 3 ? 1 : 3)
        } else {
            handleBack()
        }
    }
    useEffect(() => {
        getPfToken(null)
            .unwrap()
            .then((data) => {
                window.gpfToken = data.message;
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useExternalScript(BLUESNAP_SCRIPT, "");

    const resolveSalesChannel = (hideOther?: boolean) => {
        if (step === 2 && channels.length > 0) {
            let iconList: JSX.Element[] = [];

            // eslint-disable-next-line array-callback-return
            channels.map((channel) => {
                if (channel === 1)
                    iconList.push(
                        <Ebay className="w-[56px] h-[31px]" />
                    );
                if (channel === 2)
                    iconList.push(
                        <Amazon className="w-[76px] h-6" />
                    );
                if (channel === 3)
                    iconList.push(
                        <Shopify className="w-[86px] h-6" />
                    );
                if (channel === 4 && !hideOther)
                    iconList.push(
                        <Other className="w-[60px] h-[28px]" />
                    );
            });

            return iconList;
        } else {
            return (
                <Ebay className="w-[56px] h-[31px] invisible" />
            );
        }
    };

    const hideStepper = (introStep === 0 && step === 0) || (introStep === 1 && showFeedback) || step === 4 || (isPricingFlow && step === 2);

    return (
        <>
            {!showFullModal ? (
                <img src={REGISTRATION_BACKGROUND} alt="ZIK Dashboard" className="min-h-screen min-w-screen bg-cover w-full max-lg:hidden" />
            ) : ""}

            <Modal
                helpers={helpers}
                className="flex flex-col items-center max-lg:max-w-full max-lg:h-full"
                cardClassName={`flex flex-col  max-lg:max-w-full max-lg:h-full max-lg:rounded-none max-lg:p-0 ${step !== 3 ? "max-lg:px-5" : ""}  ${showFullModal ? "rounded-none" : "lg:max-h-[40rem] xl:max-h-[47rem] lg:justify-center"}`}
                maxWidth={showFullModal ? "max-w-[100%] h-full" : modalMaxWidth[step]}
                buttonCloseClassName="!top-[22px] right-[21px]"
                overlay
                hideBorder
                alwaysOpen
            >
                <RegistrationFunnelNavbar handleBack={onBack} introStep={introStep} step={step} />
                {hideStepper ? "" : (
                    <RegFunnelStepper step={step} onStepChange={onStepChange} handleBack={onBack} isPricingFlow={isPricingFlow} />
                )}

                {step === 2 && channels.length > 0 && (
                    <div className="lg:hidden flex items-center justify-center gap-2.5 mt-4 max-lg:mt-6 max-sm:mt-0 mb-4 max-sm:mb-2">
                        {resolveSalesChannel(true)}
                    </div>
                )}
                {children}
                <div className="absolute top-[28px] right-[27px] flex flex-col items-end gap-2 max-lg:hidden">
                    {resolveSalesChannel(true)}
                </div>
            </Modal>

            <Toaster />

        </>
    )
}

export default RegFunnelWrapper;