import { ReactComponent as ItemFinder } from "assets/icons/boxes/box.svg";
import { ReactComponent as CategoryResearch } from "assets/icons/category-research.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as ProductExplorer } from "assets/icons/product-exlorer.svg";
import { ReactComponent as ProductResearch } from "assets/icons/product-research.svg";
import { ReactComponent as TitleBuilder } from "assets/icons/title-builder.svg";
import { ReactComponent as ZikPro } from "assets/icons/zik-pro.svg";

const descs = {
  pro: "Provides professional research tools for making data-driven business decisions",
  proPlus:
    "Provides big data and automations to increase market share to the next level",
  enterprise:
    "Provides complete automation and maximum data to capture all possible opportunities",
};
const monthlyTwoPlans = [
  {
    type: "StarterM",
    planName: "Pro",
    planId: "pro",
    planDescription: descs.pro,
    isDiscount: true,
    planPrice: 29.99,
    planDiscountPrice: 14.99,
    highlight: false,
    skipAmout: 0,
  },
  {
    type: "StandardM",
    planName: "Pro+",
    planId: "pro-plus",
    planDescription: descs.proPlus,
    isDiscount: true,
    planPrice: 59.99,
    planDiscountPrice: 29.99,
    highlight: true,
    skipAmout: 0,
  },
];
const yearlyTwoPlans = [
  {
    type: "StarterY",
    planName: "Pro",
    planId: "pro",
    planDescription: descs.pro,
    isDiscount: false,
    planPrice: 24.99,
    planDiscountPrice: 12.49,
    upcampaignPrice: 12.49,
    highlight: false,
    skipAmout: 15,
  },
  {
    type: "StandardY",
    planName: "Pro+",
    planId: "pro-plus",
    planDescription: descs.proPlus,
    isDiscount: false,
    planPrice: 49.99,
    planDiscountPrice: 24.99,
    upcampaignPrice: 24.99,
    highlight: true,
    skipAmout: 25,
  },
];
const lifetimeTwoPlans = [
  {
    type: "ProLT",
    planName: "Pro",
    planId: "pro",
    planDescription: descs.pro,
    isDiscount: true,
    planPrice: 1994,
    planDiscountPrice: 997,
    highlight: false,
  },
  {
    type: "ProPlusLT",
    planName: "Pro+",
    planId: "pro-plus",
    planDescription: descs.proPlus,
    isDiscount: true,
    planPrice: 3594,
    planDiscountPrice: 1797,
    highlight: true,
  },
];

export const monthlyTwo = {
  period: "monthly",
  planList: monthlyTwoPlans,
};
export const yearlyTwo = {
  period: "yearly",
  planList: yearlyTwoPlans,
};
export const lifetimeTwo = {
  period: "lifetime",
  planList: lifetimeTwoPlans,
};

export const monthly = {
  period: "monthly",
  planList: [
    ...monthlyTwoPlans,
    {
      type: "EnterpriseM",
      planName: "Enterprise",
      planId: "enterprise",
      planDescription: descs.enterprise,
      isDiscount: true,
      planPrice: 89.99,
      planDiscountPrice: 44.99,
      highlight: false,
      skipAmout: 0,
    },
  ],
};

export const yearly = {
  period: "yearly",
  planList: [
    ...yearlyTwoPlans,
    {
      type: "EnterpriseY",
      planName: "Enterprise",
      planId: "enterprise",
      planDescription: descs.enterprise,
      isDiscount: false,
      planPrice: 74.99,
      planDiscountPrice: 37.49,
      upcampaignPrice: 37.49,
      highlight: false,
      skipAmout: 25,
    },
  ],
};

export const lifetime = {
  period: "lifetime",
  planList: [
    ...lifetimeTwoPlans,
    {
      type: "EnterpriseLT",
      planName: "Enterprise",
      planId: "enterprise",
      planDescription: descs.enterprise,
      isDiscount: true,
      planPrice: 5994,
      planDiscountPrice: 2997,
      highlight: false,
    },
  ],
};

export const proToolsProPlus = {
  period: "",
  planList: [
    monthlyTwo.planList[1],
    yearlyTwo.planList[1],
    lifetimeTwo.planList[1],
  ],
};

export const proToolsEnterprise = {
  period: "",
  planList: [monthly.planList[2], yearly.planList[2], lifetime.planList[2]],
};

export const plansComparison = [
  {
    Icon: Logo,
    icon: "dashboard",
    title: "Market Insights",
    description: "Find the best products to maximize sales.",
    pro: true,
    proPlus: true,
    enterprise: true,
    iconClassName: "w-[19px] h-[19px]",
  },
  {
    Icon: ProductResearch,
    icon: "product",
    title: "Product Research",
    description: "Find the hottest products and increase your store's profits",
    pro: true,
    proPlus: true,
    enterprise: true,
    iconClassName: "w-4 h-4",
  },
  {
    Icon: CompetitorResearch,
    icon: "competitor",
    title: "Competitor Research",
    description: "Get current intel on competitors products and strategies.",
    pro: true,
    proPlus: true,
    enterprise: true,
    iconClassName: "w-4 h-[17px]",
  },
  {
    Icon: CategoryResearch,
    icon: "category",
    title: "Category Research",
    description: "See top selling items and sellers in any eBay category.",
    pro: true,
    proPlus: true,
    enterprise: true,
    iconClassName: "w-4 h-[15px]",
  },
  {
    Icon: TitleBuilder,
    icon: "titleBuilder",
    title: "Title Builder",
    description:
      "Know and use all the keywords currently driving traffic + sales.",
    pro: true,
    proPlus: true,
    enterprise: true,
    iconClassName: "w-4 h-[15px]",
  },
  {
    Icon: ItemFinder,
    icon: "itemFinder",
    title: "Item Finder",
    description:
      "Find and cross-compare eBay items with products on AliExpress, Alibaba, Amazon and Walmart.",
    pro: true,
    proPlus: true,
    enterprise: true,
    iconClassName: "w-[19px] h-[17px]",
  },
  {
    Icon: ProductExplorer,
    icon: "productExplorer",
    title: "Product Explorer",
    description: "Find best selling eBay products in seconds",
    pro: "Limited to 20 searches / month",
    proPlus: "Limited to 100 searches / month",
    enterprise: "Unlimited",
    isNew: true,
    iconClassName: "w-[19px] h-5",
  },
  {
    Icon: ZikPro,
    icon: "proTools",
    title: "500 Best Selling eBay Items",
    description:
      "Find and cross-compare eBay items with products on AliExpress, Alibaba, Amazon and Walmart.",
    pro: false,
    proPlus: true,
    enterprise: true,
    iconClassName: "w-4.5 h-5",
  },
  {
    Icon: ZikPro,
    icon: "proTools",
    title: "Bulk Scanner",
    description:
      "Instantly find thousands of Amazon and Walmart dropship items.",
    pro: false,
    proPlus: true,
    enterprise: true,
    iconClassName: "w-4.5 h-5",
  },
  {
    Icon: ZikPro,
    icon: "proTools",
    title: "Ali Growth Scanner",
    description: "Easily mine data on trends coming from AliExpress.",
    pro: false,
    proPlus: true,
    enterprise: true,
    iconClassName: "w-4.5 h-5",
  },
  {
    Icon: ZikPro,
    icon: "proTools",
    title: "Autopilot",
    description:
      "Automatically find dropshipping items from AliExpress, Amazon and Walmart.",
    pro: false,
    proPlus: "Limited to 10 scans / month",
    enterprise: "Unlimited",
    iconClassName: "w-4.5 h-5",
  },
  {
    Icon: ZikPro,
    icon: "proTools",
    title: "Turbo Scanner",
    description:
      "Automatically discover the best selling items from Amazon and Walmart.",
    pro: false,
    proPlus: "Limited to 10 scans / month",
    enterprise: "Unlimited",
    iconClassName: "w-4.5 h-5",
  },
];
