import { FilterFn } from "@tanstack/react-table";

export const tableSearch: FilterFn<any> = (row, columnId, value) => {
  const safeValue = (() => {
    const value = row.getValue(columnId);

    return typeof value === "number" ? String(value) : value;
  })();

  return (
    safeValue?.toString().toLowerCase().includes(value.toLowerCase()) || false
  );
};
