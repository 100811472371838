import numeral from "numeral";
import { CURRENCY } from "types/currency";

// ----------------------------------------------------------------------

export type CURRENCY_SIGNS = "$" | "€" | "£" | null;
const currencies = {
  [CURRENCY.dollar]: "$",
  [CURRENCY.euro]: "€",
  [CURRENCY.pound]: "£",
  [CURRENCY.malaysianRinggit]: "RM",
  [CURRENCY.none]: "",
};
export const formatCurrency = (
  number: string | number,
  currency: CURRENCY = CURRENCY.dollar,
  customCurrency?: CURRENCY_SIGNS,
  hideLabel?: boolean
) => {
  const sign = customCurrency || currencies[currency];

  return hideLabel
    ? sign
    : sign +
        numeral(number).format(Number(number) % 1 === 0 ? `0,0` : `0,0.00`);
};

export const formatPercent = (number: string | number) =>
  numeral(Number(number.toString()) / 100).format(
    Number(number) % 1 === 0 ? "0%" : "0.00%"
  );

export const formatNumber = (number: string | number | null) =>
  numeral(number).format();
