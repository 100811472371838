import { SEARCH_DEBAUNCE_TIME } from "config/config";
import { useEffect, useState } from "react";

const useDebaunceSearch = (
  onDebauncedValueChange?: (newVal: string) => void
) => {
  const [immediateValue, setImmediateValue] = useState("");
  const [delayedValue, setDelayedValue] = useState("");
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setDelayedValue(immediateValue);
      onDebauncedValueChange && onDebauncedValueChange(delayedValue);
    }, SEARCH_DEBAUNCE_TIME);

    return () => clearTimeout(delayDebounceFn);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [immediateValue]);

  const onChange = (newVal: string) => setImmediateValue(newVal);

  return { immediateValue, delayedValue, onChange };
};

export default useDebaunceSearch;
