import HoveredIcon from "components/HoveredIcon";

//icons
import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import { TokenList } from "types/services/quickSettings";

export const tokenCols = (
  handleDelete: (id: string) => void,
  updateLoading?: boolean,
  selectedItem?: string
): ColumnDef<TokenList>[] => [
    {
      header: "Tokens",
      accessorKey: "token",
      cell: ({ row: { original } }) => (
        <p className="min-w-[115px]">
          {"************************" +
            original.token.substring(original.token.length - 4)}
        </p>
      ),
      meta: {
        tooltipTitle: "A digital token represents a specific amount of digital resources you can own, assign to another, or redeem later. "
      }
    },
    {
      header: "Store Name",
      accessorKey: "storeName",
      cell: (storeName) => String(storeName.getValue()),
    },
    {
      header: () => "Type",
      accessorKey: "type",
      cell: (type) => (Number(type.getValue()) === 0 ? "Auto DS" : Number(type.getValue()) === 1 ? "Sale Freaks" : ""),
    },
    {
      accessorKey: "bulkStoreId",

      header: () => <Trash className="fill-darkBlue dark:fill-grey100" />,
      minSize: 50,
      cell: ({ row: { original } }) => (
        <HoveredIcon
          variant="outlined"
          Icon={Trash}
          width="w-6"
          height="h-6"
          isLoading={
            updateLoading && selectedItem === String(original.bulkStoreId)
          }
          handleClick={() => handleDelete(String(original.bulkStoreId))}
          iconClassName={"w-[10px]"}
        />
      ),
      meta: {
        tooltipTitle: "Delete",
        alignHead: "justify-center"
      },
    },
  ];
