import { ReactComponent as Close } from "assets/icons/clear-input.svg";

interface Props {
    title: string;
    index: number;
    handleRemoveTag: (index: number) => void;
    className?: string;
}
const Tag = ({ title, handleRemoveTag, index, className }: Props) => {
    return (
        <div className={`flex p-[8px] min-h-[32px] h-full items-center rounded-full bg-secondary dark:bg-grey200 dark:text-black gap-[4px] text-[12px] font-[500] ${className}`}>
            <span className="break-all h-fit">{title}</span>

            <div onClick={() => handleRemoveTag(index)} className="w-4 h-4 flex items-center justify-center cursor-pointer select-none">
                <Close className="fill-darkBlue w-[8px] min-w-[8px] h-[8px]" />
            </div>
        </div>
    )
}

export default Tag