import { plansComparison } from "assets/data/plans/plans";
import { useState } from "react";
import { PlansType, TSelectedPlan } from "types/plans";
import ComparisonHeader from "./ComparisonHeader";
import ComparsionMobileTabs from "./ComparisonMobileTabs";
import PlanComparisonFooter from "./PlanComparisonFooter";
import PlansComparisonItem from "./PlansComparisonItem";

interface Props {
  plans: PlansType;
  handleSelectPlan?: (newVal: TSelectedPlan) => void;
  fromRegistration?: boolean;
  selectedPlan?: "pro" | "pro-plus" | "enterprise";
  isPayNow?: boolean;
  wrapperClassName?: string;
}

const EbayPlansComparison = ({
  plans,
  handleSelectPlan,
  fromRegistration = false,
  isPayNow,
  wrapperClassName
}: Props) => {
  const showTwoPlans = plans.planList.length === 2
  const [selectedPlan, setSelectedPlan] = useState<string>("pro");
  const onPlanSelect = (tab: string) => setSelectedPlan(tab);
  const headerSameProps = {
    selectedPlan
  }

  return <div className={`w-full dark:text-darkBlue ${wrapperClassName}`}>
    <div className="md:hidden  sticky top-[5px] z-[99]">
      <ComparsionMobileTabs selectedPlan={selectedPlan} onPlanSelect={onPlanSelect} />
    </div>
    <div className={`flex justify-center w-full`}>
      <div className={`${showTwoPlans ? "max-w-[703px]" : "max-w-[855px]"} max-md:max-w-[330px] w-full`}>
        <div className="z-10 overflow-hidden relative z-[20]">
          <div className={`flex rounded-t-[15px]`}>
            <div className={`bg-transparent text-[24px] max-w-[400px] w-full pl-4 max-md:text-lg h-[123px]`}>
              <p className={`h-[106px]  flex items-center mt-[17px] dark:text-grey100`}>Compare tools accross plans!</p>
            </div>
            <ComparisonHeader id="pro" plan={plans.planList[0]} {...headerSameProps} />
            <ComparisonHeader id="pro-plus" plan={plans.planList[1]} {...headerSameProps} />
            {!showTwoPlans ? (
              <ComparisonHeader id="enterprise" plan={plans.planList[2]} {...headerSameProps} />
            ) : ""}
          </div>
          {plansComparison.map((item, i) => (
            <PlansComparisonItem
              key={i}
              period={plans.period}
              isFirst={i === 0}
              showTwoPlans={showTwoPlans}
              selectedPlan={selectedPlan}
              item={item}
              isLast={plansComparison.length === i + 1}
            />
          ))}
        </div>
        <PlanComparisonFooter
          selectedPlan={selectedPlan}
          planList={plans.planList}
          isPayNow={isPayNow}
          handleSelectPlan={handleSelectPlan}
          fromRegistration={fromRegistration}
        />
      </div>
    </div>
  </div>


}

export default EbayPlansComparison;