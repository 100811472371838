import { similarItemsCols } from "assets/tableColumns/iconFinder";
import Loading from "components/Loading";
import Table from "components/Table";
import { TableStyles } from "components/Table/Table";
import useTable from "hooks/useTable";
import { FC, useEffect, useState } from "react";
import { useFindAmazonItemsQuery, useFindPEAmazonItemsQuery, useFindThirdPartyDataQuery } from "store/api/iconFinderApi";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { Option } from "types/common";
import {
  IconFinderItem,
  IconFinderSimilarList,
  PostAddManuallyResponse
} from "types/services/iconFinder";
import {
  calculateAmazonProfit,
  calculateWalmartProfit
} from "utils/calculateProfit";
import { CURRENCY_SIGNS } from "utils/formatCurrency";
import { getAmazonDomain } from "utils/settings";
import { SaveItem } from ".";

interface Props {
  item: IconFinderItem;
  selectedFolder: Option | null;
  addAsinData?: PostAddManuallyResponse | null;
  onSaveItem: (item: SaveItem) => void;
  saveLoading: boolean;
  site?: string;
  currency?: CURRENCY_SIGNS;
  thirdParty?: boolean;
  fromPE?: boolean;
  productNotFound?: boolean;
}

const tableStyles: TableStyles = {
  px: "px-0",
  style: "max-[1400px]:overflow-x-visible mb-3",
  body: {
    contHeight: "h-[50px]",
    rowHeight: "h-[85px]",
  },
};
const initialItem = {
  asin: "",
  outOfStock: false,
  pictureUrl: "",
  price: 0,
  similiar: false,
  title: "",
  url: "",
  profit: 0
};

const SimilarItemsList: FC<Props> = ({
  item,
  selectedFolder,
  addAsinData,
  currency,
  onSaveItem,
  site,
  productNotFound,
  saveLoading,
  fromPE,
  thirdParty = false
}) => {
  const { data: settings } = useGetSettingsQuery();
  const isWal = site === "WAL";
  const { data, isFetching: amazonLoading } = useFindAmazonItemsQuery({
    asin: item.asin!,
    ebayId: item.id,
  }, {
    refetchOnMountOrArgChange: true,
    skip: thirdParty || fromPE,
  }
  );

  const { data: thirdPartyData, isFetching: thirdPartyLoading } = useFindThirdPartyDataQuery(item.id, {
    refetchOnMountOrArgChange: true,
    skip: !thirdParty || (!item.isGreen && thirdParty) || fromPE,
  }
  );

  const { data: amazonPEData, isFetching: amazonPELoading } = useFindPEAmazonItemsQuery(
    { ebayId: item.id, isUK: site === "UK", isUS: site === "US" }, {
    refetchOnMountOrArgChange: true,
    skip: !fromPE || productNotFound
  })

  const similarLoading = amazonLoading || thirdPartyLoading || amazonPELoading;
  const [newItem, setNewItem] = useState<typeof initialItem>(
    fromPE && amazonPEData && amazonPEData?.result.data !== null ? amazonPEData?.result.data :
      !thirdParty && data && data?.result.amazonProduct !== null ? data.result.amazonProduct :
        thirdParty && thirdPartyData && thirdPartyData?.result.data !== null ? thirdPartyData?.result.data :
          initialItem
  );


  const breakEven = settings?.result.breakEven

  const settingsBreakEven = breakEven ? breakEven : 0;

  const profit = (!thirdParty && !fromPE) ? newItem?.profit : isWal
    ? calculateWalmartProfit(item.price, newItem?.price, settingsBreakEven)
    : calculateAmazonProfit(item.price, newItem?.price, settingsBreakEven);

  useEffect(() => {
    if (data && !thirdParty) {
      setNewItem(data.result.amazonProduct);
    }

    if (thirdParty && thirdPartyData) {
      setNewItem(thirdPartyData?.result.data);
    }

    if (fromPE && amazonPEData) {
      setNewItem(amazonPEData?.result.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, thirdPartyData, amazonPEData]);


  useEffect(() => {
    if (addAsinData) {
      setNewItem({
        asin: addAsinData.result.item ? addAsinData.result.item.asin : "",
        outOfStock: addAsinData.result.item
          ? addAsinData.result.item.outOfStock
          : false,
        pictureUrl: addAsinData.result.item
          ? addAsinData.result.item.amazonImageURL
          : "",
        price: addAsinData.result.item
          ? addAsinData.result.item.amazonFormattedPrice
          : 0,
        similiar: false,
        title: addAsinData.result.item
          ? addAsinData.result.item.amazonTitle
          : "",
        url: addAsinData.result.item ? isWal ? `https://www.walmart.com/ip/${addAsinData.result.item.asin}` : `https://www.amazon${getAmazonDomain(site || "US")}/dp/${addAsinData.result.item.asin}` : "",
        profit: 0
      });
    }
    else {
      setNewItem(initialItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAsinData]);

  const { table } = useTable({
    columns: similarItemsCols(
      () =>
        onSaveItem({
          asin: newItem.asin,
          title: newItem.title,
          price: newItem.price,
          pictureUrl: newItem.pictureUrl,
          profit,

        }),
      isWal,
      getAmazonDomain(site || "US"),
      saveLoading,
      currency
    ),
    dataDeps: [newItem],
    columnDeps: [selectedFolder, saveLoading, newItem],
    data: newItem?.asin ? [{ ...newItem, profit: profit?.toString(), url: newItem.url ?? `https://www.amazon${getAmazonDomain(site || "US")}/dp/${newItem?.asin}` }] : [],
  });

  return similarLoading ? (
    <div className="flex justify-center items-center h-28">
      <Loading fill="fill-primaryPurple dark:fill-purple500" width={48} height={48} />
    </div>
  ) : newItem?.asin ? (
    <Table<IconFinderSimilarList>
      table={table}
      hideTableHead
      styles={tableStyles}
    />
  ) : (
    <div className="mb-10" />
  );
};

export default SimilarItemsList;
