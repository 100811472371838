//Icons
import { ColumnDef } from "@tanstack/react-table";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import HoveredIcon from "components/HoveredIcon";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";
import { WatchList } from "types/services/watchList";
import { formatNumber } from "utils/formatCurrency";
import { formatDate } from "utils/formatTime";
import { makeQueryParams } from "utils/makeQueryParams";

export const mySearchCols = (
  onAction: (item: WatchList, isEdit?: boolean) => void,
  deleteLoading: boolean,
  selectedItem: WatchList | null,
  handleSort: (column: string) => void,
  isAliexpress?: boolean,
  isPr?: boolean,
): ColumnDef<WatchList>[] => [
    {
      header: isPr ? "Search Term" : "Store Name",
      accessorKey: "sentWord",
      cell: (title) => (
        <Link
          to={makeQueryParams(PATH[isPr ? "productResearch" : "competitorResearch"][
            isAliexpress ? "aliexpress" : "ebay"
          ], { search: String(title.getValue()) })}
          target="_blank"
          rel="noreferrer"
          className="hover:text-primaryPurple dark:hover:text-purple500"
        >
          {String(title.getValue())}
        </Link>
      ),
      meta: {
        customSort: () => handleSort("SentWord"),
        sortColumnId: "SentWord",
        align: "justify-center",
        alignHead: "justify-between"
      },
    },
    {
      header: "Your Rating",
      accessorKey: "rating",
      cell: (rating) => formatNumber(String(rating.getValue())),
      meta: {
        customSort: () => handleSort("Rating"),
        sortColumnId: "Rating",
        align: "justify-center",
        alignHead: "justify-between"
      },
    },
    {
      header: "Comment",
      accessorKey: "comment",
      cell: (comment) => <p>{String(comment.getValue() || "-")}</p>,
      maxSize: 200,
      meta: {
        customSort: () => handleSort("Comment"),
        sortColumnId: "Comment",
        align: "justify-center",
        alignHead: "justify-between"
      },
    },
    {
      header: "Date Added",
      accessorKey: "dateAdded",
      cell: (date) => formatDate(String(date.getValue())),
      meta: {
        customSort: () => handleSort("DateAdded"),
        sortColumnId: "DateAdded",
        align: "justify-center",
        alignHead: "justify-between"
      },
    },
    {
      minSize: 50,
      header: "Edit",
      accessorKey: "id",
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <HoveredIcon
          variant="outlined"
          Icon={Edit}
          handleClick={() => onAction(original, true)}
          width="w-7"
          height="h-7"
          iconClassName={"w-[12.25px]"}
        />
      ),
      meta: {
        tooltipTitle: "Edit the comment and rating of competitor"
      }
    },
    {
      header: () => <Trash className="fill-darkBlue dark:fill-grey100" />,
      accessorKey: "watchListId",
      minSize: 50,
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <HoveredIcon
          variant="outlined"
          Icon={Trash}
          width="w-7"
          height="h-7"
          isLoading={
            (selectedItem &&
              selectedItem.watchListId === original.watchListId &&
              deleteLoading) ||
            false
          }
          handleClick={() => onAction(original)}
          iconClassName={"w-2.5"}

        />
      ),
      meta: { alignHead: "justify-center" }
    },
  ];
