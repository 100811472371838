import HoveredIcon from "components/HoveredIcon";
import { FC } from "react";

import { ReactComponent as RedOpen } from "assets/icons/boxes/red-open.svg";
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import IconFinder from "components/IconFinder";
import useModal from "hooks/useModal";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { IconFinderItem } from "types/services/iconFinder";
import findBoxIcon from "utils/findBoxIcon";
import { CURRENCY_SIGNS } from "utils/formatCurrency";

interface Props {
  openIconFinder: (item: IconFinderItem) => void;
  isGreen: boolean;
  isSimilar: boolean;
  sales: number;
  currentPrice: number;
  title: string;
  itemID: number;
  images: string;
  productUrl: string;
  alwaysShowAli?: boolean;
  showRedBox?: boolean;
  showCheckmark?: boolean;
  customMarketplace?: "Ali" | "Cjd" | "Baba" | "WAL" | "AmazonUS" | "AmazonUK";
  currency?: CURRENCY_SIGNS;
  fromPE?: boolean
}

const IconFinderBox: FC<Props> = ({
  openIconFinder,
  isGreen,
  isSimilar,
  sales,
  currentPrice,
  title,
  itemID,
  images,
  currency,
  productUrl,
  customMarketplace,
  showRedBox,
  showCheckmark,
  fromPE = false,
  alwaysShowAli = false
}) => {
  const modal = useModal();
  const { data } = useGetSettingsQuery();
  const amazonsite = alwaysShowAli ? "Ali" :
    customMarketplace === undefined ? data?.result.amazonsite :
      (customMarketplace === "AmazonUS" || customMarketplace === "AmazonUK" ? "Amazon" : customMarketplace)
  const findIcon = findBoxIcon(amazonsite || "");
  const selectedItem = {
    sold: sales,
    price: currentPrice,
    title,
    id: itemID,
    img: images,
    url: productUrl,
    isSimilar: isSimilar,
    isGreen: isGreen,
  }

  const handleClick = () => {
    if (fromPE) {
      modal.open();
    } else {
      openIconFinder(selectedItem)
    }
  }

  const showBox = amazonsite === "Baba" || amazonsite === "Cjd" || amazonsite === "Ali" || alwaysShowAli;
  const isHideRedBox = fromPE ? !showRedBox : (showBox || isGreen);
  const showCheck = !showBox && (fromPE ? showCheckmark : !isSimilar)
  const tooltipTitle =
    !isHideRedBox ? "Product not found, add product ID manually" :
      amazonsite === "Ali" ? "Find product by Image on AliExpress " :
        amazonsite === "Baba" || amazonsite === "Cjd" ? "Find product by Image on Alibaba" :
          `${(fromPE ? !showCheckmark : isSimilar) ? "Similar" : "Exact"} product found on ${amazonsite === "WAL" ? "Walmart" : "Amazon"}`;

  return <>
    {isHideRedBox ? (
      <div className="relative w-fit">
        {showCheck ? (
          <div className="absolute bg-spaceGreen right-1 top-0 w-[15px] h-[15px] flex items-center justify-center rounded-full">
            <Checkmark className="fill-darkBlue w-2 h-1.5" />
          </div>
        ) : ""}
        <HoveredIcon
          Icon={findIcon.icon}
          className="w-9 h-9"
          anchorId="item-finder-tooltip-id"
          HoveredIcon={findIcon.iconHovered}
          iconClassName={findIcon.iconClassName}
          hoveredIconClassName={findIcon.iconClassName}
          iconColor={"dark:fill-grey100 fill-darkBlue"}
          handleClick={handleClick}
          title={tooltipTitle}
        />
      </div>
    ) : (
      <HoveredIcon
        Icon={RedOpen}
        anchorId="red-item-finder-tooltip-id"
        iconColor={"fill-errorRed"}
        handleClick={handleClick}
        title={tooltipTitle}
      />
    )}
    {fromPE ? (
      <IconFinder
        marketplace={customMarketplace}
        helpers={modal}
        currency={currency}
        item={selectedItem}
        showCheckmark={showCheckmark}
        productNotFound={showRedBox}
        fromPE
      />
    ) : ""}
  </>
};

export default IconFinderBox;
