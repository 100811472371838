import { suppliers } from "assets/data/filters";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import { ReactComponent as Ranking } from "assets/icons/average-price.svg";
import { ReactComponent as Box } from "assets/icons/boxes/box.svg";
import { ReactComponent as CatRes } from "assets/icons/category-research.svg";
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as Globe } from "assets/icons/globe.svg";
import { ReactComponent as Time } from "assets/icons/time.svg";

import Button from "components/Button";
import Card from "components/Card";
import InfoTooltip from "components/Card/InfoTooltip";
import FilterCheckbox from "components/Filters/FilterCheckbox";
import FiltersCard from "components/Filters/FiltersCard";
import MinMaxFilter from "components/Filters/MinMaxFilter";
import Switch from "components/Input/Switch";
import CenteredLoading from "components/Loading/CenteredLoading";
import Rating from "components/Rating";
import Search from "components/Search";
import Select from "components/Select";
import { useAppSelector } from "hooks/useAppSelector";
import useDecodeToken from "hooks/useDecodeToken";
import useModal from "hooks/useModal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useTurboGetCatsQuery,
  useTurboScanPrevFiltersQuery
} from "store/api/turboScannerApi";
import { useProPlusLimitsQuery } from "store/api/zikProApi";
import { turboScanFilters } from "store/initialValues/turboScanner";
import {
  handleTsFilterChange,
  setTsFilters
} from "store/slices/turboScannerSlice";
import { PLAN_TYPE } from "types/plans";
import ExceedScanLimit from "./ExceedLimit";
import TurboSelectCategories from "./Modal/SelectCategories";
interface Props {
  isLoading?: boolean;
  onFilter: () => void;
}

const inclusionsList = [
  {
    text: "Prime Only", value: "onlyPrime",
    // toolTip: "Include only Amazon Prime items in your search"
  },
  { text: "Include Books", value: "includeBooks" },
  { text: "Include DVDs/CDs", value: "includeDVDs" },
];

const walmartInclusionList = [
  {
    text: "Sold by Walmart", value: "onlyPrime",
    // toolTip: "Walmart includes items that are sold and shipped directly by Walmart,  and also items that are sold and shipped by other registered marketplace sellers. You can choose to get only the ones shipped and sold directly by Walmart."
  },

]
const TurboScannerFilters = ({ isLoading, onFilter }: Props) => {
  const userDetails = useDecodeToken();
  const plan = userDetails.package;
  const showLimitTxt = plan === PLAN_TYPE.PRO_PLUS;
  const limitReachedModal = useModal();
  const { data: limitsData } = useProPlusLimitsQuery("TurboScanner", { skip: !showLimitTxt });
  const modal = useModal();
  const dispatch = useDispatch();
  const { scanFilters } = useAppSelector((state) => state.turboScannerSlice);
  const [categoryTitle, setCategoryTitle] = useState("Select Category");
  const [invalidField, setInvalidField] = useState<any>({})
  const [rating, setRating] = useState(false);
  const { data: categories } = useTurboGetCatsQuery(scanFilters?.supplier);
  const { data: prevFilters, isFetching: prevFiltersFetching } =
    useTurboScanPrevFiltersQuery();
  const limitReached = showLimitTxt && limitsData?.result.limitReached
  const { data } = useTurboGetCatsQuery(scanFilters.supplier, {
    skip: !modal.isOpen,
  });
  const onScan100Products = () => {
    handleFilterChange(100, "numberOfProducts", "max");
  }

  const isWalmart = scanFilters.supplier === "4"
  useEffect(() => {
    if (showLimitTxt) {
      onScan100Products()
    }
  }, [showLimitTxt])

  useEffect(() => {
    if (prevFilters && prevFilters?.result) {
      setRating(prevFilters.result.rating !== 0 && prevFilters.result.rating !== null);
      handleFilterChange(prevFilters.result.rating, "rating");
      dispatch(
        setTsFilters({
          ...scanFilters,
          inclusions: [
            Boolean(prevFilters.result.onlyPrime) && "onlyPrime",
            Boolean(prevFilters.result.includeBooks) && "includeBooks",
            Boolean(prevFilters.result.includeDVDs) && "includeDVDs",
          ],
        })
      );
      // handleFilterChange([] as string[], "inclusions");
    } else {
      setRating(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevFilters]);

  const handleFilterChange = (
    newVal: string | number,
    field: keyof typeof turboScanFilters,
    type?: "min" | "max"
  ) => {
    dispatch(handleTsFilterChange({ newVal, field, type }));
  };

  useEffect(() => {
    if (
      categories &&
      categories?.result.length > 0 &&
      scanFilters.category.length > 0 &&
      scanFilters.category[0] !== ""
    ) {
      const title =
        [...(categories?.result || [])].find(
          (item) => item.checkBoxValue.toString() === scanFilters?.category?.[0]
        )?.categoryName + `${scanFilters.category.length > 1 ? "..." : ""}`;

      setCategoryTitle(title);
    } else {
      setCategoryTitle("Select Category");
    }
  }, [scanFilters.category, categories]);
  const handleListChange = (
    newVal: string | number,
    field: "inclusions" | "category"
  ) => {
    const find = scanFilters[field].includes(newVal.toString());
    const remove = scanFilters[field].filter((item) => item !== newVal);
    dispatch(
      setTsFilters({
        ...scanFilters,
        [field]: find ? remove : [...scanFilters[field], newVal],
      })
    );
  };

  const handleSelectAll = () => {
    if (data) {
      dispatch(setTsFilters({
        ...scanFilters, category:
          scanFilters.category.length === data?.result.length ? [] :
            data.result.map((item) => item.checkBoxValue.toString())
      }));
    }
  };


  const startNewScan = () => {
    const {
      reviewCount,
      sellerRanking,
      price,
      numberOfProducts
    } = scanFilters;
    if (showLimitTxt && numberOfProducts.max > 100) {
      limitReachedModal.open();
      return;
    }
    let errors: any = {}

    if (reviewCount.max && reviewCount.min && reviewCount.min > reviewCount.max) {
      errors.reviewCount = true
    }
    if (!isWalmart) {
      if (sellerRanking.max && sellerRanking.min && sellerRanking.min > sellerRanking.max) {
        errors.sellerRanking = true
      }
    }
    if (price.max && price.min && price.min > price.max) {
      errors.price = true
    }
    setInvalidField(errors)
    if (Object.keys(errors).length === 0) {
      onFilter();
    }

  }

  return (
    <Card className="p-4">
      <CenteredLoading
        loading={prevFiltersFetching}
        contMinHeight="min-h-[200px]"
      >
        <div className="flex gap-[15px] max-sm:flex-col">
          <div className="w-[37%] max-xl:w-1/2 flex gap-[15px] max-lg:flex-col max-sm:w-full">
            <div className="w-full flex flex-col gap-2.5">
              <FiltersCard
                Icon={Globe}
                title="Suppliers"
                titleWrapperClassName="mb-6"
                iconClassName="w-4 h-4"
                className="w-full"
                infoTitle="Pick a supplier to scan the items from (Amazon.com, Amazon.co.uk, Walmart.com)"
              >
                <Select
                  options={suppliers}
                  listWrapperClassName="!top-11"
                  btnClassName="w-full !h-[35px]"
                  arrowClassName="w-2.5 h-[6px] !mr-[2px]"
                  value={scanFilters.supplier}
                  handleChange={(newValue) => {
                    dispatch(
                      setTsFilters({
                        ...scanFilters,
                        category: [],
                        supplier: newValue,
                      })
                    );
                  }}
                />
              </FiltersCard>
              <FiltersCard
                title=""
                className="pb-[23px]"
                iconClassName="w-4 h-4"
              >
                <div className="flex flex-col gap-4">
                  <div className="flex items-center justify-between">
                    <h5 className="text-sm font-bold dark:text-grey100">Rating</h5>
                    <div className="flex items-center gap-2">
                      <Switch
                        isChecked={rating}
                        setIsChecked={setRating}
                        handleChange={() =>
                          handleFilterChange(rating ? 0 : 1, "rating")
                        }
                      />
                      <InfoTooltip title="Item rating (1-5 stars)" />
                    </div>

                  </div>
                  <div className="flex items-center gap-[11px]">
                    <Rating
                      rating={scanFilters.rating}
                      handleRatingChange={(newVal) => {
                        if (!rating) {
                          setRating(true)
                        }
                        handleFilterChange(newVal, "rating")
                      }}
                    />

                  </div>
                </div>
              </FiltersCard>
              <div>
                <FiltersCard
                  title="No. Reviews"
                  className="w-full !px-2.5 !py-2"
                  titleWrapperClassName="!mb-1"
                  Icon={Checkmark}
                  iconClassName="w-4 h-4"
                  infoTitle="Number of reviews (from min. to max.)"
                >
                  <MinMaxFilter
                    isInvalid={invalidField.reviewCount}
                    value={scanFilters.reviewCount}
                    minInputLimit={10000000}
                    maxInputLimit={10000000}
                    name="reviewCount"
                    inputWidth="w-16"
                    handleChange={(newVal, type) =>
                      handleFilterChange(newVal, "reviewCount", type)
                    }
                  />
                </FiltersCard>
              </div>
            </div>

            <div className="gap-2.5 flex flex-col w-full">
              <FiltersCard
                title="Categories"
                Icon={CatRes}
                titleWrapperClassName="mb-6"
                className="w-full"
                iconClassName="w-4 h-4"
                infoTitle="Choose the category you wish to see the items from"
              >
                <button
                  className="mt-2 flex items-center justify-between w-full text-sm hover:text-primaryPurple dark:hover:text-purple500 hover:border-b-primaryPurple dark:hover:border-b-purple500 h-[35px] relative group
                  after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-[3px] after:rounded-full after:bg-darkBlue hover:after:bg-primaryPurple dark:hover:after:bg-purple500 dark:after:bg-grey100 dark:hover:after:bg-purple500"
                  onClick={modal.open}
                >
                  <p className=" font-medium dark:text-grey100 group-hover:text-purple500">{categoryTitle}</p>
                  <div className="w-6 h-[35px] flex items-center justify-center">
                    <ArrowDown
                      className="group-hover:fill-primaryPurple dark:group-hover:fill-purple500 fill-darkBlue dark:fill-grey100 w-2.5 h-[6px]"
                    />
                  </div>

                </button>
              </FiltersCard>
              <FilterCheckbox
                options={isWalmart ? walmartInclusionList : inclusionsList}
                value={scanFilters.inclusions}
                multiselect
                rectangleCheckbox
                checkboxClassName="!w-4 !h-4"
                checkmarkClassName="!w-2 !h-1.5 mt-[2px]"
                className="gap-[17px] w-full "
                handleChange={(newValue) =>
                  handleListChange(newValue, "inclusions")
                }
              />
            </div>
          </div>

          <div className={`flex w-[63%] flex-col max-xl:w-[50%] max-sm:w-full ${isWalmart ? "gap-4" : " gap-2.5"}`}>
            <div className="w-full flex gap-[15px] max-[1450px]:flex-col">
              <div className={`flex gap-[15px] max-lg:flex-col ${isWalmart ? " max-xl:w-full w-1/2" : "w-full"}`}>
                {isWalmart ? "" : (
                  <FiltersCard
                    title="Best Seller Ranking"
                    Icon={Ranking}
                    titleWrapperClassName="!mb-[5px]"
                    iconClassName="w-4 h-4"
                    className="w-full !px-2"
                    infoTitle="The Best Seller Ranking (BSR) is based on a supplier's sales and reflects both recent and historical sales of each item within a specific category. If an item has a BSR of 1, it means that the item is the top-selling product in its category."
                  >

                    <MinMaxFilter
                      isInvalid={invalidField.sellerRanking}
                      value={scanFilters.sellerRanking}
                      minInputLimit={10000000}
                      maxInputLimit={10000000}
                      name="sellerRanking"
                      isFromTo
                      inputWidth="w-16"
                      handleChange={(newVal, type) =>
                        handleFilterChange(newVal, "sellerRanking", type)
                      }
                    />
                  </FiltersCard>
                )}

                <FiltersCard
                  title="30 Days Sales"
                  Icon={Time}
                  titleWrapperClassName="!mb-[5px]"
                  className={`${isWalmart ? "" : "max-w-[180px]"} max-lg:max-w-full w-full`}
                  iconClassName="w-4 h-4"
                  infoTitle="Filter by sales made in the last 30 days"
                >
                  <MinMaxFilter
                    value={scanFilters.recentSale}
                    minInputLimit={10000000}
                    maxInputLimit={10000000}
                    name="recentSale"
                    inputWidth="w-16"
                    hideMax
                    handleChange={(newVal, type) =>
                      handleFilterChange(newVal, "recentSale", type)
                    }
                  />
                </FiltersCard>
              </div>

              <div className="flex gap-[15px] w-full max-lg:flex-col">
                <FiltersCard
                  title="Price"
                  Icon={Dollar}
                  titleWrapperClassName="!mb-[5px]"
                  className="w-full"
                  iconClassName="w-4 h-4"
                  infoTitle="Filter by minimum/maximum selling prices"
                >
                  <MinMaxFilter
                    value={scanFilters.price}
                    isInvalid={invalidField.price}
                    minInputLimit={10000000}
                    maxInputLimit={10000000}
                    name="price"
                    inputWidth="w-16"
                    handleChange={(newVal, type) =>
                      handleFilterChange(newVal, "price", type)
                    }
                  />
                </FiltersCard>

                <FiltersCard
                  title="No. Products"
                  Icon={Box}
                  titleWrapperClassName="!mb-[5px]"
                  className={`${isWalmart ? "" : "max-w-[180px]"} w-full max-lg:max-w-full`}
                  iconClassName="w-4 h-4"
                  infoTitle="Filter by the number of products you want in the scan result"
                >
                  <MinMaxFilter
                    value={scanFilters.numberOfProducts}
                    name="numberOfProducts"
                    inputWidth="w-16"
                    hideMin
                    minInputLimit={10000}
                    maxInputLimit={10000}
                    handleChange={(newVal, type) => {
                      handleFilterChange(newVal, "numberOfProducts", type)
                    }
                    }
                  />
                </FiltersCard>
              </div>
            </div>
            <div className="flex gap-5 max-lg:flex-col max-lg:gap-2 max-lg:items-end">
              <Search
                search={scanFilters.keywords}
                borderColor="border-darkBlue dark:border-grey100"
                handleChange={(newValue) =>
                  handleFilterChange(newValue, "keywords")
                }
                placeholder="Filter by keywords"
                toolTip="Ex: baby, Car, Father Gifts, Premium, Mother Gift, Christmas gift When you split a set of keywords/long tail keywords by a comma, it means you're looking for supplier items with a title that contains at least one of these keywords"
              />
              <Button
                title="Start New Scan"
                loading={isLoading}
                disabled={limitReached}
                handleClick={startNewScan}
                height="h-9"
                className={`max-w-[11.25rem] ${limitReached ? "dark:text-deepSpace900" : ""}`}
                color="primaryPurple"
              />
            </div>
          </div>
        </div>

        <div className="flex gap-[15px]">
          <div className="flex flex-col gap-2.5" />
          <div className="flex flex-col gap-8 w-[55.52%] max-xl:w-1/3 max-lg:w-full" />
        </div>

        <TurboSelectCategories
          helpers={modal}
          value={scanFilters.category}
          handleChange={(newVal: string) => {
            if (newVal === "all") {
              handleSelectAll();
            } else {
              handleListChange(newVal, "category");
            }
          }}
          type={scanFilters.supplier}
        />
      </CenteredLoading>
      <ExceedScanLimit helpers={limitReachedModal} getLimitedProducts={onScan100Products} />
    </Card>
  );
};

export default TurboScannerFilters;
