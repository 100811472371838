import { noOfEntries } from "assets/data/filters";
import Search from "components/Search";
import Select from "components/Select";

// Icons
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { paidRequestsCols } from "assets/tableColumns/paymentHistory";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { TableStyles } from "components/Table/Table";
import useModal from "hooks/useModal";
import useTable from "hooks/useTable";
import RequestPayout from "pages/ManagementPanel/lists/AffiliateManager/RequestPayout";
import { FC, useState } from "react";
import { PaidRequestList } from "types/services/affiliateManager";

const tableStyles: TableStyles = {
  px: "px-0",
  head: { contHeight: "h-[37px]", rowHeight: "h-[55px]" },
  body: { contHeight: "h-[37px]", rowHeight: "h-[55px]" },
  empty: { rowHeight: "h-[80px]" },
  minWidth: "min-w-[700px]",
};

interface RequestProps {
  title: string;
  listLoading: boolean;
  list: PaidRequestList[];
}

const RequestList: FC<RequestProps> = ({ title, list, listLoading }) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const requestPayout = useModal();
  const infoClick = (id: string) => {
    requestPayout.open();
    setSelectedItem(id);
  };
  const { table } = useTable({
    columns: paidRequestsCols(infoClick),
    data: list,
    dataDeps: [list],
    initialPageSize: 50,
    search: true,
  });

  return (
    <div className="max-w-[1140px] w-full m-auto border-2 border-neutral2 dark:border-grey700 dark:bg-grey800 rounded-[10px] px-5.5 pb-[39px] pt-[37px] mb-10">
      <div className="font-bold dark:text-grey100">{title}</div>

      <div className="flex items-center justify-between mb-7  max-[750px]:flex-col max-[750px]:items-start max-[750px]:gap-4.5">
        <Select
          options={noOfEntries}
          value={table.getState().pagination.pageSize.toString()}
          handleChange={(value) => table.setPageSize(Number(value))}
          Icon={Menu}
          btnClassName="min-w-[12rem]"
        />
        <Search
          search={table.getState().globalFilter}
          handleChange={table.setGlobalFilter}
          maxWidth="18.8125rem"
          className="w-[18.8125rem] border-darkBlue"
          size="sm"
          htmlFor="table-search"
        />
      </div>
      <Table table={table} styles={tableStyles} isLoading={listLoading} />
      <Pagination table={table} labeled />
      {selectedItem ? (
        <RequestPayout helpers={requestPayout} id={selectedItem} />
      ) : (
        ""
      )}
    </div>
  );
};

export default RequestList;
