import Pro from "./Pro";
import ProPlus from "./ProPlus";

interface Props {
  planType: string;
  isProTools: boolean;
  isCurrentTrial?: boolean;
}

const PlanFeatures = ({ planType, isProTools, isCurrentTrial }: Props) => (
  <>
    {planType.toLowerCase() === "starterm" ||
      planType.toLowerCase() === "startery" ||
      planType.toLowerCase() === "prolt" ? (
      <Pro isCurrentTrial={isCurrentTrial} />
    ) : (
      <ProPlus
        isCurrentTrial={isCurrentTrial}
        isProTools={isProTools}
        isProPlus={planType.toLowerCase() === "standardm" ||
          planType.toLowerCase() === "standardy" ||
          planType.toLowerCase() === "propluslt"} />
    )}
  </>
);

export default PlanFeatures;
