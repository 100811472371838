import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left-extended.svg";
import { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
    children: ReactNode;
}
const BillingBackButton: FC<Props> = ({ children }) => {
    const navigate = useNavigate();

    return (
        <div className="flex justify-between w-full dark:text-grey100 px-6 relative max-md:pt-9">
            <button className="absolute min-w-[36px] left-[16px] top-[0px] h-9 flex justify-center items-center bg-white rounded-full border-2 border-darkBlue cursor-pointer hover:bg-primaryPurple hover:border-primaryPurple group
            dark:bg-deepSpace900 dark:border-lilac400 dark:hover:bg-purple500 dark:hover:border-purple500" onClick={() => navigate(-1)}>
                <ArrowLeft className="fill-darkBlue w-[13px] h-[12px] group-hover:fill-white dark:fill-lilac400 dark:group-hover:fill-lilac400" />
            </button>
            {children}
        </div>
    )
}

export default BillingBackButton