import { yupResolver } from "@hookform/resolvers/yup";
import { addCampaignTypeOptions } from "assets/data/ManagementPanel/affiliateManager";
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left.svg";
import Button from "components/Button";
import HoveredIcon from "components/HoveredIcon";
import ControlledInput from "components/Input/ControlledInput";
import LabeledInput from "components/Input/LabeledInput";
import Select from "components/Select";
import { LANDING_PAGE_LINKS } from "config/constants";
import { FC } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useAddCampaignMutation } from "store/api/affiliateManagerApi";
import { object, string } from "yup";

interface FormValues {
  name: string;
  type: string;
  url: string;
}
interface Props {
  pageSection: string;
  onBackClick: () => void;
  handleClose: () => void;
}

let integrationsSchema = object({
  name: string().required("Campaign Name is required"),
  url: string().required("Campaign URL is required"),
});

const CampaignSettings: FC<Props> = ({
  onBackClick,
  pageSection,
  handleClose,
}) => {
  const [addCampaign, { isLoading: addLoading }] = useAddCampaignMutation();
  const {
    handleSubmit,
    control,
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(integrationsSchema),
    defaultValues: {
      type: addCampaignTypeOptions[0].value,
      url: "",
    },
  });

  const onAddSuccess = (message: string) => {
    if (message === "AlreadyExist") {
      toast.error("Campaign URL already exist, please use different one!")

      return;
    }
    toast.success("Campaign Created");
    handleClose();
    onBackClick();
    reset();
  };
  const onAddError = () => {
    toast.error("Something went wrong, please try again later.");
    onBackClick();
    reset();
  };
  const onSubmit: SubmitHandler<FormValues> = (values) => {
    addCampaign({
      pageSection,
      campaignName: values.name,
      campaignType: values.type,
      campaignUrl: values.url,
    })
      .unwrap()
      .then((data) => onAddSuccess(data?.message))
      .catch(onAddError);
  };

  const handleChangeInput = () => {
    let newVal = getValues("url");

    let val = newVal.replace(" ", "-").replace("--", "-");
    let regEx = val.replace(/[^a-z0-9\-]/gi, '');
    setValue("url", regEx);
  }

  return (
    <div className="pb-8">
      <HoveredIcon
        Icon={LeftArrow}
        handleClick={onBackClick}
        variant="outlined"
        className="absolute top-8 left-4"
      />
      <form
        className="px-[25px] flex flex-col gap-5 "
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value } }) => (
            <LabeledInput
              variant="contained"
              value={value}
              handleChange={onChange}
              placeholder="Type a Campaign Name"
              name="name"
              labelClassName="text-sm"
              className="h-9 pl-5 rounded-[7px] dark:bg-deepSpace900"
              wrapperClassName="max-w-[450px] !gap-2"
              label="Campaign Name"
              message={errors.name?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="type"
          render={({ field: { onChange, value } }) => (
            <div>
              <label className="text-sm dark:text-grey100">Campaign Type</label>
              <Select
                className="max-w-[195px] w-full"
                listWrapperClassName="!top-12"
                options={addCampaignTypeOptions}
                btnClassName="w-full max-h-10"
                value={value}
                handleChange={onChange}
              />
              {errors.type?.message && (
                <p className={`text-errorRed text-xs mt-2`}>
                  {errors.type?.message?.toString()}
                </p>
              )}
            </div>
          )}
        />
        <div className="flex items-center w-[450px]">
          <LabeledInput
            value={`${LANDING_PAGE_LINKS.r}/`}
            handleChange={() => { }}
            placeholder="URL"
            variant="contained"
            labelClassName="text-sm"
            disabled
            wrapperClassName="w-[550px] !gap-2 mb-3"
            className="border-2 dark:border-grey100 border-secondary h-9 pl-5 rounded-l-[7px] border-r-0 dark:bg-deepSpace900"
            name="url"
            label="URL"
            message={errors.url?.message}
          />
          <ControlledInput
            name="url"
            variant="contained"
            register={register}
            errors={null}
            className={` h-9 pb-0 pl-2 rounded-r-[7px] dark:bg-deepSpace900 ${errors.url?.message ? "-mt-2" : "mt-[15.5px]"}`}
            focusedClassName="!border-primaryPurple dark:!border-purple500"
            handleChange={() => handleChangeInput()}
          />
        </div>
        <Button
          height="h-9"
          title="Create"
          loading={addLoading}
          className="max-w-[205px] mx-auto dark:bg-deepSpace900 dark:border-paradiseBlue"
          type="submit"
        />
      </form>
    </div>
  );
};

export default CampaignSettings;
