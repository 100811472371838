import {
  lifetime,
  lifetimeTwo,
  monthly,
  monthlyTwo,
  yearly,
  yearlyTwo,
} from "assets/data/plans/plans";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { PATH } from "routes/path";
import { PlansType } from "types/plans";

const usePlans = (
  planType: string,
  noSetParams?: boolean,
  fromReg?: boolean
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const isUpcampaign = pathname.includes(PATH.managementPanel.upcampaign);
  const getPlans = fromReg
    ? {
        monthly: monthlyTwo,
        yearly: yearlyTwo,
        lifetime: lifetimeTwo,
      }
    : {
        monthly,
        yearly,
        lifetime,
      };

  const selectedPlan = (type: string) =>
    type === "monthly"
      ? getPlans.monthly
      : type === "yearly"
      ? {
          ...getPlans.yearly,
          planList: getPlans.yearly.planList.map((item) => ({
            ...item,
            planDiscountPrice: isUpcampaign
              ? item.upcampaignPrice
              : item.planDiscountPrice,
          })),
        }
      : getPlans.lifetime;

  const [plans, setPlans] = useState<PlansType>(selectedPlan(planType));

  useEffect(() => {
    if (searchParams.has("plan")) {
      setPlans(selectedPlan(searchParams.get("plan") || planType));
    } else {
      setPlans(selectedPlan(planType));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, fromReg]);

  const handlePlanChange = (type: string) => {
    setPlans(selectedPlan(type));
    if (!noSetParams) {
      searchParams.set("plan", type);
      setSearchParams(searchParams);
    }
  };

  return {
    plans,
    handlePlanChange,
  };
};

export default usePlans;
