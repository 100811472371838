export const queryTags = {
  bulkScanner: {
    scannedSellers: "ScannedSellers",
    multiBulkSellers: "MultiBulkSellers",
    matchedItemsList: "MatchedItemsList",
    similarItemsList: "SimilarItemsList",
    notFoundItemsList: "NotFoundItemsList",
  },
  store: {
    getStores: "getStores",
    loadStore: "loadStore",
  },
  quickSettings: {
    getSettings: "QuickSettings",
    tokenList: "TokenList",
    getUserDeatils: "GetUSerDetails",
  },
  folders: {
    folders: "Folders",
  },
  iconFinder: {
    findItems: "FindItems",
  },
  watchList: {
    list: "WatchList",
    ebayCrHistory: "EbayCrHistory",
  },
  waitingList: {
    list: "WaitingList",
  },
  dashboardInsight: {
    insightPreferences: "InsightPreferences",
    favoritesMenu: "FavoritesMenu",
    announcements: "Announcements",
  },
  proPlusLimits: "ProPlusLimits",
  turboScanner: "TurboScanner",
  productMangement: "ProductManagement",
  affiliateManager: {
    campaigns: "campaigns",
    earningStats: "earningStats",
  },
  managePayments: {
    subscription: "userSubscription",
    defaultCoupon: "defaultCoupon",
    checkSubscriptionRegistration: "checkSubscriptionRegistration",
  },
  salesSpy: {
    list: "salesSpyList",
    details: "salesSpyDetails",
  },
  competitorResearch: {
    getCr: "GetCr",
  },
};
