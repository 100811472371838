import { createContext, ReactNode, useContext, useState } from "react";

interface TooltipContextType {
    activeTooltipId: string | null;
    setActiveTooltipId: (id: string | null) => void;
}

const TooltipContext = createContext<TooltipContextType | undefined>(undefined);

export function useTooltipContext(): TooltipContextType {
    const context = useContext(TooltipContext);
    if (!context) {
        throw new Error("useTooltipContext must be used within a TooltipProvider");
    }

    return context;
}

interface TooltipProviderProps {
    children: ReactNode;
}

export function TooltipProvider({ children }: TooltipProviderProps): JSX.Element {
    const [activeTooltipId, setActiveTooltipId] = useState<string | null>(null);

    return (
        <TooltipContext.Provider value={{ activeTooltipId, setActiveTooltipId }}>
            {children}
        </TooltipContext.Provider>
    );
}