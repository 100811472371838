import { FC } from "react";
import { formatCurrency, formatPercent } from "utils/formatCurrency";

interface Props {
  title: string;
  amount: number;
  isPercentage?: boolean;
  isCurrency?: boolean;
}

export const EarningsRow: FC<Props> = ({ title, amount, isPercentage, isCurrency }) => (
  <div className="flex justify-between !text-sm">
    <h6>{title}</h6> <p className="font-bold">{isPercentage ? formatPercent(amount) : isCurrency ? formatCurrency(amount) : amount}</p>
  </div>
);

//export default EarningsRow;
