import Card from "components/Card";
import { FC, useState } from "react";
import { sidebarCountries } from "./SidebarConfig";

interface Props {
  selectedCity: string;
  handleSelectCity: (id: string) => void;
  localCountry?: {
    location: { name: string; tz_id: string; country: string };
    current: {
      temp_c: number;
      condition: { text: string; icon: string; code: number };
    };
  }
}


const SubmenuCities: FC<Props> = ({ handleSelectCity, selectedCity, localCountry }) => {
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const onSelectCity = (id: string) => {
    if (id === "USA") {
      setSelectedCountry(id);
    } else {
      handleSelectCity(id);
    }
  };

  const cardStyles = "bg-white border-primaryPurple dark:border-purple500 w-[7.250rem] py-2 px-1 flex items-center flex-col gap-[5px]"
  const optionStyles = (isActive: boolean) => `py-[2px] rounded-full border-2 cursor-pointer pointer-events-auto dark:text-grey100
  hover:bg-primaryPurple hover:border-primaryPurple dark:hover:bg-purple500 dark:hover:border-purple500 hover:text-white text-left pl-4 w-full gap-2.5 h-full ${isActive
      ? "border-primaryPurple dark:border-purple500"
      : "border-transparent"
    }`
  const cardProps = {
    className: cardStyles,
    rounded: "rounded-[15px]",
    bordered: true
  }
  const cardWrapper = "text-xs z-50 pointer-events-auto absolute"

  return (
    <>
      <div className={`left-[55px] bottom-[10px] ${cardWrapper}`}>
        <Card
          {...cardProps}
        >
          {localCountry && (<button
            onClick={() => handleSelectCity(localCountry.location.name)}
            key={localCountry.location.country}
            className={optionStyles(selectedCity === localCountry.location.name)}
          >
            {localCountry.location.country === "United States of America" ? "USA" : localCountry.location.country}
          </button>)}
          {sidebarCountries.map((item) => (
            <button
              onClick={() => onSelectCity(item.id)}
              key={item.title}
              className={optionStyles(selectedCity === item.id)}
            >
              {item.title}
            </button>
          ))}


        </Card>
      </div>
      {selectedCountry === "USA" && (
        <div className={`left-[174px] -top-20 ${cardWrapper}`}>
          <Card
            {...cardProps}
          >
            {sidebarCountries.find((item) => item.id === "USA")?.cities?.map((city) => (
              <button
                onClick={() => onSelectCity(city.id)}
                key={city.title}
                className={optionStyles(selectedCity === city.id)}
              >
                {city.title}
              </button>
            ))}


          </Card>
        </div>
      )}


    </>
  )
};

export default SubmenuCities;

