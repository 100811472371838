import _ from "lodash";
import {
  ShopifyCountries,
  ShopifyCurencies,
  ShopifyLanguages,
} from "types/services/storeFinder";

import { Option } from "types/common";

const KEYS = {
  ACCESS: "access",
  REFRESH: "refresh",
  USER: "user",
  HIDE_TRACKING_INITIATED: "hideTrackingInitiated",
  HIDE_TRACKING_INITIATED_FOREVER: "hideTrackingInitiatedForever",
  AUTO_DS_PAGE: "autoDsPage",
  SAVED_CITY: "savedCity",
  SAVED_LANG: "savedLang",
  HIDE_BANNER: "hideAdvancedToolsBanner",
  NY_BANNER: "nyBanner",
  NY_NOTIFICATION: "nyNotification",
  DARK_MODE: "darkMode",
  SHOPIFY_COUNTRIES: "shopifyCountries",
  SHOPIFY_CURRENCIES: "shopifyCurrencies",
  SHOPIFY_LANGUAGES: "shopifyLanguages",
  DEFAULT_MARKETPLACE: "defaultMarketplace",
};

export const LOCAL_STORAGE_KEYS = KEYS;

export enum HISTORY_KEYS {
  prEbaySearch = "prEbaySearch",
  prAliSearch = "prAliSearch",
  crEbaySearch = "crEbaySearch",
  crAliSearch = "crAliSearch",
}

export const GetMarketplaceByUserId = (userId: string) => {
  try {
    var marketplace = localStorage.getItem(KEYS.DEFAULT_MARKETPLACE);
    if (marketplace) {
      var userMarketplace = JSON.parse(marketplace);
      var marketplaceUserId = userMarketplace.userId;

      if (marketplaceUserId == userId) return userMarketplace.marketplace;
      else return "";
    } else return "";
  } catch {
    var marketplace = localStorage.getItem(KEYS.DEFAULT_MARKETPLACE);
    return marketplace;
  }
};

export const GetMarketplace = () => {
  try {
    var userInfo = localStorage.getItem(KEYS.USER);
    if (userInfo) {
      var userId = JSON.parse(userInfo).userId;

      var marketplace = localStorage.getItem(KEYS.DEFAULT_MARKETPLACE);
      if (marketplace) {
        var userMarketplace = JSON.parse(marketplace);
        var marketplaceUserId = userMarketplace.userId;
        if (marketplaceUserId == userId) return userMarketplace.marketplace;
        else return "";
      } else return "";
    }
    var dMarketplace = localStorage.getItem(KEYS.DEFAULT_MARKETPLACE);
    if (dMarketplace) {
      var userMarketplace = JSON.parse(dMarketplace);
      return userMarketplace.marketplace;
    } else return "";
  } catch (err) {
    var marketplace = localStorage.getItem(KEYS.DEFAULT_MARKETPLACE);
    return marketplace;
  }
};

export const SetMarketplace = (marketplace: string) => {
  var marketplaceString = marketplace;
  var userInfo = localStorage.getItem(KEYS.USER);
  if (userInfo) {
    var userId = JSON.parse(userInfo).userId;
    const marketplaceData = {
      userId: userId,
      marketplace: marketplace,
    };

    // Stringify the JSON object
    marketplaceString = JSON.stringify(marketplaceData);
  }
  return localStorage.setItem(KEYS.DEFAULT_MARKETPLACE, marketplaceString);
};

export const GetNyBanner = () =>
  localStorage.getItem(KEYS.NY_BANNER) === "true" ? true : false;
export const HideNyBanner = () => localStorage.setItem(KEYS.NY_BANNER, "true");
export const GetHideBanner = () =>
  localStorage.getItem(KEYS.HIDE_BANNER) === "true" ? true : false;

export const HideBanner = () => localStorage.setItem(KEYS.HIDE_BANNER, "true");

export const GetSavedCity = () => localStorage.getItem(KEYS.SAVED_CITY);

export const SetSavedCity = (newCity: string) =>
  localStorage.setItem(KEYS.SAVED_CITY, newCity);

export const GetTrackingInitiatedForever = () =>
  localStorage.getItem(KEYS.HIDE_TRACKING_INITIATED_FOREVER) === "true"
    ? true
    : false;
export const HideTrackingInitiatedForever = () =>
  localStorage.setItem(KEYS.HIDE_TRACKING_INITIATED_FOREVER, "true");

export const GetHideTrackingInitiated = (storeName: string) => {
  const formattedStore = storeName.toLowerCase();
  const getDomains = localStorage.getItem(KEYS.HIDE_TRACKING_INITIATED);
  const hiddenDomains: string[] = getDomains ? JSON.parse(getDomains) : [];
  const isAlreadySeen = hiddenDomains.find(
    (item) => item.toLowerCase() === formattedStore
  );

  return isAlreadySeen ? true : false;
};

export const SetHideTrackingInitiated = (storeName: string) => {
  const formattedStore = storeName.toLowerCase();
  const getDomains = localStorage.getItem(KEYS.HIDE_TRACKING_INITIATED);
  const existingDomains = getDomains ? JSON.parse(getDomains) : [];
  const newDomains = [...existingDomains, formattedStore];

  localStorage.setItem(
    KEYS.HIDE_TRACKING_INITIATED,
    JSON.stringify(newDomains)
  );
};

export const GetSavedLang = () => localStorage.getItem(KEYS.SAVED_LANG);

export const SetSavedLang = (newLang: string) =>
  localStorage.setItem(KEYS.SAVED_LANG, newLang);

export const SetAccessToken = (token: string) =>
  localStorage.setItem(KEYS.ACCESS, token);

export const GetAccessToken = () => localStorage.getItem(KEYS.ACCESS);

export const SetRefreshToken = (token: string) =>
  localStorage.setItem(KEYS.REFRESH, token);

export const GetRefreshToken = () => localStorage.getItem(KEYS.REFRESH);

export const SetUserDetails = (user: object) =>
  localStorage.setItem(KEYS.USER, JSON.stringify(user));

export const SetAutoDsPage = (page: string) =>
  localStorage.setItem(KEYS.AUTO_DS_PAGE, page);

export const GetAutoDsPage = () => localStorage.getItem(KEYS.AUTO_DS_PAGE);

export const RemoveAutoDsPage = () =>
  localStorage.removeItem(KEYS.AUTO_DS_PAGE);

export const GetUserDetails = () => localStorage.getItem(KEYS.USER);

// Shopify data
export const SetShopifyCountries = (countries: ShopifyCountries[]) => {
  // array that present top 5 countries in array
  const topCountries = ["US", "GB", "AU", "DE", "FR"];
  // filter top 5 countries and sort them in order from topCountries array
  const filteredTop = countries
    .filter((country) => {
      for (let top of topCountries) {
        if (country.countryCode === top) {
          return country;
        }
      }
    })
    .sort(
      (a, b) =>
        topCountries.indexOf(a.countryCode) -
        topCountries.indexOf(b.countryCode)
    );

  const restCountries = _.differenceBy(countries, filteredTop, "countryCode");
  const mergedCountries = [...filteredTop, ...restCountries];
  localStorage.setItem(KEYS.SHOPIFY_COUNTRIES, JSON.stringify(mergedCountries));
};

export const SetShopifyCurrencies = (currencies: ShopifyCurencies[]) => {
  const topCurrencies = ["USD", "EUR", "GBP"];
  const filteredTop = currencies
    .filter((currency) => {
      for (let top of topCurrencies) {
        if (currency.countryCode === top) {
          return currency;
        }
      }
    })
    .sort(
      (a, b) =>
        topCurrencies.indexOf(a.countryCode) -
        topCurrencies.indexOf(b.countryCode)
    );

  const restCurrencies = _.differenceBy(currencies, filteredTop, "countryCode");
  const mergedCountries = [...filteredTop, ...restCurrencies];
  localStorage.setItem(
    KEYS.SHOPIFY_CURRENCIES,
    JSON.stringify(mergedCountries)
  );
};

export const SetShopifyLanguages = (languages: ShopifyLanguages[]) => {
  const topLanguages = ["en", "de", "fr"];
  const filteredTop = languages
    .filter((language) => {
      for (let top of topLanguages) {
        if (language.languageCode === top) {
          return language;
        }
      }
    })
    .sort(
      (a, b) =>
        topLanguages.indexOf(a.languageCode) -
        topLanguages.indexOf(b.languageCode)
    );
  const restLanguages = _.differenceBy(languages, filteredTop, "languageCode");
  const mergedLanguages = [...filteredTop, ...restLanguages];
  localStorage.setItem(KEYS.SHOPIFY_LANGUAGES, JSON.stringify(mergedLanguages));
};

export const GetShopifyCountries = () => {
  let countries = JSON.parse(
    localStorage.getItem(KEYS.SHOPIFY_COUNTRIES) || "[]"
  );
  let formattedCountries: Option[] = countries.map(
    (country: { country: string; countryCode: string }) => {
      let cntr: Option = {
        value: "",
        text: "",
      };
      cntr.text = country.country;
      cntr.value = country.countryCode;

      return cntr;
    }
  );

  return formattedCountries;
};

export const GetShopifyCurrencies = () => {
  let currencies = JSON.parse(
    localStorage.getItem(KEYS.SHOPIFY_CURRENCIES) || "[]"
  );

  let formattedCurrencies: Option[] = currencies.map(
    (country: { country: string; countryCode: string }) => {
      let cntr: Option = {
        value: "",
        text: "",
      };
      cntr.text = country.countryCode;
      cntr.value = country.countryCode;

      return cntr;
    }
  );

  return formattedCurrencies;
};

export const GetShopifyLanguages = () => {
  let languages = JSON.parse(
    localStorage.getItem(KEYS.SHOPIFY_LANGUAGES) || "[]"
  );

  let formattedLanguages: Option[] = languages.map(
    (country: { language: string; languageCode: string }) => {
      let cntr: Option = {
        value: "",
        text: "",
      };
      cntr.text = country.language;
      cntr.value = country.language;

      return cntr;
    }
  );

  return formattedLanguages;
};

// Search History
export const GetHistory = (type: HISTORY_KEYS) =>
  JSON.parse(localStorage.getItem(HISTORY_KEYS[type]) || "[]");

export const SetHistory = (type: HISTORY_KEYS, newSearch: string) => {
  const list = [newSearch, ...GetHistory(type)];
  localStorage.setItem(HISTORY_KEYS[type], JSON.stringify(list));
};

export const RemoveHistoryItem = (
  type: HISTORY_KEYS,
  newSearch?: string,
  all?: boolean
) => {
  const list = GetHistory(type).filter((item: string) => newSearch !== item);
  localStorage.setItem(HISTORY_KEYS[type], JSON.stringify(all ? [] : list));
};

// disclaimer
export const GetDisclaimer = (disclaimerName: string) =>
  localStorage.getItem(disclaimerName);

export const HideDisclaimer = (disclaimerName: string) =>
  localStorage.setItem(disclaimerName, "true");
