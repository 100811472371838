import useModal from "hooks/useModal";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useUpdateWatchListMutation } from "store/api/watchListApi";
import { WatchList } from "types/services/watchList";
import SaveSearchModal from "./Modal";

interface Props {
  modal: ReturnType<useModal>;
  item: WatchList;
  isSearch?: boolean;
}

const UpdateSearch: FC<Props> = ({ modal, item, isSearch }) => {
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);
  const onRatingChange = (rating: number) => setRating(rating);
  const [update, { isLoading, isSuccess }] = useUpdateWatchListMutation();
  useEffect(() => {
    setComment(item.comment);
    setRating(item.rating);
  }, [item]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Changes Saved Successfully");
      modal.close();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  const onCommentChange = (newVal: string) => setComment(newVal);
  const onSave = () => {
    update({
      comment,
      rating,
      id: item.watchListId,
    });
  };

  return (
    <SaveSearchModal
      helpers={modal}
      rating={rating}
      onRatingChange={onRatingChange}
      comment={comment}
      onCommentChange={onCommentChange}
      isLoading={isLoading}
      modalBtnTitle={"Save Changes"}
      title={isSearch ? "Edit Search" : "Edit Competitor"}
      onSaveSearch={onSave}
    />
  );
};

export default UpdateSearch;
