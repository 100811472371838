export const noOfEntries = [
  { value: "10", text: "10 Entries" },
  { value: "25", text: "25 Entries" },
  { value: "50", text: "50 Entries" },
  { value: "100", text: "100 Entries" },
];

export const marketplaceList = [
  { value: "amazon", text: "Amazon.com" },
  { value: "amazon.co", text: "Amazon.co.uk" },
  { value: "walmart", text: "Walmart" },
  { value: "aliexpress", text: "AliExpress" },
  { value: "alibaba", text: "Alibaba" },
  { value: "ebay", text: "eBay" },
];

export const suppliers = [
  { text: "Amazon.com", value: "1" },
  { text: "Amazon.co.uk", value: "2" },
  { text: "Walmart.com", value: "4" },
];

export const getSupplierType = {
  1: "Amazon.com",
  2: "Amazon.co.uk",
  4: "Walmart.com",
};

export const marketPlaces = [
  { text: "All", value: "ALL", link: "https://www.ebay.com" },
  { text: "ebay.com", value: "EBAY-US", link: "https://www.ebay.com" },
  { text: "ebay.co.uk", value: "EBAY-GB", link: "https://www.ebay.co.uk" },
  { text: "ebay.com.au", value: "EBAY-AU", link: "https://www.ebay.com.au" },
  { text: "ebay.ca", value: "EBAY-ENCA", link: "https://www.ebay.ca" },
  { text: "ebay.fr", value: "EBAY-FR", link: "https://www.ebay.fr" },
  { text: "ebay.de", value: "EBAY-DE", link: "https://www.ebay.de" },
  { text: "ebay.it", value: "EBAY-IT", link: "https://www.ebay.it" },
  { text: "ebay.es", value: "EBAY-ES", link: "https://www.ebay.es" },
  { text: "ebay.com.my", value: "EBAY-MY", link: "https://www.ebay.com.my" },
  { text: "ebay.com.sg", value: "EBAY-SG", link: "https://www.ebay.com.sg" },
];

export const marketplacesDropdown = [
  { value: "ebay", text: "eBay" },
  { value: "aliexpress", text: "Aliexpress" },
  { value: "amazon", text: "Amazon" },
];

export const shippingLocations = [
  { text: "All Locations", value: "Worldwide" },
  { text: "United States", value: "US" },
  { text: "United Kingdom", value: "GB" },
  { text: "Canada", value: "CA" },
  { text: "Australia", value: "AU" },
  { text: "China", value: "CN" },
  { text: "France", value: "FR" },
  { text: "Germany", value: "DE" },
  { text: "Italy", value: "IT" },
  { text: "Spain", value: "ES" },
  { text: "Malaysia", value: "MY" },
  { text: "Singapore", value: "SG" },
];

export const nichesLocations = [
  { text: "United States", value: "us" },
  { text: "United Kingdom", value: "uk" },
  { text: "Germany", value: "de" },
  { text: "Australia", value: "au" },
  { text: "China", value: "cn" },
  { text: "AliExpress", value: "ali" },
];

export const saleDateRangeArr = [
  { value: "30", text: "30 Days" },
  { value: "21", text: "21 Days" },
  { value: "14", text: "14 Days" },
  { value: "7", text: "7 Days" },
];

export const listingsList = [
  { text: "All Listings", value: "all" },
  { text: "Fixed Price", value: "FixedPrice" },
  { text: "Auction", value: "Auction" },
];
export const conditionList = [
  { text: "Brand New", value: "New" },
  { text: "Used", value: "Used" },
];

export const sourceSites = [
  {
    value: "1",
    text: "Amazon.com",
  },
  {
    value: "2",
    text: "Amazon.co.uk",
  },
  {
    value: "4",
    text: "Walmart.com",
  },
];
