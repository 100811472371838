import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import Present from "assets/images/present.png";
import Button from "components/Button";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

interface Props {
    loading?: boolean;
    isCC?: boolean;
}

const RegistrationPaymentCompleted = ({ loading, isCC }: Props) => {
    const navigate = useNavigate();
    const [, setCookie, removeCookie] = useCookies(["pe-pp"])
    useEffect(() => {
        // set cookie for ebay PE dashboard popup
        const expirationTimestamp = Date.now();
        const cookieValue = {
            value: "new",
            attempt: 0,
            expiration: expirationTimestamp,
        };
        setCookie("pe-pp", JSON.stringify(cookieValue), { path: "/" });
        //
    }, []);

    const onStartClick = () => {
        navigate("/")
        if (isCC) {
            window.location.reload();
        }
    }

    return (

        <div className="w-full h-full flex justify-center items-center overflow-y-auto my-auto w-full dark:text-darkBlue h-screen bg-white">
            <div className="flex flex-col items-center justify-center bg-white w-full ">
                <h5 className="text-center mb-6 max-sm:text-xl text-[24px] font-medium">
                    You are all set!
                </h5>
                <div className="flex items-center gap-7 mb-[48px] max-sm:flex-col">
                    <div className="bg-smoothGreen rounded-full w-[35px] h-[35px] flex justify-center items-center">
                        <Checkmark className="w-[23.33px] h-[17.5px] fill-white" />
                    </div>
                    <h6 className="font-bold text-[28px]">Payment Completed</h6>
                </div>
                <img src={Present} alt="" className="w-10 h-10 mb-12" />
                <Button
                    loading={loading}
                    title="Start using ZIK!"
                    className="max-w-[300px] hover:text-white hover:bg-darkBlue"
                    titleClassName="!font-bold !text-base"
                    height="h-[64px]"
                    handleClick={onStartClick}
                />
            </div>
        </div>
    )
}

export default RegistrationPaymentCompleted