//mock data
import Button from "components/Button";
import Card from "components/Card";
import Header from "components/Header";
import HeaderTab from "components/Header/Tab";
import UploadHistory from "components/ProductManagementButtons/UploadHistory";
import { PATH } from "routes/path";
import AutopilotScanFilter from "./components/ScanFilters";

// Types

// Hooks
import useTabs from "hooks/useTabs";

// Icons
import { ReactComponent as ArrowBack } from "assets/icons/back-arrow.svg";
import ExportAll from "components/ProductManagementButtons/ExportAll";
import ExportSelected from "components/ProductManagementButtons/ExportSelected";
import UploadAll from "components/ProductManagementButtons/UploadAll";
import UploadSelected from "components/ProductManagementButtons/UploadSelect";
import Toaster from "components/Toast";
import usePagination from "hooks/usePagination";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useGetScanFiltersQuery,
  useLoadScanQuery
} from "store/api/autopilotApi";
import AutopilotScanList from "./components/lists/AutopilotScan";

enum TABS {
  bulkScanner = "bulk-scanner",
  bestSellingItems = "best-selling-items",
  aliGrowthScanner = "ali-growth-scanner",
  turboScanner = "turbo-scanner",
  autopilot = "autopilot",
}

export const listers = [
  { text: "Auto DS", value: "autods" },
  { text: "Sale Freaks", value: "sf" },
];

export const exclude = (style: boolean = false) => ([
  { text: "Exclude VeRO Brands", value: "vero", className: style ? "max-w-[241px] w-full" : "" },
  { text: "Exclude Restricted Keywords", value: "restricted", className: style ? "w-full" : "" },
]);

export const autopilotScanState = {
  lister: "autods",
  excludes: [] as string[],
  sfStoreName: "",
  sfKey: "",
};

enum SortDir {
  asc = "asc",
  desc = "desc",
}

const initialSort = {
  direction: SortDir.desc,
  column: "Sales",
};

const AutopilotScan = () => {
  const { id } = useParams();
  const [pageCount, setPageCount] = useState(0);
  const pagination = usePagination(pageCount, 50);
  const [sort, setSort] = useState(initialSort);
  const { data } = useLoadScanQuery({
    autoScanId: Number(id),
    start: pagination.current * 50,
    length: 50,
    sortColumn: sort.column,
    sortColumnDir: sort.direction,
  });
  const [uploadProgress, setUploadProgress] = useState(false);
  const onUploadProgress = () => setUploadProgress(true);
  const onUploadSuccess = () => setUploadProgress(false);

  const { data: filtersData } = useGetScanFiltersQuery(String(id));
  const { activeTab } = useTabs<TABS>(TABS.autopilot);
  const [searchParams, setSearchParams] = useSearchParams();
  const supplier = searchParams.get("sup");
  const toasterShown = useRef(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const { t } = useTranslation(['home']);
  const onSortChange = (col: string, dir: SortDir) => {
    setSort({ column: col, direction: dir });
  };

  useEffect(() => {
    if (data) {
      if (data?.result.recordsTotal !== pageCount) {
        setPageCount(data?.result.recordsTotal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (
      searchParams &&
      searchParams.get("autods") === "1" &&
      !toasterShown.current
    ) {
      toast.success("AutoDs Store successfully Added");
      setSearchParams({});
      toasterShown.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [searchParams]);

  return (
    <>
      <Header
        leftContent={
          <>
            <Button
              title="Go Back"
              isLink
              redirect={PATH.zikPro.autopilot}
              color="primaryPurple"
              height="h-9"
              iconClassName="w-3.5"
              className="max-w-[138px] shrink-0"
              StartIcon={ArrowBack}
            />

            <HeaderTab
              title={t('Autopilot')}
              className="min-w-[90px]"
              isActive={activeTab === TABS.autopilot}
            />
          </>
        }
      />
      <Card className="px-[25px] pt-5 pb-6 mb-3 shadow-shadow1" bordered>
        <div className="grid grid-cols-10 gap-y-4.5 mb-[26px] max-[1450px]:grid-cols-[repeat(auto-fill,160px)] max-[900px]:grid-cols-3 max-[650px]:grid-cols-2">
          <AutopilotScanFilter filtersData={filtersData?.result} />
        </div>
        {supplier !== "Ali" &&
          <div className="flex items-center justify-start gap-10 flex-wrap max-[1229px]:gap-5">
            <UploadAll type="AutoPilot" onUploadProgress={onUploadProgress} />
            <UploadSelected
              type="AutoPilot"
              selectedItems={selectedItems}
              onUploadProgress={onUploadProgress}
              allChecked={false}
            />
            <ExportAll type="AutoPilot" />
            <ExportSelected
              selectedItems={selectedItems}
              type="AutoPilot"
              allChecked={false}
            />
            <UploadHistory
              type="AutoPilot"
              uploadProgress={uploadProgress}
              onUploadSuccess={onUploadSuccess}
            />
          </div>
        }

      </Card>

      <AutopilotScanList
        pagination={pagination}
        sort={sort}
        changeSelectedItems={(selectedIds) => setSelectedItems(selectedIds)}
        onSortChange={onSortChange}
        selectedIds={selectedItems}
      />
      <Toaster />
    </>
  );
};

export default AutopilotScan;
