import { createSlice } from "@reduxjs/toolkit";
import { prApi } from "store/api/prApi";
import { PostPrAliResponse, PostPrEbayResponse } from "types/services/pr";
import {
  prAliData,
  prAliFilters,
  prEbayData,
  prEbayFilters,
} from "../initialValues/pr";

interface PrFilterChangePayload {
  marketplace: "aliexpress" | "ebay";
  field: keyof typeof prAliFilters | keyof typeof prEbayFilters;
  type?: "min" | "max";
  value: string | number;
}
export const initiaState = {
  filters: {
    search: "",
    aliexpress: prAliFilters,
    ebay: prEbayFilters,
  },

  ebayData: { result: prEbayData, success: false } as PostPrEbayResponse,
  aliData: { result: prAliData, success: false } as PostPrAliResponse,
};

const prSlice = createSlice({
  name: "prSlice",
  initialState: initiaState,
  reducers: {
    setPrEbayFilters: (state, { payload }) => {
      state.filters.ebay = payload;
    },
    handlePrSearchChange: (state, { payload }) => {
      state.filters.search = payload;
    },
    setAliInitialFilters: (state, { payload }) => {
      state.filters.aliexpress = payload;
    },
    handlePrFilterChange: (
      state,
      { payload }: { payload: PrFilterChangePayload }
    ) => {
      const { marketplace, field, type, value } = payload;
      // @ts-ignore
      state.filters[marketplace][field] =
        type === "min" || type === "max"
          ? {
              // @ts-ignore
              ...state.filters[marketplace][field],
              ...(type && { [type]: value }),
            }
          : value;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      prApi.endpoints.prEbayFilter.matchFulfilled,
      (state, { payload }) => {
        state.ebayData = payload;

        return state;
      }
    );
    builder.addMatcher(
      prApi.endpoints.prAliFilter.matchFulfilled,
      (state, { payload }) => {
        state.aliData = payload;

        return state;
      }
    );
  },
});

export const {
  handlePrFilterChange,
  handlePrSearchChange,
  setPrEbayFilters,
  setAliInitialFilters,
} = prSlice.actions;

export default prSlice.reducer;
