import Toaster from "components/Toast";
import AliexpressSearches from "../lists/SavedSearches/AliexpressSearches";
import EbaySearches from "../lists/SavedSearches/EbaySearches";

const SavedSearches = () => (
  <>
    <EbaySearches />
    <AliexpressSearches />
    <Toaster />
  </>
);

export default SavedSearches;
