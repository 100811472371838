type options = Record<string, any>;

export const makeQueryParams = (
  url: string,
  options: options,
  source?: string
): string => {
  const keyValues = Object.entries(options);

  if (keyValues.length) {
    let result = `${url}?`;
    source === "scan" && (result = url);

    keyValues.forEach(([key, value]) => {
      if (
        typeof value === "string" ||
        typeof value === "number" ||
        Array.isArray(value) ||
        typeof value === "boolean"
      ) {
        if (Array.isArray(value)) {
          result = `${result}${key}=${value.join(",")}&`;
        } else {
          result = `${result}${key}=${value}&`;
        }
      }
    });

    result = result.slice(0, result.length - 1);

    return result;
  }

  return url;
};
