import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { ReactComponent as Close } from "assets/icons/clear-input.svg";
import Button from "components/Button";
import { FC } from "react";
import { TSelectedPlan } from "types/plans";
import { formatCurrency } from "utils/formatCurrency";

export interface Props {
    name: string;
    skipAmount?: number;
    id: string;
    price: number;
    discountedPrice: number;
    handleSelectPlan: (plan: TSelectedPlan) => void;
    isEbay?: boolean;
    isPopular?: boolean;
    type: string;
    period?: string;
    wrapperClassName?: string;
    features: {
        Icon: React.FC<React.SVGProps<SVGSVGElement>>
        title: string;
        subtitle: string;
        width?: number;
        height?: number;
        pro?: boolean | string;
        proPlus?: boolean | string;
        enterprise?: boolean | string;
    }[]
}

const PlansDetailedB: FC<Props> = ({ name, price, discountedPrice, period, type, skipAmount, id, features, isEbay = false, isPopular, handleSelectPlan, wrapperClassName }) => {
    const isShopifyYearly = id === "yearly"
    const isEbayYearly = period === "yearly"
    const isEbayMonthly = period === "monthly"
    const checkDisableId = id.replace("-p", "P") as "pro" | "proPlus" | "enterprise";
    const uppercaseTitle = isEbay && id !== "enterprise" ? "uppercase" : ""
    const crossedPriceStlyes = "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full h-[2px] bg-errorRed w-[64px]"
    const onStartClick = (skip: boolean) => handleSelectPlan({
        type: type,
        planName: name,
        planPrice: price,
        skip: skip
    })
    return (
        <div className={`rounded-lg border border-secondary flex w-[310px] p-[15px] flex-col justify-center items-center gap-4 ${wrapperClassName}`}>
            {isEbay ? <h5 className={`w-full text-left text-[20px] font-bold ${uppercaseTitle}`}>{name}</h5> : ""}
            <div className="flex flex-col w-full gap-[15px]">
                {features.map((feature) =>
                    <div className={`flex items-center w-full ${isEbay && !feature[checkDisableId] ? "text-grey300" : ""}`} key={feature.title}>
                        <div className="w-full">
                            <div className="flex items-center gap-[5px] mb-[5px]">
                                <div className="w-6 h-6 flex items-center justify-center">
                                    <feature.Icon className={isEbay && !feature[checkDisableId] ? "fill-grey300" : "fill-darkBlue"} width={feature.width} height={feature.height} />
                                </div>
                                <h6 className="text-sm font-bold leading-[20px]">
                                    {feature.title}
                                </h6>
                            </div>
                            <p className={`text-xs ${isEbay ? "font-normal" : "font-medium"}`}
                                dangerouslySetInnerHTML={{ __html: feature.subtitle }}
                            />
                        </div>
                        <div className="min-w-[24px] ml-1 h-6 flex justify-center items-center">
                            {isEbay && !feature[checkDisableId] ? <Close className="fill-grey300 w-3 h-3" /> : <Checkmark className="fill-smoothGreen w-3 h-[9px]" />}
                        </div>
                    </div>)}
            </div>
            <p className="w-full bg-neutral2 rounded-[5px] leading-[28px] text-[10px] font-medium text-center">
                VIP Community + Training + Live Chat
            </p>
            <div className={`w-full flex p-2.5  items-center flex-col justify-center rounded-lg border-2  ${isShopifyYearly || isPopular ? "bg-darkBlue text-white border-smoothGreen" : "bg-white border-neutral2"}`}>
                <div className={`flex justify-between w-full text-lg ${isShopifyYearly ? "mb-2" : isEbayMonthly ? "flex-col items-center mb-[5px]" : "mb-4"}`}>
                    <p className={`font-bold !leading-[28px] ${isEbayMonthly ? "w-full text-left" : ""} ${uppercaseTitle}`}>
                        {name}
                    </p>
                    {isEbayMonthly ? <div className="text-center mt-[5px]">
                        <p className="relative text-sm">
                            {formatCurrency(price)}
                            <span className={crossedPriceStlyes}></span>
                        </p>
                        <p className="font-bold text-[24px] leading-[32px]">
                            {formatCurrency(discountedPrice)}
                        </p>
                        <p className={`text-xs ${isPopular ? "text-smoothGreen" : "text-primaryPurple"}`}>for 1st month</p>
                    </div> : <div className="flex items-center">
                        {isEbayYearly ? <p className="w-[64px] text-center text-lg relative mr-2">
                            {formatCurrency(price)}
                            <span className={crossedPriceStlyes}></span>
                        </p> : ""}
                        <p className="font-bold text-[20px] leading-[28px]">
                            {formatCurrency(discountedPrice)}
                        </p>
                        {!isEbay && <p className={`${isShopifyYearly ? "text-grey200 font-normal text-xs leading-[16px] mt-1" : "font-medium text-[10px] leading-[12px] ml-1"}`}>
                            {isShopifyYearly ? "month" : " for 1st month"}
                        </p>}
                    </div>}

                </div>
                {isShopifyYearly ? (
                    <p className="w-full text-right text-xs text-grey100 font-normal mb-4">
                        {formatCurrency(discountedPrice * 12 + 0.11)}/year
                    </p>
                ) : ""}
                <Button
                    title="Start $1 Trial Now"
                    height="h-[56px]"
                    titleClassName="!text-base font-medium"
                    color="smoothGreen"
                    handleClick={() => onStartClick(false)}
                    className={`w-full max-w-[226px] mb-2 bg-white shadow-[2px_5px_30px_-4px_rgba(28,_163,_147,_0.15)]`}
                />
                <button
                    onClick={() => onStartClick(true)}
                    className={`text-xs underline leading-4 ${isShopifyYearly || isPopular ? "text-secondary" : "text-electricBlue"}`}>
                    Skip Trial {skipAmount === 0 ? "Now!" : `and Save ${formatCurrency(String(skipAmount))}`}
                </button>
            </div>

        </div>
    );
};

export default PlansDetailedB;