import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import Button from "components/Button";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import { useLazyValidateCouponQuery } from "store/api/managePaymentApi";
import Input from ".";

interface Props {
  handleApplyCoupon: (newVal: string) => void;
  selectedPlan: string;
  preloadCoupon?: string;
  fromRegistration?: boolean
  couponValidating?: (validating: boolean) => void;
  handleRefetchUpgrade?: () => void;
  isShopify?: boolean;
  changeCouponUsed?: (isUsed: boolean) => void;
}

const CheckoutCouponInput = ({
  handleApplyCoupon,
  selectedPlan,
  preloadCoupon,
  fromRegistration,
  couponValidating,
  handleRefetchUpgrade,
  changeCouponUsed
}: Props) => {
  const { pathname } = useLocation();
  const [validateSuccess, setValidateSuccess] = useState(false);
  const isUpcampaign = pathname.includes(PATH.managementPanel.upcampaign)
  const [defaultCoupon, setDefaultCoupon] = useState<string | null>(null)
  const [cookies] = useCookies(['ReferrerId']);
  const [coupon, setCoupon] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState("")
  const [couponToggle, setCouponToggle] = useState(false)
  const [validateCoupon, { isFetching }] = useLazyValidateCouponQuery();

  const onDefaultCouponChange = (val: string) => {
    setDefaultCoupon(val)
    setCoupon(val)
    setAppliedCoupon(val);
    if (val !== "") {
      setCouponToggle(true)
    }
  }

  const onApplyCoup = (val: string) => {
    setAppliedCoupon(val);
    handleApplyCoupon(val);
    setCoupon(val);
  };

  useEffect(() => {
    onDefaultCouponChange(preloadCoupon || "");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preloadCoupon])

  useEffect(() => {
    if (isUpcampaign && (selectedPlan === "StarterY" || selectedPlan === "StandardY" || selectedPlan === "EnterpriseY")) {
      onDefaultCouponChange("SUCCESS24")
    }
  }, [isUpcampaign, selectedPlan])

  useEffect(() => {
    const { ReferrerId } = cookies
    if (ReferrerId && fromRegistration) {
      const val = ReferrerId === "138070" ? "MORF2021" : ReferrerId === "166215" ? "1STMILLION" : ReferrerId === "216205" ? "THAER2021" : ""
      if (val !== "") {
        onDefaultCouponChange(val)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.ReferrerId])

  const applyCoupon = () => {
    if (coupon === null || coupon?.trim().length === 0) {
      handleApplyCoupon("");
    }

    const onSuccess = (res: boolean) => {
      if (res === true) {
        onApplyCoup(coupon);
      } else {
        onApplyCoup("");
      }
    }
    const onError = () => onApplyCoup("");

    onValidateRun(onSuccess, onError);
  };

  const onValidateRun = (onSuccess: (res: boolean) => void, onError: (errorMsg: string) => void, coup?: string) => {
    const applyingCoupon = coup === undefined ? coupon : coup
    couponValidating?.(true);
    validateCoupon({
      couponName: applyingCoupon,
      subscriptionType: selectedPlan,
    })
      .unwrap()
      .then((data) => {
        onSuccess(data?.result);
        setValidateSuccess(true);
        couponValidating?.(false)
        setTimeout(() => {
          setValidateSuccess(false);
        }, 4000);
      }).catch((error) => {
        const msg = error.data.message
        toast.error(msg);
        onError(msg);
        couponValidating?.(false)
      })
  }

  useEffect(() => {
    if (defaultCoupon !== null && coupon !== "") {
      const onSuccess = (res: boolean) => {
        if (res === true) {
          handleApplyCoupon(coupon);
        } else {
          onApplyCoup("");
          handleRefetchUpgrade?.()
        }
      }
      const onError = (error: string) => {
        if (error.includes("Exclusive Coupon")) {
          changeCouponUsed?.(true);
        } else {
          changeCouponUsed?.(false)
        }
        onApplyCoup("");
        handleRefetchUpgrade?.();
        setCouponToggle(false);
      }

      onValidateRun(onSuccess, onError);
    } else {
      couponValidating?.(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCoupon]);

  const handleClearClick = () => {
    onApplyCoup("")
    const onSuccess = (res: boolean) => {
      if (res === true) {
        onApplyCoup("");
      } else {
        onApplyCoup("");
      }
    }
    const onError = () => onApplyCoup("");

    onValidateRun(onSuccess, onError, "");
  }

  return (
    <div className={`max-sm:max-w-[335px] max-sm:w-full flex flex-col ${fromRegistration ? "items-start" : "max-sm:mx-auto"}`}>
      <button
        className={`font-normal cursor-pointer select-none sm:text-left text-sm mb-[9px] max-sm:mb-0 ${couponToggle ? "text-darkBlue dark:text-grey100" : "text-grey400"}`}
        onClick={() => setCouponToggle(!couponToggle)}
      >
        Got a Coupon?
      </button>
      <div className={couponToggle ? `flex max-sm:flex-col items-center gap-4 mt-2` : "hidden"}>
        <div>
          <Input
            className={`h-9 w-full !pb-[0px] max-sm:px-1 dark:bg-black900 max-w-[194px] min-w-[194px] ${validateSuccess ? "!text-success" : ""}`}
            eraseClassName={"!top-1/2 -translate-y-1/2"}
            inputClassName="max-sm:pr-0 !pb-[0px]"
            value={coupon}
            erase={!validateSuccess}
            CustomIcon={validateSuccess ? Checkmark : undefined}
            customIconStyles="fill-success min-w-[8px] max-w-[8px] h-1.5 block mr-1"
            variant="outlined"
            name="coupon"
            onClearClick={handleClearClick}
            placeholder="Coupon code"
            handleChange={(newVal) => setCoupon(newVal)}
          />
        </div>
        <Button
          loading={isFetching}
          className={`w-full dark:bg-deepSpace900 min-w-[143px] max-w-[143px] ${appliedCoupon === coupon && appliedCoupon !== "" ? "invisible max-sm:hidden" : ""}`}
          height="h-9"
          title="Apply"
          handleClick={applyCoupon}
        />
      </div>
    </div >
  );
};

export default CheckoutCouponInput;
