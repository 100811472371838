import { ReactComponent as ItemFinder } from "assets/icons/boxes/box.svg";
import { ReactComponent as CategoryResearch } from "assets/icons/category-research.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Info } from "assets/icons/info.svg";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as ProductExplorer } from "assets/icons/product-exlorer.svg";
import { ReactComponent as ProductResearch } from "assets/icons/product-research.svg";
import { ReactComponent as TitleBuilder } from "assets/icons/title-builder.svg";
import Tooltip from "components/Tooltip";
import { FC } from "react";

const planFeatures = [{
  title: "Market Insights",
  Icon: <Logo className="w-[17px]" />,
  desc: "Find the best products to maximize sales"
}, {
  title: "Product Research",
  Icon: <ProductResearch className="w-[15px] fill-primaryPurple dark:fill-purple500" />,
  desc: <span>Find the hottest products and increase your store's profits</span>,
}, {
  title: "Competitor Research",
  Icon: <CompetitorResearch className="fill-primaryPurple dark:fill-purple500 w-[15px]" />,
  desc: <span>Get current intel on competitors products and strategies</span>
},
{
  title: "Category Research",
  Icon: <CategoryResearch className="fill-primaryPurple dark:fill-purple500 w-[15px]" />,
  desc: <span>See top selling items and sellers in any eBay category</span>
},
{
  title: "Title Builder",
  Icon: <TitleBuilder className="fill-primaryPurple  dark:fill-purple500 w-[15px]" />,
  desc: <span>Know and use all the keywords to drive traffic and sales</span>
},
{
  title: "Item Finder",
  Icon:
    <ItemFinder className="fill-primaryPurple dark:fill-purple500 w-[15px]" />,
  desc: "Find and cross-compare eBay items with products on AliExpress, Alibaba, Amazon and Walmart."
},
{
  title: "Product Explorer",
  isNew: true,
  limit: "20 searches / month",
  infoTooltip: "Limited to 20 searches a month. Upgrade later for Unlimited!",
  Icon:
    <ProductExplorer className="fill-primaryPurple dark:fill-purple500 w-[18px] h-[19px]" />,
  desc: "Find best selling eBay products in seconds"
},
]

interface Props {
  isCurrentTrial?: boolean;
}
const Pro: FC<Props> = ({ isCurrentTrial }) => (
  <div className="flex flex-col gap-2 mx-auto">
    <div className="font-medium text-sm text-left px-2">Key Benefits:</div>
    <div className="flex flex-col gap-2">
      {planFeatures.map(({ isNew, limit, title, desc, Icon, infoTooltip }, idx) => (
        <div key={idx} className={`flex gap-[5px] ${isNew && !isCurrentTrial ? "bg-neutral1 p-[7px] pl-[0px] border border-solid border-grey100 rounded-[8px]" : ""}`}>
          <div className={`flex h-[30px] min-w-[30px] items-center justify-center ${limit ? "mt-[5px]" : ""}`}>
            {Icon}
          </div>
          <div className="w-full">
            <div className={`flex items-center justify-between`} >
              <div className={`flex items-center ${limit ? "mb-1" : ""}`}>
                <p className={`text-sm font-bold ${limit ? "" : "leading-[30px] "}`}>{title}</p>
                {infoTooltip ? (
                  <div className="h-3">
                    <Info className={"info-icon w-3 h-3 ml-2 cursor-pointer"} data-tooltip-id={title + limit} />
                    <Tooltip title={infoTooltip} anchorId={title + limit} className="text-center" maxWidth={"max-sm:max-w-[200px]"} />
                  </div>
                ) : ""}
              </div>

              {isNew && (
                <div className="bg-[#C93A1B] text-white font-medium h-5 leading-[20px] text-xs w-[46px] text-center rounded-[4px] ml-2">New!</div>
              )}
            </div>
            <div className="text-xs">
              {limit && (
                <p className="text-primaryPurple font-medium mb-2">{limit}</p>
              )}
              <p className="font-body">{desc}</p>
            </div>
          </div>
        </div>
      ))}
    </div>

  </div>
);

export default Pro;
