import { FC } from "react";

type Props = {
  value: string;
  handleChange: (newVal: string) => void;
  placeholder?: string;
  className?: string;
};

const Textarea: FC<Props> = ({
  value,
  placeholder,
  className,
  handleChange,
}) => (
  <textarea
    value={value}
    onChange={(e) => handleChange(e.target.value)}
    placeholder={placeholder || ""}
    className={`w-full focus:outline-none 
  bg-white border-2 rounded-[15px] border-secondary font-normal p-2 text-sm placeholder:font-normal dark:bg-deepSpace900 dark:border-grey700 dark:text-grey100 ${className}`}
  />
);

export default Textarea;
