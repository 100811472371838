import { FC, useState } from "react";
import StepOne from "./steps/StepOne";
import StepThree from "./steps/StepThree";
import StepTwo from "./steps/StepTwo";

interface Props {
    isCC?: boolean;
}
const ShopifyAffiliateFunnel: FC<Props> = ({ isCC }) => {
    const [step, setStep] = useState(1);
    const handleStepChange = (step: number) => {
        setStep(step);
    }
    const sameProps = {
        handleStepChange,
        isCC
    }
    return (
        <>
            <div className="flex min-h-full relative">
                <img src="https://za01.groupbuy.pk/_zikmedia/current_zik_dashboard.svg" alt="ZIK Dashboard" className="min-h-screen min-w-screen bg-cover w-full max-lg:hidden" />
                <div className="absolute top-0 left-0 w-full h-full bg-[#808080]/30"></div>
            </div>
            <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center">
                {step === 2 ? <StepTwo {...sameProps} /> : step === 3 ? <StepThree {...sameProps} /> : <StepOne {...sameProps} />}

            </div>
        </>
    )
}

export default ShopifyAffiliateFunnel