import { FC, ReactNode } from "react";
import Loading from ".";

interface Props {
  loading: boolean;
  children: ReactNode;
  width?: number;
  height?: number;
  contMinHeight?: string;
}
const CenteredLoading: FC<Props> = ({
  loading,
  children,
  width,
  height,
  contMinHeight,
}) => (
  <div
    className={
      loading
        ? `w-full h-full flex items-center justify-center ${contMinHeight || ""
        }`
        : "w-full h-full"
    }
  >
    {loading ? (
      <Loading
        fill="fill-primaryPurple dark:fill-purple500"
        width={width || 48}
        height={height || 48}
      />
    ) : (
      children
    )}
  </div>
);

export default CenteredLoading;
