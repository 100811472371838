import { affiliate_landing } from "assets/data/ManagementPanel/affiliateManager";
import Button from "components/Button";
import { FC } from "react";

interface ItemProps {
  img: string;
  link?: string;
  id: string;
  pageTitle: string;
  onSelect: (id: string) => void;
}
interface Props {
  onSelect: (id: string) => void;
}

const LandingItem: FC<ItemProps> = ({ img, link, pageTitle, id, onSelect }) => (
  <div className="px-[22.5px] max-xl:w-1/2 max-lg:w-full  h-full">
    <div className="flex flex-col justify-between w-full items-center h-full gap-2.5">
      <img src={img} alt={pageTitle} className="max-w-[349px] shadow-shadow2 rounded-[5px]" />
      <h5 className="font-bold px-4 text-center text-sm dark:text-grey100">{pageTitle}</h5>
      <div className="flex items-center gap-6 px-8 w-full">
        <Button
          title="Preview"
          redirect={link}
          isLink
          target="_blank"
          className="dark:bg-deepSpace900"
          height="h-7"
        />
        <Button title="Choose" height="h-7" handleClick={() => onSelect(id)} className="dark:bg-deepSpace900" />
      </div>
    </div>
  </div>
);

const ChooseLanding: FC<Props> = ({ onSelect }) => (
  <div className="flex items-center justify-center gap-y-[30px] flex-wrap max-h-[640px]  scrollbar mr-2 overflow-y-auto pb-12  ">
    {affiliate_landing.map((item) => (
      <LandingItem {...item} key={item.id} onSelect={onSelect} />
    ))}
  </div>
);

export default ChooseLanding;
