import { useState } from "react";

export type handleSortType = (
  field: string,
  type?: "number" | "string" | undefined
) => void;

export enum SortDir {
  asc = "asc",
  des = "des",
}

const defaultInitialSort = {
  direction: SortDir.asc,
  column: "",
};
interface Sort {
  direction: SortDir;
  column: string;
}

const useSort = (initialSort?: Sort) => {
  const [sort, setSort] = useState<Sort>(initialSort || defaultInitialSort);

  const handleSort = (column: string) => {
    setSort((prev) => ({
      column,
      direction:
        prev.column === column
          ? prev.direction === SortDir.des
            ? SortDir.asc
            : SortDir.des
          : SortDir.asc,
    }));
  };

  return { sort, handleSort, isAsc: sort.direction === SortDir.asc };
};

export default useSort;
