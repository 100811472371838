import Button from "components/Button";
import Input from "components/Input";
import SocialIcons from "components/SocialIcons";
import { GOOGLE_CAPTCHA_KEY } from "config/constants";
import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import {
  useLazyRequestPasswordQuery,
  useLazyResetPasswordQuery
} from "store/api/authApi";
import LoginLayout from "./LoginLayout";

interface Props {
  isRequest?: boolean;
  isReset?: boolean;
}

const isValidEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

const ResetPassword = ({ isRequest, isReset }: Props) => {
  const inputClassName = "h-[44px] max-md:h-[48px] !py-[5px] px-[17px] border-2 rounded-[7px] after:content-none";
  const labelClassName = "text-lg font-bold leading-[28px]"
  const buttonText = isRequest ? "Send Reset Instructions" : "Change Password";
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const [guid, setGuid] = useState("");
  const [state, setState] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [recaptchaErr, setRecatchaErr] = useState("");

  useEffect(() => {
    if (isReset) {
      const paramGuid = queryParams.get("guid");
      if (paramGuid === null || paramGuid === "") {
        navigate("/");

        return;
      }

      setGuid(paramGuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (field: string, newVal: string) => {
    setState({
      ...state,
      [field]: newVal,
    });
  };

  const [requestPassword, { isLoading: requestLoading }] =
    useLazyRequestPasswordQuery();

  const [resetPassword, { isLoading: resetLoading }] =
    useLazyResetPasswordQuery();

  const handleRequest = () => {
    if (state.email === "") {
      toast.error("Please provide an email address");

      return;
    }

    if (!isValidEmail(state.email)) {
      toast.error("Please provide valid email address");

      return;
    }

    const recaptchaValue = recaptchaRef.current?.getValue();
    if (recaptchaValue === "" || recaptchaValue === null) {
      setRecatchaErr("Captcha is required");

      return;
    }
    toast.success("Please check your email for reset instructions")

    requestPassword(state.email)
      .unwrap()
      .then((data) => {
        if (data.result.success === false) {
          toast.error(data.result.message);

          return;
        }
        window.location.replace(PATH.login);
      });
  };

  const handleReset = () => {
    if (state.password === "" || state.confirmPassword === "") {
      toast.error("Please provide Password and Confirm Password");

      return;
    }

    if (state.password.length < 5) {
      toast.error("Password must have minimum 5 characters.");

      return;
    }

    if (state.confirmPassword !== state.password) {
      toast.error("Password and Confirm password does not match!");

      return;
    }

    resetPassword({
      guid: guid,
      password: state.password,
    })
      .unwrap()
      .then((data) => {
        if (data.result.success === false) {
          toast.error(data.result.message);

          return;
        }

        toast.success("You have successfully changed your password!");
        setTimeout(() => {
          navigate(PATH.login);
        }, 3000);
      });
  };

  const onCaptchaChange = (value: string | null) => {
    if (value !== "" || value !== null) {
      setRecatchaErr("");
    }
  }

  return (
    <LoginLayout>
      <div className="mx-auto max-w-[365px] max-md:max-w-[330px] w-full">
        <h5 className="text-[28px] leading-[36px] font-medium mb-[35px] max-sm:!text-center max-sm:text-2xl !text-left">Reset Password</h5>
        {isRequest ? (
          <>
            <div className="flex flex-col gap-3.5">
              <label htmlFor="email" className={labelClassName}>Email</label>
              <Input
                className={inputClassName}
                type="email"
                handleChange={(newVal) => handleChange("email", newVal)}
                value={state.email}
                fontSize="text-base"
                placeholder="Enter your email"
                name="email" />
            </div>
            <div className="flex flex-col justify-center items-center mt-[12px]">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={GOOGLE_CAPTCHA_KEY}
                onChange={onCaptchaChange} />
              {recaptchaErr !== "" && <div className="text-errorRed text-xs mt-2 ">{recaptchaErr}</div>}
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col gap-3.5 mb-[25px]">
              <label htmlFor="password" className={labelClassName}>Password</label>
              <Input
                className={inputClassName}
                type="password"
                handleChange={(newVal) => handleChange("password", newVal)}
                value={state.password}
                placeholder="Enter your new password"
                name="password"
                togglePassword
              />
            </div>
            <div className="flex flex-col gap-3.5">
              <label htmlFor="confirmPassword" className={labelClassName}>Confirm Password</label>
              <Input
                className={inputClassName}
                type="password"
                handleChange={(newVal) =>
                  handleChange("confirmPassword", newVal)
                }
                value={state.confirmPassword}
                placeholder="Confirm your new password"
                name="confirmPassword"
                togglePassword
              />
            </div>
          </>
        )}
        <div className="w-full flex justify-center">
          <Button
            handleClick={() => (isRequest ? handleRequest() : handleReset())}
            title={buttonText}
            loading={requestLoading || resetLoading}
            color="primaryPurple"
            height="h-[44px] max-md:h-[48px]"
            titleClassName="!text-base font-bold"
            className="w-full my-[25px] text-darkblue dark:text-lilac400"
          />
        </div>
        <div className="flex justify-center items-center">
          <SocialIcons />
        </div>
      </div>
    </LoginLayout>
  );
};

export default ResetPassword;
