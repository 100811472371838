import { createApi } from "@reduxjs/toolkit/query/react";
import {
  PrevScanFiltersRes,
  TurboScanFiltersRes,
} from "types/services/autopilot";
import {
  DeleteSelectedScanItemReq,
  GetTurboCats,
  GetTurboScannerHistoryReq,
  GetTurboScannerHistoryRes,
  LoadTurboResultsReq,
  LoadTurboResultsRes,
  PostLoadTurboResultsRes,
  PostLoadTurboScanReq,
  PostLoadTurboScanRes,
} from "types/services/turboScanner";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";
import { queryTags } from "./queryTags";

export const turboScannerApi = createApi({
  reducerPath: "TurboScannerApi",
  baseQuery,
  tagTypes: [queryTags.turboScanner],
  endpoints: (builder) => ({
    turboLoadScanHistory: builder.query<
      GetTurboScannerHistoryRes,
      GetTurboScannerHistoryReq
    >({
      query: (params) => ({
        url: queryKeys.zikPro.turboScanner.loadHistory,
        method: "GET",
        params,
      }),
    }),
    turboLoadScan: builder.mutation<PostLoadTurboScanRes, PostLoadTurboScanReq>(
      {
        query: (body) => ({
          url: queryKeys.zikPro.turboScanner.load,
          method: "POST",
          body,
        }),
        invalidatesTags: [queryTags.turboScanner],
      }
    ),
    turboLoadResults: builder.query<LoadTurboResultsRes, LoadTurboResultsReq>({
      query: (body) => ({
        url: queryKeys.zikPro.turboScanner.loadResults,
        method: "POST",
        body,
      }),
      providesTags: [queryTags.turboScanner],
    }),
    turboScanHistory: builder.query<PostLoadTurboResultsRes, void>({
      query: () => ({
        url: queryKeys.zikPro.turboScanner.scanHistory,
        method: "GET",
      }),
      providesTags: [queryTags.turboScanner],
    }),
    turboDeleteAll: builder.mutation<null, void>({
      query: (body) => ({
        url: queryKeys.zikPro.turboScanner.deleteAllScans,
        method: "DELETE",
        body,
      }),
      invalidatesTags: [queryTags.turboScanner],
    }),
    turboGetCats: builder.query<GetTurboCats, string>({
      query: (type) => ({
        url: queryKeys.zikPro.turboScanner.getCategories,
        method: "GET",
        params: { type },
      }),
    }),
    turboScanFilters: builder.query<TurboScanFiltersRes, string>({
      query: (scanId) => ({
        url: queryKeys.zikPro.turboScanner.getScanFilters,
        params: { scanId },
        method: "GET",
      }),
    }),
    turboScanPrevFilters: builder.query<PrevScanFiltersRes, void>({
      query: () => ({
        url: queryKeys.zikPro.turboScanner.getPrevScanFilters,
        method: "GET",
      }),
    }),
    turboDelete: builder.mutation<null, number>({
      query: (scanId) => ({
        url: queryKeys.zikPro.turboScanner.deleteScan,
        params: { scanId },
        method: "DELETE",
      }),
      invalidatesTags: [queryTags.turboScanner],
    }),
    turboDeleteScanItem: builder.mutation<null, DeleteSelectedScanItemReq>({
      query: (body) => ({
        url: queryKeys.zikPro.turboScanner.deleteScanItem,
        method: "DELETE",
        body,
      }),
      invalidatesTags: [queryTags.turboScanner],
    }),
  }),
});

export const {
  useTurboDeleteScanItemMutation,
  useTurboLoadResultsQuery,
  useTurboLoadScanHistoryQuery,
  useTurboLoadScanMutation,
  useTurboGetCatsQuery,
  useTurboScanHistoryQuery,
  useTurboScanFiltersQuery,
  useTurboScanPrevFiltersQuery,
  useTurboDeleteAllMutation,
  useTurboDeleteMutation,
} = turboScannerApi;
