import { createApi } from "@reduxjs/toolkit/query/react";
import {
  GetCompetitorsRes,
  GetEbayTrendsRes,
  GetEbayWeeklyBestSellersRes,
} from "types/services/dashboard";
import baseQuery from "./baseQuery";
import { queryKeys } from "./queryKeys";

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery,
  endpoints: (builder) => ({
    getEbayWeeklyBestSellers: builder.query<GetEbayWeeklyBestSellersRes, void>({
      query: () => ({
        url: queryKeys.dashboard.getEbayWeeklyBestSellers,
        method: "GET",
      }),
    }),
    getCompetitors: builder.query<GetCompetitorsRes, void>({
      query: () => ({
        url: queryKeys.dashboard.getCompetitors,
        method: "GET",
      }),
    }),
    getEbayTrends: builder.query<GetEbayTrendsRes, void>({
      query: () => ({
        url: queryKeys.dashboard.getEbayTrends,
        method: "GET",
      }),
    }),
    getStore: builder.query<void, void>({
      query: () => ({
        url: queryKeys.dashboard.getStore,
        method: "GET",
      }),
    }),
    getChallenge: builder.query<void, void>({
      query: () => ({
        url: queryKeys.dashboard.getChallange,
        method: "GET",
      }),
    }),
    getStats: builder.query<void, void>({
      query: () => ({
        url: queryKeys.dashboard.getStats,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetStatsQuery,
  useGetChallengeQuery,
  useGetStoreQuery,
  useGetEbayTrendsQuery,
  useGetCompetitorsQuery,
  useGetEbayWeeklyBestSellersQuery,
} = dashboardApi;
