import { PlanList, PlansType, TSelectedPlan } from "types/plans";
import NextPrevSlider from "./NextPrevSlider";
import Plan from "./Plan";

import { SwiperSlide } from "swiper/react";

import SwiperWrapper from "components/SwiperWrapper";
import { useState } from "react";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import "swiper/css/pagination";


interface Props {
    plans: PlansType;
    plansWrapperClassName?: string;
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    handleProToolUpgrade?: () => void;
    fromRegistration?: boolean;
    isPayNow?: boolean;
    showTwoPlans?: boolean;
}


const SlidedPlans = ({
    plans,
    fromRegistration = false,
    plansWrapperClassName,
    handleSelectPlan,
    handleProToolUpgrade,
    showTwoPlans,
    isPayNow,
}: Props) => {
    const [activeSlide, setActiveSlide] = useState(1);
    const onActiveSlideChange = (idx: number) => setActiveSlide(idx);
    const plansProps = (plan: PlanList) => ({
        plan,
        handleSelectPlan,
        handleProToolUpgrade,
        fromRegistration,
        isPayNow,
    })
    return (
        <div className="w-full">
            <div className={`flex justify-between w-full max-md:hidden gap-6 mx-auto max-md:px-2 ${plansWrapperClassName || ""}`}>
                {plans.planList.map((plan, i) => (
                    <Plan
                        {...plansProps(plan)}
                        key={i}
                    />

                ))}
            </div>
            <div
                className={`w-full md:hidden ${plansWrapperClassName || ""}`}
            >
                <SwiperWrapper
                    onActiveChange={onActiveSlideChange}
                >
                    {plans.planList.map((plan, i) => (
                        <SwiperSlide className="max-w-[350px]" content="fit" key={i}>
                            <Plan
                                {...plansProps(plan)}
                            />
                        </SwiperSlide>

                    ))}
                    <NextPrevSlider activeSlide={activeSlide} showTwoPlans={showTwoPlans} />
                </SwiperWrapper>
            </div>
        </div>)
};

export default SlidedPlans;
