import { shopifyPlanComparison } from "assets/data/plans/shopifyPlans";
import { useState } from "react";
import { ShopifyPlanList, TSelectedPlan } from "types/plans";
import ComparisonHeader from "./ComparisonHeader";
import ComparsionMobileTabs from "./ComparisonMobileTabs";
import PlanComparisonFooter from "./PlanComparisonFooter";
import PlansComparisonItem from "./PlansComparisonItem";

interface Props {
    plans: ShopifyPlanList[];
    isProTools?: boolean;
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    fromRegistration?: boolean;
    selectedPlan?: string;
    isPayNow?: boolean;
    wrapperClassName?: string;
}

const ShopifyPlansComparison = ({
    plans,
    isProTools,
    handleSelectPlan,
    fromRegistration = false,
    isPayNow,
    wrapperClassName
}: Props) => {
    const [selectedPlan, setSelectedPlan] = useState<string>("monthly");
    const onPlanSelect = (tab: string) => setSelectedPlan(tab)
    const headerSameProps = {
        isProTools,
        selectedPlan,
        isShopify: true,
    }

    const shopifyPlans = plans.map((plan) => ({
        type: plan.type,
        planName: plan.name,
        planId: plan.id,
        isDiscount: plan.isDiscount,
        planDescription: plan.description,
        planPrice: plan.price,
        planDiscountPrice: plan.discountedPrice,
        upcampaignPrice: plan.discountedPrice,
        highlight: plan.isYearly,
        skipAmout: plan.skipAndSave,
    }))

    return <div className="w-full dark:text-darkBlue">
        <div className="md:hidden  sticky top-[5px] z-[99]">
            <ComparsionMobileTabs selectedPlan={selectedPlan} onPlanSelect={onPlanSelect} isShopify />
        </div>
        <div className={`flex justify-center w-full  ${wrapperClassName}`}>
            <div className={"max-w-[703px] max-md:max-w-[330px] w-full"}>
                <div className="z-10 overflow-hidden relative z-[20]">
                    <div className={`flex rounded-t-[15px]`}>
                        <div className="bg-transparent max-w-[400px] w-full h-[123px] max-md:hidden" />
                        <ComparisonHeader id="monthly" plan={shopifyPlans[0]} {...headerSameProps} />
                        <ComparisonHeader id="yearly" plan={shopifyPlans[1]} {...headerSameProps} />
                    </div>
                    {shopifyPlanComparison.map((item, i, l) => (
                        <PlansComparisonItem
                            key={i}
                            isFirst={i === 0}
                            showTwoPlans={true}
                            selectedPlan={selectedPlan}
                            item={item}
                            isLast={shopifyPlanComparison.length - 1 === i}
                            isShopify
                        />
                    ))}
                </div>
                {!isProTools && (
                    <PlanComparisonFooter
                        isShopify
                        selectedPlan={selectedPlan}
                        planList={shopifyPlans}
                        isPayNow={isPayNow}
                        handleSelectPlan={handleSelectPlan}
                        fromRegistration={fromRegistration}
                    />
                )}
            </div>
        </div>
    </div>


}

export default ShopifyPlansComparison;