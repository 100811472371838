import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right-extended-bold.svg";
import Einav from "assets/images/einav.png";
import Button from "components/Button";
import ControlledInput from "components/Input/ControlledInput";
import Loading from "components/Loading";
import Logo from "components/Logo";
import { socialIcons } from "components/SocialIcons";
import Toaster from "components/Toast";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import { useRegisterUserMutation } from "store/api/onboardingApi";
import { setUserAccessToken } from "store/slices/userSlice";
import { SetAccessToken, SetUserDetails } from "utils/localStorage";
import { object, string } from "yup";
interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    IsAffiliate: boolean;
}




let registerSchema = object({
    firstName: string().required("First Name is Required"),
    lastName: string().required("Last Name is Required"),
    email: string().required("Email is Required").max(200, "Email is invalid").email("Email is invalid"),
    password: string()
        .required("Password is Required")
        .min(5, "Password must be between 5 and 20 characters!")
        .max(20, "Password must be between 5 and 20 characters!")
});

const inputClassName = "h-14 border-2 px-[17px] max-w-[380px] w-full py-[5px] rounded-[7px] bg-white max-md:h-[48px]";
const inputContClassName = "flex flex-col gap-[10px] w-full"
const labelStyles = "text-[16px] font-[500] leading-[24px] max-md:text-[14px]"


const AffiliateCreateAccount = () => {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [registerUser, { isLoading }] = useRegisterUserMutation();
    const [cookies] = useCookies(['affiliateEmail']);
    const email = cookies.affiliateEmail
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: yupResolver(registerSchema),
    })


    useEffect(() => {
        if (email) {
            setValue("email", email)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    const saveUser = async (values: FormValues) => {
        registerUser(values)
            .unwrap()
            .then((data) => {
                if (data.result.tokens.accessToken) {
                    navigate(PATH.dashboard.myDashboard);
                    SetAccessToken(data.result.tokens.accessToken);
                    SetUserDetails(data.result.userInfo);
                    dispatch(setUserAccessToken(data.result.tokens.accessToken));
                }
            }).catch((error) => {
                toast.error(error.data);
            })
    }
    const handleMouseEnter = (index: number) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const onSubmit: SubmitHandler<FormValues> = async (values) => {
        //if email from cookies is empty, we will need to validate the email and add to potential users
        values.IsAffiliate = true;
        await saveUser(values);
        // if (cookies.rege == null) {
        //     await savePotentialUser({ email: values.email }).unwrap()
        //         .then(async () => {
        //             await saveUser(values);
        //         }).catch((error) => {
        //             toast.error(error.data);
        //         })
        // }
        // else {
        //     await saveUser(values);
        // }
    };

    const isEmailReadOnly = !email || email === null || email?.trim() === "" ? false : true

    return (
        <>
            <div className="relative overflow-hidden bg-gradient-to-b from-neutral2 to-white min-h-[100vh] flex flex-col pt-[64px] max-md:pt-[8px]">
                {/*  <AffiliateVector className="absolute top-[35%] max-xl:top-[30%] -left-[25%] max-lg:hidden" />
                <AffiliateVector className="absolute -top-[22%] -right-[18%] max-lg:hidden" /> */}
                <div className="max-lg:px-[60px] max-sm:px-[30px]">
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        autoComplete="off"
                        className="flex justify-center items-center flex-col mb-[48px] max-w-[1312px] w-full mx-auto max-md:mb-[20px]"
                    >
                        <Logo className="gap-[11px] max-md:gap-[5px]" imageClassName="w-[33px] h-[33px] max-md:w-[14px] max-md:h-[14px]" lettersClassName="w-[64px] h-[27px] max-md:w-[27px] max-md:h-[11px]" />
                        <h4
                            className="text-center mb-[32px] mt-[24px] font-[500] text-[24px] leading-[32px] max-lg:text-[20px] max-lg:leading-[32px] max-md:text-[16px] max-md:leading-[24px] max-md:max-w-[330px] max-md:mt-[8px] max-md:mb-[8px]"
                        >
                            Enter your details to create an Account and
                            Get Insights for Referral Earnings and Statistics
                        </h4>


                        <div className="grid grid-cols-2 gap-x-[64px] gap-y-[32px] max-md:gap-y-[16px] max-md:grid-cols-1 max-md:gap-y-[8px] max-w-[824px] max-md:max-w-[330px] w-full z-10 mb-[32px] max-md:mb-[16px]">
                            <div className={inputContClassName}>
                                <label htmlFor="firstName" className={labelStyles}>First Name</label>
                                <ControlledInput<FormValues>
                                    className={inputClassName}
                                    placeholder="Type your first name"
                                    name="firstName"
                                    register={register}
                                    type="text"
                                    variant="contained"
                                    inputClassName="autofill:bg-transparent"
                                    errors={errors}
                                />
                            </div>
                            <div className={inputContClassName}>
                                <label htmlFor="lastName" className={labelStyles}>Last Name</label>
                                <ControlledInput<FormValues>
                                    className={inputClassName}
                                    placeholder="Type your last name"
                                    name="lastName"
                                    register={register}
                                    type="text"
                                    variant="contained"
                                    inputClassName="autofill:bg-transparent"
                                    errors={errors}
                                />
                            </div>
                            <div className={inputContClassName}>
                                <label htmlFor="email" className={labelStyles}>Email</label>
                                <ControlledInput<FormValues>
                                    className={`${inputClassName} ${isEmailReadOnly ? "!bg-[#e9ecef]" : ""} `}
                                    placeholder="Enter your Email"
                                    name="email"
                                    register={register}
                                    type="text"
                                    variant="contained"
                                    inputClassName="autofill:bg-transparent"
                                    errors={errors}
                                    defaultValue={email}
                                    readOnly={isEmailReadOnly}
                                />
                            </div>

                            <div className={inputContClassName}>
                                <label htmlFor="password" className={labelStyles}>Password</label>
                                <ControlledInput
                                    type="password"
                                    className={inputClassName}
                                    placeholder="Password"
                                    name="password"
                                    register={register}
                                    variant="contained"
                                    togglePassword
                                    inputClassName="autofill:bg-transparent"
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="w-full flex flex-col items-center gap-[16px] max-md:gap-[8px]">
                            <button
                                type="submit"
                                className="flex items-center bg-darkBlue max-w-[380px]  max-lg:max-w-[330px] justify-center w-full h-[64px] rounded-full create-account-submit-btn z-10 group max-md:h-[48px]"
                            >
                                {isLoading ? (
                                    <Loading fill={"fill-white"} />
                                ) : (
                                    <>
                                        <span className="block text-white p-2.5">
                                            Enroll for free
                                        </span>
                                        <ArrowRight className={`fill-smoothGreen dark:fill-paradiseBlue w-[17px] h-[15px] group-hover:ml-[15px] group-hover:mr-[5px] ml-[5px] mr-[15px] transition-all duration-300 arrow-right`} />
                                    </>
                                )}
                            </button>
                            <Button className="max-w-[380px] max-lg:max-w-[330px] h-[64px] max-md:h-[48px] bg-white" titleClassName="!text-[16px]" title="Login" color="smoothGreen" isLink redirect={PATH.login} />
                        </div>

                    </form>

                    <div
                        className="flex justify-center items-center mb-[125px] max-lg:mb-[50px] max-lg:pl-[15px] max-sm:flex-col"
                    >
                        <div className="flex items-center gap-[16px]">
                            <div className="z-50">
                                <div className="border-2 border-solid border-smoothGreen dark:border-paradiseBlue h-[70px] w-[70px] max-sm:h-[60px] max-sm:w-[60px] rounded-full">
                                    <img src={Einav} alt="avatar" width="100%" height="100%" />
                                </div>
                            </div>

                            <div className="flex flex-col gap-[8px]">
                                <div className="max-w-[346px] w-full flex justify-center items-center rounded-[11px] bg-neutral2 px-[24px] py-[10px] max-sm:px-[8px] text-[16px] font-[500] leading-[24px] max-sm:max-w-[238px] max-sm:text-[12px] max-sm:leading-[16px]">
                                    <span>
                                        “Without it, I would not sell anything! ZIK it and it will sell!”
                                    </span>
                                </div>
                                <div className="text-[12px] font-[600] leading-[14px]">
                                    Einav Marila | <span className="font-[400]">eCommerce Seller</span>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>

                <div className="w-full bg-darkBlue mt-auto">
                    <div className="flex justify-center h-[104px] items-center gap-5" >
                        {socialIcons.map((item, index) => (
                            <Link
                                to={item.path}
                                key={item.path}
                                target="_blank"
                                rel="noreferrer"
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                className="flex items-center justify-center w-10 h-10"
                            >
                                {React.cloneElement(item.icon, {
                                    style: { color: hoveredIndex === index ? "#1BCFBA" : "#C8E1FF" }
                                })}

                            </Link>
                        ))}
                    </div>
                </div>
            </div >
            <Toaster />
        </>
    )
}

export default AffiliateCreateAccount