
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as Store } from "assets/icons/store.svg";
import Button from "components/Button";
import Card from "components/Card";
import ControlledInput from "components/Input/ControlledInput";
import Loading from "components/Loading";
import { ErrorCodes } from "config/errorMsgs";
import useDecodeToken from "hooks/useDecodeToken";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import { queryTags } from "store/api/queryTags";
import { storeApi, useAddStoreMutation } from "store/api/storeApi";
import { setUserAccessToken } from "store/slices/userSlice";
import { SetAccessToken } from "utils/localStorage";
import { object, string } from "yup";

interface FormValues {
  storeName: string;
}
let addStoreSchema = object({
  storeName: string().required("eBay ID is required"),
});

interface Props {
  className?: string;
  myDashboardPage?: boolean;
}
const AddStore = ({ myDashboardPage, className }: Props) => {
  const [invalidField, setInvalidField] = useState(false);
  const navigate = useNavigate();
  const userDetails = useDecodeToken();
  const { store } = userDetails;
  const dispatch = useDispatch();
  const [addStore, { isLoading }] = useAddStoreMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(addStoreSchema),
  });

  const clearInputValue = () => {
    setValue("storeName", "");
    setInvalidField(false);
  }


  const getTrimmedData = (obj: any) => {
    if (obj && typeof obj === "object") {
      // eslint-disable-next-line array-callback-return
      Object.keys(obj).map(key => {
        if (typeof obj[key] === "object") {
          getTrimmedData(obj[key]);
        } else if (typeof obj[key] === "string") {
          obj[key] = obj[key].trim();
        }
      });
    }

    return obj;
  };


  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    const vals = getTrimmedData(values);
    const storeName = values.storeName.trim();
    addStore(vals)
      .unwrap()
      .then((data) => {
        if (myDashboardPage) {
          SetAccessToken(data.result.token);
          dispatch(setUserAccessToken(data.result.token));
          dispatch(
            storeApi.util.invalidateTags(
              [queryTags.store.getStores]
            )
          )
        }
        else {
          SetAccessToken(data.result.token);
          dispatch(setUserAccessToken(data.result.token));
          dispatch(
            storeApi.util.invalidateTags(
              [queryTags.store.getStores]
            )
          )
          navigate(`${PATH.store.root}/${storeName}`);
        }
      }).catch((error) => {
        setInvalidField(true)
        if (error.data.message === ErrorCodes.alreadyAddedAsStore) {
          setValue("storeName", "Already added as store")

          toast.error("This store is already added to a different account, please contact our chat support.");
        }
        if (error.data.message === ErrorCodes.storeNotPossible) {
          setValue("storeName", "Not possible")
          toast.error("Due to large volume of items in store we are unable to connect it.Stores with 100000 items or more can't be connected.");
        }
        if (error.data.message === ErrorCodes.storeDoesntExist) {
          setValue("storeName", "Store doesn't exist!")
          toast.error("Oops! eBay store not found. Double-check the name and try again, or contact chat support for help.")
        }

      });
  };

  return (
    <>
      <Card
        className={`${myDashboardPage ? "" : "flex-1"
          } pt-[42px] pb-[49px] px-[25px] dark:text-grey100 ${className || ""}`}
      >
        {isLoading ? (
          <div className="flex items-center justify-center h-[9.7rem]">
            <Loading fill="fill-primaryPurple dark:fill-purple500" height={48} width={48} />
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center">
            {myDashboardPage ? <div className="flex justify-center items-center gap-5 mb-[26px]">
              <p className="font-bold dark:text-grey100">My Store Analytics</p>
              <Store className="fill-smoothGreen dark:fill-grey100 w-[26px] h-5.5" />
            </div> : <div className="flex justify-center items-center gap-[20px] mb-[26px]">
              <Store className="fill-smoothGreen dark:fill-paradiseBlue w-[26px] h-5.5" />
              <p className="font-bold dark:text-grey100">Add Store</p>
            </div>}

            {myDashboardPage ? (
              ""
            ) : store ? (
              <p className="font-medium text-errorRed text-lg">
                You have reached Maximum limit of 1 store per user
              </p>
            ) : (
              <p className="font-medium text-sm">
                Connect your store and analyze its performance
              </p>
            )}
            {store ? (
              ""
            ) : (
              <>
                {myDashboardPage ? <p className="text-center text-base mb-[30px] dark:text-lilac400 font-medium">
                  Connect your Store with ZIK Analytics to analyze your performance.<br />
                  <span className="text-neutral3">(Non API)</span>

                </p> : <p className="font-medium text-neutral3 dark:text-paradiseBlue mb-10 text-center text-sm">
                  Currently supporting eBay only
                </p>}

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={`flex flex-col items-center gap-[25px] justify-center w-full ${myDashboardPage ? "max-w-[219px]" : "max-w-[291px]"}`}
                >
                  <ControlledInput<FormValues>
                    register={register}
                    name="storeName"
                    placeholder="Enter your eBay ID"
                    inputClassName={``}
                    className="w-full h-8 mx-auto w-full"
                    handleChange={() => { if (invalidField) { setInvalidField(false); setValue("storeName", "") } }}
                    errors={errors}
                    isInvalid={invalidField}
                    erase
                    clearInputValue={clearInputValue}
                    getValues={watch}
                  />

                  <Button
                    title={myDashboardPage ? "Save" : "Add Store"}
                    type="submit"
                    loading={isLoading}
                    className={`dark:bg-deepSpace900 w-full m-auto ${myDashboardPage ? "" : "max-w-[164px]"}`}
                    height={myDashboardPage ? "h-9" : "h-11"}
                  />
                </form>
              </>
            )}
          </div>
        )}
      </Card>
    </>
  );
};

export default AddStore;
